import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { IGetTransactionsResponseListData, ITransactionsFilter } from "../api/transactionModels";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import { getTransactionRefundBreakdown } from "redux/actions/reportDetailSectionActions";
import { setTransactionBreakdownsDataFromMap } from "redux/reducers/reportDetailSectionReducer";
import "./transactionRefundBreakdown.scss";
import { Currency, formatter } from "utils/formatter";
import Skeleton from "react-loading-skeleton";
import classNames from "classnames";
import { clearActiveRow, setQueryFilters } from "redux/reducers/reportReducer";
import { nameof } from "ts-simple-nameof";
import { TransactionType } from "utils/enums";

const TransactionRefundBreakdown = () => {
    const activeRowData: IGetTransactionsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const transactionRefundBreakdownMap = useSelector(
        (state: RootState) => state.reportDetailSection.transactionRefundBreakdownMap
    );
    const transactionRefundBreakdown = useSelector(
        (state: RootState) => state.reportDetailSection.transactionRefundBreakdown
    );
    const isLoading = useSelector((state: RootState) => state.reportDetailSection.transactionRefundIsLoading);

    useEffect(() => {
        const request = {
            transactionId: +activeRowData.transactionId,
        };

        if (!activeRowData.refundStatus) {
            dispatch(setTransactionBreakdownsDataFromMap({ refundRequests: [], transactionId: request.transactionId }));
            return;
        }

        const transactionRefundRequests = transactionRefundBreakdownMap.find(
            x => x.transactionId === request.transactionId
        );

        if (transactionRefundRequests === undefined) {
            dispatch(getTransactionRefundBreakdown(request));
        } else {
            dispatch(setTransactionBreakdownsDataFromMap(transactionRefundRequests));
        }
    }, [activeRowData]);

    const onRedirectToTransaction = async (transactionArn: string | null): Promise<void> => {
        if (!transactionArn) {
            return;
        }

        await dispatch(
            setQueryFilters({
                [nameof<ITransactionsFilter>(x => x.searchString)]: transactionArn,
            })
        );

        await dispatch(clearActiveRow());
    };

    if (activeRowData.transactionType.value === TransactionType.Refund) {
        if (!activeRowData.referencedTransactionArn) {
            return <></>;
        }

        return (
            <div className="transaction-refund-breakdown">
                <div className="transaction-refund-breakdown__original">
                    <span className="transaction-refund-breakdown__original-title">
                        {`${t(StringResources.pages.transactions.transactionRefundBreakdown.originalTransaction)}`}
                    </span>
                    <span
                        className="transaction-refund-breakdown__original-data"
                        onClick={() => onRedirectToTransaction(activeRowData.referencedTransactionArn)}
                    >
                        {activeRowData.referencedTransactionArn}
                    </span>
                </div>
            </div>
        );
    }

    return (
        <div className="transaction-refund-breakdown">
            <div className="transaction-refund-breakdown__header">
                <div className="transaction-refund-breakdown__header__title">
                    {`${t(StringResources.pages.transactions.transactionRefundBreakdown.title)}`}
                </div>
                {/* <div className="transaction-refund-breakdown__header__link">
                    {`${t(StringResources.pages.transactions.transactionRefundBreakdown.viewRefunds)}`}
                </div> */}
            </div>

            {isLoading && (
                <div className="transaction-refund-breakdown__content">
                    <TransactionRefundHeader
                        refundStatus={activeRowData.refundStatus}
                        refundAmount={activeRowData.refundAmount}
                        currencyId={activeRowData.currencyId}
                        totalAmount={activeRowData.grossAmount}
                    />
                    <div className="transaction-refund-breakdown__content__body">
                        {[1].map(x => {
                            return (
                                <div key={x} className="transaction-refund-breakdown__content__body-item">
                                    <Skeleton width={100} />
                                    <Skeleton width={100} />
                                    <Skeleton width={100} />
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}

            {!isLoading && transactionRefundBreakdown.length === 0 && (
                <div className="transaction-refund-breakdown__content">
                    <div className="transaction-refund-breakdown__content__title">{activeRowData.refundStatus}</div>
                    <span className="transaction-refund-breakdown__content__no-records">
                        {`${t(StringResources.pages.transactions.transactionRefundBreakdown.noRecords)}`}
                    </span>
                </div>
            )}

            {!isLoading && transactionRefundBreakdown.length > 0 && (
                <div className="transaction-refund-breakdown__content">
                    <TransactionRefundHeader
                        refundStatus={activeRowData.refundStatus}
                        refundAmount={activeRowData.refundAmount}
                        currencyId={activeRowData.currencyId}
                        totalAmount={activeRowData.grossAmount}
                    />
                    <div className="transaction-refund-breakdown__content__body">
                        <div className="transaction-refund-breakdown__content__body__header">
                            <div>{`${t(StringResources.pages.transactions.transactionRefundBreakdown.date)}`}</div>
                            <div>{`${t(StringResources.pages.transactions.transactionRefundBreakdown.user)}`}</div>
                            <div>{`${t(StringResources.pages.transactions.transactionRefundBreakdown.amount)}`}</div>
                        </div>
                        {transactionRefundBreakdown.map(x => {
                            return (
                                <div key={x.refundTime} className="transaction-refund-breakdown__content__body-item">
                                    <div>{formatter(x.refundTime, "date")}</div>
                                    <div>{x.username}</div>
                                    <div
                                        className={classNames(
                                            "transaction-refund-breakdown__content__body-item--amount",
                                            {
                                                "transaction-refund-breakdown__content__body-item--amount-link":
                                                    x.refundTransactionArn !== null,
                                            }
                                        )}
                                        onClick={() => onRedirectToTransaction(x.refundTransactionArn)}
                                    >
                                        -
                                        {formatter(x.refundAmount, activeRowData.currencyId, {
                                            formatOptions: activeRowData.currencyId === "ISK" ? "Symbol" : "Code",
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </div>
    );
};

const TransactionRefundHeader = ({
    refundStatus,
    refundAmount,
    currencyId,
    totalAmount,
}: {
    refundStatus: string;
    refundAmount: number | null;
    currencyId: Currency;
    totalAmount: number;
}) => {
    const { t } = useTranslation();

    return (
        <div className="transaction-refund-breakdown__content__title">
            {refundStatus}{" "}
            {refundAmount !== null && refundAmount > 0 && (
                <span className="transaction-refund-breakdown__content__title-note">
                    (
                    {`${t(StringResources.pages.transactions.transactionRefundBreakdown.refundedOf, {
                        amount: formatter(refundAmount, currencyId, {
                            formatOptions: currencyId === "ISK" ? "Symbol" : "Code",
                        }),
                        totalAmount: formatter(totalAmount, currencyId, {
                            formatOptions: currencyId === "ISK" ? "Symbol" : "Code",
                        }),
                    })}`}
                    )
                </span>
            )}
        </div>
    );
};

export default TransactionRefundBreakdown;
