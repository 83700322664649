import { createAsyncThunk } from "@reduxjs/toolkit";
import { request } from "http";
import {
    IGetBatchDetailsRequest,
    IGetBatchDetailsResponse,
    IPaymentGetSettlementBreakdownRequest,
    ISettlementTransactionBatchesRequest,
    ITransactionRefundBreakdownRequest,
} from "redux/models/reportDetailsSectionModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const getTransactionBatchesInSettlement = createAsyncThunk<any, ISettlementTransactionBatchesRequest>(
    "settlement/getTransactionBatchesInSettlement",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: `/api/settlements/get-transaction-batches-in-settlement`,
            method: "GET",
            params: request,
        });
    }
);

export const paymentSettlementBreakdownGet = createAsyncThunk<any, IPaymentGetSettlementBreakdownRequest>(
    "payment/getPaymentSettlementBreakdown",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: `/api/payments/get-payment-settlements-breakdown`,
            method: "GET",
            params: request,
        });
    }
);

export const getTransactionRefundBreakdown = createAsyncThunk<any, ITransactionRefundBreakdownRequest>(
    "transaction/getTransactionRefundBreakdown",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: `/api/transactions/get-transaction-refund-breakdown`,
            method: "GET",
            params: request,
        });
    }
);

export const getBatchDetails = createAsyncThunk<any, IGetBatchDetailsRequest>(
    "batches/getBatchDetails",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `/api/batches/batch-details`,
            method: "GET",
            params: {
                Id: request.id,
            },
        });

        return {
            batchNumber: request.batchNumber,
            data: response.data,
        };
    }
);
