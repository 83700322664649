import { IconSVGProps } from "./types";

const DotsIcon = ({ height = 24, width = 24 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 22.125C10.5502 22.125 9.375 20.9484 9.375 19.5C9.375 18.0516 10.5502 16.875 12 16.875C13.4498 16.875 14.625 18.0516 14.625 19.5C14.625 20.9484 13.4498 22.125 12 22.125ZM12 7.125C10.5502 7.125 9.375 5.94844 9.375 4.5C9.375 3.05016 10.5502 1.875 12 1.875C13.4498 1.875 14.625 3.05016 14.625 4.5C14.625 5.94844 13.4498 7.125 12 7.125Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M12 9.375C13.4498 9.375 14.625 10.5516 14.625 12C14.625 13.4484 13.4498 14.625 12 14.625C10.5502 14.625 9.375 13.4484 9.375 12C9.375 10.5516 10.5502 9.375 12 9.375Z"
            fill="#002649"
        />
    </svg>
);

export default DotsIcon;
