import { IconSVGProps } from "./types";

const SettingsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.3711 6.50781C19.5 6.84375 19.3906 7.22266 19.125 7.46875L17.4336 9.00781C17.4765 9.33203 17.5 9.66406 17.5 9.96484C17.5 10.3359 17.4765 10.668 17.4336 10.9922L19.125 12.5312C19.3906 12.7773 19.5 13.1523 19.3711 13.4922C19.1992 13.957 18.9922 14.4063 18.7578 14.832L18.5742 15.1484C18.3164 15.5781 18.0273 15.9844 17.7109 16.3711C17.4765 16.6484 17.0976 16.7461 16.7539 16.6367L14.5781 15.9414C14.0547 16.3438 13.4414 16.6797 12.8594 16.9375L12.3711 19.168C12.293 19.5195 12.0195 19.7695 11.6601 19.8633C11.1211 19.9531 10.5664 20 9.96483 20C9.43358 20 8.87889 19.9531 8.33983 19.8633C7.98045 19.7695 7.70701 19.5195 7.62889 19.168L7.14061 16.9375C6.52342 16.6797 5.9453 16.3438 5.42186 15.9414L3.24764 16.6367C2.90233 16.7461 2.52147 16.6484 2.29022 16.3711C1.97342 15.9844 1.68436 15.5781 1.42655 15.1484L1.24374 14.832C1.00663 14.4063 0.800377 13.957 0.62733 13.4922C0.500767 13.1562 0.607408 12.7773 0.875377 12.5312L2.56522 10.9922C2.52225 10.668 2.49999 10.3359 2.49999 10C2.49999 9.66406 2.52225 9.33203 2.56522 9.00781L0.875377 7.46875C0.607408 7.22266 0.500767 6.84766 0.62733 6.50781C0.800377 6.04297 1.00702 5.59375 1.24374 5.16797L1.42616 4.85156C1.68397 4.42187 1.97342 4.01562 2.29022 3.63086C2.52147 3.35156 2.90233 3.25469 3.24764 3.36484L5.42186 4.05859C5.9453 3.65469 6.52342 3.31875 7.14061 3.06367L7.62889 0.833203C7.70701 0.478516 7.98045 0.196875 8.33983 0.137109C8.87889 0.0469141 9.43358 0 9.96483 0C10.5664 0 11.1211 0.0469141 11.6601 0.137109C12.0195 0.196875 12.293 0.478516 12.3711 0.833203L12.8594 3.06367C13.4414 3.31875 14.0547 3.65469 14.5781 4.05859L16.7539 3.36484C17.0976 3.25469 17.4765 3.35156 17.7109 3.63086C18.0273 4.01562 18.3164 4.42187 18.5742 4.85156L18.7578 5.16797C18.9922 5.59375 19.1992 6.04297 19.3711 6.50781ZM9.99998 13.125C11.7265 13.125 13.125 11.7266 13.125 9.96484C13.125 8.27344 11.7265 6.83984 9.99998 6.83984C8.27342 6.83984 6.87498 8.27344 6.87498 9.96484C6.87498 11.7266 8.27342 13.125 9.99998 13.125Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M11.875 10C11.875 11.0352 11.0352 11.875 10 11.875C8.96484 11.875 8.125 11.0352 8.125 10C8.125 8.96484 8.96484 8.125 10 8.125C11.0352 8.125 11.875 8.96484 11.875 10Z"
            fill="#002649"
        />
    </svg>
);

export default SettingsIcon;
