import { IconSVGProps } from "./types";

const AgreementsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.5 0V5H17.5L12.5 0ZM13.125 15H6.875C6.53125 15 6.25 15.2812 6.25 15.625C6.25 15.9688 6.53125 16.25 6.875 16.25H13.125C13.4702 16.25 13.75 15.9702 13.75 15.625C13.75 15.2798 13.4688 15 13.125 15ZM13.125 12.5H6.875C6.53125 12.5 6.25 12.7812 6.25 13.125C6.25 13.4688 6.53125 13.75 6.875 13.75H13.125C13.4702 13.75 13.75 13.4702 13.75 13.125C13.75 12.7798 13.4688 12.5 13.125 12.5ZM13.125 10H6.875C6.53125 10 6.25 10.2812 6.25 10.625C6.25 10.9688 6.53125 11.25 6.875 11.25H13.125C13.4688 11.25 13.75 10.9688 13.75 10.625C13.75 10.2812 13.4688 10 13.125 10Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M12.5 5V0H4.375C3.33945 0 2.5 0.839453 2.5 1.875V18.125C2.5 19.1602 3.33945 20 4.375 20H15.625C16.6605 20 17.5 19.1605 17.5 18.125V5H12.5ZM13.125 16.25H6.875C6.53125 16.25 6.25 15.9688 6.25 15.625C6.25 15.2812 6.53125 15 6.875 15H13.125C13.4702 15 13.75 15.2798 13.75 15.625C13.75 15.9702 13.4688 16.25 13.125 16.25ZM13.125 13.75H6.875C6.53125 13.75 6.25 13.4688 6.25 13.125C6.25 12.7812 6.53125 12.5 6.875 12.5H13.125C13.4702 12.5 13.75 12.7798 13.75 13.125C13.75 13.4702 13.4688 13.75 13.125 13.75ZM13.125 11.25H6.875C6.53125 11.25 6.25 10.9688 6.25 10.625C6.25 10.2812 6.53125 10 6.875 10H13.125C13.4688 10 13.75 10.2812 13.75 10.625C13.75 10.9688 13.4688 11.25 13.125 11.25Z"
            fill="#002649"
        />
    </svg>
);

export default AgreementsIcon;
