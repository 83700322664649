import BaseButton from "components/baseButton/baseButton";
import { DownloadIcon } from "components/icons";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import { saveFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import { getPaymentsDetailsReport } from "../api/paymentsApi";
import { IGetPaymentsResponseListData } from "../api/paymentsModels";
import "./paymentsHeader.scss";

const PaymentsHeader = () => {
    const activeRowData: IGetPaymentsResponseListData = useSelector((state: RootState) => state.report.activeRow);

    if (!activeRowData) {
        return <></>;
    }

    const handlePrintClick = async () => {
        try {
            const response = await getPaymentsDetailsReport({ paymentId: activeRowData.id });
            saveFile(response.data, response.filename, response.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.payments.printReportError).toString());
        }
    };

    return (
        <div className="payments-header report-details__title">
            <div className="report-details__title--label">{`${t(StringResources.pages.payments.title)}`}</div>
            <div className="report-details__title--action">
                <BaseButton
                    handleClick={handlePrintClick}
                    text={`${t(StringResources.pages.settlements.print)}`}
                    styleType="text"
                    leftIcon={<DownloadIcon />}
                    size="small"
                />
            </div>
        </div>
    );
};

export default PaymentsHeader;
