import { IconSVGProps } from "./types";

const KnightIcon = ({ height = 289, width = 178, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3759_9956)">
            <path
                d="M53.9279 7.46219C55.9804 7.46219 57.6443 5.79828 57.6443 3.74574C57.6443 1.69321 55.9804 0.0292969 53.9279 0.0292969C51.8753 0.0292969 50.2114 1.69321 50.2114 3.74574C50.2114 5.79828 51.8753 7.46219 53.9279 7.46219Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 17.4915C55.9804 17.4915 57.6443 15.8276 57.6443 13.775C57.6443 11.7225 55.9804 10.0586 53.9279 10.0586C51.8753 10.0586 50.2114 11.7225 50.2114 13.775C50.2114 15.8276 51.8753 17.4915 53.9279 17.4915Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 17.4915C65.9706 17.4915 67.6345 15.8276 67.6345 13.775C67.6345 11.7225 65.9706 10.0586 63.9181 10.0586C61.8656 10.0586 60.2017 11.7225 60.2017 13.775C60.2017 15.8276 61.8656 17.4915 63.9181 17.4915Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 27.5188C55.9804 27.5188 57.6443 25.8549 57.6443 23.8024C57.6443 21.7498 55.9804 20.0859 53.9279 20.0859C51.8753 20.0859 50.2114 21.7498 50.2114 23.8024C50.2114 25.8549 51.8753 27.5188 53.9279 27.5188Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 27.5188C65.9706 27.5188 67.6345 25.8549 67.6345 23.8024C67.6345 21.7498 65.9706 20.0859 63.9181 20.0859C61.8656 20.0859 60.2017 21.7498 60.2017 23.8024C60.2017 25.8549 61.8656 27.5188 63.9181 27.5188Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 27.5188C75.9565 27.5188 77.6204 25.8549 77.6204 23.8024C77.6204 21.7498 75.9565 20.0859 73.9039 20.0859C71.8514 20.0859 70.1875 21.7498 70.1875 23.8024C70.1875 25.8549 71.8514 27.5188 73.9039 27.5188Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 37.5501C36.0048 37.5501 37.6687 35.8862 37.6687 33.8336C37.6687 31.7811 36.0048 30.1172 33.9523 30.1172C31.8998 30.1172 30.2358 31.7811 30.2358 33.8336C30.2358 35.8862 31.8998 37.5501 33.9523 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 37.5501C45.9951 37.5501 47.659 35.8862 47.659 33.8336C47.659 31.7811 45.9951 30.1172 43.9425 30.1172C41.89 30.1172 40.2261 31.7811 40.2261 33.8336C40.2261 35.8862 41.89 37.5501 43.9425 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 37.5501C55.9804 37.5501 57.6443 35.8862 57.6443 33.8336C57.6443 31.7811 55.9804 30.1172 53.9279 30.1172C51.8753 30.1172 50.2114 31.7811 50.2114 33.8336C50.2114 35.8862 51.8753 37.5501 53.9279 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 37.5501C65.9706 37.5501 67.6345 35.8862 67.6345 33.8336C67.6345 31.7811 65.9706 30.1172 63.9181 30.1172C61.8656 30.1172 60.2017 31.7811 60.2017 33.8336C60.2017 35.8862 61.8656 37.5501 63.9181 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 37.5501C75.9565 37.5501 77.6204 35.8862 77.6204 33.8336C77.6204 31.7811 75.9565 30.1172 73.9039 30.1172C71.8514 30.1172 70.1875 31.7811 70.1875 33.8336C70.1875 35.8862 71.8514 37.5501 73.9039 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 37.5501C85.9467 37.5501 87.6106 35.8862 87.6106 33.8336C87.6106 31.7811 85.9467 30.1172 83.8942 30.1172C81.8416 30.1172 80.1777 31.7811 80.1777 33.8336C80.1777 35.8862 81.8416 37.5501 83.8942 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 37.5501C95.9326 37.5501 97.5965 35.8862 97.5965 33.8336C97.5965 31.7811 95.9326 30.1172 93.88 30.1172C91.8275 30.1172 90.1636 31.7811 90.1636 33.8336C90.1636 35.8862 91.8275 37.5501 93.88 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 37.5501C105.922 37.5501 107.586 35.8862 107.586 33.8336C107.586 31.7811 105.922 30.1172 103.87 30.1172C101.817 30.1172 100.153 31.7811 100.153 33.8336C100.153 35.8862 101.817 37.5501 103.87 37.5501Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 47.5774C26.019 47.5774 27.6829 45.9135 27.6829 43.861C27.6829 41.8084 26.019 40.1445 23.9664 40.1445C21.9139 40.1445 20.25 41.8084 20.25 43.861C20.25 45.9135 21.9139 47.5774 23.9664 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 47.5774C36.0048 47.5774 37.6687 45.9135 37.6687 43.861C37.6687 41.8084 36.0048 40.1445 33.9523 40.1445C31.8998 40.1445 30.2358 41.8084 30.2358 43.861C30.2358 45.9135 31.8998 47.5774 33.9523 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 47.5774C45.9951 47.5774 47.659 45.9135 47.659 43.861C47.659 41.8084 45.9951 40.1445 43.9425 40.1445C41.89 40.1445 40.2261 41.8084 40.2261 43.861C40.2261 45.9135 41.89 47.5774 43.9425 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 47.5774C55.9804 47.5774 57.6443 45.9135 57.6443 43.861C57.6443 41.8084 55.9804 40.1445 53.9279 40.1445C51.8753 40.1445 50.2114 41.8084 50.2114 43.861C50.2114 45.9135 51.8753 47.5774 53.9279 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 47.5774C65.9706 47.5774 67.6345 45.9135 67.6345 43.861C67.6345 41.8084 65.9706 40.1445 63.9181 40.1445C61.8656 40.1445 60.2017 41.8084 60.2017 43.861C60.2017 45.9135 61.8656 47.5774 63.9181 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 47.5774C75.9565 47.5774 77.6204 45.9135 77.6204 43.861C77.6204 41.8084 75.9565 40.1445 73.9039 40.1445C71.8514 40.1445 70.1875 41.8084 70.1875 43.861C70.1875 45.9135 71.8514 47.5774 73.9039 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 47.5774C85.9467 47.5774 87.6106 45.9135 87.6106 43.861C87.6106 41.8084 85.9467 40.1445 83.8942 40.1445C81.8416 40.1445 80.1777 41.8084 80.1777 43.861C80.1777 45.9135 81.8416 47.5774 83.8942 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 47.5774C95.9326 47.5774 97.5965 45.9135 97.5965 43.861C97.5965 41.8084 95.9326 40.1445 93.88 40.1445C91.8275 40.1445 90.1636 41.8084 90.1636 43.861C90.1636 45.9135 91.8275 47.5774 93.88 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 47.5774C105.922 47.5774 107.586 45.9135 107.586 43.861C107.586 41.8084 105.922 40.1445 103.87 40.1445C101.817 40.1445 100.153 41.8084 100.153 43.861C100.153 45.9135 101.817 47.5774 103.87 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 47.5774C115.908 47.5774 117.572 45.9135 117.572 43.861C117.572 41.8084 115.908 40.1445 113.856 40.1445C111.803 40.1445 110.139 41.8084 110.139 43.861C110.139 45.9135 111.803 47.5774 113.856 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 47.5774C125.898 47.5774 127.562 45.9135 127.562 43.861C127.562 41.8084 125.898 40.1445 123.846 40.1445C121.793 40.1445 120.129 41.8084 120.129 43.861C120.129 45.9135 121.793 47.5774 123.846 47.5774Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 57.6048C26.019 57.6048 27.6829 55.9409 27.6829 53.8883C27.6829 51.8358 26.019 50.1719 23.9664 50.1719C21.9139 50.1719 20.25 51.8358 20.25 53.8883C20.25 55.9409 21.9139 57.6048 23.9664 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 57.6048C36.0048 57.6048 37.6687 55.9409 37.6687 53.8883C37.6687 51.8358 36.0048 50.1719 33.9523 50.1719C31.8998 50.1719 30.2358 51.8358 30.2358 53.8883C30.2358 55.9409 31.8998 57.6048 33.9523 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 57.6048C45.9951 57.6048 47.659 55.9409 47.659 53.8883C47.659 51.8358 45.9951 50.1719 43.9425 50.1719C41.89 50.1719 40.2261 51.8358 40.2261 53.8883C40.2261 55.9409 41.89 57.6048 43.9425 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 57.6048C55.9804 57.6048 57.6443 55.9409 57.6443 53.8883C57.6443 51.8358 55.9804 50.1719 53.9279 50.1719C51.8753 50.1719 50.2114 51.8358 50.2114 53.8883C50.2114 55.9409 51.8753 57.6048 53.9279 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 57.6048C65.9706 57.6048 67.6345 55.9409 67.6345 53.8883C67.6345 51.8358 65.9706 50.1719 63.9181 50.1719C61.8656 50.1719 60.2017 51.8358 60.2017 53.8883C60.2017 55.9409 61.8656 57.6048 63.9181 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 57.6048C75.9565 57.6048 77.6204 55.9409 77.6204 53.8883C77.6204 51.8358 75.9565 50.1719 73.9039 50.1719C71.8514 50.1719 70.1875 51.8358 70.1875 53.8883C70.1875 55.9409 71.8514 57.6048 73.9039 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 57.6048C85.9467 57.6048 87.6106 55.9409 87.6106 53.8883C87.6106 51.8358 85.9467 50.1719 83.8942 50.1719C81.8416 50.1719 80.1777 51.8358 80.1777 53.8883C80.1777 55.9409 81.8416 57.6048 83.8942 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 57.6048C95.9326 57.6048 97.5965 55.9409 97.5965 53.8883C97.5965 51.8358 95.9326 50.1719 93.88 50.1719C91.8275 50.1719 90.1636 51.8358 90.1636 53.8883C90.1636 55.9409 91.8275 57.6048 93.88 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 57.6048C105.922 57.6048 107.586 55.9409 107.586 53.8883C107.586 51.8358 105.922 50.1719 103.87 50.1719C101.817 50.1719 100.153 51.8358 100.153 53.8883C100.153 55.9409 101.817 57.6048 103.87 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 57.6048C115.908 57.6048 117.572 55.9409 117.572 53.8883C117.572 51.8358 115.908 50.1719 113.856 50.1719C111.803 50.1719 110.139 51.8358 110.139 53.8883C110.139 55.9409 111.803 57.6048 113.856 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 57.6048C125.898 57.6048 127.562 55.9409 127.562 53.8883C127.562 51.8358 125.898 50.1719 123.846 50.1719C121.793 50.1719 120.129 51.8358 120.129 53.8883C120.129 55.9409 121.793 57.6048 123.846 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 57.6048C135.884 57.6048 137.548 55.9409 137.548 53.8883C137.548 51.8358 135.884 50.1719 133.832 50.1719C131.779 50.1719 130.115 51.8358 130.115 53.8883C130.115 55.9409 131.779 57.6048 133.832 57.6048Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 67.6321C26.019 67.6321 27.6829 65.9682 27.6829 63.9157C27.6829 61.8631 26.019 60.1992 23.9664 60.1992C21.9139 60.1992 20.25 61.8631 20.25 63.9157C20.25 65.9682 21.9139 67.6321 23.9664 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 67.6321C36.0048 67.6321 37.6687 65.9682 37.6687 63.9157C37.6687 61.8631 36.0048 60.1992 33.9523 60.1992C31.8998 60.1992 30.2358 61.8631 30.2358 63.9157C30.2358 65.9682 31.8998 67.6321 33.9523 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 67.6321C45.9951 67.6321 47.659 65.9682 47.659 63.9157C47.659 61.8631 45.9951 60.1992 43.9425 60.1992C41.89 60.1992 40.2261 61.8631 40.2261 63.9157C40.2261 65.9682 41.89 67.6321 43.9425 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 67.6321C55.9804 67.6321 57.6443 65.9682 57.6443 63.9157C57.6443 61.8631 55.9804 60.1992 53.9279 60.1992C51.8753 60.1992 50.2114 61.8631 50.2114 63.9157C50.2114 65.9682 51.8753 67.6321 53.9279 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 67.6321C65.9706 67.6321 67.6345 65.9682 67.6345 63.9157C67.6345 61.8631 65.9706 60.1992 63.9181 60.1992C61.8656 60.1992 60.2017 61.8631 60.2017 63.9157C60.2017 65.9682 61.8656 67.6321 63.9181 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 67.6321C75.9565 67.6321 77.6204 65.9682 77.6204 63.9157C77.6204 61.8631 75.9565 60.1992 73.9039 60.1992C71.8514 60.1992 70.1875 61.8631 70.1875 63.9157C70.1875 65.9682 71.8514 67.6321 73.9039 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 67.6321C85.9467 67.6321 87.6106 65.9682 87.6106 63.9157C87.6106 61.8631 85.9467 60.1992 83.8942 60.1992C81.8416 60.1992 80.1777 61.8631 80.1777 63.9157C80.1777 65.9682 81.8416 67.6321 83.8942 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 67.6321C95.9326 67.6321 97.5965 65.9682 97.5965 63.9157C97.5965 61.8631 95.9326 60.1992 93.88 60.1992C91.8275 60.1992 90.1636 61.8631 90.1636 63.9157C90.1636 65.9682 91.8275 67.6321 93.88 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 67.6321C105.922 67.6321 107.586 65.9682 107.586 63.9157C107.586 61.8631 105.922 60.1992 103.87 60.1992C101.817 60.1992 100.153 61.8631 100.153 63.9157C100.153 65.9682 101.817 67.6321 103.87 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 67.6321C115.908 67.6321 117.572 65.9682 117.572 63.9157C117.572 61.8631 115.908 60.1992 113.856 60.1992C111.803 60.1992 110.139 61.8631 110.139 63.9157C110.139 65.9682 111.803 67.6321 113.856 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 67.6321C125.898 67.6321 127.562 65.9682 127.562 63.9157C127.562 61.8631 125.898 60.1992 123.846 60.1992C121.793 60.1992 120.129 61.8631 120.129 63.9157C120.129 65.9682 121.793 67.6321 123.846 67.6321Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 77.6653C26.019 77.6653 27.6829 76.0014 27.6829 73.9489C27.6829 71.8963 26.019 70.2324 23.9664 70.2324C21.9139 70.2324 20.25 71.8963 20.25 73.9489C20.25 76.0014 21.9139 77.6653 23.9664 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 77.6653C36.0048 77.6653 37.6687 76.0014 37.6687 73.9489C37.6687 71.8963 36.0048 70.2324 33.9523 70.2324C31.8998 70.2324 30.2358 71.8963 30.2358 73.9489C30.2358 76.0014 31.8998 77.6653 33.9523 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 77.6653C45.9951 77.6653 47.659 76.0014 47.659 73.9489C47.659 71.8963 45.9951 70.2324 43.9425 70.2324C41.89 70.2324 40.2261 71.8963 40.2261 73.9489C40.2261 76.0014 41.89 77.6653 43.9425 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 77.6653C55.9804 77.6653 57.6443 76.0014 57.6443 73.9489C57.6443 71.8963 55.9804 70.2324 53.9279 70.2324C51.8753 70.2324 50.2114 71.8963 50.2114 73.9489C50.2114 76.0014 51.8753 77.6653 53.9279 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 77.6653C65.9706 77.6653 67.6345 76.0014 67.6345 73.9489C67.6345 71.8963 65.9706 70.2324 63.9181 70.2324C61.8656 70.2324 60.2017 71.8963 60.2017 73.9489C60.2017 76.0014 61.8656 77.6653 63.9181 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 77.6653C75.9565 77.6653 77.6204 76.0014 77.6204 73.9489C77.6204 71.8963 75.9565 70.2324 73.9039 70.2324C71.8514 70.2324 70.1875 71.8963 70.1875 73.9489C70.1875 76.0014 71.8514 77.6653 73.9039 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 77.6653C85.9467 77.6653 87.6106 76.0014 87.6106 73.9489C87.6106 71.8963 85.9467 70.2324 83.8942 70.2324C81.8416 70.2324 80.1777 71.8963 80.1777 73.9489C80.1777 76.0014 81.8416 77.6653 83.8942 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 77.6653C95.9326 77.6653 97.5965 76.0014 97.5965 73.9489C97.5965 71.8963 95.9326 70.2324 93.88 70.2324C91.8275 70.2324 90.1636 71.8963 90.1636 73.9489C90.1636 76.0014 91.8275 77.6653 93.88 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 77.6653C105.922 77.6653 107.586 76.0014 107.586 73.9489C107.586 71.8963 105.922 70.2324 103.87 70.2324C101.817 70.2324 100.153 71.8963 100.153 73.9489C100.153 76.0014 101.817 77.6653 103.87 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 77.6653C115.908 77.6653 117.572 76.0014 117.572 73.9489C117.572 71.8963 115.908 70.2324 113.856 70.2324C111.803 70.2324 110.139 71.8963 110.139 73.9489C110.139 76.0014 111.803 77.6653 113.856 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 77.6653C125.898 77.6653 127.562 76.0014 127.562 73.9489C127.562 71.8963 125.898 70.2324 123.846 70.2324C121.793 70.2324 120.129 71.8963 120.129 73.9489C120.129 76.0014 121.793 77.6653 123.846 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 77.6653C135.884 77.6653 137.548 76.0014 137.548 73.9489C137.548 71.8963 135.884 70.2324 133.832 70.2324C131.779 70.2324 130.115 71.8963 130.115 73.9489C130.115 76.0014 131.779 77.6653 133.832 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 77.6653C145.874 77.6653 147.538 76.0014 147.538 73.9489C147.538 71.8963 145.874 70.2324 143.822 70.2324C141.769 70.2324 140.105 71.8963 140.105 73.9489C140.105 76.0014 141.769 77.6653 143.822 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 77.6653C155.86 77.6653 157.524 76.0014 157.524 73.9489C157.524 71.8963 155.86 70.2324 153.808 70.2324C151.755 70.2324 150.091 71.8963 150.091 73.9489C150.091 76.0014 151.755 77.6653 153.808 77.6653Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 87.6927C16.0287 87.6927 17.6927 86.0287 17.6927 83.9762C17.6927 81.9237 16.0287 80.2598 13.9762 80.2598C11.9237 80.2598 10.2598 81.9237 10.2598 83.9762C10.2598 86.0287 11.9237 87.6927 13.9762 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 87.6927C26.019 87.6927 27.6829 86.0287 27.6829 83.9762C27.6829 81.9237 26.019 80.2598 23.9664 80.2598C21.9139 80.2598 20.25 81.9237 20.25 83.9762C20.25 86.0287 21.9139 87.6927 23.9664 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 87.6927C36.0048 87.6927 37.6687 86.0287 37.6687 83.9762C37.6687 81.9237 36.0048 80.2598 33.9523 80.2598C31.8998 80.2598 30.2358 81.9237 30.2358 83.9762C30.2358 86.0287 31.8998 87.6927 33.9523 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 87.6927C45.9951 87.6927 47.659 86.0287 47.659 83.9762C47.659 81.9237 45.9951 80.2598 43.9425 80.2598C41.89 80.2598 40.2261 81.9237 40.2261 83.9762C40.2261 86.0287 41.89 87.6927 43.9425 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 87.6927C55.9804 87.6927 57.6443 86.0287 57.6443 83.9762C57.6443 81.9237 55.9804 80.2598 53.9279 80.2598C51.8753 80.2598 50.2114 81.9237 50.2114 83.9762C50.2114 86.0287 51.8753 87.6927 53.9279 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 87.6927C65.9706 87.6927 67.6345 86.0287 67.6345 83.9762C67.6345 81.9237 65.9706 80.2598 63.9181 80.2598C61.8656 80.2598 60.2017 81.9237 60.2017 83.9762C60.2017 86.0287 61.8656 87.6927 63.9181 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 87.6927C75.9565 87.6927 77.6204 86.0287 77.6204 83.9762C77.6204 81.9237 75.9565 80.2598 73.9039 80.2598C71.8514 80.2598 70.1875 81.9237 70.1875 83.9762C70.1875 86.0287 71.8514 87.6927 73.9039 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 87.6927C85.9467 87.6927 87.6106 86.0287 87.6106 83.9762C87.6106 81.9237 85.9467 80.2598 83.8942 80.2598C81.8416 80.2598 80.1777 81.9237 80.1777 83.9762C80.1777 86.0287 81.8416 87.6927 83.8942 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 87.6927C95.9326 87.6927 97.5965 86.0287 97.5965 83.9762C97.5965 81.9237 95.9326 80.2598 93.88 80.2598C91.8275 80.2598 90.1636 81.9237 90.1636 83.9762C90.1636 86.0287 91.8275 87.6927 93.88 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 87.6927C105.922 87.6927 107.586 86.0287 107.586 83.9762C107.586 81.9237 105.922 80.2598 103.87 80.2598C101.817 80.2598 100.153 81.9237 100.153 83.9762C100.153 86.0287 101.817 87.6927 103.87 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 87.6927C115.908 87.6927 117.572 86.0287 117.572 83.9762C117.572 81.9237 115.908 80.2598 113.856 80.2598C111.803 80.2598 110.139 81.9237 110.139 83.9762C110.139 86.0287 111.803 87.6927 113.856 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 87.6927C125.898 87.6927 127.562 86.0287 127.562 83.9762C127.562 81.9237 125.898 80.2598 123.846 80.2598C121.793 80.2598 120.129 81.9237 120.129 83.9762C120.129 86.0287 121.793 87.6927 123.846 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 87.6927C135.884 87.6927 137.548 86.0287 137.548 83.9762C137.548 81.9237 135.884 80.2598 133.832 80.2598C131.779 80.2598 130.115 81.9237 130.115 83.9762C130.115 86.0287 131.779 87.6927 133.832 87.6927Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 97.72C16.0287 97.72 17.6927 96.0561 17.6927 94.0035C17.6927 91.951 16.0287 90.2871 13.9762 90.2871C11.9237 90.2871 10.2598 91.951 10.2598 94.0035C10.2598 96.0561 11.9237 97.72 13.9762 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 97.72C26.019 97.72 27.6829 96.0561 27.6829 94.0035C27.6829 91.951 26.019 90.2871 23.9664 90.2871C21.9139 90.2871 20.25 91.951 20.25 94.0035C20.25 96.0561 21.9139 97.72 23.9664 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 97.72C36.0048 97.72 37.6687 96.0561 37.6687 94.0035C37.6687 91.951 36.0048 90.2871 33.9523 90.2871C31.8998 90.2871 30.2358 91.951 30.2358 94.0035C30.2358 96.0561 31.8998 97.72 33.9523 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 97.72C45.9951 97.72 47.659 96.0561 47.659 94.0035C47.659 91.951 45.9951 90.2871 43.9425 90.2871C41.89 90.2871 40.2261 91.951 40.2261 94.0035C40.2261 96.0561 41.89 97.72 43.9425 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 97.72C55.9804 97.72 57.6443 96.0561 57.6443 94.0035C57.6443 91.951 55.9804 90.2871 53.9279 90.2871C51.8753 90.2871 50.2114 91.951 50.2114 94.0035C50.2114 96.0561 51.8753 97.72 53.9279 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 97.72C65.9706 97.72 67.6345 96.0561 67.6345 94.0035C67.6345 91.951 65.9706 90.2871 63.9181 90.2871C61.8656 90.2871 60.2017 91.951 60.2017 94.0035C60.2017 96.0561 61.8656 97.72 63.9181 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 97.72C75.9565 97.72 77.6204 96.0561 77.6204 94.0035C77.6204 91.951 75.9565 90.2871 73.9039 90.2871C71.8514 90.2871 70.1875 91.951 70.1875 94.0035C70.1875 96.0561 71.8514 97.72 73.9039 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 97.72C85.9467 97.72 87.6106 96.0561 87.6106 94.0035C87.6106 91.951 85.9467 90.2871 83.8942 90.2871C81.8416 90.2871 80.1777 91.951 80.1777 94.0035C80.1777 96.0561 81.8416 97.72 83.8942 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 97.72C95.9326 97.72 97.5965 96.0561 97.5965 94.0035C97.5965 91.951 95.9326 90.2871 93.88 90.2871C91.8275 90.2871 90.1636 91.951 90.1636 94.0035C90.1636 96.0561 91.8275 97.72 93.88 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 97.72C105.922 97.72 107.586 96.0561 107.586 94.0035C107.586 91.951 105.922 90.2871 103.87 90.2871C101.817 90.2871 100.153 91.951 100.153 94.0035C100.153 96.0561 101.817 97.72 103.87 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 97.72C115.908 97.72 117.572 96.0561 117.572 94.0035C117.572 91.951 115.908 90.2871 113.856 90.2871C111.803 90.2871 110.139 91.951 110.139 94.0035C110.139 96.0561 111.803 97.72 113.856 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 97.72C125.898 97.72 127.562 96.0561 127.562 94.0035C127.562 91.951 125.898 90.2871 123.846 90.2871C121.793 90.2871 120.129 91.951 120.129 94.0035C120.129 96.0561 121.793 97.72 123.846 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 97.72C135.884 97.72 137.548 96.0561 137.548 94.0035C137.548 91.951 135.884 90.2871 133.832 90.2871C131.779 90.2871 130.115 91.951 130.115 94.0035C130.115 96.0561 131.779 97.72 133.832 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 97.72C145.874 97.72 147.538 96.0561 147.538 94.0035C147.538 91.951 145.874 90.2871 143.822 90.2871C141.769 90.2871 140.105 91.951 140.105 94.0035C140.105 96.0561 141.769 97.72 143.822 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 97.72C155.86 97.72 157.524 96.0561 157.524 94.0035C157.524 91.951 155.86 90.2871 153.808 90.2871C151.755 90.2871 150.091 91.951 150.091 94.0035C150.091 96.0561 151.755 97.72 153.808 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 97.72C165.846 97.72 167.51 96.0561 167.51 94.0035C167.51 91.951 165.846 90.2871 163.794 90.2871C161.741 90.2871 160.077 91.951 160.077 94.0035C160.077 96.0561 161.741 97.72 163.794 97.72Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.99037 107.747C6.04291 107.747 7.70681 106.083 7.70681 104.031C7.70681 101.978 6.04291 100.314 3.99037 100.314C1.93783 100.314 0.273926 101.978 0.273926 104.031C0.273926 106.083 1.93783 107.747 3.99037 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 107.747C16.0287 107.747 17.6927 106.083 17.6927 104.031C17.6927 101.978 16.0287 100.314 13.9762 100.314C11.9237 100.314 10.2598 101.978 10.2598 104.031C10.2598 106.083 11.9237 107.747 13.9762 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 107.747C26.019 107.747 27.6829 106.083 27.6829 104.031C27.6829 101.978 26.019 100.314 23.9664 100.314C21.9139 100.314 20.25 101.978 20.25 104.031C20.25 106.083 21.9139 107.747 23.9664 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 107.747C36.0048 107.747 37.6687 106.083 37.6687 104.031C37.6687 101.978 36.0048 100.314 33.9523 100.314C31.8998 100.314 30.2358 101.978 30.2358 104.031C30.2358 106.083 31.8998 107.747 33.9523 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 107.747C45.9951 107.747 47.659 106.083 47.659 104.031C47.659 101.978 45.9951 100.314 43.9425 100.314C41.89 100.314 40.2261 101.978 40.2261 104.031C40.2261 106.083 41.89 107.747 43.9425 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 107.747C55.9804 107.747 57.6443 106.083 57.6443 104.031C57.6443 101.978 55.9804 100.314 53.9279 100.314C51.8753 100.314 50.2114 101.978 50.2114 104.031C50.2114 106.083 51.8753 107.747 53.9279 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 107.747C65.9706 107.747 67.6345 106.083 67.6345 104.031C67.6345 101.978 65.9706 100.314 63.9181 100.314C61.8656 100.314 60.2017 101.978 60.2017 104.031C60.2017 106.083 61.8656 107.747 63.9181 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 107.747C95.9326 107.747 97.5965 106.083 97.5965 104.031C97.5965 101.978 95.9326 100.314 93.88 100.314C91.8275 100.314 90.1636 101.978 90.1636 104.031C90.1636 106.083 91.8275 107.747 93.88 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 107.747C105.922 107.747 107.586 106.083 107.586 104.031C107.586 101.978 105.922 100.314 103.87 100.314C101.817 100.314 100.153 101.978 100.153 104.031C100.153 106.083 101.817 107.747 103.87 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 107.747C115.908 107.747 117.572 106.083 117.572 104.031C117.572 101.978 115.908 100.314 113.856 100.314C111.803 100.314 110.139 101.978 110.139 104.031C110.139 106.083 111.803 107.747 113.856 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 107.747C125.898 107.747 127.562 106.083 127.562 104.031C127.562 101.978 125.898 100.314 123.846 100.314C121.793 100.314 120.129 101.978 120.129 104.031C120.129 106.083 121.793 107.747 123.846 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 107.747C135.884 107.747 137.548 106.083 137.548 104.031C137.548 101.978 135.884 100.314 133.832 100.314C131.779 100.314 130.115 101.978 130.115 104.031C130.115 106.083 131.779 107.747 133.832 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 107.747C145.874 107.747 147.538 106.083 147.538 104.031C147.538 101.978 145.874 100.314 143.822 100.314C141.769 100.314 140.105 101.978 140.105 104.031C140.105 106.083 141.769 107.747 143.822 107.747Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.99037 117.781C6.04291 117.781 7.70681 116.117 7.70681 114.064C7.70681 112.012 6.04291 110.348 3.99037 110.348C1.93783 110.348 0.273926 112.012 0.273926 114.064C0.273926 116.117 1.93783 117.781 3.99037 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 117.781C16.0287 117.781 17.6927 116.117 17.6927 114.064C17.6927 112.012 16.0287 110.348 13.9762 110.348C11.9237 110.348 10.2598 112.012 10.2598 114.064C10.2598 116.117 11.9237 117.781 13.9762 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 117.781C26.019 117.781 27.6829 116.117 27.6829 114.064C27.6829 112.012 26.019 110.348 23.9664 110.348C21.9139 110.348 20.25 112.012 20.25 114.064C20.25 116.117 21.9139 117.781 23.9664 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 117.781C36.0048 117.781 37.6687 116.117 37.6687 114.064C37.6687 112.012 36.0048 110.348 33.9523 110.348C31.8998 110.348 30.2358 112.012 30.2358 114.064C30.2358 116.117 31.8998 117.781 33.9523 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 117.781C45.9951 117.781 47.659 116.117 47.659 114.064C47.659 112.012 45.9951 110.348 43.9425 110.348C41.89 110.348 40.2261 112.012 40.2261 114.064C40.2261 116.117 41.89 117.781 43.9425 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 117.781C85.9467 117.781 87.6106 116.117 87.6106 114.064C87.6106 112.012 85.9467 110.348 83.8942 110.348C81.8416 110.348 80.1777 112.012 80.1777 114.064C80.1777 116.117 81.8416 117.781 83.8942 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 117.781C95.9326 117.781 97.5965 116.117 97.5965 114.064C97.5965 112.012 95.9326 110.348 93.88 110.348C91.8275 110.348 90.1636 112.012 90.1636 114.064C90.1636 116.117 91.8275 117.781 93.88 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 117.781C105.922 117.781 107.586 116.117 107.586 114.064C107.586 112.012 105.922 110.348 103.87 110.348C101.817 110.348 100.153 112.012 100.153 114.064C100.153 116.117 101.817 117.781 103.87 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 117.781C115.908 117.781 117.572 116.117 117.572 114.064C117.572 112.012 115.908 110.348 113.856 110.348C111.803 110.348 110.139 112.012 110.139 114.064C110.139 116.117 111.803 117.781 113.856 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 117.781C125.898 117.781 127.562 116.117 127.562 114.064C127.562 112.012 125.898 110.348 123.846 110.348C121.793 110.348 120.129 112.012 120.129 114.064C120.129 116.117 121.793 117.781 123.846 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 117.781C135.884 117.781 137.548 116.117 137.548 114.064C137.548 112.012 135.884 110.348 133.832 110.348C131.779 110.348 130.115 112.012 130.115 114.064C130.115 116.117 131.779 117.781 133.832 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 117.781C145.874 117.781 147.538 116.117 147.538 114.064C147.538 112.012 145.874 110.348 143.822 110.348C141.769 110.348 140.105 112.012 140.105 114.064C140.105 116.117 141.769 117.781 143.822 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 117.781C155.86 117.781 157.524 116.117 157.524 114.064C157.524 112.012 155.86 110.348 153.808 110.348C151.755 110.348 150.091 112.012 150.091 114.064C150.091 116.117 151.755 117.781 153.808 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 117.781C165.846 117.781 167.51 116.117 167.51 114.064C167.51 112.012 165.846 110.348 163.794 110.348C161.741 110.348 160.077 112.012 160.077 114.064C160.077 116.117 161.741 117.781 163.794 117.781Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.99037 127.808C6.04291 127.808 7.70681 126.144 7.70681 124.091C7.70681 122.039 6.04291 120.375 3.99037 120.375C1.93783 120.375 0.273926 122.039 0.273926 124.091C0.273926 126.144 1.93783 127.808 3.99037 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 127.808C16.0287 127.808 17.6927 126.144 17.6927 124.091C17.6927 122.039 16.0287 120.375 13.9762 120.375C11.9237 120.375 10.2598 122.039 10.2598 124.091C10.2598 126.144 11.9237 127.808 13.9762 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 127.808C26.019 127.808 27.6829 126.144 27.6829 124.091C27.6829 122.039 26.019 120.375 23.9664 120.375C21.9139 120.375 20.25 122.039 20.25 124.091C20.25 126.144 21.9139 127.808 23.9664 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 127.808C36.0048 127.808 37.6687 126.144 37.6687 124.091C37.6687 122.039 36.0048 120.375 33.9523 120.375C31.8998 120.375 30.2358 122.039 30.2358 124.091C30.2358 126.144 31.8998 127.808 33.9523 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 127.808C85.9467 127.808 87.6106 126.144 87.6106 124.091C87.6106 122.039 85.9467 120.375 83.8942 120.375C81.8416 120.375 80.1777 122.039 80.1777 124.091C80.1777 126.144 81.8416 127.808 83.8942 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 127.808C95.9326 127.808 97.5965 126.144 97.5965 124.091C97.5965 122.039 95.9326 120.375 93.88 120.375C91.8275 120.375 90.1636 122.039 90.1636 124.091C90.1636 126.144 91.8275 127.808 93.88 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 127.808C105.922 127.808 107.586 126.144 107.586 124.091C107.586 122.039 105.922 120.375 103.87 120.375C101.817 120.375 100.153 122.039 100.153 124.091C100.153 126.144 101.817 127.808 103.87 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 127.808C115.908 127.808 117.572 126.144 117.572 124.091C117.572 122.039 115.908 120.375 113.856 120.375C111.803 120.375 110.139 122.039 110.139 124.091C110.139 126.144 111.803 127.808 113.856 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 127.808C125.898 127.808 127.562 126.144 127.562 124.091C127.562 122.039 125.898 120.375 123.846 120.375C121.793 120.375 120.129 122.039 120.129 124.091C120.129 126.144 121.793 127.808 123.846 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 127.808C135.884 127.808 137.548 126.144 137.548 124.091C137.548 122.039 135.884 120.375 133.832 120.375C131.779 120.375 130.115 122.039 130.115 124.091C130.115 126.144 131.779 127.808 133.832 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 127.808C145.874 127.808 147.538 126.144 147.538 124.091C147.538 122.039 145.874 120.375 143.822 120.375C141.769 120.375 140.105 122.039 140.105 124.091C140.105 126.144 141.769 127.808 143.822 127.808Z"
                fill="#DAFDA3"
            />
            <path
                d="M13.9762 137.835C16.0287 137.835 17.6927 136.171 17.6927 134.119C17.6927 132.066 16.0287 130.402 13.9762 130.402C11.9237 130.402 10.2598 132.066 10.2598 134.119C10.2598 136.171 11.9237 137.835 13.9762 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 137.835C26.019 137.835 27.6829 136.171 27.6829 134.119C27.6829 132.066 26.019 130.402 23.9664 130.402C21.9139 130.402 20.25 132.066 20.25 134.119C20.25 136.171 21.9139 137.835 23.9664 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 137.835C36.0048 137.835 37.6687 136.171 37.6687 134.119C37.6687 132.066 36.0048 130.402 33.9523 130.402C31.8998 130.402 30.2358 132.066 30.2358 134.119C30.2358 136.171 31.8998 137.835 33.9523 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 137.835C75.9565 137.835 77.6204 136.171 77.6204 134.119C77.6204 132.066 75.9565 130.402 73.9039 130.402C71.8514 130.402 70.1875 132.066 70.1875 134.119C70.1875 136.171 71.8514 137.835 73.9039 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 137.835C85.9467 137.835 87.6106 136.171 87.6106 134.119C87.6106 132.066 85.9467 130.402 83.8942 130.402C81.8416 130.402 80.1777 132.066 80.1777 134.119C80.1777 136.171 81.8416 137.835 83.8942 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 137.835C95.9326 137.835 97.5965 136.171 97.5965 134.119C97.5965 132.066 95.9326 130.402 93.88 130.402C91.8275 130.402 90.1636 132.066 90.1636 134.119C90.1636 136.171 91.8275 137.835 93.88 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 137.835C105.922 137.835 107.586 136.171 107.586 134.119C107.586 132.066 105.922 130.402 103.87 130.402C101.817 130.402 100.153 132.066 100.153 134.119C100.153 136.171 101.817 137.835 103.87 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 137.835C115.908 137.835 117.572 136.171 117.572 134.119C117.572 132.066 115.908 130.402 113.856 130.402C111.803 130.402 110.139 132.066 110.139 134.119C110.139 136.171 111.803 137.835 113.856 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 137.835C125.898 137.835 127.562 136.171 127.562 134.119C127.562 132.066 125.898 130.402 123.846 130.402C121.793 130.402 120.129 132.066 120.129 134.119C120.129 136.171 121.793 137.835 123.846 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 137.835C135.884 137.835 137.548 136.171 137.548 134.119C137.548 132.066 135.884 130.402 133.832 130.402C131.779 130.402 130.115 132.066 130.115 134.119C130.115 136.171 131.779 137.835 133.832 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 137.835C145.874 137.835 147.538 136.171 147.538 134.119C147.538 132.066 145.874 130.402 143.822 130.402C141.769 130.402 140.105 132.066 140.105 134.119C140.105 136.171 141.769 137.835 143.822 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 137.835C155.86 137.835 157.524 136.171 157.524 134.119C157.524 132.066 155.86 130.402 153.808 130.402C151.755 130.402 150.091 132.066 150.091 134.119C150.091 136.171 151.755 137.835 153.808 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 137.835C165.846 137.835 167.51 136.171 167.51 134.119C167.51 132.066 165.846 130.402 163.794 130.402C161.741 130.402 160.077 132.066 160.077 134.119C160.077 136.171 161.741 137.835 163.794 137.835Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 147.863C65.9706 147.863 67.6345 146.199 67.6345 144.146C67.6345 142.094 65.9706 140.43 63.9181 140.43C61.8656 140.43 60.2017 142.094 60.2017 144.146C60.2017 146.199 61.8656 147.863 63.9181 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 147.863C75.9565 147.863 77.6204 146.199 77.6204 144.146C77.6204 142.094 75.9565 140.43 73.9039 140.43C71.8514 140.43 70.1875 142.094 70.1875 144.146C70.1875 146.199 71.8514 147.863 73.9039 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 147.863C85.9467 147.863 87.6106 146.199 87.6106 144.146C87.6106 142.094 85.9467 140.43 83.8942 140.43C81.8416 140.43 80.1777 142.094 80.1777 144.146C80.1777 146.199 81.8416 147.863 83.8942 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 147.863C95.9326 147.863 97.5965 146.199 97.5965 144.146C97.5965 142.094 95.9326 140.43 93.88 140.43C91.8275 140.43 90.1636 142.094 90.1636 144.146C90.1636 146.199 91.8275 147.863 93.88 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 147.863C105.922 147.863 107.586 146.199 107.586 144.146C107.586 142.094 105.922 140.43 103.87 140.43C101.817 140.43 100.153 142.094 100.153 144.146C100.153 146.199 101.817 147.863 103.87 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 147.863C115.908 147.863 117.572 146.199 117.572 144.146C117.572 142.094 115.908 140.43 113.856 140.43C111.803 140.43 110.139 142.094 110.139 144.146C110.139 146.199 111.803 147.863 113.856 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 147.863C125.898 147.863 127.562 146.199 127.562 144.146C127.562 142.094 125.898 140.43 123.846 140.43C121.793 140.43 120.129 142.094 120.129 144.146C120.129 146.199 121.793 147.863 123.846 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 147.863C135.884 147.863 137.548 146.199 137.548 144.146C137.548 142.094 135.884 140.43 133.832 140.43C131.779 140.43 130.115 142.094 130.115 144.146C130.115 146.199 131.779 147.863 133.832 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 147.863C145.874 147.863 147.538 146.199 147.538 144.146C147.538 142.094 145.874 140.43 143.822 140.43C141.769 140.43 140.105 142.094 140.105 144.146C140.105 146.199 141.769 147.863 143.822 147.863Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 157.894C65.9706 157.894 67.6345 156.23 67.6345 154.177C67.6345 152.125 65.9706 150.461 63.9181 150.461C61.8656 150.461 60.2017 152.125 60.2017 154.177C60.2017 156.23 61.8656 157.894 63.9181 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 157.894C75.9565 157.894 77.6204 156.23 77.6204 154.177C77.6204 152.125 75.9565 150.461 73.9039 150.461C71.8514 150.461 70.1875 152.125 70.1875 154.177C70.1875 156.23 71.8514 157.894 73.9039 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 157.894C85.9467 157.894 87.6106 156.23 87.6106 154.177C87.6106 152.125 85.9467 150.461 83.8942 150.461C81.8416 150.461 80.1777 152.125 80.1777 154.177C80.1777 156.23 81.8416 157.894 83.8942 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 157.894C95.9326 157.894 97.5965 156.23 97.5965 154.177C97.5965 152.125 95.9326 150.461 93.88 150.461C91.8275 150.461 90.1636 152.125 90.1636 154.177C90.1636 156.23 91.8275 157.894 93.88 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 157.894C105.922 157.894 107.586 156.23 107.586 154.177C107.586 152.125 105.922 150.461 103.87 150.461C101.817 150.461 100.153 152.125 100.153 154.177C100.153 156.23 101.817 157.894 103.87 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 157.894C115.908 157.894 117.572 156.23 117.572 154.177C117.572 152.125 115.908 150.461 113.856 150.461C111.803 150.461 110.139 152.125 110.139 154.177C110.139 156.23 111.803 157.894 113.856 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 157.894C125.898 157.894 127.562 156.23 127.562 154.177C127.562 152.125 125.898 150.461 123.846 150.461C121.793 150.461 120.129 152.125 120.129 154.177C120.129 156.23 121.793 157.894 123.846 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 157.894C135.884 157.894 137.548 156.23 137.548 154.177C137.548 152.125 135.884 150.461 133.832 150.461C131.779 150.461 130.115 152.125 130.115 154.177C130.115 156.23 131.779 157.894 133.832 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 157.894C145.874 157.894 147.538 156.23 147.538 154.177C147.538 152.125 145.874 150.461 143.822 150.461C141.769 150.461 140.105 152.125 140.105 154.177C140.105 156.23 141.769 157.894 143.822 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 157.894C155.86 157.894 157.524 156.23 157.524 154.177C157.524 152.125 155.86 150.461 153.808 150.461C151.755 150.461 150.091 152.125 150.091 154.177C150.091 156.23 151.755 157.894 153.808 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 157.894C165.846 157.894 167.51 156.23 167.51 154.177C167.51 152.125 165.846 150.461 163.794 150.461C161.741 150.461 160.077 152.125 160.077 154.177C160.077 156.23 161.741 157.894 163.794 157.894Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 167.923C55.9804 167.923 57.6443 166.259 57.6443 164.207C57.6443 162.154 55.9804 160.49 53.9279 160.49C51.8753 160.49 50.2114 162.154 50.2114 164.207C50.2114 166.259 51.8753 167.923 53.9279 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 167.923C65.9706 167.923 67.6345 166.259 67.6345 164.207C67.6345 162.154 65.9706 160.49 63.9181 160.49C61.8656 160.49 60.2017 162.154 60.2017 164.207C60.2017 166.259 61.8656 167.923 63.9181 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 167.923C75.9565 167.923 77.6204 166.259 77.6204 164.207C77.6204 162.154 75.9565 160.49 73.9039 160.49C71.8514 160.49 70.1875 162.154 70.1875 164.207C70.1875 166.259 71.8514 167.923 73.9039 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 167.923C85.9467 167.923 87.6106 166.259 87.6106 164.207C87.6106 162.154 85.9467 160.49 83.8942 160.49C81.8416 160.49 80.1777 162.154 80.1777 164.207C80.1777 166.259 81.8416 167.923 83.8942 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 167.923C95.9326 167.923 97.5965 166.259 97.5965 164.207C97.5965 162.154 95.9326 160.49 93.88 160.49C91.8275 160.49 90.1636 162.154 90.1636 164.207C90.1636 166.259 91.8275 167.923 93.88 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 167.923C105.922 167.923 107.586 166.259 107.586 164.207C107.586 162.154 105.922 160.49 103.87 160.49C101.817 160.49 100.153 162.154 100.153 164.207C100.153 166.259 101.817 167.923 103.87 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 167.923C115.908 167.923 117.572 166.259 117.572 164.207C117.572 162.154 115.908 160.49 113.856 160.49C111.803 160.49 110.139 162.154 110.139 164.207C110.139 166.259 111.803 167.923 113.856 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 167.923C125.898 167.923 127.562 166.259 127.562 164.207C127.562 162.154 125.898 160.49 123.846 160.49C121.793 160.49 120.129 162.154 120.129 164.207C120.129 166.259 121.793 167.923 123.846 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 167.923C135.884 167.923 137.548 166.259 137.548 164.207C137.548 162.154 135.884 160.49 133.832 160.49C131.779 160.49 130.115 162.154 130.115 164.207C130.115 166.259 131.779 167.923 133.832 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 167.923C145.874 167.923 147.538 166.259 147.538 164.207C147.538 162.154 145.874 160.49 143.822 160.49C141.769 160.49 140.105 162.154 140.105 164.207C140.105 166.259 141.769 167.923 143.822 167.923Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 177.95C55.9804 177.95 57.6443 176.287 57.6443 174.234C57.6443 172.181 55.9804 170.518 53.9279 170.518C51.8753 170.518 50.2114 172.181 50.2114 174.234C50.2114 176.287 51.8753 177.95 53.9279 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 177.95C65.9706 177.95 67.6345 176.287 67.6345 174.234C67.6345 172.181 65.9706 170.518 63.9181 170.518C61.8656 170.518 60.2017 172.181 60.2017 174.234C60.2017 176.287 61.8656 177.95 63.9181 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 177.95C75.9565 177.95 77.6204 176.287 77.6204 174.234C77.6204 172.181 75.9565 170.518 73.9039 170.518C71.8514 170.518 70.1875 172.181 70.1875 174.234C70.1875 176.287 71.8514 177.95 73.9039 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 177.95C85.9467 177.95 87.6106 176.287 87.6106 174.234C87.6106 172.181 85.9467 170.518 83.8942 170.518C81.8416 170.518 80.1777 172.181 80.1777 174.234C80.1777 176.287 81.8416 177.95 83.8942 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 177.95C95.9326 177.95 97.5965 176.287 97.5965 174.234C97.5965 172.181 95.9326 170.518 93.88 170.518C91.8275 170.518 90.1636 172.181 90.1636 174.234C90.1636 176.287 91.8275 177.95 93.88 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 177.95C105.922 177.95 107.586 176.287 107.586 174.234C107.586 172.181 105.922 170.518 103.87 170.518C101.817 170.518 100.153 172.181 100.153 174.234C100.153 176.287 101.817 177.95 103.87 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 177.95C115.908 177.95 117.572 176.287 117.572 174.234C117.572 172.181 115.908 170.518 113.856 170.518C111.803 170.518 110.139 172.181 110.139 174.234C110.139 176.287 111.803 177.95 113.856 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 177.95C125.898 177.95 127.562 176.287 127.562 174.234C127.562 172.181 125.898 170.518 123.846 170.518C121.793 170.518 120.129 172.181 120.129 174.234C120.129 176.287 121.793 177.95 123.846 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 177.95C135.884 177.95 137.548 176.287 137.548 174.234C137.548 172.181 135.884 170.518 133.832 170.518C131.779 170.518 130.115 172.181 130.115 174.234C130.115 176.287 131.779 177.95 133.832 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 177.95C145.874 177.95 147.538 176.287 147.538 174.234C147.538 172.181 145.874 170.518 143.822 170.518C141.769 170.518 140.105 172.181 140.105 174.234C140.105 176.287 141.769 177.95 143.822 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 177.95C155.86 177.95 157.524 176.287 157.524 174.234C157.524 172.181 155.86 170.518 153.808 170.518C151.755 170.518 150.091 172.181 150.091 174.234C150.091 176.287 151.755 177.95 153.808 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 177.95C165.846 177.95 167.51 176.287 167.51 174.234C167.51 172.181 165.846 170.518 163.794 170.518C161.741 170.518 160.077 172.181 160.077 174.234C160.077 176.287 161.741 177.95 163.794 177.95Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 187.978C55.9804 187.978 57.6443 186.314 57.6443 184.261C57.6443 182.209 55.9804 180.545 53.9279 180.545C51.8753 180.545 50.2114 182.209 50.2114 184.261C50.2114 186.314 51.8753 187.978 53.9279 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 187.978C65.9706 187.978 67.6345 186.314 67.6345 184.261C67.6345 182.209 65.9706 180.545 63.9181 180.545C61.8656 180.545 60.2017 182.209 60.2017 184.261C60.2017 186.314 61.8656 187.978 63.9181 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 187.978C75.9565 187.978 77.6204 186.314 77.6204 184.261C77.6204 182.209 75.9565 180.545 73.9039 180.545C71.8514 180.545 70.1875 182.209 70.1875 184.261C70.1875 186.314 71.8514 187.978 73.9039 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 187.978C85.9467 187.978 87.6106 186.314 87.6106 184.261C87.6106 182.209 85.9467 180.545 83.8942 180.545C81.8416 180.545 80.1777 182.209 80.1777 184.261C80.1777 186.314 81.8416 187.978 83.8942 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 187.978C95.9326 187.978 97.5965 186.314 97.5965 184.261C97.5965 182.209 95.9326 180.545 93.88 180.545C91.8275 180.545 90.1636 182.209 90.1636 184.261C90.1636 186.314 91.8275 187.978 93.88 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 187.978C105.922 187.978 107.586 186.314 107.586 184.261C107.586 182.209 105.922 180.545 103.87 180.545C101.817 180.545 100.153 182.209 100.153 184.261C100.153 186.314 101.817 187.978 103.87 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 187.978C115.908 187.978 117.572 186.314 117.572 184.261C117.572 182.209 115.908 180.545 113.856 180.545C111.803 180.545 110.139 182.209 110.139 184.261C110.139 186.314 111.803 187.978 113.856 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 187.978C125.898 187.978 127.562 186.314 127.562 184.261C127.562 182.209 125.898 180.545 123.846 180.545C121.793 180.545 120.129 182.209 120.129 184.261C120.129 186.314 121.793 187.978 123.846 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 187.978C135.884 187.978 137.548 186.314 137.548 184.261C137.548 182.209 135.884 180.545 133.832 180.545C131.779 180.545 130.115 182.209 130.115 184.261C130.115 186.314 131.779 187.978 133.832 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 187.978C145.874 187.978 147.538 186.314 147.538 184.261C147.538 182.209 145.874 180.545 143.822 180.545C141.769 180.545 140.105 182.209 140.105 184.261C140.105 186.314 141.769 187.978 143.822 187.978Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 198.009C55.9804 198.009 57.6443 196.345 57.6443 194.293C57.6443 192.24 55.9804 190.576 53.9279 190.576C51.8753 190.576 50.2114 192.24 50.2114 194.293C50.2114 196.345 51.8753 198.009 53.9279 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 198.009C65.9706 198.009 67.6345 196.345 67.6345 194.293C67.6345 192.24 65.9706 190.576 63.9181 190.576C61.8656 190.576 60.2017 192.24 60.2017 194.293C60.2017 196.345 61.8656 198.009 63.9181 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 198.009C75.9565 198.009 77.6204 196.345 77.6204 194.293C77.6204 192.24 75.9565 190.576 73.9039 190.576C71.8514 190.576 70.1875 192.24 70.1875 194.293C70.1875 196.345 71.8514 198.009 73.9039 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 198.009C85.9467 198.009 87.6106 196.345 87.6106 194.293C87.6106 192.24 85.9467 190.576 83.8942 190.576C81.8416 190.576 80.1777 192.24 80.1777 194.293C80.1777 196.345 81.8416 198.009 83.8942 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 198.009C95.9326 198.009 97.5965 196.345 97.5965 194.293C97.5965 192.24 95.9326 190.576 93.88 190.576C91.8275 190.576 90.1636 192.24 90.1636 194.293C90.1636 196.345 91.8275 198.009 93.88 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 198.009C105.922 198.009 107.586 196.345 107.586 194.293C107.586 192.24 105.922 190.576 103.87 190.576C101.817 190.576 100.153 192.24 100.153 194.293C100.153 196.345 101.817 198.009 103.87 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 198.009C115.908 198.009 117.572 196.345 117.572 194.293C117.572 192.24 115.908 190.576 113.856 190.576C111.803 190.576 110.139 192.24 110.139 194.293C110.139 196.345 111.803 198.009 113.856 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 198.009C125.898 198.009 127.562 196.345 127.562 194.293C127.562 192.24 125.898 190.576 123.846 190.576C121.793 190.576 120.129 192.24 120.129 194.293C120.129 196.345 121.793 198.009 123.846 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 198.009C135.884 198.009 137.548 196.345 137.548 194.293C137.548 192.24 135.884 190.576 133.832 190.576C131.779 190.576 130.115 192.24 130.115 194.293C130.115 196.345 131.779 198.009 133.832 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 198.009C145.874 198.009 147.538 196.345 147.538 194.293C147.538 192.24 145.874 190.576 143.822 190.576C141.769 190.576 140.105 192.24 140.105 194.293C140.105 196.345 141.769 198.009 143.822 198.009Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 208.038C75.9565 208.038 77.6204 206.374 77.6204 204.322C77.6204 202.269 75.9565 200.605 73.9039 200.605C71.8514 200.605 70.1875 202.269 70.1875 204.322C70.1875 206.374 71.8514 208.038 73.9039 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 208.038C85.9467 208.038 87.6106 206.374 87.6106 204.322C87.6106 202.269 85.9467 200.605 83.8942 200.605C81.8416 200.605 80.1777 202.269 80.1777 204.322C80.1777 206.374 81.8416 208.038 83.8942 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 208.038C95.9326 208.038 97.5965 206.374 97.5965 204.322C97.5965 202.269 95.9326 200.605 93.88 200.605C91.8275 200.605 90.1636 202.269 90.1636 204.322C90.1636 206.374 91.8275 208.038 93.88 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 208.038C105.922 208.038 107.586 206.374 107.586 204.322C107.586 202.269 105.922 200.605 103.87 200.605C101.817 200.605 100.153 202.269 100.153 204.322C100.153 206.374 101.817 208.038 103.87 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 208.038C115.908 208.038 117.572 206.374 117.572 204.322C117.572 202.269 115.908 200.605 113.856 200.605C111.803 200.605 110.139 202.269 110.139 204.322C110.139 206.374 111.803 208.038 113.856 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 208.038C125.898 208.038 127.562 206.374 127.562 204.322C127.562 202.269 125.898 200.605 123.846 200.605C121.793 200.605 120.129 202.269 120.129 204.322C120.129 206.374 121.793 208.038 123.846 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 208.038C135.884 208.038 137.548 206.374 137.548 204.322C137.548 202.269 135.884 200.605 133.832 200.605C131.779 200.605 130.115 202.269 130.115 204.322C130.115 206.374 131.779 208.038 133.832 208.038Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 228.093C55.9804 228.093 57.6443 226.429 57.6443 224.377C57.6443 222.324 55.9804 220.66 53.9279 220.66C51.8753 220.66 50.2114 222.324 50.2114 224.377C50.2114 226.429 51.8753 228.093 53.9279 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 228.093C65.9706 228.093 67.6345 226.429 67.6345 224.377C67.6345 222.324 65.9706 220.66 63.9181 220.66C61.8656 220.66 60.2017 222.324 60.2017 224.377C60.2017 226.429 61.8656 228.093 63.9181 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 228.093C75.9565 228.093 77.6204 226.429 77.6204 224.377C77.6204 222.324 75.9565 220.66 73.9039 220.66C71.8514 220.66 70.1875 222.324 70.1875 224.377C70.1875 226.429 71.8514 228.093 73.9039 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 228.093C85.9467 228.093 87.6106 226.429 87.6106 224.377C87.6106 222.324 85.9467 220.66 83.8942 220.66C81.8416 220.66 80.1777 222.324 80.1777 224.377C80.1777 226.429 81.8416 228.093 83.8942 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 228.093C95.9326 228.093 97.5965 226.429 97.5965 224.377C97.5965 222.324 95.9326 220.66 93.88 220.66C91.8275 220.66 90.1636 222.324 90.1636 224.377C90.1636 226.429 91.8275 228.093 93.88 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 228.093C105.922 228.093 107.586 226.429 107.586 224.377C107.586 222.324 105.922 220.66 103.87 220.66C101.817 220.66 100.153 222.324 100.153 224.377C100.153 226.429 101.817 228.093 103.87 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 228.093C115.908 228.093 117.572 226.429 117.572 224.377C117.572 222.324 115.908 220.66 113.856 220.66C111.803 220.66 110.139 222.324 110.139 224.377C110.139 226.429 111.803 228.093 113.856 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 228.093C125.898 228.093 127.562 226.429 127.562 224.377C127.562 222.324 125.898 220.66 123.846 220.66C121.793 220.66 120.129 222.324 120.129 224.377C120.129 226.429 121.793 228.093 123.846 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 228.093C135.884 228.093 137.548 226.429 137.548 224.377C137.548 222.324 135.884 220.66 133.832 220.66C131.779 220.66 130.115 222.324 130.115 224.377C130.115 226.429 131.779 228.093 133.832 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 228.093C145.874 228.093 147.538 226.429 147.538 224.377C147.538 222.324 145.874 220.66 143.822 220.66C141.769 220.66 140.105 222.324 140.105 224.377C140.105 226.429 141.769 228.093 143.822 228.093Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 238.124C45.9951 238.124 47.659 236.46 47.659 234.408C47.659 232.355 45.9951 230.691 43.9425 230.691C41.89 230.691 40.2261 232.355 40.2261 234.408C40.2261 236.46 41.89 238.124 43.9425 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 238.124C55.9804 238.124 57.6443 236.46 57.6443 234.408C57.6443 232.355 55.9804 230.691 53.9279 230.691C51.8753 230.691 50.2114 232.355 50.2114 234.408C50.2114 236.46 51.8753 238.124 53.9279 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 238.124C65.9706 238.124 67.6345 236.46 67.6345 234.408C67.6345 232.355 65.9706 230.691 63.9181 230.691C61.8656 230.691 60.2017 232.355 60.2017 234.408C60.2017 236.46 61.8656 238.124 63.9181 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 238.124C75.9565 238.124 77.6204 236.46 77.6204 234.408C77.6204 232.355 75.9565 230.691 73.9039 230.691C71.8514 230.691 70.1875 232.355 70.1875 234.408C70.1875 236.46 71.8514 238.124 73.9039 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 238.124C85.9467 238.124 87.6106 236.46 87.6106 234.408C87.6106 232.355 85.9467 230.691 83.8942 230.691C81.8416 230.691 80.1777 232.355 80.1777 234.408C80.1777 236.46 81.8416 238.124 83.8942 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 238.124C95.9326 238.124 97.5965 236.46 97.5965 234.408C97.5965 232.355 95.9326 230.691 93.88 230.691C91.8275 230.691 90.1636 232.355 90.1636 234.408C90.1636 236.46 91.8275 238.124 93.88 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 238.124C105.922 238.124 107.586 236.46 107.586 234.408C107.586 232.355 105.922 230.691 103.87 230.691C101.817 230.691 100.153 232.355 100.153 234.408C100.153 236.46 101.817 238.124 103.87 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 238.124C115.908 238.124 117.572 236.46 117.572 234.408C117.572 232.355 115.908 230.691 113.856 230.691C111.803 230.691 110.139 232.355 110.139 234.408C110.139 236.46 111.803 238.124 113.856 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 238.124C125.898 238.124 127.562 236.46 127.562 234.408C127.562 232.355 125.898 230.691 123.846 230.691C121.793 230.691 120.129 232.355 120.129 234.408C120.129 236.46 121.793 238.124 123.846 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 238.124C135.884 238.124 137.548 236.46 137.548 234.408C137.548 232.355 135.884 230.691 133.832 230.691C131.779 230.691 130.115 232.355 130.115 234.408C130.115 236.46 131.779 238.124 133.832 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 238.124C145.874 238.124 147.538 236.46 147.538 234.408C147.538 232.355 145.874 230.691 143.822 230.691C141.769 230.691 140.105 232.355 140.105 234.408C140.105 236.46 141.769 238.124 143.822 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 238.124C155.86 238.124 157.524 236.46 157.524 234.408C157.524 232.355 155.86 230.691 153.808 230.691C151.755 230.691 150.091 232.355 150.091 234.408C150.091 236.46 151.755 238.124 153.808 238.124Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 248.152C55.9804 248.152 57.6443 246.488 57.6443 244.435C57.6443 242.383 55.9804 240.719 53.9279 240.719C51.8753 240.719 50.2114 242.383 50.2114 244.435C50.2114 246.488 51.8753 248.152 53.9279 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 248.152C65.9706 248.152 67.6345 246.488 67.6345 244.435C67.6345 242.383 65.9706 240.719 63.9181 240.719C61.8656 240.719 60.2017 242.383 60.2017 244.435C60.2017 246.488 61.8656 248.152 63.9181 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 248.152C75.9565 248.152 77.6204 246.488 77.6204 244.435C77.6204 242.383 75.9565 240.719 73.9039 240.719C71.8514 240.719 70.1875 242.383 70.1875 244.435C70.1875 246.488 71.8514 248.152 73.9039 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 248.152C85.9467 248.152 87.6106 246.488 87.6106 244.435C87.6106 242.383 85.9467 240.719 83.8942 240.719C81.8416 240.719 80.1777 242.383 80.1777 244.435C80.1777 246.488 81.8416 248.152 83.8942 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 248.152C95.9326 248.152 97.5965 246.488 97.5965 244.435C97.5965 242.383 95.9326 240.719 93.88 240.719C91.8275 240.719 90.1636 242.383 90.1636 244.435C90.1636 246.488 91.8275 248.152 93.88 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 248.152C105.922 248.152 107.586 246.488 107.586 244.435C107.586 242.383 105.922 240.719 103.87 240.719C101.817 240.719 100.153 242.383 100.153 244.435C100.153 246.488 101.817 248.152 103.87 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 248.152C115.908 248.152 117.572 246.488 117.572 244.435C117.572 242.383 115.908 240.719 113.856 240.719C111.803 240.719 110.139 242.383 110.139 244.435C110.139 246.488 111.803 248.152 113.856 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 248.152C125.898 248.152 127.562 246.488 127.562 244.435C127.562 242.383 125.898 240.719 123.846 240.719C121.793 240.719 120.129 242.383 120.129 244.435C120.129 246.488 121.793 248.152 123.846 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 248.152C135.884 248.152 137.548 246.488 137.548 244.435C137.548 242.383 135.884 240.719 133.832 240.719C131.779 240.719 130.115 242.383 130.115 244.435C130.115 246.488 131.779 248.152 133.832 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 248.152C145.874 248.152 147.538 246.488 147.538 244.435C147.538 242.383 145.874 240.719 143.822 240.719C141.769 240.719 140.105 242.383 140.105 244.435C140.105 246.488 141.769 248.152 143.822 248.152Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 268.208C36.0048 268.208 37.6687 266.544 37.6687 264.492C37.6687 262.439 36.0048 260.775 33.9523 260.775C31.8998 260.775 30.2358 262.439 30.2358 264.492C30.2358 266.544 31.8998 268.208 33.9523 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 268.208C45.9951 268.208 47.659 266.544 47.659 264.492C47.659 262.439 45.9951 260.775 43.9425 260.775C41.89 260.775 40.2261 262.439 40.2261 264.492C40.2261 266.544 41.89 268.208 43.9425 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 268.208C55.9804 268.208 57.6443 266.544 57.6443 264.492C57.6443 262.439 55.9804 260.775 53.9279 260.775C51.8753 260.775 50.2114 262.439 50.2114 264.492C50.2114 266.544 51.8753 268.208 53.9279 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 268.208C65.9706 268.208 67.6345 266.544 67.6345 264.492C67.6345 262.439 65.9706 260.775 63.9181 260.775C61.8656 260.775 60.2017 262.439 60.2017 264.492C60.2017 266.544 61.8656 268.208 63.9181 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 268.208C75.9565 268.208 77.6204 266.544 77.6204 264.492C77.6204 262.439 75.9565 260.775 73.9039 260.775C71.8514 260.775 70.1875 262.439 70.1875 264.492C70.1875 266.544 71.8514 268.208 73.9039 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 268.208C85.9467 268.208 87.6106 266.544 87.6106 264.492C87.6106 262.439 85.9467 260.775 83.8942 260.775C81.8416 260.775 80.1777 262.439 80.1777 264.492C80.1777 266.544 81.8416 268.208 83.8942 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 268.208C95.9326 268.208 97.5965 266.544 97.5965 264.492C97.5965 262.439 95.9326 260.775 93.88 260.775C91.8275 260.775 90.1636 262.439 90.1636 264.492C90.1636 266.544 91.8275 268.208 93.88 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 268.208C105.922 268.208 107.586 266.544 107.586 264.492C107.586 262.439 105.922 260.775 103.87 260.775C101.817 260.775 100.153 262.439 100.153 264.492C100.153 266.544 101.817 268.208 103.87 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 268.208C115.908 268.208 117.572 266.544 117.572 264.492C117.572 262.439 115.908 260.775 113.856 260.775C111.803 260.775 110.139 262.439 110.139 264.492C110.139 266.544 111.803 268.208 113.856 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 268.208C125.898 268.208 127.562 266.544 127.562 264.492C127.562 262.439 125.898 260.775 123.846 260.775C121.793 260.775 120.129 262.439 120.129 264.492C120.129 266.544 121.793 268.208 123.846 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 268.208C135.884 268.208 137.548 266.544 137.548 264.492C137.548 262.439 135.884 260.775 133.832 260.775C131.779 260.775 130.115 262.439 130.115 264.492C130.115 266.544 131.779 268.208 133.832 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 268.208C145.874 268.208 147.538 266.544 147.538 264.492C147.538 262.439 145.874 260.775 143.822 260.775C141.769 260.775 140.105 262.439 140.105 264.492C140.105 266.544 141.769 268.208 143.822 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 268.208C155.86 268.208 157.524 266.544 157.524 264.492C157.524 262.439 155.86 260.775 153.808 260.775C151.755 260.775 150.091 262.439 150.091 264.492C150.091 266.544 151.755 268.208 153.808 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 268.208C165.846 268.208 167.51 266.544 167.51 264.492C167.51 262.439 165.846 260.775 163.794 260.775C161.741 260.775 160.077 262.439 160.077 264.492C160.077 266.544 161.741 268.208 163.794 268.208Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 278.24C36.0048 278.24 37.6687 276.576 37.6687 274.523C37.6687 272.471 36.0048 270.807 33.9523 270.807C31.8998 270.807 30.2358 272.471 30.2358 274.523C30.2358 276.576 31.8998 278.24 33.9523 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 278.24C45.9951 278.24 47.659 276.576 47.659 274.523C47.659 272.471 45.9951 270.807 43.9425 270.807C41.89 270.807 40.2261 272.471 40.2261 274.523C40.2261 276.576 41.89 278.24 43.9425 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 278.24C55.9804 278.24 57.6443 276.576 57.6443 274.523C57.6443 272.471 55.9804 270.807 53.9279 270.807C51.8753 270.807 50.2114 272.471 50.2114 274.523C50.2114 276.576 51.8753 278.24 53.9279 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 278.24C65.9706 278.24 67.6345 276.576 67.6345 274.523C67.6345 272.471 65.9706 270.807 63.9181 270.807C61.8656 270.807 60.2017 272.471 60.2017 274.523C60.2017 276.576 61.8656 278.24 63.9181 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 278.24C75.9565 278.24 77.6204 276.576 77.6204 274.523C77.6204 272.471 75.9565 270.807 73.9039 270.807C71.8514 270.807 70.1875 272.471 70.1875 274.523C70.1875 276.576 71.8514 278.24 73.9039 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 278.24C85.9467 278.24 87.6106 276.576 87.6106 274.523C87.6106 272.471 85.9467 270.807 83.8942 270.807C81.8416 270.807 80.1777 272.471 80.1777 274.523C80.1777 276.576 81.8416 278.24 83.8942 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 278.24C95.9326 278.24 97.5965 276.576 97.5965 274.523C97.5965 272.471 95.9326 270.807 93.88 270.807C91.8275 270.807 90.1636 272.471 90.1636 274.523C90.1636 276.576 91.8275 278.24 93.88 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 278.24C105.922 278.24 107.586 276.576 107.586 274.523C107.586 272.471 105.922 270.807 103.87 270.807C101.817 270.807 100.153 272.471 100.153 274.523C100.153 276.576 101.817 278.24 103.87 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 278.24C115.908 278.24 117.572 276.576 117.572 274.523C117.572 272.471 115.908 270.807 113.856 270.807C111.803 270.807 110.139 272.471 110.139 274.523C110.139 276.576 111.803 278.24 113.856 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 278.24C125.898 278.24 127.562 276.576 127.562 274.523C127.562 272.471 125.898 270.807 123.846 270.807C121.793 270.807 120.129 272.471 120.129 274.523C120.129 276.576 121.793 278.24 123.846 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 278.24C135.884 278.24 137.548 276.576 137.548 274.523C137.548 272.471 135.884 270.807 133.832 270.807C131.779 270.807 130.115 272.471 130.115 274.523C130.115 276.576 131.779 278.24 133.832 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 278.24C145.874 278.24 147.538 276.576 147.538 274.523C147.538 272.471 145.874 270.807 143.822 270.807C141.769 270.807 140.105 272.471 140.105 274.523C140.105 276.576 141.769 278.24 143.822 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 278.24C155.86 278.24 157.524 276.576 157.524 274.523C157.524 272.471 155.86 270.807 153.808 270.807C151.755 270.807 150.091 272.471 150.091 274.523C150.091 276.576 151.755 278.24 153.808 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 278.24C165.846 278.24 167.51 276.576 167.51 274.523C167.51 272.471 165.846 270.807 163.794 270.807C161.741 270.807 160.077 272.471 160.077 274.523C160.077 276.576 161.741 278.24 163.794 278.24Z"
                fill="#DAFDA3"
            />
            <path
                d="M23.9664 288.267C26.019 288.267 27.6829 286.603 27.6829 284.55C27.6829 282.498 26.019 280.834 23.9664 280.834C21.9139 280.834 20.25 282.498 20.25 284.55C20.25 286.603 21.9139 288.267 23.9664 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M33.9523 288.267C36.0048 288.267 37.6687 286.603 37.6687 284.55C37.6687 282.498 36.0048 280.834 33.9523 280.834C31.8998 280.834 30.2358 282.498 30.2358 284.55C30.2358 286.603 31.8998 288.267 33.9523 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M43.9425 288.267C45.9951 288.267 47.659 286.603 47.659 284.55C47.659 282.498 45.9951 280.834 43.9425 280.834C41.89 280.834 40.2261 282.498 40.2261 284.55C40.2261 286.603 41.89 288.267 43.9425 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M53.9279 288.267C55.9804 288.267 57.6443 286.603 57.6443 284.55C57.6443 282.498 55.9804 280.834 53.9279 280.834C51.8753 280.834 50.2114 282.498 50.2114 284.55C50.2114 286.603 51.8753 288.267 53.9279 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M63.9181 288.267C65.9706 288.267 67.6345 286.603 67.6345 284.55C67.6345 282.498 65.9706 280.834 63.9181 280.834C61.8656 280.834 60.2017 282.498 60.2017 284.55C60.2017 286.603 61.8656 288.267 63.9181 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M73.9039 288.267C75.9565 288.267 77.6204 286.603 77.6204 284.55C77.6204 282.498 75.9565 280.834 73.9039 280.834C71.8514 280.834 70.1875 282.498 70.1875 284.55C70.1875 286.603 71.8514 288.267 73.9039 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M83.8942 288.267C85.9467 288.267 87.6106 286.603 87.6106 284.55C87.6106 282.498 85.9467 280.834 83.8942 280.834C81.8416 280.834 80.1777 282.498 80.1777 284.55C80.1777 286.603 81.8416 288.267 83.8942 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M93.88 288.267C95.9326 288.267 97.5965 286.603 97.5965 284.55C97.5965 282.498 95.9326 280.834 93.88 280.834C91.8275 280.834 90.1636 282.498 90.1636 284.55C90.1636 286.603 91.8275 288.267 93.88 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.87 288.267C105.922 288.267 107.586 286.603 107.586 284.55C107.586 282.498 105.922 280.834 103.87 280.834C101.817 280.834 100.153 282.498 100.153 284.55C100.153 286.603 101.817 288.267 103.87 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M113.856 288.267C115.908 288.267 117.572 286.603 117.572 284.55C117.572 282.498 115.908 280.834 113.856 280.834C111.803 280.834 110.139 282.498 110.139 284.55C110.139 286.603 111.803 288.267 113.856 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M123.846 288.267C125.898 288.267 127.562 286.603 127.562 284.55C127.562 282.498 125.898 280.834 123.846 280.834C121.793 280.834 120.129 282.498 120.129 284.55C120.129 286.603 121.793 288.267 123.846 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M133.832 288.267C135.884 288.267 137.548 286.603 137.548 284.55C137.548 282.498 135.884 280.834 133.832 280.834C131.779 280.834 130.115 282.498 130.115 284.55C130.115 286.603 131.779 288.267 133.832 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M143.822 288.267C145.874 288.267 147.538 286.603 147.538 284.55C147.538 282.498 145.874 280.834 143.822 280.834C141.769 280.834 140.105 282.498 140.105 284.55C140.105 286.603 141.769 288.267 143.822 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M153.808 288.267C155.86 288.267 157.524 286.603 157.524 284.55C157.524 282.498 155.86 280.834 153.808 280.834C151.755 280.834 150.091 282.498 150.091 284.55C150.091 286.603 151.755 288.267 153.808 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M163.794 288.267C165.846 288.267 167.51 286.603 167.51 284.55C167.51 282.498 165.846 280.834 163.794 280.834C161.741 280.834 160.077 282.498 160.077 284.55C160.077 286.603 161.741 288.267 163.794 288.267Z"
                fill="#DAFDA3"
            />
            <path
                d="M173.783 288.267C175.836 288.267 177.5 286.603 177.5 284.55C177.5 282.498 175.836 280.834 173.783 280.834C171.731 280.834 170.067 282.498 170.067 284.55C170.067 286.603 171.731 288.267 173.783 288.267Z"
                fill="#DAFDA3"
            />
        </g>
        <defs>
            <clipPath id="clip0_3759_9956">
                <rect width="177.226" height="288.238" fill="white" transform="translate(0.273926 0.0292969)" />
            </clipPath>
        </defs>
    </svg>
);

export default KnightIcon;
