import { IconSVGProps } from "./types";

const WarningIcon = ({ height = 22, width = 24, className, color = "#DFAE00" }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M23.7327 18.5469L13.7342 1.48438C12.9687 0.171875 11.037 0.171875 10.2664 1.48438L0.272653 18.5469C-0.496566 19.8547 0.461513 21.5 2.00328 21.5H22.0002C23.5358 21.5 24.4967 19.8594 23.7327 18.5469ZM10.8748 6.875C10.8748 6.25391 11.3787 5.75 11.9998 5.75C12.6209 5.75 13.1248 6.25625 13.1248 6.875V12.875C13.1248 13.4961 12.6209 14 12.042 14C11.4631 14 10.8748 13.4984 10.8748 12.875V6.875ZM11.9998 18.5C11.1861 18.5 10.5261 17.84 10.5261 17.0262C10.5261 16.2125 11.1856 15.5525 11.9998 15.5525C12.8141 15.5525 13.4736 16.2125 13.4736 17.0262C13.4717 17.8391 12.8155 18.5 11.9998 18.5Z"
            fill={color}
        />
    </svg>
);

export default WarningIcon;
