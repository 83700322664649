import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dashboardDataSalesAmountByCardPresent, dashboardDataTotalSalesToday, dashboardDccCommissionAmount, dashboardDccTransactionsCount, dashboardMonthlySalesTarget, dashboardSalesByOutlet, dashboardSalesPerDay, dashboardSetSalesTarget, dashboardUnpaidSettlementsData } from "redux/actions/dashboardActions";
import { getContractData } from "redux/actions/reportAction";
import { GetDashboardDccCommissionAmountResponse, GetDashboardDccTransactionsCountResponse, GetDashboardMonthlySalesTargetResponse, GetDashboardSalesAmountByCardPresentRequest, GetDashboardSalesAmountByCardPresentResponse, GetDashboardSalesByOutletResponse, GetDashboardSalesPerDayResponse, GetDashboardTotalSalesTodayResponse, GetDashboardUnpaidSettlementsDataResponse, IContractData, IDashboard } from "redux/models/dashboardModels";
import { PayloadData } from "redux/models/userModels";

const initialState: IDashboard = {
    totalSalesToday: {
        isLoading: false,
        totalSalesToday: 0,
    },
    salesAmountByCardPresent: {
        isLoading: false,
        totalSales: 0,
        onlinePaymentsAmount: 0,
        offlinePaymentsAmount: 0
    },
    salesPerDay: {
        isLoading: false,
        averageDailySales: 0,
        salesPerDay: [],
    },
    salesByOutlet: {
        isLoading: false,
        salesByOutlet: [],
    },
    dccTransactionsCount: {
        isLoading: false,
        dccTransactionCount: 0,
    },
    monthlySalesTarget: {
        isLoading: false,
        monthlyTarget: 0
    },
    unpaidSettlementsData: {
        isLoading: false,
        upcomingPayment: 0,
        upcomingPaymentDate: "",
        unpaidSettlementsAmount: 0,
    },
    dccCommissionAmount: {
        isLoading: false,
        dccCommissionAmount: 0,
    },
    contractData: [],
};

export const dashboardReducer = createSlice({
    name: "dashboard",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(dashboardDataTotalSalesToday.pending, state => {
            state.totalSalesToday.isLoading = true;
        });
        builder.addCase(
            dashboardDataTotalSalesToday.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardTotalSalesTodayResponse>>) => {
                state.totalSalesToday = action.payload.data;
                state.totalSalesToday.isLoading = false;
            }
        );
        builder.addCase(dashboardDataTotalSalesToday.rejected, state => {
            state.totalSalesToday.isLoading = false;
        });

        builder.addCase(
            dashboardDataSalesAmountByCardPresent.pending, state => {
                state.salesAmountByCardPresent.isLoading = true;
            }
        );

        builder.addCase(
            dashboardDataSalesAmountByCardPresent.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardSalesAmountByCardPresentResponse>>) => {
                state.salesAmountByCardPresent = action.payload.data;
                state.salesAmountByCardPresent.isLoading = false;
            }
        );

        builder.addCase(dashboardDataSalesAmountByCardPresent.rejected, state => {
            state.salesAmountByCardPresent.isLoading = false;
        });

        builder.addCase(dashboardSalesPerDay.pending, state => {
            state.salesPerDay.isLoading = true;
        });
        builder.addCase(
            dashboardSalesPerDay.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardSalesPerDayResponse>>) => {
                state.salesPerDay = action.payload.data;
                state.salesPerDay.isLoading = false;
            }
        );
        builder.addCase(dashboardSalesPerDay.rejected, state => {
            state.salesPerDay.isLoading = false;
        });

        builder.addCase(dashboardSalesByOutlet.pending, state => {
            state.salesByOutlet.isLoading = true;
        });
        builder.addCase(
            dashboardSalesByOutlet.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardSalesByOutletResponse>>) => {
                state.salesByOutlet = action.payload.data;
                state.salesByOutlet.isLoading = false;
            }
        );
        builder.addCase(dashboardSalesByOutlet.rejected, state => {
            state.salesByOutlet.isLoading = false;
        });

        builder.addCase(dashboardDccTransactionsCount.pending, state => {
            state.dccTransactionsCount.isLoading = true;
        });
        builder.addCase(
            dashboardDccTransactionsCount.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardDccTransactionsCountResponse>>) => {
                state.dccTransactionsCount = action.payload.data;
                state.dccTransactionsCount.isLoading = false;
            }
        );
        builder.addCase(dashboardDccTransactionsCount.rejected, state => {
            state.dccTransactionsCount.isLoading = false;
        });

        builder.addCase(dashboardMonthlySalesTarget.pending, state => {
            state.monthlySalesTarget.isLoading = true;
        });
        builder.addCase(
            dashboardMonthlySalesTarget.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardMonthlySalesTargetResponse>>) => {
                state.monthlySalesTarget = action.payload.data;
                state.monthlySalesTarget.isLoading = false;
            }
        );
        builder.addCase(dashboardMonthlySalesTarget.rejected, state => {
            state.monthlySalesTarget.isLoading = false;
        });

        builder.addCase(dashboardUnpaidSettlementsData.pending, state => {
            state.unpaidSettlementsData.isLoading = true;
        });
        builder.addCase(
            dashboardUnpaidSettlementsData.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardUnpaidSettlementsDataResponse>>) => {
                state.unpaidSettlementsData = action.payload.data;
                state.unpaidSettlementsData.isLoading = false;
            }
        );
        builder.addCase(dashboardUnpaidSettlementsData.rejected, state => {
            state.unpaidSettlementsData.isLoading = false;
        });

        builder.addCase(dashboardDccCommissionAmount.pending, state => {
            state.dccCommissionAmount.isLoading = true;
        });
        builder.addCase(
            dashboardDccCommissionAmount.fulfilled,
            (state, action: PayloadAction<PayloadData<GetDashboardDccCommissionAmountResponse>>) => {
                state.dccCommissionAmount = action.payload.data;
                state.dccCommissionAmount.isLoading = false;
            }
        );
        builder.addCase(dashboardDccCommissionAmount.rejected, state => {
            state.dccCommissionAmount.isLoading = false;
        });

        builder.addCase(dashboardSetSalesTarget.pending, state => {});
        builder.addCase(dashboardSetSalesTarget.fulfilled, state => {});
        builder.addCase(dashboardSetSalesTarget.rejected, state => {});

        builder.addCase(
            getContractData.fulfilled,
            (state, action: PayloadAction<PayloadData<Array<IContractData>>>) => {
                state.contractData = action.payload.data;
            }
        );
    },
});

const { reducer } = dashboardReducer;
export default reducer;
