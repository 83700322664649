import { IReportDetailsDefinition, IReportDetailsModel } from "api/requestModels";
import { useAppDispatch } from "redux/store";
import { DetailsRowTypeFormatterEnum, TransactionType } from "utils/enums";
import "./reportDetailsBody.scss";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { useNavigate } from "react-router-dom";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import React from "react";
import { ILookupResponse } from "utils/models";
import { Currency, CurrencyOptions, Datetime, formatter, isCurrencyFormat } from "utils/formatter";
import { t } from "i18next";
import classNames from "classnames";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { ToolTipIcon } from "components/icons";

interface IReportDetailsBodyProps {
    detailsDefinitions: IReportDetailsDefinition<any>;
    activeRowData: any;
}

const ReportDetailsBody = ({ detailsDefinitions, activeRowData }: IReportDetailsBodyProps) => {
    const idRow = detailsDefinitions.details.find(x => x.rowType === DetailsRowTypeFormatterEnum.Id);
    const idRowData = idRow ? activeRowData[idRow.key] : null;

    return (
        <>
            {idRow && idRowData && (
                <div className="report-details__body-id">
                    {idRow.label && (
                        <div className="report-details__body-id--title">
                            {t(idRow.label)}: {idRowData}
                        </div>
                    )}
                </div>
            )}
            {detailsDefinitions.details.map((detailDefinition, index) => {
                const activeRow = activeRowData[detailDefinition.key];

                // We displayed ID above everything else.
                if (detailDefinition.rowType == DetailsRowTypeFormatterEnum.Id) {
                    return;
                }

                // Grouped elements are displayed differently
                if (detailDefinition.groupId) {
                    const group = detailsDefinitions.details.filter(x => x.groupId === detailDefinition.groupId);

                    // Only display group first time.
                    if (group.findIndex(x => x.key === detailDefinition.key) > 0) {
                        return <React.Fragment key={index}></React.Fragment>;
                    }

                    const groupData = group.map(x => {
                        return {
                            data: activeRowData[x.key],
                            detailDefinition: x,
                        };
                    });

                    return (
                        <div className="report-details__body-group" key={`report-details__body-group-${index}`}>
                            {groupData.map((group, index) => (
                                <div className="report-details__body-row" key={`report-details__body-row-${index}`}>
                                    {group.detailDefinition.label && (
                                        <div className="report-details__body-row--title">
                                            {`${t(group.detailDefinition.label)}`}
                                        </div>
                                    )}
                                    <ReportDetailsRowHandler
                                        value={group.data}
                                        rowData={activeRowData}
                                        detailDefinition={group.detailDefinition}
                                    />
                                </div>
                            ))}
                        </div>
                    );
                }

                return (
                    <div className="report-details__body-row" key={detailDefinition.key}>
                        {detailDefinition.label && (
                            <div>
                                <div className="report-details__body-row--title">
                                    {`${t(detailDefinition.label)}`}
                                    {detailDefinition.toolTip && (
                                        <OverlayTrigger
                                            placement="top"
                                            delay={{ show: 250, hide: 400 }}
                                            overlay={(props: any) => (
                                                <Tooltip {...props}>{t(detailDefinition.toolTip!).toString()}</Tooltip>
                                            )}
                                        >
                                            <div className="report-details__body-row--tooltip">
                                                <ToolTipIcon />
                                            </div>
                                        </OverlayTrigger>
                                    )}
                                </div>
                            </div>
                        )}
                        <ReportDetailsRowHandler
                            value={activeRow}
                            rowData={activeRowData}
                            detailDefinition={detailDefinition}
                        />
                    </div>
                );
            })}
        </>
    );
};

interface IReportDetailsRowHandler {
    detailDefinition: IReportDetailsModel<any>;
    value: any; // prop value
    rowData: any; // row data
}

const ReportDetailsRowHandler = (props: IReportDetailsRowHandler) => {
    const { t } = useTranslation();

    if (props.value === undefined || props.value === null) {
        return <div className="report-details__body-row--data">-</div>;
    }

    switch (props.detailDefinition.rowType) {
        case DetailsRowTypeFormatterEnum.Drill:
            return <ReportDetailsDrillRow {...props} />;
        case DetailsRowTypeFormatterEnum.Currency:
            // we need to set default value. Every currency needs to have field e.g. CurrencyId so we know what currency is
            // we will have special rule for formating ISK currency. Only for them show kr. and for others show code (USD, CAD, DKK...)
            if (props.detailDefinition.format && isCurrencyFormat(props.rowData[props.detailDefinition.format])) {
                let currency = props.rowData[props.detailDefinition.format] as Currency;
                return (
                    <div className="report-details__body-row--data">
                        {formatter(props.value ? Number.parseFloat(props.value) : undefined, currency, {
                            formatOptions: currency === "ISK" ? "Symbol" : "Code",
                            specialRules: (props.detailDefinition.options as CurrencyOptions)?.specialRules ?? [],
                        })}
                    </div>
                );
            }

            return (
                <div className="report-details__body-row--data">
                    {formatter(
                        Number.parseFloat(props.value),
                        "ISK",
                        (props.detailDefinition.options as CurrencyOptions) ?? []
                    )}
                </div>
            );
        case DetailsRowTypeFormatterEnum.Boolean:
            return (
                <div className="report-details__body-row--data">
                    {props.value ? (
                        <>{`${t(StringResources.reportDetails.boolean.yesLabel)}`}</>
                    ) : (
                        <>{`${t(StringResources.reportDetails.boolean.noLabel)}`}</>
                    )}
                </div>
            );
        case DetailsRowTypeFormatterEnum.DateTime:
            return (
                <div className="report-details__body-row--data">
                    <>{formatter(props.value, (props.detailDefinition.format as Datetime) ?? "long")}</>
                </div>
            );
        case DetailsRowTypeFormatterEnum.Date:
            return (
                <div className="report-details__body-row--data">
                    <>{formatter(props.value, (props.detailDefinition.format as Datetime) ?? "date")}</>
                </div>
            );
        case DetailsRowTypeFormatterEnum.List:
            return (
                <div className="report-details__body-row--data">
                    {props.value.length > 0 ? props.value.join(", ") : "-"}
                </div>
            );
        case DetailsRowTypeFormatterEnum.ValueNameList:
            return (
                <div className="report-details__body-row--data--value-name-list">
                    {props.value.length > 0
                        ? (props.value as ILookupResponse<number>[]).map(x => x.name).join("\n")
                        : "-"}
                </div>
            );
        case DetailsRowTypeFormatterEnum.ValueName:
            return (
                <div className="report-details__body-row--data">
                    {(props.value as ILookupResponse<number>).name ?? "-"}
                </div>
            );
        case DetailsRowTypeFormatterEnum.TransactionRefundLabel:
            return (
                <div
                    className={classNames("report-details__body-row--data", {
                        "highlight-light-blue":
                            (props.value as ILookupResponse<number>).value === TransactionType.Refund,
                    })}
                >
                    {(props.value as ILookupResponse<number>).name ?? "-"}
                </div>
            );
        case DetailsRowTypeFormatterEnum.IsFinancialLabel:
            if (props.value === true) {
                return <div className={"report-details__body-row--empty"} />;
            }
            return (
                <div className={classNames("report-details__body-row--data", "highlight-light-orange")}>
                    <span>{`${t(StringResources.reportDetails.nonFinancial)}`}</span>
                </div>
            );

        default:
            return <div className="report-details__body-row--data">{props.value ?? "-"}</div>;
    }
};

const ReportDetailsDrillRow = (props: IReportDetailsRowHandler) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    if (!props.detailDefinition.drill) {
        return <></>;
    }

    if (props.value.length === 0) {
        return <>-</>;
    }

    const onRedirectClickWithDrill = (value: any) => {
        return async () => {
            await dispatch(
                setDrillFilters([
                    {
                        field: props.detailDefinition.drill!.field,
                        value: value,
                    },
                ])
            );

            navigate(props.detailDefinition.drill!.page);
        };
    };

    if (Array.isArray(props.value) && props.value.length > 0) {
        return (
            <div className="report-details__body-row--list-data">
                {props.value
                    .map<React.ReactNode>((value, index) => {
                        return (
                            <div
                                key={index}
                                className="report-details__body-row--drill"
                                onClick={onRedirectClickWithDrill(value)}
                            >
                                {value}
                            </div>
                        );
                    })
                    .reduce((prev, curr) => [prev, " , ", curr])}
            </div>
        );
    }

    return (
        <div
            className="report-details__body-row--data report-details__body-row--drill"
            onClick={onRedirectClickWithDrill(props.value)}
        >
            {props.detailDefinition.drill.label ? `${t(props.detailDefinition.drill.label)}` : props.value}
        </div>
    );
};

export default ReportDetailsBody;
