import { IconSVGProps } from "./types";

const TransactionsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.4406 3.64134L16.7188 2.94879C16.4281 2.65556 16.4281 2.17989 16.7188 1.88666C17.0125 1.59334 17.4875 1.59334 17.7531 1.88663L19.7531 3.88919C19.9219 4.03001 20 4.22122 20 4.42026C20 4.6196 19.9219 4.81175 19.7531 4.92441L17.7531 6.92725C17.4875 7.24645 17.0125 7.24645 16.7188 6.92725C16.4281 6.66125 16.4281 6.18557 16.7188 5.89141L17.4406 5.14347H12C11.5844 5.14347 11.25 4.83365 11.25 4.42026C11.25 4.0056 11.5844 3.66919 12 3.66919L17.4406 3.64134ZM7.97188 14.1563C8.41563 14.1563 8.72188 14.5193 8.72188 14.9073C8.72188 15.3486 8.41563 15.6584 7.97188 15.6584L2.56094 15.6865L3.25312 16.4063C3.57187 16.7005 3.57187 17.1762 3.25312 17.4422C2.9875 17.7614 2.5125 17.7614 2.21969 17.4422L0.219656 15.4393C0.079 15.3267 -9.37417e-06 15.1358 8.3421e-10 14.9073C8.3421e-10 14.7352 0.0790313 14.5443 0.219688 14.4035L2.21969 12.4006C2.5125 12.1096 2.9875 12.1096 3.25312 12.4006C3.57187 12.6948 3.57187 13.1705 3.25312 13.4365L2.56063 14.1844L7.97188 14.1563Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M10.375 4.42013C10.375 5.3186 11.1031 6.01959 12 6.01959H15.0344C15.1406 6.59541 15.4656 7.06482 15.9187 7.35899C15.9719 7.4341 16.0344 7.50607 16.1 7.57492C16.7344 8.2102 17.7656 8.2102 18.4 7.57492L19 6.97094V13.6836C19 14.7883 18.1031 15.6864 17 15.6864H9.44062C9.55937 15.4611 9.59687 15.2076 9.59687 14.9072C9.59687 14.0372 8.89687 13.2799 7.97187 13.2799H4.96562C4.85937 12.7604 4.53437 12.291 4.08125 11.9968C4.02812 11.9217 3.96562 11.8497 3.9 11.7809C3.26562 11.1456 2.23562 11.1456 1.60094 11.7809L1 12.3849V5.67222C1 4.56597 1.89531 3.66938 3 3.66938H10.5594C10.4406 3.89408 10.375 4.14944 10.375 4.42013ZM3 5.64406V7.6469C4.10312 7.6469 5 6.77691 5 5.64406H3ZM17 11.6526C15.8969 11.6526 15 12.5789 15 13.6836H17V11.6526ZM10 6.64548C8.31562 6.64548 7 7.99114 7 9.64974C7 11.3365 8.31562 12.6822 10 12.6822C11.6562 12.6822 13 11.3365 13 9.64974C13 7.99114 11.6562 6.64548 10 6.64548Z"
            fill="#002649"
        />
    </svg>
);

export default TransactionsIcon;
