import { useState, FocusEvent, ChangeEvent } from "react";
import classNames from "classnames";
import { t } from "i18next";
import { StringResources } from "utils/language/languageResource";
import "./baseInput.scss";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import BaseToolTip from "components/baseTooltip/baseToolTip";

type InputELement = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;

type BaseInputProps = InputELement & {
    label?: React.ReactNode;
    labelRef?: React.RefObject<HTMLLabelElement>;
    inputRef?: React.RefObject<HTMLInputElement>;
    disabled?: boolean;
    name?: string;
    autocomplete?: string;
    readonly?: boolean;
    invalid?: string | undefined | boolean;
    isAutoFilled?: (value: boolean) => void;
    toolTipDefinitions?: string[];
};

/**
 *
 * Custom input component that have different types (text, email, password, number, phone number, url)
 * and sizes (lg, default, sm) and other options
 */

const BaseInput: React.FC<BaseInputProps> = ({
    value,
    id,
    label,
    onChange,
    children,
    type,
    disabled,
    name,
    autocomplete,
    readonly,
    invalid,
    onBlur,
    isAutoFilled,
    toolTipDefinitions,
    ...props
}) => {
    const [inputType, setInputType] = useState(type);
    const [isPasswordInput, setIsPasswordInput] = useState(true);
    const [autoFilled, setAutoFilled] = useState(false);

    const inputClasses = classNames("form-control", type === "password" && "form-control--password");

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        onBlur && onBlur(e);
    };

    const togglePasswordField = () => {
        setIsPasswordInput(!isPasswordInput);

        if (inputType === "password") {
            setInputType("text");
        } else {
            setInputType("password");
        }
    };

    const onAnimationStart = (event: { animationName: string }) => {
        if (event.animationName === "onAutoFillStart") {
            setAutoFilled(true);
            isAutoFilled && isAutoFilled(true);
        }
    };

    return (
        <BaseInputWrapper label={label} value={value} autoFilled={autoFilled} invalid={invalid}>
            <div className="input-wrapper">
                <input
                    type={inputType}
                    name={name}
                    className={inputClasses}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={value ?? ""}
                    onAnimationStart={onAnimationStart}
                    id={id}
                    autoComplete={autocomplete}
                    readOnly={readonly}
                    disabled={disabled}
                    {...props}
                />
                {type === "password" && (
                    <button type="button" className="form-control-action" onClick={togglePasswordField}>{`${t(
                        isPasswordInput
                            ? StringResources.input.passwordShowCaption
                            : StringResources.input.passwordHideCaption
                    )}`}</button>
                )}
                {toolTipDefinitions && (
                    <div className="input-wrapper__tool-tip">
                        <BaseToolTip data={toolTipDefinitions} />
                    </div>
                )}
            </div>
        </BaseInputWrapper>
    );
};

export default BaseInput;
