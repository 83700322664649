import { useFormik } from "formik";
import { t } from "i18next";
import { Form, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import { PageTypeEnum, ReportType } from "utils/reportDefinitions";
import * as Yup from "yup";
import "./createOrEditWebhooksModal.scss";
import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import BaseMultiselect, { Option } from "components/baseMultiselect/baseMultiselect";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import { InfoIcon } from "components/icons";

interface ICreateOrEditWebhooksForm {
    webhookName?: string;
    webhookUrl?: string;
    isSubscribedToAllContracts?: boolean;
    contractIds?: Array<number>;
}

interface ICreateOrEditWebhooksForm {
    webhookName?: string;
    webhookUrl?: string;
    isSubscribedToAllContracts?: boolean;
    contractIds?: Array<number>;
}

interface ICreateOrEditWebhooksModalProps {
    data: {
        webhookId?: number;
        webhookName?: string;
        webhookUrl?: string;
        isSubscribedToAllContracts?: boolean;
        contractIds?: Array<number>;
    };
}

const CreateOrEditWebhooks = (props: ICreateOrEditWebhooksModalProps) => {
    const { webhookId, webhookName, webhookUrl, contractIds, isSubscribedToAllContracts } = props.data;
    const contractData = useSelector((state: RootState) => state.dashboard.contractData);
    const dispatch = useAppDispatch();

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnBlur: true,
        initialValues: {
            webhookName: webhookName ?? "",
            webhookUrl: webhookUrl ?? "",
            isSubscribedToAllContracts: isSubscribedToAllContracts,
            contractIds: contractIds,
        },
        validationSchema: Yup.object({
            webhookName: Yup.string().required(t(StringResources.pages.webhooks.errors.webhookNameRequired)),
            webhookUrl: Yup.string()
                .required(t(StringResources.pages.webhooks.errors.webhookUrlRequired))
                .matches(
                    /((https?):\/\/)?([a-z0-9-]+\.)+[a-z]{2,6}(\/[a-zA-Z0-9#-_]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                    t(StringResources.pages.webhooks.errors.incorrectUrl)
                ),
            isSubscribedToAllContracts: Yup.boolean().optional(),
            contractIds: Yup.array()
                .required()
                .when("isSubscribedToAllContracts", (isSubscribedToAllContracts: boolean, schema) => {
                    if (isSubscribedToAllContracts) {
                        return schema.min(0);
                    }
                    return schema.min(1);
                }),
        }),
        onSubmit: async (value: ICreateOrEditWebhooksForm) => {
            if (webhookId) {
                try {
                    const response = await executeAxiosRequestWithRefresh({
                        url: "/api/webhooks/edit",
                        method: "POST",
                        data: {
                            webhookId: webhookId,
                            isSubscribedToAllContracts: value.isSubscribedToAllContracts ?? false,
                            contractIds: value.contractIds,
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditWebhooks }));
                    toast.success(`${t(StringResources.pages[ReportType.Webhooks].editWebhookSuccess)}`);
                } catch (error) {
                    toast.error(`${t(StringResources.pages[ReportType.Webhooks].editWebhookError)}`);
                }

                return;
            }

            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: "/api/webhooks/create",
                    method: "POST",
                    data: {
                        webhookName: value.webhookName,
                        webhookUrl: value.webhookUrl,
                        isSubscribedToAllContracts: value.isSubscribedToAllContracts ?? false,
                        contractIds: value.contractIds,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateOrEditWebhooks }));
                toast.success(`${t(StringResources.pages[ReportType.Webhooks].createWebhookSuccess)}`);
            } catch (error) {
                toast.error(`${t(StringResources.pages[ReportType.Webhooks].createWebhookError)}`);
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreateOrEditWebhooks }));
    };

    const onSubmit = async () => {
        await formValidation.handleSubmit();
    };

    const setIsSubscribedToAllContracts = async () => {
        await formValidation.handleBlur("isSubscribedToAllContracts");
        await formValidation.setFieldValue(
            "isSubscribedToAllContracts",
            !formValidation.values["isSubscribedToAllContracts"]
        );

        if (!formValidation.values["isSubscribedToAllContracts"]) {
            await formValidation.setFieldValue("contractIds", []);
        }

        await formValidation.validateField("contractIds");
    };

    const isFormValid = formValidation.dirty && formValidation.isValid;

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal create-edit-webhooks">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header>
                    {!webhookId
                        ? `${t(StringResources.modal.webhooks.generateWebhookTitle)}`
                        : `${t(StringResources.modal.webhooks.editWebhookTitle)}`}
                </Modal.Header>
                <Modal.Body>
                    {!webhookId && (
                        <div className="create-edit-webhooks__info">
                            <div className="create-edit-webhooks__body">
                                <div className="create-edit-webhooks__icon">
                                    <InfoIcon />
                                </div>
                                <div className="create-edit-webhooks__text">
                                    <p>{`${t(StringResources.pages[PageTypeEnum.Webhooks].modalFirstPharagraph)}`}</p>
                                    <br />
                                    <p>{`${t(StringResources.pages[PageTypeEnum.Webhooks].modalSecondPharagraph)}`}</p>
                                </div>
                            </div>
                        </div>
                    )}
                    <BaseInput
                        type={"text"}
                        name={"webhookName"}
                        autocomplete={"off"}
                        label={`${t(StringResources.pages.webhooks.webhookName)}`}
                        value={formValidation.values["webhookName"]}
                        invalid={formValidation.touched["webhookName"] && formValidation.errors["webhookName"]}
                        onBlur={formValidation.handleBlur}
                        onChange={formValidation.handleChange}
                        disabled={webhookId ? true : false}
                    />

                    <BaseInput
                        type={"text"}
                        name={"webhookUrl"}
                        autocomplete={"off"}
                        label={`${t(StringResources.pages.webhooks.webhookUrl)}`}
                        value={formValidation.values["webhookUrl"]}
                        invalid={formValidation.touched["webhookUrl"] && formValidation.errors["webhookUrl"]}
                        onBlur={formValidation.handleBlur}
                        onChange={formValidation.handleChange}
                        disabled={webhookId ? true : false}
                    />

                    <BaseCheckbox
                        id={"isSubscribedToAllContracts"}
                        checked={formValidation.values["isSubscribedToAllContracts"] ?? false}
                        onChange={setIsSubscribedToAllContracts}
                        label={`${t(StringResources.pages.apiKeys.isSubscribedToAllContracts)}`}
                    />

                    <BaseMultiselect
                        disabled={formValidation.values["isSubscribedToAllContracts"]}
                        options={
                            contractData.map(x => {
                                return {
                                    value: x.contractId,
                                    label: x.contractNumber,
                                    selected:
                                        formValidation.values["contractIds"]?.some((y: number) => y === x.contractId) ??
                                        false,
                                };
                            }) ?? []
                        }
                        showSelectAll={true}
                        label={`${t(StringResources.pages[PageTypeEnum.Webhooks].contractNumbers)}`}
                        onChange={async (selectedItems: Option[]) => {
                            await formValidation.handleBlur("contractIds");
                            await formValidation.setFieldValue(
                                "contractIds",
                                selectedItems.map(x => x.value),
                                true
                            );

                            await formValidation.validateField("contractIds");
                        }}
                    />
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onSubmit}
                        text={!webhookId ? `${t(StringResources.modal.create)}` : `${t(StringResources.modal.save)}`}
                        styleType="solid"
                        disabled={!isFormValid}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default CreateOrEditWebhooks;
