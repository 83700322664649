import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { dismissAnnouncement, getUnreadAnnouncements } from "redux/actions/announcementActions";
import {
    IAnnouncementResponse,
    IAnnouncementState,
    IDismissAnnouncementRequest,
    IDismissAnnouncementResponse,
} from "redux/models/announcementModels";
import { PayloadData } from "redux/models/userModels";

const initialState: IAnnouncementState = {
    unreadAnnouncements: [],
};

const announcementReducer = createSlice({
    name: "announcement",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(
            getUnreadAnnouncements.fulfilled,
            (state, action: PayloadAction<PayloadData<IAnnouncementResponse>>) => {
                state.unreadAnnouncements = action.payload.data.announcements;
            }
        );
        builder.addCase(dismissAnnouncement.fulfilled, (state, action: PayloadAction<IDismissAnnouncementResponse>) => {
            state.unreadAnnouncements = state.unreadAnnouncements.filter(x => x.id !== action.payload.announcementId);
        });
    },
});

const { reducer } = announcementReducer;
export default reducer;
