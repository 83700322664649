import { IconSVGProps } from "./types";

const SmileyIcon = ({ height = 292, width = 290, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_3759_1600)">
            <path
                d="M94.6116 8.25453C96.6823 8.25453 98.361 6.57589 98.361 4.50519C98.361 2.43449 96.6823 0.755859 94.6116 0.755859C92.5409 0.755859 90.8623 2.43449 90.8623 4.50519C90.8623 6.57589 92.5409 8.25453 94.6116 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 8.25453C106.761 8.25453 108.44 6.57589 108.44 4.50519C108.44 2.43449 106.761 0.755859 104.691 0.755859C102.62 0.755859 100.941 2.43449 100.941 4.50519C100.941 6.57589 102.62 8.25453 104.691 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 8.25453C116.836 8.25453 118.514 6.57589 118.514 4.50519C118.514 2.43449 116.836 0.755859 114.765 0.755859C112.694 0.755859 111.016 2.43449 111.016 4.50519C111.016 6.57589 112.694 8.25453 114.765 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 8.25453C126.914 8.25453 128.592 6.57589 128.592 4.50519C128.592 2.43449 126.914 0.755859 124.843 0.755859C122.772 0.755859 121.094 2.43449 121.094 4.50519C121.094 6.57589 122.772 8.25453 124.843 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 8.25453C136.988 8.25453 138.667 6.57589 138.667 4.50519C138.667 2.43449 136.988 0.755859 134.917 0.755859C132.847 0.755859 131.168 2.43449 131.168 4.50519C131.168 6.57589 132.847 8.25453 134.917 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 8.25453C147.067 8.25453 148.746 6.57589 148.746 4.50519C148.746 2.43449 147.067 0.755859 144.996 0.755859C142.926 0.755859 141.247 2.43449 141.247 4.50519C141.247 6.57589 142.926 8.25453 144.996 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 18.3717C66.4558 18.3717 68.1344 16.6931 68.1344 14.6224C68.1344 12.5517 66.4558 10.873 64.3851 10.873C62.3144 10.873 60.6357 12.5517 60.6357 14.6224C60.6357 16.6931 62.3144 18.3717 64.3851 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 18.3717C76.53 18.3717 78.2086 16.6931 78.2086 14.6224C78.2086 12.5517 76.53 10.873 74.4593 10.873C72.3886 10.873 70.71 12.5517 70.71 14.6224C70.71 16.6931 72.3886 18.3717 74.4593 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 18.3717C86.6081 18.3717 88.2868 16.6931 88.2868 14.6224C88.2868 12.5517 86.6081 10.873 84.5374 10.873C82.4667 10.873 80.7881 12.5517 80.7881 14.6224C80.7881 16.6931 82.4667 18.3717 84.5374 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 18.3717C96.6823 18.3717 98.361 16.6931 98.361 14.6224C98.361 12.5517 96.6823 10.873 94.6116 10.873C92.5409 10.873 90.8623 12.5517 90.8623 14.6224C90.8623 16.6931 92.5409 18.3717 94.6116 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 18.3717C106.761 18.3717 108.44 16.6931 108.44 14.6224C108.44 12.5517 106.761 10.873 104.691 10.873C102.62 10.873 100.941 12.5517 100.941 14.6224C100.941 16.6931 102.62 18.3717 104.691 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 18.3717C116.836 18.3717 118.514 16.6931 118.514 14.6224C118.514 12.5517 116.836 10.873 114.765 10.873C112.694 10.873 111.016 12.5517 111.016 14.6224C111.016 16.6931 112.694 18.3717 114.765 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 18.3717C126.914 18.3717 128.592 16.6931 128.592 14.6224C128.592 12.5517 126.914 10.873 124.843 10.873C122.772 10.873 121.094 12.5517 121.094 14.6224C121.094 16.6931 122.772 18.3717 124.843 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 18.3717C136.988 18.3717 138.667 16.6931 138.667 14.6224C138.667 12.5517 136.988 10.873 134.917 10.873C132.847 10.873 131.168 12.5517 131.168 14.6224C131.168 16.6931 132.847 18.3717 134.917 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 18.3717C147.067 18.3717 148.746 16.6931 148.746 14.6224C148.746 12.5517 147.067 10.873 144.996 10.873C142.926 10.873 141.247 12.5517 141.247 14.6224C141.247 16.6931 142.926 18.3717 144.996 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 28.4869C56.3776 28.4869 58.0563 26.8083 58.0563 24.7376C58.0563 22.6669 56.3776 20.9883 54.3069 20.9883C52.2362 20.9883 50.5576 22.6669 50.5576 24.7376C50.5576 26.8083 52.2362 28.4869 54.3069 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 28.4869C66.4558 28.4869 68.1344 26.8083 68.1344 24.7376C68.1344 22.6669 66.4558 20.9883 64.3851 20.9883C62.3144 20.9883 60.6357 22.6669 60.6357 24.7376C60.6357 26.8083 62.3144 28.4869 64.3851 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 28.4869C76.53 28.4869 78.2086 26.8083 78.2086 24.7376C78.2086 22.6669 76.53 20.9883 74.4593 20.9883C72.3886 20.9883 70.71 22.6669 70.71 24.7376C70.71 26.8083 72.3886 28.4869 74.4593 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 28.4869C86.6081 28.4869 88.2868 26.8083 88.2868 24.7376C88.2868 22.6669 86.6081 20.9883 84.5374 20.9883C82.4667 20.9883 80.7881 22.6669 80.7881 24.7376C80.7881 26.8083 82.4667 28.4869 84.5374 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 28.4869C96.6823 28.4869 98.361 26.8083 98.361 24.7376C98.361 22.6669 96.6823 20.9883 94.6116 20.9883C92.5409 20.9883 90.8623 22.6669 90.8623 24.7376C90.8623 26.8083 92.5409 28.4869 94.6116 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 28.4869C106.761 28.4869 108.44 26.8083 108.44 24.7376C108.44 22.6669 106.761 20.9883 104.691 20.9883C102.62 20.9883 100.941 22.6669 100.941 24.7376C100.941 26.8083 102.62 28.4869 104.691 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 28.4869C116.836 28.4869 118.514 26.8083 118.514 24.7376C118.514 22.6669 116.836 20.9883 114.765 20.9883C112.694 20.9883 111.016 22.6669 111.016 24.7376C111.016 26.8083 112.694 28.4869 114.765 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 28.4869C126.914 28.4869 128.592 26.8083 128.592 24.7376C128.592 22.6669 126.914 20.9883 124.843 20.9883C122.772 20.9883 121.094 22.6669 121.094 24.7376C121.094 26.8083 122.772 28.4869 124.843 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 28.4869C136.988 28.4869 138.667 26.8083 138.667 24.7376C138.667 22.6669 136.988 20.9883 134.917 20.9883C132.847 20.9883 131.168 22.6669 131.168 24.7376C131.168 26.8083 132.847 28.4869 134.917 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 28.4869C147.067 28.4869 148.746 26.8083 148.746 24.7376C148.746 22.6669 147.067 20.9883 144.996 20.9883C142.926 20.9883 141.247 22.6669 141.247 24.7376C141.247 26.8083 142.926 28.4869 144.996 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 38.608C46.3034 38.608 47.9821 36.9294 47.9821 34.8587C47.9821 32.788 46.3034 31.1094 44.2327 31.1094C42.162 31.1094 40.4834 32.788 40.4834 34.8587C40.4834 36.9294 42.162 38.608 44.2327 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 38.608C56.3776 38.608 58.0563 36.9294 58.0563 34.8587C58.0563 32.788 56.3776 31.1094 54.3069 31.1094C52.2362 31.1094 50.5576 32.788 50.5576 34.8587C50.5576 36.9294 52.2362 38.608 54.3069 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 38.608C66.4558 38.608 68.1344 36.9294 68.1344 34.8587C68.1344 32.788 66.4558 31.1094 64.3851 31.1094C62.3144 31.1094 60.6357 32.788 60.6357 34.8587C60.6357 36.9294 62.3144 38.608 64.3851 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 38.608C76.53 38.608 78.2086 36.9294 78.2086 34.8587C78.2086 32.788 76.53 31.1094 74.4593 31.1094C72.3886 31.1094 70.71 32.788 70.71 34.8587C70.71 36.9294 72.3886 38.608 74.4593 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 38.608C86.6081 38.608 88.2868 36.9294 88.2868 34.8587C88.2868 32.788 86.6081 31.1094 84.5374 31.1094C82.4667 31.1094 80.7881 32.788 80.7881 34.8587C80.7881 36.9294 82.4667 38.608 84.5374 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 38.608C96.6823 38.608 98.361 36.9294 98.361 34.8587C98.361 32.788 96.6823 31.1094 94.6116 31.1094C92.5409 31.1094 90.8623 32.788 90.8623 34.8587C90.8623 36.9294 92.5409 38.608 94.6116 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 38.608C106.761 38.608 108.44 36.9294 108.44 34.8587C108.44 32.788 106.761 31.1094 104.691 31.1094C102.62 31.1094 100.941 32.788 100.941 34.8587C100.941 36.9294 102.62 38.608 104.691 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 38.608C116.836 38.608 118.514 36.9294 118.514 34.8587C118.514 32.788 116.836 31.1094 114.765 31.1094C112.694 31.1094 111.016 32.788 111.016 34.8587C111.016 36.9294 112.694 38.608 114.765 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 38.608C126.914 38.608 128.592 36.9294 128.592 34.8587C128.592 32.788 126.914 31.1094 124.843 31.1094C122.772 31.1094 121.094 32.788 121.094 34.8587C121.094 36.9294 122.772 38.608 124.843 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 38.608C136.988 38.608 138.667 36.9294 138.667 34.8587C138.667 32.788 136.988 31.1094 134.917 31.1094C132.847 31.1094 131.168 32.788 131.168 34.8587C131.168 36.9294 132.847 38.608 134.917 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 38.608C147.067 38.608 148.746 36.9294 148.746 34.8587C148.746 32.788 147.067 31.1094 144.996 31.1094C142.926 31.1094 141.247 32.788 141.247 34.8587C141.247 36.9294 142.926 38.608 144.996 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 48.7233C36.2243 48.7233 37.903 47.0446 37.903 44.9739C37.903 42.9032 36.2243 41.2246 34.1536 41.2246C32.0829 41.2246 30.4043 42.9032 30.4043 44.9739C30.4043 47.0446 32.0829 48.7233 34.1536 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 48.7233C46.3034 48.7233 47.9821 47.0446 47.9821 44.9739C47.9821 42.9032 46.3034 41.2246 44.2327 41.2246C42.162 41.2246 40.4834 42.9032 40.4834 44.9739C40.4834 47.0446 42.162 48.7233 44.2327 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 48.7233C56.3776 48.7233 58.0563 47.0446 58.0563 44.9739C58.0563 42.9032 56.3776 41.2246 54.3069 41.2246C52.2362 41.2246 50.5576 42.9032 50.5576 44.9739C50.5576 47.0446 52.2362 48.7233 54.3069 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 48.7233C66.4558 48.7233 68.1344 47.0446 68.1344 44.9739C68.1344 42.9032 66.4558 41.2246 64.3851 41.2246C62.3144 41.2246 60.6357 42.9032 60.6357 44.9739C60.6357 47.0446 62.3144 48.7233 64.3851 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 48.7233C76.53 48.7233 78.2086 47.0446 78.2086 44.9739C78.2086 42.9032 76.53 41.2246 74.4593 41.2246C72.3886 41.2246 70.71 42.9032 70.71 44.9739C70.71 47.0446 72.3886 48.7233 74.4593 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 48.7233C86.6081 48.7233 88.2868 47.0446 88.2868 44.9739C88.2868 42.9032 86.6081 41.2246 84.5374 41.2246C82.4667 41.2246 80.7881 42.9032 80.7881 44.9739C80.7881 47.0446 82.4667 48.7233 84.5374 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 48.7233C96.6823 48.7233 98.361 47.0446 98.361 44.9739C98.361 42.9032 96.6823 41.2246 94.6116 41.2246C92.5409 41.2246 90.8623 42.9032 90.8623 44.9739C90.8623 47.0446 92.5409 48.7233 94.6116 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 48.7233C106.761 48.7233 108.44 47.0446 108.44 44.9739C108.44 42.9032 106.761 41.2246 104.691 41.2246C102.62 41.2246 100.941 42.9032 100.941 44.9739C100.941 47.0446 102.62 48.7233 104.691 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 48.7233C116.836 48.7233 118.514 47.0446 118.514 44.9739C118.514 42.9032 116.836 41.2246 114.765 41.2246C112.694 41.2246 111.016 42.9032 111.016 44.9739C111.016 47.0446 112.694 48.7233 114.765 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 48.7233C126.914 48.7233 128.592 47.0446 128.592 44.9739C128.592 42.9032 126.914 41.2246 124.843 41.2246C122.772 41.2246 121.094 42.9032 121.094 44.9739C121.094 47.0446 122.772 48.7233 124.843 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 48.7233C136.988 48.7233 138.667 47.0446 138.667 44.9739C138.667 42.9032 136.988 41.2246 134.917 41.2246C132.847 41.2246 131.168 42.9032 131.168 44.9739C131.168 47.0446 132.847 48.7233 134.917 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 48.7233C147.067 48.7233 148.746 47.0446 148.746 44.9739C148.746 42.9032 147.067 41.2246 144.996 41.2246C142.926 41.2246 141.247 42.9032 141.247 44.9739C141.247 47.0446 142.926 48.7233 144.996 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 58.8405C26.1511 58.8405 27.8297 57.1618 27.8297 55.0911C27.8297 53.0204 26.1511 51.3418 24.0804 51.3418C22.0097 51.3418 20.3311 53.0204 20.3311 55.0911C20.3311 57.1618 22.0097 58.8405 24.0804 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 58.8405C36.2243 58.8405 37.903 57.1618 37.903 55.0911C37.903 53.0204 36.2243 51.3418 34.1536 51.3418C32.0829 51.3418 30.4043 53.0204 30.4043 55.0911C30.4043 57.1618 32.0829 58.8405 34.1536 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 58.8405C46.3034 58.8405 47.9821 57.1618 47.9821 55.0911C47.9821 53.0204 46.3034 51.3418 44.2327 51.3418C42.162 51.3418 40.4834 53.0204 40.4834 55.0911C40.4834 57.1618 42.162 58.8405 44.2327 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 58.8405C56.3776 58.8405 58.0563 57.1618 58.0563 55.0911C58.0563 53.0204 56.3776 51.3418 54.3069 51.3418C52.2362 51.3418 50.5576 53.0204 50.5576 55.0911C50.5576 57.1618 52.2362 58.8405 54.3069 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 58.8405C66.4558 58.8405 68.1344 57.1618 68.1344 55.0911C68.1344 53.0204 66.4558 51.3418 64.3851 51.3418C62.3144 51.3418 60.6357 53.0204 60.6357 55.0911C60.6357 57.1618 62.3144 58.8405 64.3851 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 58.8405C76.53 58.8405 78.2086 57.1618 78.2086 55.0911C78.2086 53.0204 76.53 51.3418 74.4593 51.3418C72.3886 51.3418 70.71 53.0204 70.71 55.0911C70.71 57.1618 72.3886 58.8405 74.4593 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 58.8405C86.6081 58.8405 88.2868 57.1618 88.2868 55.0911C88.2868 53.0204 86.6081 51.3418 84.5374 51.3418C82.4667 51.3418 80.7881 53.0204 80.7881 55.0911C80.7881 57.1618 82.4667 58.8405 84.5374 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 58.8405C96.6823 58.8405 98.361 57.1618 98.361 55.0911C98.361 53.0204 96.6823 51.3418 94.6116 51.3418C92.5409 51.3418 90.8623 53.0204 90.8623 55.0911C90.8623 57.1618 92.5409 58.8405 94.6116 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 58.8405C106.761 58.8405 108.44 57.1618 108.44 55.0911C108.44 53.0204 106.761 51.3418 104.691 51.3418C102.62 51.3418 100.941 53.0204 100.941 55.0911C100.941 57.1618 102.62 58.8405 104.691 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 58.8405C116.836 58.8405 118.514 57.1618 118.514 55.0911C118.514 53.0204 116.836 51.3418 114.765 51.3418C112.694 51.3418 111.016 53.0204 111.016 55.0911C111.016 57.1618 112.694 58.8405 114.765 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 58.8405C126.914 58.8405 128.592 57.1618 128.592 55.0911C128.592 53.0204 126.914 51.3418 124.843 51.3418C122.772 51.3418 121.094 53.0204 121.094 55.0911C121.094 57.1618 122.772 58.8405 124.843 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 58.8405C136.988 58.8405 138.667 57.1618 138.667 55.0911C138.667 53.0204 136.988 51.3418 134.917 51.3418C132.847 51.3418 131.168 53.0204 131.168 55.0911C131.168 57.1618 132.847 58.8405 134.917 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 58.8405C147.067 58.8405 148.746 57.1618 148.746 55.0911C148.746 53.0204 147.067 51.3418 144.996 51.3418C142.926 51.3418 141.247 53.0204 141.247 55.0911C141.247 57.1618 142.926 58.8405 144.996 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 68.9576C26.1511 68.9576 27.8297 67.279 27.8297 65.2083C27.8297 63.1376 26.1511 61.459 24.0804 61.459C22.0097 61.459 20.3311 63.1376 20.3311 65.2083C20.3311 67.279 22.0097 68.9576 24.0804 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 68.9576C36.2243 68.9576 37.903 67.279 37.903 65.2083C37.903 63.1376 36.2243 61.459 34.1536 61.459C32.0829 61.459 30.4043 63.1376 30.4043 65.2083C30.4043 67.279 32.0829 68.9576 34.1536 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 68.9576C46.3034 68.9576 47.9821 67.279 47.9821 65.2083C47.9821 63.1376 46.3034 61.459 44.2327 61.459C42.162 61.459 40.4834 63.1376 40.4834 65.2083C40.4834 67.279 42.162 68.9576 44.2327 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 68.9576C56.3776 68.9576 58.0563 67.279 58.0563 65.2083C58.0563 63.1376 56.3776 61.459 54.3069 61.459C52.2362 61.459 50.5576 63.1376 50.5576 65.2083C50.5576 67.279 52.2362 68.9576 54.3069 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 68.9576C66.4558 68.9576 68.1344 67.279 68.1344 65.2083C68.1344 63.1376 66.4558 61.459 64.3851 61.459C62.3144 61.459 60.6357 63.1376 60.6357 65.2083C60.6357 67.279 62.3144 68.9576 64.3851 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 68.9576C76.53 68.9576 78.2086 67.279 78.2086 65.2083C78.2086 63.1376 76.53 61.459 74.4593 61.459C72.3886 61.459 70.71 63.1376 70.71 65.2083C70.71 67.279 72.3886 68.9576 74.4593 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 68.9576C86.6081 68.9576 88.2868 67.279 88.2868 65.2083C88.2868 63.1376 86.6081 61.459 84.5374 61.459C82.4667 61.459 80.7881 63.1376 80.7881 65.2083C80.7881 67.279 82.4667 68.9576 84.5374 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 68.9576C96.6823 68.9576 98.361 67.279 98.361 65.2083C98.361 63.1376 96.6823 61.459 94.6116 61.459C92.5409 61.459 90.8623 63.1376 90.8623 65.2083C90.8623 67.279 92.5409 68.9576 94.6116 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 68.9576C126.914 68.9576 128.592 67.279 128.592 65.2083C128.592 63.1376 126.914 61.459 124.843 61.459C122.772 61.459 121.094 63.1376 121.094 65.2083C121.094 67.279 122.772 68.9576 124.843 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 68.9576C136.988 68.9576 138.667 67.279 138.667 65.2083C138.667 63.1376 136.988 61.459 134.917 61.459C132.847 61.459 131.168 63.1376 131.168 65.2083C131.168 67.279 132.847 68.9576 134.917 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 68.9576C147.067 68.9576 148.746 67.279 148.746 65.2083C148.746 63.1376 147.067 61.459 144.996 61.459C142.926 61.459 141.247 63.1376 141.247 65.2083C141.247 67.279 142.926 68.9576 144.996 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 79.0787C16.072 79.0787 17.7506 77.4001 17.7506 75.3294C17.7506 73.2587 16.072 71.5801 14.0013 71.5801C11.9306 71.5801 10.252 73.2587 10.252 75.3294C10.252 77.4001 11.9306 79.0787 14.0013 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 79.0787C26.1511 79.0787 27.8297 77.4001 27.8297 75.3294C27.8297 73.2587 26.1511 71.5801 24.0804 71.5801C22.0097 71.5801 20.3311 73.2587 20.3311 75.3294C20.3311 77.4001 22.0097 79.0787 24.0804 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 79.0787C36.2243 79.0787 37.903 77.4001 37.903 75.3294C37.903 73.2587 36.2243 71.5801 34.1536 71.5801C32.0829 71.5801 30.4043 73.2587 30.4043 75.3294C30.4043 77.4001 32.0829 79.0787 34.1536 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 79.0787C46.3034 79.0787 47.9821 77.4001 47.9821 75.3294C47.9821 73.2587 46.3034 71.5801 44.2327 71.5801C42.162 71.5801 40.4834 73.2587 40.4834 75.3294C40.4834 77.4001 42.162 79.0787 44.2327 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 79.0787C56.3776 79.0787 58.0563 77.4001 58.0563 75.3294C58.0563 73.2587 56.3776 71.5801 54.3069 71.5801C52.2362 71.5801 50.5576 73.2587 50.5576 75.3294C50.5576 77.4001 52.2362 79.0787 54.3069 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 79.0787C66.4558 79.0787 68.1344 77.4001 68.1344 75.3294C68.1344 73.2587 66.4558 71.5801 64.3851 71.5801C62.3144 71.5801 60.6357 73.2587 60.6357 75.3294C60.6357 77.4001 62.3144 79.0787 64.3851 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 79.0787C76.53 79.0787 78.2086 77.4001 78.2086 75.3294C78.2086 73.2587 76.53 71.5801 74.4593 71.5801C72.3886 71.5801 70.71 73.2587 70.71 75.3294C70.71 77.4001 72.3886 79.0787 74.4593 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 79.0787C86.6081 79.0787 88.2868 77.4001 88.2868 75.3294C88.2868 73.2587 86.6081 71.5801 84.5374 71.5801C82.4667 71.5801 80.7881 73.2587 80.7881 75.3294C80.7881 77.4001 82.4667 79.0787 84.5374 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 79.0787C96.6823 79.0787 98.361 77.4001 98.361 75.3294C98.361 73.2587 96.6823 71.5801 94.6116 71.5801C92.5409 71.5801 90.8623 73.2587 90.8623 75.3294C90.8623 77.4001 92.5409 79.0787 94.6116 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 79.0787C126.914 79.0787 128.592 77.4001 128.592 75.3294C128.592 73.2587 126.914 71.5801 124.843 71.5801C122.772 71.5801 121.094 73.2587 121.094 75.3294C121.094 77.4001 122.772 79.0787 124.843 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 79.0787C136.988 79.0787 138.667 77.4001 138.667 75.3294C138.667 73.2587 136.988 71.5801 134.917 71.5801C132.847 71.5801 131.168 73.2587 131.168 75.3294C131.168 77.4001 132.847 79.0787 134.917 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 79.0787C147.067 79.0787 148.746 77.4001 148.746 75.3294C148.746 73.2587 147.067 71.5801 144.996 71.5801C142.926 71.5801 141.247 73.2587 141.247 75.3294C141.247 77.4001 142.926 79.0787 144.996 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 89.194C16.072 89.194 17.7506 87.5153 17.7506 85.4446C17.7506 83.3739 16.072 81.6953 14.0013 81.6953C11.9306 81.6953 10.252 83.3739 10.252 85.4446C10.252 87.5153 11.9306 89.194 14.0013 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 89.194C26.1511 89.194 27.8297 87.5153 27.8297 85.4446C27.8297 83.3739 26.1511 81.6953 24.0804 81.6953C22.0097 81.6953 20.3311 83.3739 20.3311 85.4446C20.3311 87.5153 22.0097 89.194 24.0804 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 89.194C36.2243 89.194 37.903 87.5153 37.903 85.4446C37.903 83.3739 36.2243 81.6953 34.1536 81.6953C32.0829 81.6953 30.4043 83.3739 30.4043 85.4446C30.4043 87.5153 32.0829 89.194 34.1536 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 89.194C46.3034 89.194 47.9821 87.5153 47.9821 85.4446C47.9821 83.3739 46.3034 81.6953 44.2327 81.6953C42.162 81.6953 40.4834 83.3739 40.4834 85.4446C40.4834 87.5153 42.162 89.194 44.2327 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 89.194C56.3776 89.194 58.0563 87.5153 58.0563 85.4446C58.0563 83.3739 56.3776 81.6953 54.3069 81.6953C52.2362 81.6953 50.5576 83.3739 50.5576 85.4446C50.5576 87.5153 52.2362 89.194 54.3069 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 89.194C66.4558 89.194 68.1344 87.5153 68.1344 85.4446C68.1344 83.3739 66.4558 81.6953 64.3851 81.6953C62.3144 81.6953 60.6357 83.3739 60.6357 85.4446C60.6357 87.5153 62.3144 89.194 64.3851 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 89.194C76.53 89.194 78.2086 87.5153 78.2086 85.4446C78.2086 83.3739 76.53 81.6953 74.4593 81.6953C72.3886 81.6953 70.71 83.3739 70.71 85.4446C70.71 87.5153 72.3886 89.194 74.4593 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 89.194C86.6081 89.194 88.2868 87.5153 88.2868 85.4446C88.2868 83.3739 86.6081 81.6953 84.5374 81.6953C82.4667 81.6953 80.7881 83.3739 80.7881 85.4446C80.7881 87.5153 82.4667 89.194 84.5374 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 89.194C96.6823 89.194 98.361 87.5153 98.361 85.4446C98.361 83.3739 96.6823 81.6953 94.6116 81.6953C92.5409 81.6953 90.8623 83.3739 90.8623 85.4446C90.8623 87.5153 92.5409 89.194 94.6116 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 89.194C126.914 89.194 128.592 87.5153 128.592 85.4446C128.592 83.3739 126.914 81.6953 124.843 81.6953C122.772 81.6953 121.094 83.3739 121.094 85.4446C121.094 87.5153 122.772 89.194 124.843 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 89.194C136.988 89.194 138.667 87.5153 138.667 85.4446C138.667 83.3739 136.988 81.6953 134.917 81.6953C132.847 81.6953 131.168 83.3739 131.168 85.4446C131.168 87.5153 132.847 89.194 134.917 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 89.194C147.067 89.194 148.746 87.5153 148.746 85.4446C148.746 83.3739 147.067 81.6953 144.996 81.6953C142.926 81.6953 141.247 83.3739 141.247 85.4446C141.247 87.5153 142.926 89.194 144.996 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 99.3112C5.99777 99.3112 7.6764 97.6325 7.6764 95.5618C7.6764 93.4911 5.99777 91.8125 3.92707 91.8125C1.85637 91.8125 0.177734 93.4911 0.177734 95.5618C0.177734 97.6325 1.85637 99.3112 3.92707 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 99.3112C16.072 99.3112 17.7506 97.6325 17.7506 95.5618C17.7506 93.4911 16.072 91.8125 14.0013 91.8125C11.9306 91.8125 10.252 93.4911 10.252 95.5618C10.252 97.6325 11.9306 99.3112 14.0013 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 99.3112C26.1511 99.3112 27.8297 97.6325 27.8297 95.5618C27.8297 93.4911 26.1511 91.8125 24.0804 91.8125C22.0097 91.8125 20.3311 93.4911 20.3311 95.5618C20.3311 97.6325 22.0097 99.3112 24.0804 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 99.3112C36.2243 99.3112 37.903 97.6325 37.903 95.5618C37.903 93.4911 36.2243 91.8125 34.1536 91.8125C32.0829 91.8125 30.4043 93.4911 30.4043 95.5618C30.4043 97.6325 32.0829 99.3112 34.1536 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 99.3112C46.3034 99.3112 47.9821 97.6325 47.9821 95.5618C47.9821 93.4911 46.3034 91.8125 44.2327 91.8125C42.162 91.8125 40.4834 93.4911 40.4834 95.5618C40.4834 97.6325 42.162 99.3112 44.2327 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 99.3112C56.3776 99.3112 58.0563 97.6325 58.0563 95.5618C58.0563 93.4911 56.3776 91.8125 54.3069 91.8125C52.2362 91.8125 50.5576 93.4911 50.5576 95.5618C50.5576 97.6325 52.2362 99.3112 54.3069 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 99.3112C66.4558 99.3112 68.1344 97.6325 68.1344 95.5618C68.1344 93.4911 66.4558 91.8125 64.3851 91.8125C62.3144 91.8125 60.6357 93.4911 60.6357 95.5618C60.6357 97.6325 62.3144 99.3112 64.3851 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 99.3112C76.53 99.3112 78.2086 97.6325 78.2086 95.5618C78.2086 93.4911 76.53 91.8125 74.4593 91.8125C72.3886 91.8125 70.71 93.4911 70.71 95.5618C70.71 97.6325 72.3886 99.3112 74.4593 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 99.3112C86.6081 99.3112 88.2868 97.6325 88.2868 95.5618C88.2868 93.4911 86.6081 91.8125 84.5374 91.8125C82.4667 91.8125 80.7881 93.4911 80.7881 95.5618C80.7881 97.6325 82.4667 99.3112 84.5374 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 99.3112C96.6823 99.3112 98.361 97.6325 98.361 95.5618C98.361 93.4911 96.6823 91.8125 94.6116 91.8125C92.5409 91.8125 90.8623 93.4911 90.8623 95.5618C90.8623 97.6325 92.5409 99.3112 94.6116 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 99.3112C126.914 99.3112 128.592 97.6325 128.592 95.5618C128.592 93.4911 126.914 91.8125 124.843 91.8125C122.772 91.8125 121.094 93.4911 121.094 95.5618C121.094 97.6325 122.772 99.3112 124.843 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 99.3112C136.988 99.3112 138.667 97.6325 138.667 95.5618C138.667 93.4911 136.988 91.8125 134.917 91.8125C132.847 91.8125 131.168 93.4911 131.168 95.5618C131.168 97.6325 132.847 99.3112 134.917 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 99.3112C147.067 99.3112 148.746 97.6325 148.746 95.5618C148.746 93.4911 147.067 91.8125 144.996 91.8125C142.926 91.8125 141.247 93.4911 141.247 95.5618C141.247 97.6325 142.926 99.3112 144.996 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 109.426C5.99777 109.426 7.6764 107.748 7.6764 105.677C7.6764 103.606 5.99777 101.928 3.92707 101.928C1.85637 101.928 0.177734 103.606 0.177734 105.677C0.177734 107.748 1.85637 109.426 3.92707 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 109.426C16.072 109.426 17.7506 107.748 17.7506 105.677C17.7506 103.606 16.072 101.928 14.0013 101.928C11.9306 101.928 10.252 103.606 10.252 105.677C10.252 107.748 11.9306 109.426 14.0013 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 109.426C26.1511 109.426 27.8297 107.748 27.8297 105.677C27.8297 103.606 26.1511 101.928 24.0804 101.928C22.0097 101.928 20.3311 103.606 20.3311 105.677C20.3311 107.748 22.0097 109.426 24.0804 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 109.426C36.2243 109.426 37.903 107.748 37.903 105.677C37.903 103.606 36.2243 101.928 34.1536 101.928C32.0829 101.928 30.4043 103.606 30.4043 105.677C30.4043 107.748 32.0829 109.426 34.1536 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 109.426C46.3034 109.426 47.9821 107.748 47.9821 105.677C47.9821 103.606 46.3034 101.928 44.2327 101.928C42.162 101.928 40.4834 103.606 40.4834 105.677C40.4834 107.748 42.162 109.426 44.2327 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 109.426C56.3776 109.426 58.0563 107.748 58.0563 105.677C58.0563 103.606 56.3776 101.928 54.3069 101.928C52.2362 101.928 50.5576 103.606 50.5576 105.677C50.5576 107.748 52.2362 109.426 54.3069 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 109.426C66.4558 109.426 68.1344 107.748 68.1344 105.677C68.1344 103.606 66.4558 101.928 64.3851 101.928C62.3144 101.928 60.6357 103.606 60.6357 105.677C60.6357 107.748 62.3144 109.426 64.3851 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 109.426C76.53 109.426 78.2086 107.748 78.2086 105.677C78.2086 103.606 76.53 101.928 74.4593 101.928C72.3886 101.928 70.71 103.606 70.71 105.677C70.71 107.748 72.3886 109.426 74.4593 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 109.426C86.6081 109.426 88.2868 107.748 88.2868 105.677C88.2868 103.606 86.6081 101.928 84.5374 101.928C82.4667 101.928 80.7881 103.606 80.7881 105.677C80.7881 107.748 82.4667 109.426 84.5374 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 109.426C96.6823 109.426 98.361 107.748 98.361 105.677C98.361 103.606 96.6823 101.928 94.6116 101.928C92.5409 101.928 90.8623 103.606 90.8623 105.677C90.8623 107.748 92.5409 109.426 94.6116 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 109.426C126.914 109.426 128.592 107.748 128.592 105.677C128.592 103.606 126.914 101.928 124.843 101.928C122.772 101.928 121.094 103.606 121.094 105.677C121.094 107.748 122.772 109.426 124.843 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 109.426C136.988 109.426 138.667 107.748 138.667 105.677C138.667 103.606 136.988 101.928 134.917 101.928C132.847 101.928 131.168 103.606 131.168 105.677C131.168 107.748 132.847 109.426 134.917 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 109.426C147.067 109.426 148.746 107.748 148.746 105.677C148.746 103.606 147.067 101.928 144.996 101.928C142.926 101.928 141.247 103.606 141.247 105.677C141.247 107.748 142.926 109.426 144.996 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 119.547C5.99777 119.547 7.6764 117.869 7.6764 115.798C7.6764 113.727 5.99777 112.049 3.92707 112.049C1.85637 112.049 0.177734 113.727 0.177734 115.798C0.177734 117.869 1.85637 119.547 3.92707 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 119.547C16.072 119.547 17.7506 117.869 17.7506 115.798C17.7506 113.727 16.072 112.049 14.0013 112.049C11.9306 112.049 10.252 113.727 10.252 115.798C10.252 117.869 11.9306 119.547 14.0013 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 119.547C26.1511 119.547 27.8297 117.869 27.8297 115.798C27.8297 113.727 26.1511 112.049 24.0804 112.049C22.0097 112.049 20.3311 113.727 20.3311 115.798C20.3311 117.869 22.0097 119.547 24.0804 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 119.547C36.2243 119.547 37.903 117.869 37.903 115.798C37.903 113.727 36.2243 112.049 34.1536 112.049C32.0829 112.049 30.4043 113.727 30.4043 115.798C30.4043 117.869 32.0829 119.547 34.1536 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 119.547C46.3034 119.547 47.9821 117.869 47.9821 115.798C47.9821 113.727 46.3034 112.049 44.2327 112.049C42.162 112.049 40.4834 113.727 40.4834 115.798C40.4834 117.869 42.162 119.547 44.2327 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 119.547C56.3776 119.547 58.0563 117.869 58.0563 115.798C58.0563 113.727 56.3776 112.049 54.3069 112.049C52.2362 112.049 50.5576 113.727 50.5576 115.798C50.5576 117.869 52.2362 119.547 54.3069 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 119.547C66.4558 119.547 68.1344 117.869 68.1344 115.798C68.1344 113.727 66.4558 112.049 64.3851 112.049C62.3144 112.049 60.6357 113.727 60.6357 115.798C60.6357 117.869 62.3144 119.547 64.3851 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 119.547C76.53 119.547 78.2086 117.869 78.2086 115.798C78.2086 113.727 76.53 112.049 74.4593 112.049C72.3886 112.049 70.71 113.727 70.71 115.798C70.71 117.869 72.3886 119.547 74.4593 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 119.547C86.6081 119.547 88.2868 117.869 88.2868 115.798C88.2868 113.727 86.6081 112.049 84.5374 112.049C82.4667 112.049 80.7881 113.727 80.7881 115.798C80.7881 117.869 82.4667 119.547 84.5374 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 119.547C96.6823 119.547 98.361 117.869 98.361 115.798C98.361 113.727 96.6823 112.049 94.6116 112.049C92.5409 112.049 90.8623 113.727 90.8623 115.798C90.8623 117.869 92.5409 119.547 94.6116 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 119.547C126.914 119.547 128.592 117.869 128.592 115.798C128.592 113.727 126.914 112.049 124.843 112.049C122.772 112.049 121.094 113.727 121.094 115.798C121.094 117.869 122.772 119.547 124.843 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 119.547C136.988 119.547 138.667 117.869 138.667 115.798C138.667 113.727 136.988 112.049 134.917 112.049C132.847 112.049 131.168 113.727 131.168 115.798C131.168 117.869 132.847 119.547 134.917 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 119.547C147.067 119.547 148.746 117.869 148.746 115.798C148.746 113.727 147.067 112.049 144.996 112.049C142.926 112.049 141.247 113.727 141.247 115.798C141.247 117.869 142.926 119.547 144.996 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 129.665C5.99777 129.665 7.6764 127.986 7.6764 125.915C7.6764 123.845 5.99777 122.166 3.92707 122.166C1.85637 122.166 0.177734 123.845 0.177734 125.915C0.177734 127.986 1.85637 129.665 3.92707 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 129.665C16.072 129.665 17.7506 127.986 17.7506 125.915C17.7506 123.845 16.072 122.166 14.0013 122.166C11.9306 122.166 10.252 123.845 10.252 125.915C10.252 127.986 11.9306 129.665 14.0013 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 129.665C26.1511 129.665 27.8297 127.986 27.8297 125.915C27.8297 123.845 26.1511 122.166 24.0804 122.166C22.0097 122.166 20.3311 123.845 20.3311 125.915C20.3311 127.986 22.0097 129.665 24.0804 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 129.665C36.2243 129.665 37.903 127.986 37.903 125.915C37.903 123.845 36.2243 122.166 34.1536 122.166C32.0829 122.166 30.4043 123.845 30.4043 125.915C30.4043 127.986 32.0829 129.665 34.1536 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 129.665C46.3034 129.665 47.9821 127.986 47.9821 125.915C47.9821 123.845 46.3034 122.166 44.2327 122.166C42.162 122.166 40.4834 123.845 40.4834 125.915C40.4834 127.986 42.162 129.665 44.2327 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 129.665C56.3776 129.665 58.0563 127.986 58.0563 125.915C58.0563 123.845 56.3776 122.166 54.3069 122.166C52.2362 122.166 50.5576 123.845 50.5576 125.915C50.5576 127.986 52.2362 129.665 54.3069 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 129.665C66.4558 129.665 68.1344 127.986 68.1344 125.915C68.1344 123.845 66.4558 122.166 64.3851 122.166C62.3144 122.166 60.6357 123.845 60.6357 125.915C60.6357 127.986 62.3144 129.665 64.3851 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 129.665C76.53 129.665 78.2086 127.986 78.2086 125.915C78.2086 123.845 76.53 122.166 74.4593 122.166C72.3886 122.166 70.71 123.845 70.71 125.915C70.71 127.986 72.3886 129.665 74.4593 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 129.665C86.6081 129.665 88.2868 127.986 88.2868 125.915C88.2868 123.845 86.6081 122.166 84.5374 122.166C82.4667 122.166 80.7881 123.845 80.7881 125.915C80.7881 127.986 82.4667 129.665 84.5374 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 129.665C96.6823 129.665 98.361 127.986 98.361 125.915C98.361 123.845 96.6823 122.166 94.6116 122.166C92.5409 122.166 90.8623 123.845 90.8623 125.915C90.8623 127.986 92.5409 129.665 94.6116 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 129.665C106.761 129.665 108.44 127.986 108.44 125.915C108.44 123.845 106.761 122.166 104.691 122.166C102.62 122.166 100.941 123.845 100.941 125.915C100.941 127.986 102.62 129.665 104.691 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 129.665C116.836 129.665 118.514 127.986 118.514 125.915C118.514 123.845 116.836 122.166 114.765 122.166C112.694 122.166 111.016 123.845 111.016 125.915C111.016 127.986 112.694 129.665 114.765 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 129.665C126.914 129.665 128.592 127.986 128.592 125.915C128.592 123.845 126.914 122.166 124.843 122.166C122.772 122.166 121.094 123.845 121.094 125.915C121.094 127.986 122.772 129.665 124.843 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 129.665C136.988 129.665 138.667 127.986 138.667 125.915C138.667 123.845 136.988 122.166 134.917 122.166C132.847 122.166 131.168 123.845 131.168 125.915C131.168 127.986 132.847 129.665 134.917 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 129.665C147.067 129.665 148.746 127.986 148.746 125.915C148.746 123.845 147.067 122.166 144.996 122.166C142.926 122.166 141.247 123.845 141.247 125.915C141.247 127.986 142.926 129.665 144.996 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 139.78C5.99777 139.78 7.6764 138.101 7.6764 136.031C7.6764 133.96 5.99777 132.281 3.92707 132.281C1.85637 132.281 0.177734 133.96 0.177734 136.031C0.177734 138.101 1.85637 139.78 3.92707 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 139.78C16.072 139.78 17.7506 138.101 17.7506 136.031C17.7506 133.96 16.072 132.281 14.0013 132.281C11.9306 132.281 10.252 133.96 10.252 136.031C10.252 138.101 11.9306 139.78 14.0013 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 139.78C26.1511 139.78 27.8297 138.101 27.8297 136.031C27.8297 133.96 26.1511 132.281 24.0804 132.281C22.0097 132.281 20.3311 133.96 20.3311 136.031C20.3311 138.101 22.0097 139.78 24.0804 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 139.78C36.2243 139.78 37.903 138.101 37.903 136.031C37.903 133.96 36.2243 132.281 34.1536 132.281C32.0829 132.281 30.4043 133.96 30.4043 136.031C30.4043 138.101 32.0829 139.78 34.1536 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 139.78C46.3034 139.78 47.9821 138.101 47.9821 136.031C47.9821 133.96 46.3034 132.281 44.2327 132.281C42.162 132.281 40.4834 133.96 40.4834 136.031C40.4834 138.101 42.162 139.78 44.2327 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 139.78C56.3776 139.78 58.0563 138.101 58.0563 136.031C58.0563 133.96 56.3776 132.281 54.3069 132.281C52.2362 132.281 50.5576 133.96 50.5576 136.031C50.5576 138.101 52.2362 139.78 54.3069 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 139.78C66.4558 139.78 68.1344 138.101 68.1344 136.031C68.1344 133.96 66.4558 132.281 64.3851 132.281C62.3144 132.281 60.6357 133.96 60.6357 136.031C60.6357 138.101 62.3144 139.78 64.3851 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 139.78C76.53 139.78 78.2086 138.101 78.2086 136.031C78.2086 133.96 76.53 132.281 74.4593 132.281C72.3886 132.281 70.71 133.96 70.71 136.031C70.71 138.101 72.3886 139.78 74.4593 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 139.78C86.6081 139.78 88.2868 138.101 88.2868 136.031C88.2868 133.96 86.6081 132.281 84.5374 132.281C82.4667 132.281 80.7881 133.96 80.7881 136.031C80.7881 138.101 82.4667 139.78 84.5374 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 139.78C96.6823 139.78 98.361 138.101 98.361 136.031C98.361 133.96 96.6823 132.281 94.6116 132.281C92.5409 132.281 90.8623 133.96 90.8623 136.031C90.8623 138.101 92.5409 139.78 94.6116 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 139.78C106.761 139.78 108.44 138.101 108.44 136.031C108.44 133.96 106.761 132.281 104.691 132.281C102.62 132.281 100.941 133.96 100.941 136.031C100.941 138.101 102.62 139.78 104.691 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 139.78C116.836 139.78 118.514 138.101 118.514 136.031C118.514 133.96 116.836 132.281 114.765 132.281C112.694 132.281 111.016 133.96 111.016 136.031C111.016 138.101 112.694 139.78 114.765 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 139.78C126.914 139.78 128.592 138.101 128.592 136.031C128.592 133.96 126.914 132.281 124.843 132.281C122.772 132.281 121.094 133.96 121.094 136.031C121.094 138.101 122.772 139.78 124.843 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 139.78C136.988 139.78 138.667 138.101 138.667 136.031C138.667 133.96 136.988 132.281 134.917 132.281C132.847 132.281 131.168 133.96 131.168 136.031C131.168 138.101 132.847 139.78 134.917 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 139.78C147.067 139.78 148.746 138.101 148.746 136.031C148.746 133.96 147.067 132.281 144.996 132.281C142.926 132.281 141.247 133.96 141.247 136.031C141.247 138.101 142.926 139.78 144.996 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 149.897C5.99777 149.897 7.6764 148.218 7.6764 146.148C7.6764 144.077 5.99777 142.398 3.92707 142.398C1.85637 142.398 0.177734 144.077 0.177734 146.148C0.177734 148.218 1.85637 149.897 3.92707 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 149.897C16.072 149.897 17.7506 148.218 17.7506 146.148C17.7506 144.077 16.072 142.398 14.0013 142.398C11.9306 142.398 10.252 144.077 10.252 146.148C10.252 148.218 11.9306 149.897 14.0013 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 149.897C26.1511 149.897 27.8297 148.218 27.8297 146.148C27.8297 144.077 26.1511 142.398 24.0804 142.398C22.0097 142.398 20.3311 144.077 20.3311 146.148C20.3311 148.218 22.0097 149.897 24.0804 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 149.897C36.2243 149.897 37.903 148.218 37.903 146.148C37.903 144.077 36.2243 142.398 34.1536 142.398C32.0829 142.398 30.4043 144.077 30.4043 146.148C30.4043 148.218 32.0829 149.897 34.1536 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 149.897C46.3034 149.897 47.9821 148.218 47.9821 146.148C47.9821 144.077 46.3034 142.398 44.2327 142.398C42.162 142.398 40.4834 144.077 40.4834 146.148C40.4834 148.218 42.162 149.897 44.2327 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 149.897C56.3776 149.897 58.0563 148.218 58.0563 146.148C58.0563 144.077 56.3776 142.398 54.3069 142.398C52.2362 142.398 50.5576 144.077 50.5576 146.148C50.5576 148.218 52.2362 149.897 54.3069 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 149.897C66.4558 149.897 68.1344 148.218 68.1344 146.148C68.1344 144.077 66.4558 142.398 64.3851 142.398C62.3144 142.398 60.6357 144.077 60.6357 146.148C60.6357 148.218 62.3144 149.897 64.3851 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 149.897C76.53 149.897 78.2086 148.218 78.2086 146.148C78.2086 144.077 76.53 142.398 74.4593 142.398C72.3886 142.398 70.71 144.077 70.71 146.148C70.71 148.218 72.3886 149.897 74.4593 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 149.897C86.6081 149.897 88.2868 148.218 88.2868 146.148C88.2868 144.077 86.6081 142.398 84.5374 142.398C82.4667 142.398 80.7881 144.077 80.7881 146.148C80.7881 148.218 82.4667 149.897 84.5374 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 149.897C96.6823 149.897 98.361 148.218 98.361 146.148C98.361 144.077 96.6823 142.398 94.6116 142.398C92.5409 142.398 90.8623 144.077 90.8623 146.148C90.8623 148.218 92.5409 149.897 94.6116 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 149.897C106.761 149.897 108.44 148.218 108.44 146.148C108.44 144.077 106.761 142.398 104.691 142.398C102.62 142.398 100.941 144.077 100.941 146.148C100.941 148.218 102.62 149.897 104.691 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 149.897C116.836 149.897 118.514 148.218 118.514 146.148C118.514 144.077 116.836 142.398 114.765 142.398C112.694 142.398 111.016 144.077 111.016 146.148C111.016 148.218 112.694 149.897 114.765 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 149.897C126.914 149.897 128.592 148.218 128.592 146.148C128.592 144.077 126.914 142.398 124.843 142.398C122.772 142.398 121.094 144.077 121.094 146.148C121.094 148.218 122.772 149.897 124.843 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 149.897C136.988 149.897 138.667 148.218 138.667 146.148C138.667 144.077 136.988 142.398 134.917 142.398C132.847 142.398 131.168 144.077 131.168 146.148C131.168 148.218 132.847 149.897 134.917 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 149.897C147.067 149.897 148.746 148.218 148.746 146.148C148.746 144.077 147.067 142.398 144.996 142.398C142.926 142.398 141.247 144.077 141.247 146.148C141.247 148.218 142.926 149.897 144.996 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 8.25453C197.446 8.25453 199.125 6.57589 199.125 4.50519C199.125 2.43449 197.446 0.755859 195.375 0.755859C193.305 0.755859 191.626 2.43449 191.626 4.50519C191.626 6.57589 193.305 8.25453 195.375 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 8.25453C187.368 8.25453 189.047 6.57589 189.047 4.50519C189.047 2.43449 187.368 0.755859 185.297 0.755859C183.226 0.755859 181.548 2.43449 181.548 4.50519C181.548 6.57589 183.226 8.25453 185.297 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 8.25453C177.294 8.25453 178.972 6.57589 178.972 4.50519C178.972 2.43449 177.294 0.755859 175.223 0.755859C173.152 0.755859 171.474 2.43449 171.474 4.50519C171.474 6.57589 173.152 8.25453 175.223 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 8.25453C167.215 8.25453 168.893 6.57589 168.893 4.50519C168.893 2.43449 167.215 0.755859 165.144 0.755859C163.073 0.755859 161.395 2.43449 161.395 4.50519C161.395 6.57589 163.073 8.25453 165.144 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 8.25453C157.14 8.25453 158.819 6.57589 158.819 4.50519C158.819 2.43449 157.14 0.755859 155.07 0.755859C152.999 0.755859 151.32 2.43449 151.32 4.50519C151.32 6.57589 152.999 8.25453 155.07 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 8.25453C147.067 8.25453 148.746 6.57589 148.746 4.50519C148.746 2.43449 147.067 0.755859 144.996 0.755859C142.926 0.755859 141.247 2.43449 141.247 4.50519C141.247 6.57589 142.926 8.25453 144.996 8.25453Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 18.3717C227.673 18.3717 229.351 16.6931 229.351 14.6224C229.351 12.5517 227.673 10.873 225.602 10.873C223.531 10.873 221.853 12.5517 221.853 14.6224C221.853 16.6931 223.531 18.3717 225.602 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 18.3717C217.598 18.3717 219.277 16.6931 219.277 14.6224C219.277 12.5517 217.598 10.873 215.528 10.873C213.457 10.873 211.778 12.5517 211.778 14.6224C211.778 16.6931 213.457 18.3717 215.528 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 18.3717C207.52 18.3717 209.199 16.6931 209.199 14.6224C209.199 12.5517 207.52 10.873 205.45 10.873C203.379 10.873 201.7 12.5517 201.7 14.6224C201.7 16.6931 203.379 18.3717 205.45 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 18.3717C197.446 18.3717 199.125 16.6931 199.125 14.6224C199.125 12.5517 197.446 10.873 195.375 10.873C193.305 10.873 191.626 12.5517 191.626 14.6224C191.626 16.6931 193.305 18.3717 195.375 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 18.3717C187.368 18.3717 189.047 16.6931 189.047 14.6224C189.047 12.5517 187.368 10.873 185.297 10.873C183.226 10.873 181.548 12.5517 181.548 14.6224C181.548 16.6931 183.226 18.3717 185.297 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 18.3717C177.294 18.3717 178.972 16.6931 178.972 14.6224C178.972 12.5517 177.294 10.873 175.223 10.873C173.152 10.873 171.474 12.5517 171.474 14.6224C171.474 16.6931 173.152 18.3717 175.223 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 18.3717C167.215 18.3717 168.893 16.6931 168.893 14.6224C168.893 12.5517 167.215 10.873 165.144 10.873C163.073 10.873 161.395 12.5517 161.395 14.6224C161.395 16.6931 163.073 18.3717 165.144 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 18.3717C157.14 18.3717 158.819 16.6931 158.819 14.6224C158.819 12.5517 157.14 10.873 155.07 10.873C152.999 10.873 151.32 12.5517 151.32 14.6224C151.32 16.6931 152.999 18.3717 155.07 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 18.3717C147.067 18.3717 148.746 16.6931 148.746 14.6224C148.746 12.5517 147.067 10.873 144.996 10.873C142.926 10.873 141.247 12.5517 141.247 14.6224C141.247 16.6931 142.926 18.3717 144.996 18.3717Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 28.4869C237.752 28.4869 239.43 26.8083 239.43 24.7376C239.43 22.6669 237.752 20.9883 235.681 20.9883C233.61 20.9883 231.932 22.6669 231.932 24.7376C231.932 26.8083 233.61 28.4869 235.681 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 28.4869C227.673 28.4869 229.351 26.8083 229.351 24.7376C229.351 22.6669 227.673 20.9883 225.602 20.9883C223.531 20.9883 221.853 22.6669 221.853 24.7376C221.853 26.8083 223.531 28.4869 225.602 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 28.4869C217.598 28.4869 219.277 26.8083 219.277 24.7376C219.277 22.6669 217.598 20.9883 215.528 20.9883C213.457 20.9883 211.778 22.6669 211.778 24.7376C211.778 26.8083 213.457 28.4869 215.528 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 28.4869C207.52 28.4869 209.199 26.8083 209.199 24.7376C209.199 22.6669 207.52 20.9883 205.45 20.9883C203.379 20.9883 201.7 22.6669 201.7 24.7376C201.7 26.8083 203.379 28.4869 205.45 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 28.4869C197.446 28.4869 199.125 26.8083 199.125 24.7376C199.125 22.6669 197.446 20.9883 195.375 20.9883C193.305 20.9883 191.626 22.6669 191.626 24.7376C191.626 26.8083 193.305 28.4869 195.375 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 28.4869C187.368 28.4869 189.047 26.8083 189.047 24.7376C189.047 22.6669 187.368 20.9883 185.297 20.9883C183.226 20.9883 181.548 22.6669 181.548 24.7376C181.548 26.8083 183.226 28.4869 185.297 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 28.4869C177.294 28.4869 178.972 26.8083 178.972 24.7376C178.972 22.6669 177.294 20.9883 175.223 20.9883C173.152 20.9883 171.474 22.6669 171.474 24.7376C171.474 26.8083 173.152 28.4869 175.223 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 28.4869C167.215 28.4869 168.893 26.8083 168.893 24.7376C168.893 22.6669 167.215 20.9883 165.144 20.9883C163.073 20.9883 161.395 22.6669 161.395 24.7376C161.395 26.8083 163.073 28.4869 165.144 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 28.4869C157.14 28.4869 158.819 26.8083 158.819 24.7376C158.819 22.6669 157.14 20.9883 155.07 20.9883C152.999 20.9883 151.32 22.6669 151.32 24.7376C151.32 26.8083 152.999 28.4869 155.07 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 28.4869C147.067 28.4869 148.746 26.8083 148.746 24.7376C148.746 22.6669 147.067 20.9883 144.996 20.9883C142.926 20.9883 141.247 22.6669 141.247 24.7376C141.247 26.8083 142.926 28.4869 144.996 28.4869Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 38.608C247.826 38.608 249.505 36.9294 249.505 34.8587C249.505 32.788 247.826 31.1094 245.755 31.1094C243.684 31.1094 242.006 32.788 242.006 34.8587C242.006 36.9294 243.684 38.608 245.755 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 38.608C237.752 38.608 239.43 36.9294 239.43 34.8587C239.43 32.788 237.752 31.1094 235.681 31.1094C233.61 31.1094 231.932 32.788 231.932 34.8587C231.932 36.9294 233.61 38.608 235.681 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 38.608C227.673 38.608 229.351 36.9294 229.351 34.8587C229.351 32.788 227.673 31.1094 225.602 31.1094C223.531 31.1094 221.853 32.788 221.853 34.8587C221.853 36.9294 223.531 38.608 225.602 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 38.608C217.598 38.608 219.277 36.9294 219.277 34.8587C219.277 32.788 217.598 31.1094 215.528 31.1094C213.457 31.1094 211.778 32.788 211.778 34.8587C211.778 36.9294 213.457 38.608 215.528 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 38.608C207.52 38.608 209.199 36.9294 209.199 34.8587C209.199 32.788 207.52 31.1094 205.45 31.1094C203.379 31.1094 201.7 32.788 201.7 34.8587C201.7 36.9294 203.379 38.608 205.45 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 38.608C197.446 38.608 199.125 36.9294 199.125 34.8587C199.125 32.788 197.446 31.1094 195.375 31.1094C193.305 31.1094 191.626 32.788 191.626 34.8587C191.626 36.9294 193.305 38.608 195.375 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 38.608C187.368 38.608 189.047 36.9294 189.047 34.8587C189.047 32.788 187.368 31.1094 185.297 31.1094C183.226 31.1094 181.548 32.788 181.548 34.8587C181.548 36.9294 183.226 38.608 185.297 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 38.608C177.294 38.608 178.972 36.9294 178.972 34.8587C178.972 32.788 177.294 31.1094 175.223 31.1094C173.152 31.1094 171.474 32.788 171.474 34.8587C171.474 36.9294 173.152 38.608 175.223 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 38.608C167.215 38.608 168.893 36.9294 168.893 34.8587C168.893 32.788 167.215 31.1094 165.144 31.1094C163.073 31.1094 161.395 32.788 161.395 34.8587C161.395 36.9294 163.073 38.608 165.144 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 38.608C157.14 38.608 158.819 36.9294 158.819 34.8587C158.819 32.788 157.14 31.1094 155.07 31.1094C152.999 31.1094 151.32 32.788 151.32 34.8587C151.32 36.9294 152.999 38.608 155.07 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 38.608C147.067 38.608 148.746 36.9294 148.746 34.8587C148.746 32.788 147.067 31.1094 144.996 31.1094C142.926 31.1094 141.247 32.788 141.247 34.8587C141.247 36.9294 142.926 38.608 144.996 38.608Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 48.7233C257.904 48.7233 259.583 47.0446 259.583 44.9739C259.583 42.9032 257.904 41.2246 255.833 41.2246C253.763 41.2246 252.084 42.9032 252.084 44.9739C252.084 47.0446 253.763 48.7233 255.833 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 48.7233C247.826 48.7233 249.505 47.0446 249.505 44.9739C249.505 42.9032 247.826 41.2246 245.755 41.2246C243.684 41.2246 242.006 42.9032 242.006 44.9739C242.006 47.0446 243.684 48.7233 245.755 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 48.7233C237.752 48.7233 239.43 47.0446 239.43 44.9739C239.43 42.9032 237.752 41.2246 235.681 41.2246C233.61 41.2246 231.932 42.9032 231.932 44.9739C231.932 47.0446 233.61 48.7233 235.681 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 48.7233C227.673 48.7233 229.351 47.0446 229.351 44.9739C229.351 42.9032 227.673 41.2246 225.602 41.2246C223.531 41.2246 221.853 42.9032 221.853 44.9739C221.853 47.0446 223.531 48.7233 225.602 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 48.7233C217.598 48.7233 219.277 47.0446 219.277 44.9739C219.277 42.9032 217.598 41.2246 215.528 41.2246C213.457 41.2246 211.778 42.9032 211.778 44.9739C211.778 47.0446 213.457 48.7233 215.528 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 48.7233C207.52 48.7233 209.199 47.0446 209.199 44.9739C209.199 42.9032 207.52 41.2246 205.45 41.2246C203.379 41.2246 201.7 42.9032 201.7 44.9739C201.7 47.0446 203.379 48.7233 205.45 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 48.7233C197.446 48.7233 199.125 47.0446 199.125 44.9739C199.125 42.9032 197.446 41.2246 195.375 41.2246C193.305 41.2246 191.626 42.9032 191.626 44.9739C191.626 47.0446 193.305 48.7233 195.375 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 48.7233C187.368 48.7233 189.047 47.0446 189.047 44.9739C189.047 42.9032 187.368 41.2246 185.297 41.2246C183.226 41.2246 181.548 42.9032 181.548 44.9739C181.548 47.0446 183.226 48.7233 185.297 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 48.7233C177.294 48.7233 178.972 47.0446 178.972 44.9739C178.972 42.9032 177.294 41.2246 175.223 41.2246C173.152 41.2246 171.474 42.9032 171.474 44.9739C171.474 47.0446 173.152 48.7233 175.223 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 48.7233C167.215 48.7233 168.893 47.0446 168.893 44.9739C168.893 42.9032 167.215 41.2246 165.144 41.2246C163.073 41.2246 161.395 42.9032 161.395 44.9739C161.395 47.0446 163.073 48.7233 165.144 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 48.7233C157.14 48.7233 158.819 47.0446 158.819 44.9739C158.819 42.9032 157.14 41.2246 155.07 41.2246C152.999 41.2246 151.32 42.9032 151.32 44.9739C151.32 47.0446 152.999 48.7233 155.07 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 48.7233C147.067 48.7233 148.746 47.0446 148.746 44.9739C148.746 42.9032 147.067 41.2246 144.996 41.2246C142.926 41.2246 141.247 42.9032 141.247 44.9739C141.247 47.0446 142.926 48.7233 144.996 48.7233Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 58.8405C267.978 58.8405 269.657 57.1618 269.657 55.0911C269.657 53.0204 267.978 51.3418 265.908 51.3418C263.837 51.3418 262.158 53.0204 262.158 55.0911C262.158 57.1618 263.837 58.8405 265.908 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 58.8405C257.904 58.8405 259.583 57.1618 259.583 55.0911C259.583 53.0204 257.904 51.3418 255.833 51.3418C253.763 51.3418 252.084 53.0204 252.084 55.0911C252.084 57.1618 253.763 58.8405 255.833 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 58.8405C247.826 58.8405 249.505 57.1618 249.505 55.0911C249.505 53.0204 247.826 51.3418 245.755 51.3418C243.684 51.3418 242.006 53.0204 242.006 55.0911C242.006 57.1618 243.684 58.8405 245.755 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 58.8405C237.752 58.8405 239.43 57.1618 239.43 55.0911C239.43 53.0204 237.752 51.3418 235.681 51.3418C233.61 51.3418 231.932 53.0204 231.932 55.0911C231.932 57.1618 233.61 58.8405 235.681 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 58.8405C227.673 58.8405 229.351 57.1618 229.351 55.0911C229.351 53.0204 227.673 51.3418 225.602 51.3418C223.531 51.3418 221.853 53.0204 221.853 55.0911C221.853 57.1618 223.531 58.8405 225.602 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 58.8405C217.598 58.8405 219.277 57.1618 219.277 55.0911C219.277 53.0204 217.598 51.3418 215.528 51.3418C213.457 51.3418 211.778 53.0204 211.778 55.0911C211.778 57.1618 213.457 58.8405 215.528 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 58.8405C207.52 58.8405 209.199 57.1618 209.199 55.0911C209.199 53.0204 207.52 51.3418 205.45 51.3418C203.379 51.3418 201.7 53.0204 201.7 55.0911C201.7 57.1618 203.379 58.8405 205.45 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 58.8405C197.446 58.8405 199.125 57.1618 199.125 55.0911C199.125 53.0204 197.446 51.3418 195.375 51.3418C193.305 51.3418 191.626 53.0204 191.626 55.0911C191.626 57.1618 193.305 58.8405 195.375 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 58.8405C187.368 58.8405 189.047 57.1618 189.047 55.0911C189.047 53.0204 187.368 51.3418 185.297 51.3418C183.226 51.3418 181.548 53.0204 181.548 55.0911C181.548 57.1618 183.226 58.8405 185.297 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 58.8405C177.294 58.8405 178.972 57.1618 178.972 55.0911C178.972 53.0204 177.294 51.3418 175.223 51.3418C173.152 51.3418 171.474 53.0204 171.474 55.0911C171.474 57.1618 173.152 58.8405 175.223 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 58.8405C167.215 58.8405 168.893 57.1618 168.893 55.0911C168.893 53.0204 167.215 51.3418 165.144 51.3418C163.073 51.3418 161.395 53.0204 161.395 55.0911C161.395 57.1618 163.073 58.8405 165.144 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 58.8405C157.14 58.8405 158.819 57.1618 158.819 55.0911C158.819 53.0204 157.14 51.3418 155.07 51.3418C152.999 51.3418 151.32 53.0204 151.32 55.0911C151.32 57.1618 152.999 58.8405 155.07 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 58.8405C147.067 58.8405 148.746 57.1618 148.746 55.0911C148.746 53.0204 147.067 51.3418 144.996 51.3418C142.926 51.3418 141.247 53.0204 141.247 55.0911C141.247 57.1618 142.926 58.8405 144.996 58.8405Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 68.9576C267.978 68.9576 269.657 67.279 269.657 65.2083C269.657 63.1376 267.978 61.459 265.908 61.459C263.837 61.459 262.158 63.1376 262.158 65.2083C262.158 67.279 263.837 68.9576 265.908 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 68.9576C257.904 68.9576 259.583 67.279 259.583 65.2083C259.583 63.1376 257.904 61.459 255.833 61.459C253.763 61.459 252.084 63.1376 252.084 65.2083C252.084 67.279 253.763 68.9576 255.833 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 68.9576C247.826 68.9576 249.505 67.279 249.505 65.2083C249.505 63.1376 247.826 61.459 245.755 61.459C243.684 61.459 242.006 63.1376 242.006 65.2083C242.006 67.279 243.684 68.9576 245.755 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 68.9576C237.752 68.9576 239.43 67.279 239.43 65.2083C239.43 63.1376 237.752 61.459 235.681 61.459C233.61 61.459 231.932 63.1376 231.932 65.2083C231.932 67.279 233.61 68.9576 235.681 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 68.9576C227.673 68.9576 229.351 67.279 229.351 65.2083C229.351 63.1376 227.673 61.459 225.602 61.459C223.531 61.459 221.853 63.1376 221.853 65.2083C221.853 67.279 223.531 68.9576 225.602 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 68.9576C217.598 68.9576 219.277 67.279 219.277 65.2083C219.277 63.1376 217.598 61.459 215.528 61.459C213.457 61.459 211.778 63.1376 211.778 65.2083C211.778 67.279 213.457 68.9576 215.528 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 68.9576C207.52 68.9576 209.199 67.279 209.199 65.2083C209.199 63.1376 207.52 61.459 205.45 61.459C203.379 61.459 201.7 63.1376 201.7 65.2083C201.7 67.279 203.379 68.9576 205.45 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 68.9576C197.446 68.9576 199.125 67.279 199.125 65.2083C199.125 63.1376 197.446 61.459 195.375 61.459C193.305 61.459 191.626 63.1376 191.626 65.2083C191.626 67.279 193.305 68.9576 195.375 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 68.9576C167.215 68.9576 168.893 67.279 168.893 65.2083C168.893 63.1376 167.215 61.459 165.144 61.459C163.073 61.459 161.395 63.1376 161.395 65.2083C161.395 67.279 163.073 68.9576 165.144 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 68.9576C157.14 68.9576 158.819 67.279 158.819 65.2083C158.819 63.1376 157.14 61.459 155.07 61.459C152.999 61.459 151.32 63.1376 151.32 65.2083C151.32 67.279 152.999 68.9576 155.07 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 68.9576C147.067 68.9576 148.746 67.279 148.746 65.2083C148.746 63.1376 147.067 61.459 144.996 61.459C142.926 61.459 141.247 63.1376 141.247 65.2083C141.247 67.279 142.926 68.9576 144.996 68.9576Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 79.0787C278.056 79.0787 279.735 77.4001 279.735 75.3294C279.735 73.2587 278.056 71.5801 275.986 71.5801C273.915 71.5801 272.236 73.2587 272.236 75.3294C272.236 77.4001 273.915 79.0787 275.986 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 79.0787C267.978 79.0787 269.657 77.4001 269.657 75.3294C269.657 73.2587 267.978 71.5801 265.908 71.5801C263.837 71.5801 262.158 73.2587 262.158 75.3294C262.158 77.4001 263.837 79.0787 265.908 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 79.0787C257.904 79.0787 259.583 77.4001 259.583 75.3294C259.583 73.2587 257.904 71.5801 255.833 71.5801C253.763 71.5801 252.084 73.2587 252.084 75.3294C252.084 77.4001 253.763 79.0787 255.833 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 79.0787C247.826 79.0787 249.505 77.4001 249.505 75.3294C249.505 73.2587 247.826 71.5801 245.755 71.5801C243.684 71.5801 242.006 73.2587 242.006 75.3294C242.006 77.4001 243.684 79.0787 245.755 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 79.0787C237.752 79.0787 239.43 77.4001 239.43 75.3294C239.43 73.2587 237.752 71.5801 235.681 71.5801C233.61 71.5801 231.932 73.2587 231.932 75.3294C231.932 77.4001 233.61 79.0787 235.681 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 79.0787C227.673 79.0787 229.351 77.4001 229.351 75.3294C229.351 73.2587 227.673 71.5801 225.602 71.5801C223.531 71.5801 221.853 73.2587 221.853 75.3294C221.853 77.4001 223.531 79.0787 225.602 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 79.0787C217.598 79.0787 219.277 77.4001 219.277 75.3294C219.277 73.2587 217.598 71.5801 215.528 71.5801C213.457 71.5801 211.778 73.2587 211.778 75.3294C211.778 77.4001 213.457 79.0787 215.528 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 79.0787C207.52 79.0787 209.199 77.4001 209.199 75.3294C209.199 73.2587 207.52 71.5801 205.45 71.5801C203.379 71.5801 201.7 73.2587 201.7 75.3294C201.7 77.4001 203.379 79.0787 205.45 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 79.0787C197.446 79.0787 199.125 77.4001 199.125 75.3294C199.125 73.2587 197.446 71.5801 195.375 71.5801C193.305 71.5801 191.626 73.2587 191.626 75.3294C191.626 77.4001 193.305 79.0787 195.375 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 79.0787C167.215 79.0787 168.893 77.4001 168.893 75.3294C168.893 73.2587 167.215 71.5801 165.144 71.5801C163.073 71.5801 161.395 73.2587 161.395 75.3294C161.395 77.4001 163.073 79.0787 165.144 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 79.0787C157.14 79.0787 158.819 77.4001 158.819 75.3294C158.819 73.2587 157.14 71.5801 155.07 71.5801C152.999 71.5801 151.32 73.2587 151.32 75.3294C151.32 77.4001 152.999 79.0787 155.07 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 79.0787C147.067 79.0787 148.746 77.4001 148.746 75.3294C148.746 73.2587 147.067 71.5801 144.996 71.5801C142.926 71.5801 141.247 73.2587 141.247 75.3294C141.247 77.4001 142.926 79.0787 144.996 79.0787Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 89.194C278.056 89.194 279.735 87.5153 279.735 85.4446C279.735 83.3739 278.056 81.6953 275.986 81.6953C273.915 81.6953 272.236 83.3739 272.236 85.4446C272.236 87.5153 273.915 89.194 275.986 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 89.194C267.978 89.194 269.657 87.5153 269.657 85.4446C269.657 83.3739 267.978 81.6953 265.908 81.6953C263.837 81.6953 262.158 83.3739 262.158 85.4446C262.158 87.5153 263.837 89.194 265.908 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 89.194C257.904 89.194 259.583 87.5153 259.583 85.4446C259.583 83.3739 257.904 81.6953 255.833 81.6953C253.763 81.6953 252.084 83.3739 252.084 85.4446C252.084 87.5153 253.763 89.194 255.833 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 89.194C247.826 89.194 249.505 87.5153 249.505 85.4446C249.505 83.3739 247.826 81.6953 245.755 81.6953C243.684 81.6953 242.006 83.3739 242.006 85.4446C242.006 87.5153 243.684 89.194 245.755 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 89.194C237.752 89.194 239.43 87.5153 239.43 85.4446C239.43 83.3739 237.752 81.6953 235.681 81.6953C233.61 81.6953 231.932 83.3739 231.932 85.4446C231.932 87.5153 233.61 89.194 235.681 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 89.194C227.673 89.194 229.351 87.5153 229.351 85.4446C229.351 83.3739 227.673 81.6953 225.602 81.6953C223.531 81.6953 221.853 83.3739 221.853 85.4446C221.853 87.5153 223.531 89.194 225.602 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 89.194C217.598 89.194 219.277 87.5153 219.277 85.4446C219.277 83.3739 217.598 81.6953 215.528 81.6953C213.457 81.6953 211.778 83.3739 211.778 85.4446C211.778 87.5153 213.457 89.194 215.528 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 89.194C207.52 89.194 209.199 87.5153 209.199 85.4446C209.199 83.3739 207.52 81.6953 205.45 81.6953C203.379 81.6953 201.7 83.3739 201.7 85.4446C201.7 87.5153 203.379 89.194 205.45 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 89.194C197.446 89.194 199.125 87.5153 199.125 85.4446C199.125 83.3739 197.446 81.6953 195.375 81.6953C193.305 81.6953 191.626 83.3739 191.626 85.4446C191.626 87.5153 193.305 89.194 195.375 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 89.194C167.215 89.194 168.893 87.5153 168.893 85.4446C168.893 83.3739 167.215 81.6953 165.144 81.6953C163.073 81.6953 161.395 83.3739 161.395 85.4446C161.395 87.5153 163.073 89.194 165.144 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 89.194C157.14 89.194 158.819 87.5153 158.819 85.4446C158.819 83.3739 157.14 81.6953 155.07 81.6953C152.999 81.6953 151.32 83.3739 151.32 85.4446C151.32 87.5153 152.999 89.194 155.07 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 89.194C147.067 89.194 148.746 87.5153 148.746 85.4446C148.746 83.3739 147.067 81.6953 144.996 81.6953C142.926 81.6953 141.247 83.3739 141.247 85.4446C141.247 87.5153 142.926 89.194 144.996 89.194Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 99.3112C288.131 99.3112 289.809 97.6325 289.809 95.5618C289.809 93.4911 288.131 91.8125 286.06 91.8125C283.989 91.8125 282.311 93.4911 282.311 95.5618C282.311 97.6325 283.989 99.3112 286.06 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 99.3112C278.056 99.3112 279.735 97.6325 279.735 95.5618C279.735 93.4911 278.056 91.8125 275.986 91.8125C273.915 91.8125 272.236 93.4911 272.236 95.5618C272.236 97.6325 273.915 99.3112 275.986 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 99.3112C267.978 99.3112 269.657 97.6325 269.657 95.5618C269.657 93.4911 267.978 91.8125 265.908 91.8125C263.837 91.8125 262.158 93.4911 262.158 95.5618C262.158 97.6325 263.837 99.3112 265.908 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 99.3112C257.904 99.3112 259.583 97.6325 259.583 95.5618C259.583 93.4911 257.904 91.8125 255.833 91.8125C253.763 91.8125 252.084 93.4911 252.084 95.5618C252.084 97.6325 253.763 99.3112 255.833 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 99.3112C247.826 99.3112 249.505 97.6325 249.505 95.5618C249.505 93.4911 247.826 91.8125 245.755 91.8125C243.684 91.8125 242.006 93.4911 242.006 95.5618C242.006 97.6325 243.684 99.3112 245.755 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 99.3112C237.752 99.3112 239.43 97.6325 239.43 95.5618C239.43 93.4911 237.752 91.8125 235.681 91.8125C233.61 91.8125 231.932 93.4911 231.932 95.5618C231.932 97.6325 233.61 99.3112 235.681 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 99.3112C227.673 99.3112 229.351 97.6325 229.351 95.5618C229.351 93.4911 227.673 91.8125 225.602 91.8125C223.531 91.8125 221.853 93.4911 221.853 95.5618C221.853 97.6325 223.531 99.3112 225.602 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 99.3112C217.598 99.3112 219.277 97.6325 219.277 95.5618C219.277 93.4911 217.598 91.8125 215.528 91.8125C213.457 91.8125 211.778 93.4911 211.778 95.5618C211.778 97.6325 213.457 99.3112 215.528 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 99.3112C207.52 99.3112 209.199 97.6325 209.199 95.5618C209.199 93.4911 207.52 91.8125 205.45 91.8125C203.379 91.8125 201.7 93.4911 201.7 95.5618C201.7 97.6325 203.379 99.3112 205.45 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 99.3112C197.446 99.3112 199.125 97.6325 199.125 95.5618C199.125 93.4911 197.446 91.8125 195.375 91.8125C193.305 91.8125 191.626 93.4911 191.626 95.5618C191.626 97.6325 193.305 99.3112 195.375 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 99.3112C167.215 99.3112 168.893 97.6325 168.893 95.5618C168.893 93.4911 167.215 91.8125 165.144 91.8125C163.073 91.8125 161.395 93.4911 161.395 95.5618C161.395 97.6325 163.073 99.3112 165.144 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 99.3112C157.14 99.3112 158.819 97.6325 158.819 95.5618C158.819 93.4911 157.14 91.8125 155.07 91.8125C152.999 91.8125 151.32 93.4911 151.32 95.5618C151.32 97.6325 152.999 99.3112 155.07 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 99.3112C147.067 99.3112 148.746 97.6325 148.746 95.5618C148.746 93.4911 147.067 91.8125 144.996 91.8125C142.926 91.8125 141.247 93.4911 141.247 95.5618C141.247 97.6325 142.926 99.3112 144.996 99.3112Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 109.426C288.131 109.426 289.809 107.748 289.809 105.677C289.809 103.606 288.131 101.928 286.06 101.928C283.989 101.928 282.311 103.606 282.311 105.677C282.311 107.748 283.989 109.426 286.06 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 109.426C278.056 109.426 279.735 107.748 279.735 105.677C279.735 103.606 278.056 101.928 275.986 101.928C273.915 101.928 272.236 103.606 272.236 105.677C272.236 107.748 273.915 109.426 275.986 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 109.426C267.978 109.426 269.657 107.748 269.657 105.677C269.657 103.606 267.978 101.928 265.908 101.928C263.837 101.928 262.158 103.606 262.158 105.677C262.158 107.748 263.837 109.426 265.908 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 109.426C257.904 109.426 259.583 107.748 259.583 105.677C259.583 103.606 257.904 101.928 255.833 101.928C253.763 101.928 252.084 103.606 252.084 105.677C252.084 107.748 253.763 109.426 255.833 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 109.426C247.826 109.426 249.505 107.748 249.505 105.677C249.505 103.606 247.826 101.928 245.755 101.928C243.684 101.928 242.006 103.606 242.006 105.677C242.006 107.748 243.684 109.426 245.755 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 109.426C237.752 109.426 239.43 107.748 239.43 105.677C239.43 103.606 237.752 101.928 235.681 101.928C233.61 101.928 231.932 103.606 231.932 105.677C231.932 107.748 233.61 109.426 235.681 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 109.426C227.673 109.426 229.351 107.748 229.351 105.677C229.351 103.606 227.673 101.928 225.602 101.928C223.531 101.928 221.853 103.606 221.853 105.677C221.853 107.748 223.531 109.426 225.602 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 109.426C217.598 109.426 219.277 107.748 219.277 105.677C219.277 103.606 217.598 101.928 215.528 101.928C213.457 101.928 211.778 103.606 211.778 105.677C211.778 107.748 213.457 109.426 215.528 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 109.426C207.52 109.426 209.199 107.748 209.199 105.677C209.199 103.606 207.52 101.928 205.45 101.928C203.379 101.928 201.7 103.606 201.7 105.677C201.7 107.748 203.379 109.426 205.45 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 109.426C197.446 109.426 199.125 107.748 199.125 105.677C199.125 103.606 197.446 101.928 195.375 101.928C193.305 101.928 191.626 103.606 191.626 105.677C191.626 107.748 193.305 109.426 195.375 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 109.426C167.215 109.426 168.893 107.748 168.893 105.677C168.893 103.606 167.215 101.928 165.144 101.928C163.073 101.928 161.395 103.606 161.395 105.677C161.395 107.748 163.073 109.426 165.144 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 109.426C157.14 109.426 158.819 107.748 158.819 105.677C158.819 103.606 157.14 101.928 155.07 101.928C152.999 101.928 151.32 103.606 151.32 105.677C151.32 107.748 152.999 109.426 155.07 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 109.426C147.067 109.426 148.746 107.748 148.746 105.677C148.746 103.606 147.067 101.928 144.996 101.928C142.926 101.928 141.247 103.606 141.247 105.677C141.247 107.748 142.926 109.426 144.996 109.426Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 119.547C288.131 119.547 289.809 117.869 289.809 115.798C289.809 113.727 288.131 112.049 286.06 112.049C283.989 112.049 282.311 113.727 282.311 115.798C282.311 117.869 283.989 119.547 286.06 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 119.547C278.056 119.547 279.735 117.869 279.735 115.798C279.735 113.727 278.056 112.049 275.986 112.049C273.915 112.049 272.236 113.727 272.236 115.798C272.236 117.869 273.915 119.547 275.986 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 119.547C267.978 119.547 269.657 117.869 269.657 115.798C269.657 113.727 267.978 112.049 265.908 112.049C263.837 112.049 262.158 113.727 262.158 115.798C262.158 117.869 263.837 119.547 265.908 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 119.547C257.904 119.547 259.583 117.869 259.583 115.798C259.583 113.727 257.904 112.049 255.833 112.049C253.763 112.049 252.084 113.727 252.084 115.798C252.084 117.869 253.763 119.547 255.833 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 119.547C247.826 119.547 249.505 117.869 249.505 115.798C249.505 113.727 247.826 112.049 245.755 112.049C243.684 112.049 242.006 113.727 242.006 115.798C242.006 117.869 243.684 119.547 245.755 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 119.547C237.752 119.547 239.43 117.869 239.43 115.798C239.43 113.727 237.752 112.049 235.681 112.049C233.61 112.049 231.932 113.727 231.932 115.798C231.932 117.869 233.61 119.547 235.681 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 119.547C227.673 119.547 229.351 117.869 229.351 115.798C229.351 113.727 227.673 112.049 225.602 112.049C223.531 112.049 221.853 113.727 221.853 115.798C221.853 117.869 223.531 119.547 225.602 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 119.547C217.598 119.547 219.277 117.869 219.277 115.798C219.277 113.727 217.598 112.049 215.528 112.049C213.457 112.049 211.778 113.727 211.778 115.798C211.778 117.869 213.457 119.547 215.528 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 119.547C207.52 119.547 209.199 117.869 209.199 115.798C209.199 113.727 207.52 112.049 205.45 112.049C203.379 112.049 201.7 113.727 201.7 115.798C201.7 117.869 203.379 119.547 205.45 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 119.547C197.446 119.547 199.125 117.869 199.125 115.798C199.125 113.727 197.446 112.049 195.375 112.049C193.305 112.049 191.626 113.727 191.626 115.798C191.626 117.869 193.305 119.547 195.375 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 119.547C167.215 119.547 168.893 117.869 168.893 115.798C168.893 113.727 167.215 112.049 165.144 112.049C163.073 112.049 161.395 113.727 161.395 115.798C161.395 117.869 163.073 119.547 165.144 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 119.547C157.14 119.547 158.819 117.869 158.819 115.798C158.819 113.727 157.14 112.049 155.07 112.049C152.999 112.049 151.32 113.727 151.32 115.798C151.32 117.869 152.999 119.547 155.07 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 119.547C147.067 119.547 148.746 117.869 148.746 115.798C148.746 113.727 147.067 112.049 144.996 112.049C142.926 112.049 141.247 113.727 141.247 115.798C141.247 117.869 142.926 119.547 144.996 119.547Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 129.665C288.131 129.665 289.809 127.986 289.809 125.915C289.809 123.845 288.131 122.166 286.06 122.166C283.989 122.166 282.311 123.845 282.311 125.915C282.311 127.986 283.989 129.665 286.06 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 129.665C278.056 129.665 279.735 127.986 279.735 125.915C279.735 123.845 278.056 122.166 275.986 122.166C273.915 122.166 272.236 123.845 272.236 125.915C272.236 127.986 273.915 129.665 275.986 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 129.665C267.978 129.665 269.657 127.986 269.657 125.915C269.657 123.845 267.978 122.166 265.908 122.166C263.837 122.166 262.158 123.845 262.158 125.915C262.158 127.986 263.837 129.665 265.908 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 129.665C257.904 129.665 259.583 127.986 259.583 125.915C259.583 123.845 257.904 122.166 255.833 122.166C253.763 122.166 252.084 123.845 252.084 125.915C252.084 127.986 253.763 129.665 255.833 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 129.665C247.826 129.665 249.505 127.986 249.505 125.915C249.505 123.845 247.826 122.166 245.755 122.166C243.684 122.166 242.006 123.845 242.006 125.915C242.006 127.986 243.684 129.665 245.755 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 129.665C237.752 129.665 239.43 127.986 239.43 125.915C239.43 123.845 237.752 122.166 235.681 122.166C233.61 122.166 231.932 123.845 231.932 125.915C231.932 127.986 233.61 129.665 235.681 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 129.665C227.673 129.665 229.351 127.986 229.351 125.915C229.351 123.845 227.673 122.166 225.602 122.166C223.531 122.166 221.853 123.845 221.853 125.915C221.853 127.986 223.531 129.665 225.602 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 129.665C217.598 129.665 219.277 127.986 219.277 125.915C219.277 123.845 217.598 122.166 215.528 122.166C213.457 122.166 211.778 123.845 211.778 125.915C211.778 127.986 213.457 129.665 215.528 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 129.665C207.52 129.665 209.199 127.986 209.199 125.915C209.199 123.845 207.52 122.166 205.45 122.166C203.379 122.166 201.7 123.845 201.7 125.915C201.7 127.986 203.379 129.665 205.45 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 129.665C197.446 129.665 199.125 127.986 199.125 125.915C199.125 123.845 197.446 122.166 195.375 122.166C193.305 122.166 191.626 123.845 191.626 125.915C191.626 127.986 193.305 129.665 195.375 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 129.665C187.368 129.665 189.047 127.986 189.047 125.915C189.047 123.845 187.368 122.166 185.297 122.166C183.226 122.166 181.548 123.845 181.548 125.915C181.548 127.986 183.226 129.665 185.297 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 129.665C177.294 129.665 178.972 127.986 178.972 125.915C178.972 123.845 177.294 122.166 175.223 122.166C173.152 122.166 171.474 123.845 171.474 125.915C171.474 127.986 173.152 129.665 175.223 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 129.665C167.215 129.665 168.893 127.986 168.893 125.915C168.893 123.845 167.215 122.166 165.144 122.166C163.073 122.166 161.395 123.845 161.395 125.915C161.395 127.986 163.073 129.665 165.144 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 129.665C157.14 129.665 158.819 127.986 158.819 125.915C158.819 123.845 157.14 122.166 155.07 122.166C152.999 122.166 151.32 123.845 151.32 125.915C151.32 127.986 152.999 129.665 155.07 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 129.665C147.067 129.665 148.746 127.986 148.746 125.915C148.746 123.845 147.067 122.166 144.996 122.166C142.926 122.166 141.247 123.845 141.247 125.915C141.247 127.986 142.926 129.665 144.996 129.665Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 139.78C288.131 139.78 289.809 138.101 289.809 136.031C289.809 133.96 288.131 132.281 286.06 132.281C283.989 132.281 282.311 133.96 282.311 136.031C282.311 138.101 283.989 139.78 286.06 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 139.78C278.056 139.78 279.735 138.101 279.735 136.031C279.735 133.96 278.056 132.281 275.986 132.281C273.915 132.281 272.236 133.96 272.236 136.031C272.236 138.101 273.915 139.78 275.986 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 139.78C267.978 139.78 269.657 138.101 269.657 136.031C269.657 133.96 267.978 132.281 265.908 132.281C263.837 132.281 262.158 133.96 262.158 136.031C262.158 138.101 263.837 139.78 265.908 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 139.78C257.904 139.78 259.583 138.101 259.583 136.031C259.583 133.96 257.904 132.281 255.833 132.281C253.763 132.281 252.084 133.96 252.084 136.031C252.084 138.101 253.763 139.78 255.833 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 139.78C247.826 139.78 249.505 138.101 249.505 136.031C249.505 133.96 247.826 132.281 245.755 132.281C243.684 132.281 242.006 133.96 242.006 136.031C242.006 138.101 243.684 139.78 245.755 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 139.78C237.752 139.78 239.43 138.101 239.43 136.031C239.43 133.96 237.752 132.281 235.681 132.281C233.61 132.281 231.932 133.96 231.932 136.031C231.932 138.101 233.61 139.78 235.681 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 139.78C227.673 139.78 229.351 138.101 229.351 136.031C229.351 133.96 227.673 132.281 225.602 132.281C223.531 132.281 221.853 133.96 221.853 136.031C221.853 138.101 223.531 139.78 225.602 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 139.78C217.598 139.78 219.277 138.101 219.277 136.031C219.277 133.96 217.598 132.281 215.528 132.281C213.457 132.281 211.778 133.96 211.778 136.031C211.778 138.101 213.457 139.78 215.528 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 139.78C207.52 139.78 209.199 138.101 209.199 136.031C209.199 133.96 207.52 132.281 205.45 132.281C203.379 132.281 201.7 133.96 201.7 136.031C201.7 138.101 203.379 139.78 205.45 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 139.78C197.446 139.78 199.125 138.101 199.125 136.031C199.125 133.96 197.446 132.281 195.375 132.281C193.305 132.281 191.626 133.96 191.626 136.031C191.626 138.101 193.305 139.78 195.375 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 139.78C187.368 139.78 189.047 138.101 189.047 136.031C189.047 133.96 187.368 132.281 185.297 132.281C183.226 132.281 181.548 133.96 181.548 136.031C181.548 138.101 183.226 139.78 185.297 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 139.78C177.294 139.78 178.972 138.101 178.972 136.031C178.972 133.96 177.294 132.281 175.223 132.281C173.152 132.281 171.474 133.96 171.474 136.031C171.474 138.101 173.152 139.78 175.223 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 139.78C167.215 139.78 168.893 138.101 168.893 136.031C168.893 133.96 167.215 132.281 165.144 132.281C163.073 132.281 161.395 133.96 161.395 136.031C161.395 138.101 163.073 139.78 165.144 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 139.78C157.14 139.78 158.819 138.101 158.819 136.031C158.819 133.96 157.14 132.281 155.07 132.281C152.999 132.281 151.32 133.96 151.32 136.031C151.32 138.101 152.999 139.78 155.07 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 139.78C147.067 139.78 148.746 138.101 148.746 136.031C148.746 133.96 147.067 132.281 144.996 132.281C142.926 132.281 141.247 133.96 141.247 136.031C141.247 138.101 142.926 139.78 144.996 139.78Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 149.897C288.131 149.897 289.809 148.218 289.809 146.148C289.809 144.077 288.131 142.398 286.06 142.398C283.989 142.398 282.311 144.077 282.311 146.148C282.311 148.218 283.989 149.897 286.06 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 149.897C278.056 149.897 279.735 148.218 279.735 146.148C279.735 144.077 278.056 142.398 275.986 142.398C273.915 142.398 272.236 144.077 272.236 146.148C272.236 148.218 273.915 149.897 275.986 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 149.897C267.978 149.897 269.657 148.218 269.657 146.148C269.657 144.077 267.978 142.398 265.908 142.398C263.837 142.398 262.158 144.077 262.158 146.148C262.158 148.218 263.837 149.897 265.908 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 149.897C257.904 149.897 259.583 148.218 259.583 146.148C259.583 144.077 257.904 142.398 255.833 142.398C253.763 142.398 252.084 144.077 252.084 146.148C252.084 148.218 253.763 149.897 255.833 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 149.897C247.826 149.897 249.505 148.218 249.505 146.148C249.505 144.077 247.826 142.398 245.755 142.398C243.684 142.398 242.006 144.077 242.006 146.148C242.006 148.218 243.684 149.897 245.755 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 149.897C237.752 149.897 239.43 148.218 239.43 146.148C239.43 144.077 237.752 142.398 235.681 142.398C233.61 142.398 231.932 144.077 231.932 146.148C231.932 148.218 233.61 149.897 235.681 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 149.897C227.673 149.897 229.351 148.218 229.351 146.148C229.351 144.077 227.673 142.398 225.602 142.398C223.531 142.398 221.853 144.077 221.853 146.148C221.853 148.218 223.531 149.897 225.602 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 149.897C217.598 149.897 219.277 148.218 219.277 146.148C219.277 144.077 217.598 142.398 215.528 142.398C213.457 142.398 211.778 144.077 211.778 146.148C211.778 148.218 213.457 149.897 215.528 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 149.897C207.52 149.897 209.199 148.218 209.199 146.148C209.199 144.077 207.52 142.398 205.45 142.398C203.379 142.398 201.7 144.077 201.7 146.148C201.7 148.218 203.379 149.897 205.45 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 149.897C197.446 149.897 199.125 148.218 199.125 146.148C199.125 144.077 197.446 142.398 195.375 142.398C193.305 142.398 191.626 144.077 191.626 146.148C191.626 148.218 193.305 149.897 195.375 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 149.897C187.368 149.897 189.047 148.218 189.047 146.148C189.047 144.077 187.368 142.398 185.297 142.398C183.226 142.398 181.548 144.077 181.548 146.148C181.548 148.218 183.226 149.897 185.297 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 149.897C177.294 149.897 178.972 148.218 178.972 146.148C178.972 144.077 177.294 142.398 175.223 142.398C173.152 142.398 171.474 144.077 171.474 146.148C171.474 148.218 173.152 149.897 175.223 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 149.897C167.215 149.897 168.893 148.218 168.893 146.148C168.893 144.077 167.215 142.398 165.144 142.398C163.073 142.398 161.395 144.077 161.395 146.148C161.395 148.218 163.073 149.897 165.144 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 149.897C157.14 149.897 158.819 148.218 158.819 146.148C158.819 144.077 157.14 142.398 155.07 142.398C152.999 142.398 151.32 144.077 151.32 146.148C151.32 148.218 152.999 149.897 155.07 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 149.897C147.067 149.897 148.746 148.218 148.746 146.148C148.746 144.077 147.067 142.398 144.996 142.398C142.926 142.398 141.247 144.077 141.247 146.148C141.247 148.218 142.926 149.897 144.996 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 291.544C96.6823 291.544 98.361 289.865 98.361 287.794C98.361 285.724 96.6823 284.045 94.6116 284.045C92.5409 284.045 90.8623 285.724 90.8623 287.794C90.8623 289.865 92.5409 291.544 94.6116 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 291.544C106.761 291.544 108.44 289.865 108.44 287.794C108.44 285.724 106.761 284.045 104.691 284.045C102.62 284.045 100.941 285.724 100.941 287.794C100.941 289.865 102.62 291.544 104.691 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 291.544C116.836 291.544 118.514 289.865 118.514 287.794C118.514 285.724 116.836 284.045 114.765 284.045C112.694 284.045 111.016 285.724 111.016 287.794C111.016 289.865 112.694 291.544 114.765 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 291.544C126.914 291.544 128.592 289.865 128.592 287.794C128.592 285.724 126.914 284.045 124.843 284.045C122.772 284.045 121.094 285.724 121.094 287.794C121.094 289.865 122.772 291.544 124.843 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 291.544C136.988 291.544 138.667 289.865 138.667 287.794C138.667 285.724 136.988 284.045 134.917 284.045C132.847 284.045 131.168 285.724 131.168 287.794C131.168 289.865 132.847 291.544 134.917 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 291.544C147.067 291.544 148.746 289.865 148.746 287.794C148.746 285.724 147.067 284.045 144.996 284.045C142.926 284.045 141.247 285.724 141.247 287.794C141.247 289.865 142.926 291.544 144.996 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 281.428C66.4558 281.428 68.1344 279.75 68.1344 277.679C68.1344 275.608 66.4558 273.93 64.3851 273.93C62.3144 273.93 60.6357 275.608 60.6357 277.679C60.6357 279.75 62.3144 281.428 64.3851 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 281.428C76.53 281.428 78.2086 279.75 78.2086 277.679C78.2086 275.608 76.53 273.93 74.4593 273.93C72.3886 273.93 70.71 275.608 70.71 277.679C70.71 279.75 72.3886 281.428 74.4593 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 281.428C86.6081 281.428 88.2868 279.75 88.2868 277.679C88.2868 275.608 86.6081 273.93 84.5374 273.93C82.4667 273.93 80.7881 275.608 80.7881 277.679C80.7881 279.75 82.4667 281.428 84.5374 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 281.428C96.6823 281.428 98.361 279.75 98.361 277.679C98.361 275.608 96.6823 273.93 94.6116 273.93C92.5409 273.93 90.8623 275.608 90.8623 277.679C90.8623 279.75 92.5409 281.428 94.6116 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 281.428C106.761 281.428 108.44 279.75 108.44 277.679C108.44 275.608 106.761 273.93 104.691 273.93C102.62 273.93 100.941 275.608 100.941 277.679C100.941 279.75 102.62 281.428 104.691 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 281.428C116.836 281.428 118.514 279.75 118.514 277.679C118.514 275.608 116.836 273.93 114.765 273.93C112.694 273.93 111.016 275.608 111.016 277.679C111.016 279.75 112.694 281.428 114.765 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 281.428C126.914 281.428 128.592 279.75 128.592 277.679C128.592 275.608 126.914 273.93 124.843 273.93C122.772 273.93 121.094 275.608 121.094 277.679C121.094 279.75 122.772 281.428 124.843 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 281.428C136.988 281.428 138.667 279.75 138.667 277.679C138.667 275.608 136.988 273.93 134.917 273.93C132.847 273.93 131.168 275.608 131.168 277.679C131.168 279.75 132.847 281.428 134.917 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 281.428C147.067 281.428 148.746 279.75 148.746 277.679C148.746 275.608 147.067 273.93 144.996 273.93C142.926 273.93 141.247 275.608 141.247 277.679C141.247 279.75 142.926 281.428 144.996 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 271.307C56.3776 271.307 58.0563 269.629 58.0563 267.558C58.0563 265.487 56.3776 263.809 54.3069 263.809C52.2362 263.809 50.5576 265.487 50.5576 267.558C50.5576 269.629 52.2362 271.307 54.3069 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 271.307C66.4558 271.307 68.1344 269.629 68.1344 267.558C68.1344 265.487 66.4558 263.809 64.3851 263.809C62.3144 263.809 60.6357 265.487 60.6357 267.558C60.6357 269.629 62.3144 271.307 64.3851 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 271.307C76.53 271.307 78.2086 269.629 78.2086 267.558C78.2086 265.487 76.53 263.809 74.4593 263.809C72.3886 263.809 70.71 265.487 70.71 267.558C70.71 269.629 72.3886 271.307 74.4593 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 271.307C86.6081 271.307 88.2868 269.629 88.2868 267.558C88.2868 265.487 86.6081 263.809 84.5374 263.809C82.4667 263.809 80.7881 265.487 80.7881 267.558C80.7881 269.629 82.4667 271.307 84.5374 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 271.307C96.6823 271.307 98.361 269.629 98.361 267.558C98.361 265.487 96.6823 263.809 94.6116 263.809C92.5409 263.809 90.8623 265.487 90.8623 267.558C90.8623 269.629 92.5409 271.307 94.6116 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 271.307C106.761 271.307 108.44 269.629 108.44 267.558C108.44 265.487 106.761 263.809 104.691 263.809C102.62 263.809 100.941 265.487 100.941 267.558C100.941 269.629 102.62 271.307 104.691 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 271.307C116.836 271.307 118.514 269.629 118.514 267.558C118.514 265.487 116.836 263.809 114.765 263.809C112.694 263.809 111.016 265.487 111.016 267.558C111.016 269.629 112.694 271.307 114.765 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 271.307C126.914 271.307 128.592 269.629 128.592 267.558C128.592 265.487 126.914 263.809 124.843 263.809C122.772 263.809 121.094 265.487 121.094 267.558C121.094 269.629 122.772 271.307 124.843 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 271.307C136.988 271.307 138.667 269.629 138.667 267.558C138.667 265.487 136.988 263.809 134.917 263.809C132.847 263.809 131.168 265.487 131.168 267.558C131.168 269.629 132.847 271.307 134.917 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 271.307C147.067 271.307 148.746 269.629 148.746 267.558C148.746 265.487 147.067 263.809 144.996 263.809C142.926 263.809 141.247 265.487 141.247 267.558C141.247 269.629 142.926 271.307 144.996 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 261.19C46.3034 261.19 47.9821 259.511 47.9821 257.441C47.9821 255.37 46.3034 253.691 44.2327 253.691C42.162 253.691 40.4834 255.37 40.4834 257.441C40.4834 259.511 42.162 261.19 44.2327 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 261.19C56.3776 261.19 58.0563 259.511 58.0563 257.441C58.0563 255.37 56.3776 253.691 54.3069 253.691C52.2362 253.691 50.5576 255.37 50.5576 257.441C50.5576 259.511 52.2362 261.19 54.3069 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 261.19C66.4558 261.19 68.1344 259.511 68.1344 257.441C68.1344 255.37 66.4558 253.691 64.3851 253.691C62.3144 253.691 60.6357 255.37 60.6357 257.441C60.6357 259.511 62.3144 261.19 64.3851 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 261.19C76.53 261.19 78.2086 259.511 78.2086 257.441C78.2086 255.37 76.53 253.691 74.4593 253.691C72.3886 253.691 70.71 255.37 70.71 257.441C70.71 259.511 72.3886 261.19 74.4593 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 261.19C86.6081 261.19 88.2868 259.511 88.2868 257.441C88.2868 255.37 86.6081 253.691 84.5374 253.691C82.4667 253.691 80.7881 255.37 80.7881 257.441C80.7881 259.511 82.4667 261.19 84.5374 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 261.19C96.6823 261.19 98.361 259.511 98.361 257.441C98.361 255.37 96.6823 253.691 94.6116 253.691C92.5409 253.691 90.8623 255.37 90.8623 257.441C90.8623 259.511 92.5409 261.19 94.6116 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 261.19C106.761 261.19 108.44 259.511 108.44 257.441C108.44 255.37 106.761 253.691 104.691 253.691C102.62 253.691 100.941 255.37 100.941 257.441C100.941 259.511 102.62 261.19 104.691 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 261.19C116.836 261.19 118.514 259.511 118.514 257.441C118.514 255.37 116.836 253.691 114.765 253.691C112.694 253.691 111.016 255.37 111.016 257.441C111.016 259.511 112.694 261.19 114.765 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 261.19C126.914 261.19 128.592 259.511 128.592 257.441C128.592 255.37 126.914 253.691 124.843 253.691C122.772 253.691 121.094 255.37 121.094 257.441C121.094 259.511 122.772 261.19 124.843 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 261.19C136.988 261.19 138.667 259.511 138.667 257.441C138.667 255.37 136.988 253.691 134.917 253.691C132.847 253.691 131.168 255.37 131.168 257.441C131.168 259.511 132.847 261.19 134.917 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 261.19C147.067 261.19 148.746 259.511 148.746 257.441C148.746 255.37 147.067 253.691 144.996 253.691C142.926 253.691 141.247 255.37 141.247 257.441C141.247 259.511 142.926 261.19 144.996 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 251.075C36.2243 251.075 37.903 249.396 37.903 247.326C37.903 245.255 36.2243 243.576 34.1536 243.576C32.0829 243.576 30.4043 245.255 30.4043 247.326C30.4043 249.396 32.0829 251.075 34.1536 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 251.075C46.3034 251.075 47.9821 249.396 47.9821 247.326C47.9821 245.255 46.3034 243.576 44.2327 243.576C42.162 243.576 40.4834 245.255 40.4834 247.326C40.4834 249.396 42.162 251.075 44.2327 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 251.075C56.3776 251.075 58.0563 249.396 58.0563 247.326C58.0563 245.255 56.3776 243.576 54.3069 243.576C52.2362 243.576 50.5576 245.255 50.5576 247.326C50.5576 249.396 52.2362 251.075 54.3069 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 251.075C66.4558 251.075 68.1344 249.396 68.1344 247.326C68.1344 245.255 66.4558 243.576 64.3851 243.576C62.3144 243.576 60.6357 245.255 60.6357 247.326C60.6357 249.396 62.3144 251.075 64.3851 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 251.075C76.53 251.075 78.2086 249.396 78.2086 247.326C78.2086 245.255 76.53 243.576 74.4593 243.576C72.3886 243.576 70.71 245.255 70.71 247.326C70.71 249.396 72.3886 251.075 74.4593 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 251.075C86.6081 251.075 88.2868 249.396 88.2868 247.326C88.2868 245.255 86.6081 243.576 84.5374 243.576C82.4667 243.576 80.7881 245.255 80.7881 247.326C80.7881 249.396 82.4667 251.075 84.5374 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 251.075C96.6823 251.075 98.361 249.396 98.361 247.326C98.361 245.255 96.6823 243.576 94.6116 243.576C92.5409 243.576 90.8623 245.255 90.8623 247.326C90.8623 249.396 92.5409 251.075 94.6116 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 251.075C106.761 251.075 108.44 249.396 108.44 247.326C108.44 245.255 106.761 243.576 104.691 243.576C102.62 243.576 100.941 245.255 100.941 247.326C100.941 249.396 102.62 251.075 104.691 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 240.958C26.1511 240.958 27.8297 239.279 27.8297 237.208C27.8297 235.138 26.1511 233.459 24.0804 233.459C22.0097 233.459 20.3311 235.138 20.3311 237.208C20.3311 239.279 22.0097 240.958 24.0804 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 240.958C36.2243 240.958 37.903 239.279 37.903 237.208C37.903 235.138 36.2243 233.459 34.1536 233.459C32.0829 233.459 30.4043 235.138 30.4043 237.208C30.4043 239.279 32.0829 240.958 34.1536 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 240.958C46.3034 240.958 47.9821 239.279 47.9821 237.208C47.9821 235.138 46.3034 233.459 44.2327 233.459C42.162 233.459 40.4834 235.138 40.4834 237.208C40.4834 239.279 42.162 240.958 44.2327 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 240.958C56.3776 240.958 58.0563 239.279 58.0563 237.208C58.0563 235.138 56.3776 233.459 54.3069 233.459C52.2362 233.459 50.5576 235.138 50.5576 237.208C50.5576 239.279 52.2362 240.958 54.3069 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 240.958C66.4558 240.958 68.1344 239.279 68.1344 237.208C68.1344 235.138 66.4558 233.459 64.3851 233.459C62.3144 233.459 60.6357 235.138 60.6357 237.208C60.6357 239.279 62.3144 240.958 64.3851 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 240.958C76.53 240.958 78.2086 239.279 78.2086 237.208C78.2086 235.138 76.53 233.459 74.4593 233.459C72.3886 233.459 70.71 235.138 70.71 237.208C70.71 239.279 72.3886 240.958 74.4593 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 240.958C86.6081 240.958 88.2868 239.279 88.2868 237.208C88.2868 235.138 86.6081 233.459 84.5374 233.459C82.4667 233.459 80.7881 235.138 80.7881 237.208C80.7881 239.279 82.4667 240.958 84.5374 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 240.958C126.914 240.958 128.592 239.279 128.592 237.208C128.592 235.138 126.914 233.459 124.843 233.459C122.772 233.459 121.094 235.138 121.094 237.208C121.094 239.279 122.772 240.958 124.843 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 240.958C136.988 240.958 138.667 239.279 138.667 237.208C138.667 235.138 136.988 233.459 134.917 233.459C132.847 233.459 131.168 235.138 131.168 237.208C131.168 239.279 132.847 240.958 134.917 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 240.958C147.067 240.958 148.746 239.279 148.746 237.208C148.746 235.138 147.067 233.459 144.996 233.459C142.926 233.459 141.247 235.138 141.247 237.208C141.247 239.279 142.926 240.958 144.996 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 230.837C26.1511 230.837 27.8297 229.158 27.8297 227.087C27.8297 225.017 26.1511 223.338 24.0804 223.338C22.0097 223.338 20.3311 225.017 20.3311 227.087C20.3311 229.158 22.0097 230.837 24.0804 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 230.837C36.2243 230.837 37.903 229.158 37.903 227.087C37.903 225.017 36.2243 223.338 34.1536 223.338C32.0829 223.338 30.4043 225.017 30.4043 227.087C30.4043 229.158 32.0829 230.837 34.1536 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 230.837C46.3034 230.837 47.9821 229.158 47.9821 227.087C47.9821 225.017 46.3034 223.338 44.2327 223.338C42.162 223.338 40.4834 225.017 40.4834 227.087C40.4834 229.158 42.162 230.837 44.2327 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 230.837C56.3776 230.837 58.0563 229.158 58.0563 227.087C58.0563 225.017 56.3776 223.338 54.3069 223.338C52.2362 223.338 50.5576 225.017 50.5576 227.087C50.5576 229.158 52.2362 230.837 54.3069 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 230.837C66.4558 230.837 68.1344 229.158 68.1344 227.087C68.1344 225.017 66.4558 223.338 64.3851 223.338C62.3144 223.338 60.6357 225.017 60.6357 227.087C60.6357 229.158 62.3144 230.837 64.3851 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 230.837C76.53 230.837 78.2086 229.158 78.2086 227.087C78.2086 225.017 76.53 223.338 74.4593 223.338C72.3886 223.338 70.71 225.017 70.71 227.087C70.71 229.158 72.3886 230.837 74.4593 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 230.837C106.761 230.837 108.44 229.158 108.44 227.087C108.44 225.017 106.761 223.338 104.691 223.338C102.62 223.338 100.941 225.017 100.941 227.087C100.941 229.158 102.62 230.837 104.691 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 230.837C116.836 230.837 118.514 229.158 118.514 227.087C118.514 225.017 116.836 223.338 114.765 223.338C112.694 223.338 111.016 225.017 111.016 227.087C111.016 229.158 112.694 230.837 114.765 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 230.837C126.914 230.837 128.592 229.158 128.592 227.087C128.592 225.017 126.914 223.338 124.843 223.338C122.772 223.338 121.094 225.017 121.094 227.087C121.094 229.158 122.772 230.837 124.843 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 230.837C136.988 230.837 138.667 229.158 138.667 227.087C138.667 225.017 136.988 223.338 134.917 223.338C132.847 223.338 131.168 225.017 131.168 227.087C131.168 229.158 132.847 230.837 134.917 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 230.837C147.067 230.837 148.746 229.158 148.746 227.087C148.746 225.017 147.067 223.338 144.996 223.338C142.926 223.338 141.247 225.017 141.247 227.087C141.247 229.158 142.926 230.837 144.996 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 220.721C16.072 220.721 17.7506 219.043 17.7506 216.972C17.7506 214.901 16.072 213.223 14.0013 213.223C11.9306 213.223 10.252 214.901 10.252 216.972C10.252 219.043 11.9306 220.721 14.0013 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 220.721C26.1511 220.721 27.8297 219.043 27.8297 216.972C27.8297 214.901 26.1511 213.223 24.0804 213.223C22.0097 213.223 20.3311 214.901 20.3311 216.972C20.3311 219.043 22.0097 220.721 24.0804 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 220.721C36.2243 220.721 37.903 219.043 37.903 216.972C37.903 214.901 36.2243 213.223 34.1536 213.223C32.0829 213.223 30.4043 214.901 30.4043 216.972C30.4043 219.043 32.0829 220.721 34.1536 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 220.721C46.3034 220.721 47.9821 219.043 47.9821 216.972C47.9821 214.901 46.3034 213.223 44.2327 213.223C42.162 213.223 40.4834 214.901 40.4834 216.972C40.4834 219.043 42.162 220.721 44.2327 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 220.721C56.3776 220.721 58.0563 219.043 58.0563 216.972C58.0563 214.901 56.3776 213.223 54.3069 213.223C52.2362 213.223 50.5576 214.901 50.5576 216.972C50.5576 219.043 52.2362 220.721 54.3069 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 220.721C66.4558 220.721 68.1344 219.043 68.1344 216.972C68.1344 214.901 66.4558 213.223 64.3851 213.223C62.3144 213.223 60.6357 214.901 60.6357 216.972C60.6357 219.043 62.3144 220.721 64.3851 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 220.721C96.6823 220.721 98.361 219.043 98.361 216.972C98.361 214.901 96.6823 213.223 94.6116 213.223C92.5409 213.223 90.8623 214.901 90.8623 216.972C90.8623 219.043 92.5409 220.721 94.6116 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 220.721C106.761 220.721 108.44 219.043 108.44 216.972C108.44 214.901 106.761 213.223 104.691 213.223C102.62 213.223 100.941 214.901 100.941 216.972C100.941 219.043 102.62 220.721 104.691 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 220.721C116.836 220.721 118.514 219.043 118.514 216.972C118.514 214.901 116.836 213.223 114.765 213.223C112.694 213.223 111.016 214.901 111.016 216.972C111.016 219.043 112.694 220.721 114.765 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 220.721C126.914 220.721 128.592 219.043 128.592 216.972C128.592 214.901 126.914 213.223 124.843 213.223C122.772 213.223 121.094 214.901 121.094 216.972C121.094 219.043 122.772 220.721 124.843 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 220.721C136.988 220.721 138.667 219.043 138.667 216.972C138.667 214.901 136.988 213.223 134.917 213.223C132.847 213.223 131.168 214.901 131.168 216.972C131.168 219.043 132.847 220.721 134.917 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 220.721C147.067 220.721 148.746 219.043 148.746 216.972C148.746 214.901 147.067 213.223 144.996 213.223C142.926 213.223 141.247 214.901 141.247 216.972C141.247 219.043 142.926 220.721 144.996 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 210.604C16.072 210.604 17.7506 208.926 17.7506 206.855C17.7506 204.784 16.072 203.105 14.0013 203.105C11.9306 203.105 10.252 204.784 10.252 206.855C10.252 208.926 11.9306 210.604 14.0013 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 210.604C26.1511 210.604 27.8297 208.926 27.8297 206.855C27.8297 204.784 26.1511 203.105 24.0804 203.105C22.0097 203.105 20.3311 204.784 20.3311 206.855C20.3311 208.926 22.0097 210.604 24.0804 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 210.604C36.2243 210.604 37.903 208.926 37.903 206.855C37.903 204.784 36.2243 203.105 34.1536 203.105C32.0829 203.105 30.4043 204.784 30.4043 206.855C30.4043 208.926 32.0829 210.604 34.1536 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 210.604C46.3034 210.604 47.9821 208.926 47.9821 206.855C47.9821 204.784 46.3034 203.105 44.2327 203.105C42.162 203.105 40.4834 204.784 40.4834 206.855C40.4834 208.926 42.162 210.604 44.2327 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 210.604C56.3776 210.604 58.0563 208.926 58.0563 206.855C58.0563 204.784 56.3776 203.105 54.3069 203.105C52.2362 203.105 50.5576 204.784 50.5576 206.855C50.5576 208.926 52.2362 210.604 54.3069 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 210.604C86.6081 210.604 88.2868 208.926 88.2868 206.855C88.2868 204.784 86.6081 203.105 84.5374 203.105C82.4667 203.105 80.7881 204.784 80.7881 206.855C80.7881 208.926 82.4667 210.604 84.5374 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 210.604C96.6823 210.604 98.361 208.926 98.361 206.855C98.361 204.784 96.6823 203.105 94.6116 203.105C92.5409 203.105 90.8623 204.784 90.8623 206.855C90.8623 208.926 92.5409 210.604 94.6116 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 210.604C106.761 210.604 108.44 208.926 108.44 206.855C108.44 204.784 106.761 203.105 104.691 203.105C102.62 203.105 100.941 204.784 100.941 206.855C100.941 208.926 102.62 210.604 104.691 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 210.604C116.836 210.604 118.514 208.926 118.514 206.855C118.514 204.784 116.836 203.105 114.765 203.105C112.694 203.105 111.016 204.784 111.016 206.855C111.016 208.926 112.694 210.604 114.765 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 210.604C126.914 210.604 128.592 208.926 128.592 206.855C128.592 204.784 126.914 203.105 124.843 203.105C122.772 203.105 121.094 204.784 121.094 206.855C121.094 208.926 122.772 210.604 124.843 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 210.604C136.988 210.604 138.667 208.926 138.667 206.855C138.667 204.784 136.988 203.105 134.917 203.105C132.847 203.105 131.168 204.784 131.168 206.855C131.168 208.926 132.847 210.604 134.917 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 210.604C147.067 210.604 148.746 208.926 148.746 206.855C148.746 204.784 147.067 203.105 144.996 203.105C142.926 203.105 141.247 204.784 141.247 206.855C141.247 208.926 142.926 210.604 144.996 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 200.487C5.99777 200.487 7.6764 198.808 7.6764 196.738C7.6764 194.667 5.99777 192.988 3.92707 192.988C1.85637 192.988 0.177734 194.667 0.177734 196.738C0.177734 198.808 1.85637 200.487 3.92707 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 200.487C16.072 200.487 17.7506 198.808 17.7506 196.738C17.7506 194.667 16.072 192.988 14.0013 192.988C11.9306 192.988 10.252 194.667 10.252 196.738C10.252 198.808 11.9306 200.487 14.0013 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 200.487C26.1511 200.487 27.8297 198.808 27.8297 196.738C27.8297 194.667 26.1511 192.988 24.0804 192.988C22.0097 192.988 20.3311 194.667 20.3311 196.738C20.3311 198.808 22.0097 200.487 24.0804 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 200.487C36.2243 200.487 37.903 198.808 37.903 196.738C37.903 194.667 36.2243 192.988 34.1536 192.988C32.0829 192.988 30.4043 194.667 30.4043 196.738C30.4043 198.808 32.0829 200.487 34.1536 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 200.487C46.3034 200.487 47.9821 198.808 47.9821 196.738C47.9821 194.667 46.3034 192.988 44.2327 192.988C42.162 192.988 40.4834 194.667 40.4834 196.738C40.4834 198.808 42.162 200.487 44.2327 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 200.487C56.3776 200.487 58.0563 198.808 58.0563 196.738C58.0563 194.667 56.3776 192.988 54.3069 192.988C52.2362 192.988 50.5576 194.667 50.5576 196.738C50.5576 198.808 52.2362 200.487 54.3069 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 200.487C86.6081 200.487 88.2868 198.808 88.2868 196.738C88.2868 194.667 86.6081 192.988 84.5374 192.988C82.4667 192.988 80.7881 194.667 80.7881 196.738C80.7881 198.808 82.4667 200.487 84.5374 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 200.487C96.6823 200.487 98.361 198.808 98.361 196.738C98.361 194.667 96.6823 192.988 94.6116 192.988C92.5409 192.988 90.8623 194.667 90.8623 196.738C90.8623 198.808 92.5409 200.487 94.6116 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 200.487C106.761 200.487 108.44 198.808 108.44 196.738C108.44 194.667 106.761 192.988 104.691 192.988C102.62 192.988 100.941 194.667 100.941 196.738C100.941 198.808 102.62 200.487 104.691 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 200.487C116.836 200.487 118.514 198.808 118.514 196.738C118.514 194.667 116.836 192.988 114.765 192.988C112.694 192.988 111.016 194.667 111.016 196.738C111.016 198.808 112.694 200.487 114.765 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 200.487C126.914 200.487 128.592 198.808 128.592 196.738C128.592 194.667 126.914 192.988 124.843 192.988C122.772 192.988 121.094 194.667 121.094 196.738C121.094 198.808 122.772 200.487 124.843 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 200.487C136.988 200.487 138.667 198.808 138.667 196.738C138.667 194.667 136.988 192.988 134.917 192.988C132.847 192.988 131.168 194.667 131.168 196.738C131.168 198.808 132.847 200.487 134.917 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 200.487C147.067 200.487 148.746 198.808 148.746 196.738C148.746 194.667 147.067 192.988 144.996 192.988C142.926 192.988 141.247 194.667 141.247 196.738C141.247 198.808 142.926 200.487 144.996 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 190.368C5.99777 190.368 7.6764 188.689 7.6764 186.618C7.6764 184.548 5.99777 182.869 3.92707 182.869C1.85637 182.869 0.177734 184.548 0.177734 186.618C0.177734 188.689 1.85637 190.368 3.92707 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 190.368C16.072 190.368 17.7506 188.689 17.7506 186.618C17.7506 184.548 16.072 182.869 14.0013 182.869C11.9306 182.869 10.252 184.548 10.252 186.618C10.252 188.689 11.9306 190.368 14.0013 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 190.368C26.1511 190.368 27.8297 188.689 27.8297 186.618C27.8297 184.548 26.1511 182.869 24.0804 182.869C22.0097 182.869 20.3311 184.548 20.3311 186.618C20.3311 188.689 22.0097 190.368 24.0804 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 190.368C36.2243 190.368 37.903 188.689 37.903 186.618C37.903 184.548 36.2243 182.869 34.1536 182.869C32.0829 182.869 30.4043 184.548 30.4043 186.618C30.4043 188.689 32.0829 190.368 34.1536 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 190.368C46.3034 190.368 47.9821 188.689 47.9821 186.618C47.9821 184.548 46.3034 182.869 44.2327 182.869C42.162 182.869 40.4834 184.548 40.4834 186.618C40.4834 188.689 42.162 190.368 44.2327 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 190.368C56.3776 190.368 58.0563 188.689 58.0563 186.618C58.0563 184.548 56.3776 182.869 54.3069 182.869C52.2362 182.869 50.5576 184.548 50.5576 186.618C50.5576 188.689 52.2362 190.368 54.3069 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 190.368C76.53 190.368 78.2086 188.689 78.2086 186.618C78.2086 184.548 76.53 182.869 74.4593 182.869C72.3886 182.869 70.71 184.548 70.71 186.618C70.71 188.689 72.3886 190.368 74.4593 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 190.368C86.6081 190.368 88.2868 188.689 88.2868 186.618C88.2868 184.548 86.6081 182.869 84.5374 182.869C82.4667 182.869 80.7881 184.548 80.7881 186.618C80.7881 188.689 82.4667 190.368 84.5374 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 190.368C96.6823 190.368 98.361 188.689 98.361 186.618C98.361 184.548 96.6823 182.869 94.6116 182.869C92.5409 182.869 90.8623 184.548 90.8623 186.618C90.8623 188.689 92.5409 190.368 94.6116 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 190.368C106.761 190.368 108.44 188.689 108.44 186.618C108.44 184.548 106.761 182.869 104.691 182.869C102.62 182.869 100.941 184.548 100.941 186.618C100.941 188.689 102.62 190.368 104.691 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 190.368C116.836 190.368 118.514 188.689 118.514 186.618C118.514 184.548 116.836 182.869 114.765 182.869C112.694 182.869 111.016 184.548 111.016 186.618C111.016 188.689 112.694 190.368 114.765 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 190.368C126.914 190.368 128.592 188.689 128.592 186.618C128.592 184.548 126.914 182.869 124.843 182.869C122.772 182.869 121.094 184.548 121.094 186.618C121.094 188.689 122.772 190.368 124.843 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 190.368C136.988 190.368 138.667 188.689 138.667 186.618C138.667 184.548 136.988 182.869 134.917 182.869C132.847 182.869 131.168 184.548 131.168 186.618C131.168 188.689 132.847 190.368 134.917 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 190.368C147.067 190.368 148.746 188.689 148.746 186.618C148.746 184.548 147.067 182.869 144.996 182.869C142.926 182.869 141.247 184.548 141.247 186.618C141.247 188.689 142.926 190.368 144.996 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 180.251C5.99777 180.251 7.6764 178.572 7.6764 176.501C7.6764 174.431 5.99777 172.752 3.92707 172.752C1.85637 172.752 0.177734 174.431 0.177734 176.501C0.177734 178.572 1.85637 180.251 3.92707 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 180.251C16.072 180.251 17.7506 178.572 17.7506 176.501C17.7506 174.431 16.072 172.752 14.0013 172.752C11.9306 172.752 10.252 174.431 10.252 176.501C10.252 178.572 11.9306 180.251 14.0013 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 180.251C26.1511 180.251 27.8297 178.572 27.8297 176.501C27.8297 174.431 26.1511 172.752 24.0804 172.752C22.0097 172.752 20.3311 174.431 20.3311 176.501C20.3311 178.572 22.0097 180.251 24.0804 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 180.251C36.2243 180.251 37.903 178.572 37.903 176.501C37.903 174.431 36.2243 172.752 34.1536 172.752C32.0829 172.752 30.4043 174.431 30.4043 176.501C30.4043 178.572 32.0829 180.251 34.1536 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 180.251C46.3034 180.251 47.9821 178.572 47.9821 176.501C47.9821 174.431 46.3034 172.752 44.2327 172.752C42.162 172.752 40.4834 174.431 40.4834 176.501C40.4834 178.572 42.162 180.251 44.2327 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 180.251C56.3776 180.251 58.0563 178.572 58.0563 176.501C58.0563 174.431 56.3776 172.752 54.3069 172.752C52.2362 172.752 50.5576 174.431 50.5576 176.501C50.5576 178.572 52.2362 180.251 54.3069 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 180.251C76.53 180.251 78.2086 178.572 78.2086 176.501C78.2086 174.431 76.53 172.752 74.4593 172.752C72.3886 172.752 70.71 174.431 70.71 176.501C70.71 178.572 72.3886 180.251 74.4593 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 180.251C86.6081 180.251 88.2868 178.572 88.2868 176.501C88.2868 174.431 86.6081 172.752 84.5374 172.752C82.4667 172.752 80.7881 174.431 80.7881 176.501C80.7881 178.572 82.4667 180.251 84.5374 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 180.251C96.6823 180.251 98.361 178.572 98.361 176.501C98.361 174.431 96.6823 172.752 94.6116 172.752C92.5409 172.752 90.8623 174.431 90.8623 176.501C90.8623 178.572 92.5409 180.251 94.6116 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 180.251C106.761 180.251 108.44 178.572 108.44 176.501C108.44 174.431 106.761 172.752 104.691 172.752C102.62 172.752 100.941 174.431 100.941 176.501C100.941 178.572 102.62 180.251 104.691 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 180.251C116.836 180.251 118.514 178.572 118.514 176.501C118.514 174.431 116.836 172.752 114.765 172.752C112.694 172.752 111.016 174.431 111.016 176.501C111.016 178.572 112.694 180.251 114.765 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 180.251C126.914 180.251 128.592 178.572 128.592 176.501C128.592 174.431 126.914 172.752 124.843 172.752C122.772 172.752 121.094 174.431 121.094 176.501C121.094 178.572 122.772 180.251 124.843 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 180.251C136.988 180.251 138.667 178.572 138.667 176.501C138.667 174.431 136.988 172.752 134.917 172.752C132.847 172.752 131.168 174.431 131.168 176.501C131.168 178.572 132.847 180.251 134.917 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 180.251C147.067 180.251 148.746 178.572 148.746 176.501C148.746 174.431 147.067 172.752 144.996 172.752C142.926 172.752 141.247 174.431 141.247 176.501C141.247 178.572 142.926 180.251 144.996 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 170.133C5.99777 170.133 7.6764 168.455 7.6764 166.384C7.6764 164.313 5.99777 162.635 3.92707 162.635C1.85637 162.635 0.177734 164.313 0.177734 166.384C0.177734 168.455 1.85637 170.133 3.92707 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 170.133C16.072 170.133 17.7506 168.455 17.7506 166.384C17.7506 164.313 16.072 162.635 14.0013 162.635C11.9306 162.635 10.252 164.313 10.252 166.384C10.252 168.455 11.9306 170.133 14.0013 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 170.133C26.1511 170.133 27.8297 168.455 27.8297 166.384C27.8297 164.313 26.1511 162.635 24.0804 162.635C22.0097 162.635 20.3311 164.313 20.3311 166.384C20.3311 168.455 22.0097 170.133 24.0804 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 170.133C36.2243 170.133 37.903 168.455 37.903 166.384C37.903 164.313 36.2243 162.635 34.1536 162.635C32.0829 162.635 30.4043 164.313 30.4043 166.384C30.4043 168.455 32.0829 170.133 34.1536 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 170.133C46.3034 170.133 47.9821 168.455 47.9821 166.384C47.9821 164.313 46.3034 162.635 44.2327 162.635C42.162 162.635 40.4834 164.313 40.4834 166.384C40.4834 168.455 42.162 170.133 44.2327 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 170.133C56.3776 170.133 58.0563 168.455 58.0563 166.384C58.0563 164.313 56.3776 162.635 54.3069 162.635C52.2362 162.635 50.5576 164.313 50.5576 166.384C50.5576 168.455 52.2362 170.133 54.3069 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 170.133C76.53 170.133 78.2086 168.455 78.2086 166.384C78.2086 164.313 76.53 162.635 74.4593 162.635C72.3886 162.635 70.71 164.313 70.71 166.384C70.71 168.455 72.3886 170.133 74.4593 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 170.133C86.6081 170.133 88.2868 168.455 88.2868 166.384C88.2868 164.313 86.6081 162.635 84.5374 162.635C82.4667 162.635 80.7881 164.313 80.7881 166.384C80.7881 168.455 82.4667 170.133 84.5374 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 170.133C96.6823 170.133 98.361 168.455 98.361 166.384C98.361 164.313 96.6823 162.635 94.6116 162.635C92.5409 162.635 90.8623 164.313 90.8623 166.384C90.8623 168.455 92.5409 170.133 94.6116 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 170.133C106.761 170.133 108.44 168.455 108.44 166.384C108.44 164.313 106.761 162.635 104.691 162.635C102.62 162.635 100.941 164.313 100.941 166.384C100.941 168.455 102.62 170.133 104.691 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 170.133C116.836 170.133 118.514 168.455 118.514 166.384C118.514 164.313 116.836 162.635 114.765 162.635C112.694 162.635 111.016 164.313 111.016 166.384C111.016 168.455 112.694 170.133 114.765 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 170.133C126.914 170.133 128.592 168.455 128.592 166.384C128.592 164.313 126.914 162.635 124.843 162.635C122.772 162.635 121.094 164.313 121.094 166.384C121.094 168.455 122.772 170.133 124.843 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 170.133C136.988 170.133 138.667 168.455 138.667 166.384C138.667 164.313 136.988 162.635 134.917 162.635C132.847 162.635 131.168 164.313 131.168 166.384C131.168 168.455 132.847 170.133 134.917 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 170.133C147.067 170.133 148.746 168.455 148.746 166.384C148.746 164.313 147.067 162.635 144.996 162.635C142.926 162.635 141.247 164.313 141.247 166.384C141.247 168.455 142.926 170.133 144.996 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 160.018C5.99777 160.018 7.6764 158.34 7.6764 156.269C7.6764 154.198 5.99777 152.52 3.92707 152.52C1.85637 152.52 0.177734 154.198 0.177734 156.269C0.177734 158.34 1.85637 160.018 3.92707 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 160.018C16.072 160.018 17.7506 158.34 17.7506 156.269C17.7506 154.198 16.072 152.52 14.0013 152.52C11.9306 152.52 10.252 154.198 10.252 156.269C10.252 158.34 11.9306 160.018 14.0013 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 160.018C26.1511 160.018 27.8297 158.34 27.8297 156.269C27.8297 154.198 26.1511 152.52 24.0804 152.52C22.0097 152.52 20.3311 154.198 20.3311 156.269C20.3311 158.34 22.0097 160.018 24.0804 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 160.018C36.2243 160.018 37.903 158.34 37.903 156.269C37.903 154.198 36.2243 152.52 34.1536 152.52C32.0829 152.52 30.4043 154.198 30.4043 156.269C30.4043 158.34 32.0829 160.018 34.1536 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 160.018C96.6823 160.018 98.361 158.34 98.361 156.269C98.361 154.198 96.6823 152.52 94.6116 152.52C92.5409 152.52 90.8623 154.198 90.8623 156.269C90.8623 158.34 92.5409 160.018 94.6116 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 160.018C106.761 160.018 108.44 158.34 108.44 156.269C108.44 154.198 106.761 152.52 104.691 152.52C102.62 152.52 100.941 154.198 100.941 156.269C100.941 158.34 102.62 160.018 104.691 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 160.018C116.836 160.018 118.514 158.34 118.514 156.269C118.514 154.198 116.836 152.52 114.765 152.52C112.694 152.52 111.016 154.198 111.016 156.269C111.016 158.34 112.694 160.018 114.765 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 160.018C126.914 160.018 128.592 158.34 128.592 156.269C128.592 154.198 126.914 152.52 124.843 152.52C122.772 152.52 121.094 154.198 121.094 156.269C121.094 158.34 122.772 160.018 124.843 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 160.018C136.988 160.018 138.667 158.34 138.667 156.269C138.667 154.198 136.988 152.52 134.917 152.52C132.847 152.52 131.168 154.198 131.168 156.269C131.168 158.34 132.847 160.018 134.917 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 160.018C147.067 160.018 148.746 158.34 148.746 156.269C148.746 154.198 147.067 152.52 144.996 152.52C142.926 152.52 141.247 154.198 141.247 156.269C141.247 158.34 142.926 160.018 144.996 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M3.92707 149.897C5.99777 149.897 7.6764 148.218 7.6764 146.148C7.6764 144.077 5.99777 142.398 3.92707 142.398C1.85637 142.398 0.177734 144.077 0.177734 146.148C0.177734 148.218 1.85637 149.897 3.92707 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M14.0013 149.897C16.072 149.897 17.7506 148.218 17.7506 146.148C17.7506 144.077 16.072 142.398 14.0013 142.398C11.9306 142.398 10.252 144.077 10.252 146.148C10.252 148.218 11.9306 149.897 14.0013 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M24.0804 149.897C26.1511 149.897 27.8297 148.218 27.8297 146.148C27.8297 144.077 26.1511 142.398 24.0804 142.398C22.0097 142.398 20.3311 144.077 20.3311 146.148C20.3311 148.218 22.0097 149.897 24.0804 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M34.1536 149.897C36.2243 149.897 37.903 148.218 37.903 146.148C37.903 144.077 36.2243 142.398 34.1536 142.398C32.0829 142.398 30.4043 144.077 30.4043 146.148C30.4043 148.218 32.0829 149.897 34.1536 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M44.2327 149.897C46.3034 149.897 47.9821 148.218 47.9821 146.148C47.9821 144.077 46.3034 142.398 44.2327 142.398C42.162 142.398 40.4834 144.077 40.4834 146.148C40.4834 148.218 42.162 149.897 44.2327 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M54.3069 149.897C56.3776 149.897 58.0563 148.218 58.0563 146.148C58.0563 144.077 56.3776 142.398 54.3069 142.398C52.2362 142.398 50.5576 144.077 50.5576 146.148C50.5576 148.218 52.2362 149.897 54.3069 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M64.3851 149.897C66.4558 149.897 68.1344 148.218 68.1344 146.148C68.1344 144.077 66.4558 142.398 64.3851 142.398C62.3144 142.398 60.6357 144.077 60.6357 146.148C60.6357 148.218 62.3144 149.897 64.3851 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M74.4593 149.897C76.53 149.897 78.2086 148.218 78.2086 146.148C78.2086 144.077 76.53 142.398 74.4593 142.398C72.3886 142.398 70.71 144.077 70.71 146.148C70.71 148.218 72.3886 149.897 74.4593 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M84.5374 149.897C86.6081 149.897 88.2868 148.218 88.2868 146.148C88.2868 144.077 86.6081 142.398 84.5374 142.398C82.4667 142.398 80.7881 144.077 80.7881 146.148C80.7881 148.218 82.4667 149.897 84.5374 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.6116 149.897C96.6823 149.897 98.361 148.218 98.361 146.148C98.361 144.077 96.6823 142.398 94.6116 142.398C92.5409 142.398 90.8623 144.077 90.8623 146.148C90.8623 148.218 92.5409 149.897 94.6116 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M104.691 149.897C106.761 149.897 108.44 148.218 108.44 146.148C108.44 144.077 106.761 142.398 104.691 142.398C102.62 142.398 100.941 144.077 100.941 146.148C100.941 148.218 102.62 149.897 104.691 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M114.765 149.897C116.836 149.897 118.514 148.218 118.514 146.148C118.514 144.077 116.836 142.398 114.765 142.398C112.694 142.398 111.016 144.077 111.016 146.148C111.016 148.218 112.694 149.897 114.765 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M124.843 149.897C126.914 149.897 128.592 148.218 128.592 146.148C128.592 144.077 126.914 142.398 124.843 142.398C122.772 142.398 121.094 144.077 121.094 146.148C121.094 148.218 122.772 149.897 124.843 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M134.917 149.897C136.988 149.897 138.667 148.218 138.667 146.148C138.667 144.077 136.988 142.398 134.917 142.398C132.847 142.398 131.168 144.077 131.168 146.148C131.168 148.218 132.847 149.897 134.917 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 149.897C147.067 149.897 148.746 148.218 148.746 146.148C148.746 144.077 147.067 142.398 144.996 142.398C142.926 142.398 141.247 144.077 141.247 146.148C141.247 148.218 142.926 149.897 144.996 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 291.544C197.446 291.544 199.125 289.865 199.125 287.794C199.125 285.724 197.446 284.045 195.375 284.045C193.305 284.045 191.626 285.724 191.626 287.794C191.626 289.865 193.305 291.544 195.375 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 291.544C187.368 291.544 189.047 289.865 189.047 287.794C189.047 285.724 187.368 284.045 185.297 284.045C183.226 284.045 181.548 285.724 181.548 287.794C181.548 289.865 183.226 291.544 185.297 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 291.544C177.294 291.544 178.972 289.865 178.972 287.794C178.972 285.724 177.294 284.045 175.223 284.045C173.152 284.045 171.474 285.724 171.474 287.794C171.474 289.865 173.152 291.544 175.223 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 291.544C167.215 291.544 168.893 289.865 168.893 287.794C168.893 285.724 167.215 284.045 165.144 284.045C163.073 284.045 161.395 285.724 161.395 287.794C161.395 289.865 163.073 291.544 165.144 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 291.544C157.14 291.544 158.819 289.865 158.819 287.794C158.819 285.724 157.14 284.045 155.07 284.045C152.999 284.045 151.32 285.724 151.32 287.794C151.32 289.865 152.999 291.544 155.07 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 291.544C147.067 291.544 148.746 289.865 148.746 287.794C148.746 285.724 147.067 284.045 144.996 284.045C142.926 284.045 141.247 285.724 141.247 287.794C141.247 289.865 142.926 291.544 144.996 291.544Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 281.428C227.673 281.428 229.351 279.75 229.351 277.679C229.351 275.608 227.673 273.93 225.602 273.93C223.531 273.93 221.853 275.608 221.853 277.679C221.853 279.75 223.531 281.428 225.602 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 281.428C217.598 281.428 219.277 279.75 219.277 277.679C219.277 275.608 217.598 273.93 215.528 273.93C213.457 273.93 211.778 275.608 211.778 277.679C211.778 279.75 213.457 281.428 215.528 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 281.428C207.52 281.428 209.199 279.75 209.199 277.679C209.199 275.608 207.52 273.93 205.45 273.93C203.379 273.93 201.7 275.608 201.7 277.679C201.7 279.75 203.379 281.428 205.45 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 281.428C197.446 281.428 199.125 279.75 199.125 277.679C199.125 275.608 197.446 273.93 195.375 273.93C193.305 273.93 191.626 275.608 191.626 277.679C191.626 279.75 193.305 281.428 195.375 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 281.428C187.368 281.428 189.047 279.75 189.047 277.679C189.047 275.608 187.368 273.93 185.297 273.93C183.226 273.93 181.548 275.608 181.548 277.679C181.548 279.75 183.226 281.428 185.297 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 281.428C177.294 281.428 178.972 279.75 178.972 277.679C178.972 275.608 177.294 273.93 175.223 273.93C173.152 273.93 171.474 275.608 171.474 277.679C171.474 279.75 173.152 281.428 175.223 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 281.428C167.215 281.428 168.893 279.75 168.893 277.679C168.893 275.608 167.215 273.93 165.144 273.93C163.073 273.93 161.395 275.608 161.395 277.679C161.395 279.75 163.073 281.428 165.144 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 281.428C157.14 281.428 158.819 279.75 158.819 277.679C158.819 275.608 157.14 273.93 155.07 273.93C152.999 273.93 151.32 275.608 151.32 277.679C151.32 279.75 152.999 281.428 155.07 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 281.428C147.067 281.428 148.746 279.75 148.746 277.679C148.746 275.608 147.067 273.93 144.996 273.93C142.926 273.93 141.247 275.608 141.247 277.679C141.247 279.75 142.926 281.428 144.996 281.428Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 271.307C237.752 271.307 239.43 269.629 239.43 267.558C239.43 265.487 237.752 263.809 235.681 263.809C233.61 263.809 231.932 265.487 231.932 267.558C231.932 269.629 233.61 271.307 235.681 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 271.307C227.673 271.307 229.351 269.629 229.351 267.558C229.351 265.487 227.673 263.809 225.602 263.809C223.531 263.809 221.853 265.487 221.853 267.558C221.853 269.629 223.531 271.307 225.602 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 271.307C217.598 271.307 219.277 269.629 219.277 267.558C219.277 265.487 217.598 263.809 215.528 263.809C213.457 263.809 211.778 265.487 211.778 267.558C211.778 269.629 213.457 271.307 215.528 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 271.307C207.52 271.307 209.199 269.629 209.199 267.558C209.199 265.487 207.52 263.809 205.45 263.809C203.379 263.809 201.7 265.487 201.7 267.558C201.7 269.629 203.379 271.307 205.45 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 271.307C197.446 271.307 199.125 269.629 199.125 267.558C199.125 265.487 197.446 263.809 195.375 263.809C193.305 263.809 191.626 265.487 191.626 267.558C191.626 269.629 193.305 271.307 195.375 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 271.307C187.368 271.307 189.047 269.629 189.047 267.558C189.047 265.487 187.368 263.809 185.297 263.809C183.226 263.809 181.548 265.487 181.548 267.558C181.548 269.629 183.226 271.307 185.297 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 271.307C177.294 271.307 178.972 269.629 178.972 267.558C178.972 265.487 177.294 263.809 175.223 263.809C173.152 263.809 171.474 265.487 171.474 267.558C171.474 269.629 173.152 271.307 175.223 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 271.307C167.215 271.307 168.893 269.629 168.893 267.558C168.893 265.487 167.215 263.809 165.144 263.809C163.073 263.809 161.395 265.487 161.395 267.558C161.395 269.629 163.073 271.307 165.144 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 271.307C157.14 271.307 158.819 269.629 158.819 267.558C158.819 265.487 157.14 263.809 155.07 263.809C152.999 263.809 151.32 265.487 151.32 267.558C151.32 269.629 152.999 271.307 155.07 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 271.307C147.067 271.307 148.746 269.629 148.746 267.558C148.746 265.487 147.067 263.809 144.996 263.809C142.926 263.809 141.247 265.487 141.247 267.558C141.247 269.629 142.926 271.307 144.996 271.307Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 261.19C247.826 261.19 249.505 259.511 249.505 257.441C249.505 255.37 247.826 253.691 245.755 253.691C243.684 253.691 242.006 255.37 242.006 257.441C242.006 259.511 243.684 261.19 245.755 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 261.19C237.752 261.19 239.43 259.511 239.43 257.441C239.43 255.37 237.752 253.691 235.681 253.691C233.61 253.691 231.932 255.37 231.932 257.441C231.932 259.511 233.61 261.19 235.681 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 261.19C227.673 261.19 229.351 259.511 229.351 257.441C229.351 255.37 227.673 253.691 225.602 253.691C223.531 253.691 221.853 255.37 221.853 257.441C221.853 259.511 223.531 261.19 225.602 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 261.19C217.598 261.19 219.277 259.511 219.277 257.441C219.277 255.37 217.598 253.691 215.528 253.691C213.457 253.691 211.778 255.37 211.778 257.441C211.778 259.511 213.457 261.19 215.528 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 261.19C207.52 261.19 209.199 259.511 209.199 257.441C209.199 255.37 207.52 253.691 205.45 253.691C203.379 253.691 201.7 255.37 201.7 257.441C201.7 259.511 203.379 261.19 205.45 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 261.19C197.446 261.19 199.125 259.511 199.125 257.441C199.125 255.37 197.446 253.691 195.375 253.691C193.305 253.691 191.626 255.37 191.626 257.441C191.626 259.511 193.305 261.19 195.375 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 261.19C187.368 261.19 189.047 259.511 189.047 257.441C189.047 255.37 187.368 253.691 185.297 253.691C183.226 253.691 181.548 255.37 181.548 257.441C181.548 259.511 183.226 261.19 185.297 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 261.19C177.294 261.19 178.972 259.511 178.972 257.441C178.972 255.37 177.294 253.691 175.223 253.691C173.152 253.691 171.474 255.37 171.474 257.441C171.474 259.511 173.152 261.19 175.223 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 261.19C167.215 261.19 168.893 259.511 168.893 257.441C168.893 255.37 167.215 253.691 165.144 253.691C163.073 253.691 161.395 255.37 161.395 257.441C161.395 259.511 163.073 261.19 165.144 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 261.19C157.14 261.19 158.819 259.511 158.819 257.441C158.819 255.37 157.14 253.691 155.07 253.691C152.999 253.691 151.32 255.37 151.32 257.441C151.32 259.511 152.999 261.19 155.07 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 261.19C147.067 261.19 148.746 259.511 148.746 257.441C148.746 255.37 147.067 253.691 144.996 253.691C142.926 253.691 141.247 255.37 141.247 257.441C141.247 259.511 142.926 261.19 144.996 261.19Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 251.075C257.904 251.075 259.583 249.396 259.583 247.326C259.583 245.255 257.904 243.576 255.833 243.576C253.763 243.576 252.084 245.255 252.084 247.326C252.084 249.396 253.763 251.075 255.833 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 251.075C247.826 251.075 249.505 249.396 249.505 247.326C249.505 245.255 247.826 243.576 245.755 243.576C243.684 243.576 242.006 245.255 242.006 247.326C242.006 249.396 243.684 251.075 245.755 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 251.075C237.752 251.075 239.43 249.396 239.43 247.326C239.43 245.255 237.752 243.576 235.681 243.576C233.61 243.576 231.932 245.255 231.932 247.326C231.932 249.396 233.61 251.075 235.681 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 251.075C227.673 251.075 229.351 249.396 229.351 247.326C229.351 245.255 227.673 243.576 225.602 243.576C223.531 243.576 221.853 245.255 221.853 247.326C221.853 249.396 223.531 251.075 225.602 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 251.075C217.598 251.075 219.277 249.396 219.277 247.326C219.277 245.255 217.598 243.576 215.528 243.576C213.457 243.576 211.778 245.255 211.778 247.326C211.778 249.396 213.457 251.075 215.528 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 251.075C207.52 251.075 209.199 249.396 209.199 247.326C209.199 245.255 207.52 243.576 205.45 243.576C203.379 243.576 201.7 245.255 201.7 247.326C201.7 249.396 203.379 251.075 205.45 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 251.075C197.446 251.075 199.125 249.396 199.125 247.326C199.125 245.255 197.446 243.576 195.375 243.576C193.305 243.576 191.626 245.255 191.626 247.326C191.626 249.396 193.305 251.075 195.375 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 251.075C187.368 251.075 189.047 249.396 189.047 247.326C189.047 245.255 187.368 243.576 185.297 243.576C183.226 243.576 181.548 245.255 181.548 247.326C181.548 249.396 183.226 251.075 185.297 251.075Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 240.958C267.978 240.958 269.657 239.279 269.657 237.208C269.657 235.138 267.978 233.459 265.908 233.459C263.837 233.459 262.158 235.138 262.158 237.208C262.158 239.279 263.837 240.958 265.908 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 240.958C257.904 240.958 259.583 239.279 259.583 237.208C259.583 235.138 257.904 233.459 255.833 233.459C253.763 233.459 252.084 235.138 252.084 237.208C252.084 239.279 253.763 240.958 255.833 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 240.958C247.826 240.958 249.505 239.279 249.505 237.208C249.505 235.138 247.826 233.459 245.755 233.459C243.684 233.459 242.006 235.138 242.006 237.208C242.006 239.279 243.684 240.958 245.755 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 240.958C237.752 240.958 239.43 239.279 239.43 237.208C239.43 235.138 237.752 233.459 235.681 233.459C233.61 233.459 231.932 235.138 231.932 237.208C231.932 239.279 233.61 240.958 235.681 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 240.958C227.673 240.958 229.351 239.279 229.351 237.208C229.351 235.138 227.673 233.459 225.602 233.459C223.531 233.459 221.853 235.138 221.853 237.208C221.853 239.279 223.531 240.958 225.602 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 240.958C217.598 240.958 219.277 239.279 219.277 237.208C219.277 235.138 217.598 233.459 215.528 233.459C213.457 233.459 211.778 235.138 211.778 237.208C211.778 239.279 213.457 240.958 215.528 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 240.958C207.52 240.958 209.199 239.279 209.199 237.208C209.199 235.138 207.52 233.459 205.45 233.459C203.379 233.459 201.7 235.138 201.7 237.208C201.7 239.279 203.379 240.958 205.45 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 240.958C167.215 240.958 168.893 239.279 168.893 237.208C168.893 235.138 167.215 233.459 165.144 233.459C163.073 233.459 161.395 235.138 161.395 237.208C161.395 239.279 163.073 240.958 165.144 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 240.958C157.14 240.958 158.819 239.279 158.819 237.208C158.819 235.138 157.14 233.459 155.07 233.459C152.999 233.459 151.32 235.138 151.32 237.208C151.32 239.279 152.999 240.958 155.07 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 240.958C147.067 240.958 148.746 239.279 148.746 237.208C148.746 235.138 147.067 233.459 144.996 233.459C142.926 233.459 141.247 235.138 141.247 237.208C141.247 239.279 142.926 240.958 144.996 240.958Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 230.837C267.978 230.837 269.657 229.158 269.657 227.087C269.657 225.017 267.978 223.338 265.908 223.338C263.837 223.338 262.158 225.017 262.158 227.087C262.158 229.158 263.837 230.837 265.908 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 230.837C257.904 230.837 259.583 229.158 259.583 227.087C259.583 225.017 257.904 223.338 255.833 223.338C253.763 223.338 252.084 225.017 252.084 227.087C252.084 229.158 253.763 230.837 255.833 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 230.837C247.826 230.837 249.505 229.158 249.505 227.087C249.505 225.017 247.826 223.338 245.755 223.338C243.684 223.338 242.006 225.017 242.006 227.087C242.006 229.158 243.684 230.837 245.755 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 230.837C237.752 230.837 239.43 229.158 239.43 227.087C239.43 225.017 237.752 223.338 235.681 223.338C233.61 223.338 231.932 225.017 231.932 227.087C231.932 229.158 233.61 230.837 235.681 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 230.837C227.673 230.837 229.351 229.158 229.351 227.087C229.351 225.017 227.673 223.338 225.602 223.338C223.531 223.338 221.853 225.017 221.853 227.087C221.853 229.158 223.531 230.837 225.602 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 230.837C217.598 230.837 219.277 229.158 219.277 227.087C219.277 225.017 217.598 223.338 215.528 223.338C213.457 223.338 211.778 225.017 211.778 227.087C211.778 229.158 213.457 230.837 215.528 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 230.837C187.368 230.837 189.047 229.158 189.047 227.087C189.047 225.017 187.368 223.338 185.297 223.338C183.226 223.338 181.548 225.017 181.548 227.087C181.548 229.158 183.226 230.837 185.297 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 230.837C177.294 230.837 178.972 229.158 178.972 227.087C178.972 225.017 177.294 223.338 175.223 223.338C173.152 223.338 171.474 225.017 171.474 227.087C171.474 229.158 173.152 230.837 175.223 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 230.837C167.215 230.837 168.893 229.158 168.893 227.087C168.893 225.017 167.215 223.338 165.144 223.338C163.073 223.338 161.395 225.017 161.395 227.087C161.395 229.158 163.073 230.837 165.144 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 230.837C157.14 230.837 158.819 229.158 158.819 227.087C158.819 225.017 157.14 223.338 155.07 223.338C152.999 223.338 151.32 225.017 151.32 227.087C151.32 229.158 152.999 230.837 155.07 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 230.837C147.067 230.837 148.746 229.158 148.746 227.087C148.746 225.017 147.067 223.338 144.996 223.338C142.926 223.338 141.247 225.017 141.247 227.087C141.247 229.158 142.926 230.837 144.996 230.837Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 220.721C278.056 220.721 279.735 219.043 279.735 216.972C279.735 214.901 278.056 213.223 275.986 213.223C273.915 213.223 272.236 214.901 272.236 216.972C272.236 219.043 273.915 220.721 275.986 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 220.721C267.978 220.721 269.657 219.043 269.657 216.972C269.657 214.901 267.978 213.223 265.908 213.223C263.837 213.223 262.158 214.901 262.158 216.972C262.158 219.043 263.837 220.721 265.908 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 220.721C257.904 220.721 259.583 219.043 259.583 216.972C259.583 214.901 257.904 213.223 255.833 213.223C253.763 213.223 252.084 214.901 252.084 216.972C252.084 219.043 253.763 220.721 255.833 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 220.721C247.826 220.721 249.505 219.043 249.505 216.972C249.505 214.901 247.826 213.223 245.755 213.223C243.684 213.223 242.006 214.901 242.006 216.972C242.006 219.043 243.684 220.721 245.755 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 220.721C237.752 220.721 239.43 219.043 239.43 216.972C239.43 214.901 237.752 213.223 235.681 213.223C233.61 213.223 231.932 214.901 231.932 216.972C231.932 219.043 233.61 220.721 235.681 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M225.602 220.721C227.673 220.721 229.351 219.043 229.351 216.972C229.351 214.901 227.673 213.223 225.602 213.223C223.531 213.223 221.853 214.901 221.853 216.972C221.853 219.043 223.531 220.721 225.602 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 220.721C197.446 220.721 199.125 219.043 199.125 216.972C199.125 214.901 197.446 213.223 195.375 213.223C193.305 213.223 191.626 214.901 191.626 216.972C191.626 219.043 193.305 220.721 195.375 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 220.721C187.368 220.721 189.047 219.043 189.047 216.972C189.047 214.901 187.368 213.223 185.297 213.223C183.226 213.223 181.548 214.901 181.548 216.972C181.548 219.043 183.226 220.721 185.297 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 220.721C177.294 220.721 178.972 219.043 178.972 216.972C178.972 214.901 177.294 213.223 175.223 213.223C173.152 213.223 171.474 214.901 171.474 216.972C171.474 219.043 173.152 220.721 175.223 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 220.721C167.215 220.721 168.893 219.043 168.893 216.972C168.893 214.901 167.215 213.223 165.144 213.223C163.073 213.223 161.395 214.901 161.395 216.972C161.395 219.043 163.073 220.721 165.144 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 220.721C157.14 220.721 158.819 219.043 158.819 216.972C158.819 214.901 157.14 213.223 155.07 213.223C152.999 213.223 151.32 214.901 151.32 216.972C151.32 219.043 152.999 220.721 155.07 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 220.721C147.067 220.721 148.746 219.043 148.746 216.972C148.746 214.901 147.067 213.223 144.996 213.223C142.926 213.223 141.247 214.901 141.247 216.972C141.247 219.043 142.926 220.721 144.996 220.721Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 210.604C278.056 210.604 279.735 208.926 279.735 206.855C279.735 204.784 278.056 203.105 275.986 203.105C273.915 203.105 272.236 204.784 272.236 206.855C272.236 208.926 273.915 210.604 275.986 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 210.604C267.978 210.604 269.657 208.926 269.657 206.855C269.657 204.784 267.978 203.105 265.908 203.105C263.837 203.105 262.158 204.784 262.158 206.855C262.158 208.926 263.837 210.604 265.908 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 210.604C257.904 210.604 259.583 208.926 259.583 206.855C259.583 204.784 257.904 203.105 255.833 203.105C253.763 203.105 252.084 204.784 252.084 206.855C252.084 208.926 253.763 210.604 255.833 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 210.604C247.826 210.604 249.505 208.926 249.505 206.855C249.505 204.784 247.826 203.105 245.755 203.105C243.684 203.105 242.006 204.784 242.006 206.855C242.006 208.926 243.684 210.604 245.755 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 210.604C237.752 210.604 239.43 208.926 239.43 206.855C239.43 204.784 237.752 203.105 235.681 203.105C233.61 203.105 231.932 204.784 231.932 206.855C231.932 208.926 233.61 210.604 235.681 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 210.604C207.52 210.604 209.199 208.926 209.199 206.855C209.199 204.784 207.52 203.105 205.45 203.105C203.379 203.105 201.7 204.784 201.7 206.855C201.7 208.926 203.379 210.604 205.45 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 210.604C197.446 210.604 199.125 208.926 199.125 206.855C199.125 204.784 197.446 203.105 195.375 203.105C193.305 203.105 191.626 204.784 191.626 206.855C191.626 208.926 193.305 210.604 195.375 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 210.604C187.368 210.604 189.047 208.926 189.047 206.855C189.047 204.784 187.368 203.105 185.297 203.105C183.226 203.105 181.548 204.784 181.548 206.855C181.548 208.926 183.226 210.604 185.297 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 210.604C177.294 210.604 178.972 208.926 178.972 206.855C178.972 204.784 177.294 203.105 175.223 203.105C173.152 203.105 171.474 204.784 171.474 206.855C171.474 208.926 173.152 210.604 175.223 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 210.604C167.215 210.604 168.893 208.926 168.893 206.855C168.893 204.784 167.215 203.105 165.144 203.105C163.073 203.105 161.395 204.784 161.395 206.855C161.395 208.926 163.073 210.604 165.144 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 210.604C157.14 210.604 158.819 208.926 158.819 206.855C158.819 204.784 157.14 203.105 155.07 203.105C152.999 203.105 151.32 204.784 151.32 206.855C151.32 208.926 152.999 210.604 155.07 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 210.604C147.067 210.604 148.746 208.926 148.746 206.855C148.746 204.784 147.067 203.105 144.996 203.105C142.926 203.105 141.247 204.784 141.247 206.855C141.247 208.926 142.926 210.604 144.996 210.604Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 200.487C288.131 200.487 289.809 198.808 289.809 196.738C289.809 194.667 288.131 192.988 286.06 192.988C283.989 192.988 282.311 194.667 282.311 196.738C282.311 198.808 283.989 200.487 286.06 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 200.487C278.056 200.487 279.735 198.808 279.735 196.738C279.735 194.667 278.056 192.988 275.986 192.988C273.915 192.988 272.236 194.667 272.236 196.738C272.236 198.808 273.915 200.487 275.986 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 200.487C267.978 200.487 269.657 198.808 269.657 196.738C269.657 194.667 267.978 192.988 265.908 192.988C263.837 192.988 262.158 194.667 262.158 196.738C262.158 198.808 263.837 200.487 265.908 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 200.487C257.904 200.487 259.583 198.808 259.583 196.738C259.583 194.667 257.904 192.988 255.833 192.988C253.763 192.988 252.084 194.667 252.084 196.738C252.084 198.808 253.763 200.487 255.833 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 200.487C247.826 200.487 249.505 198.808 249.505 196.738C249.505 194.667 247.826 192.988 245.755 192.988C243.684 192.988 242.006 194.667 242.006 196.738C242.006 198.808 243.684 200.487 245.755 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 200.487C237.752 200.487 239.43 198.808 239.43 196.738C239.43 194.667 237.752 192.988 235.681 192.988C233.61 192.988 231.932 194.667 231.932 196.738C231.932 198.808 233.61 200.487 235.681 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 200.487C207.52 200.487 209.199 198.808 209.199 196.738C209.199 194.667 207.52 192.988 205.45 192.988C203.379 192.988 201.7 194.667 201.7 196.738C201.7 198.808 203.379 200.487 205.45 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 200.487C197.446 200.487 199.125 198.808 199.125 196.738C199.125 194.667 197.446 192.988 195.375 192.988C193.305 192.988 191.626 194.667 191.626 196.738C191.626 198.808 193.305 200.487 195.375 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 200.487C187.368 200.487 189.047 198.808 189.047 196.738C189.047 194.667 187.368 192.988 185.297 192.988C183.226 192.988 181.548 194.667 181.548 196.738C181.548 198.808 183.226 200.487 185.297 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 200.487C177.294 200.487 178.972 198.808 178.972 196.738C178.972 194.667 177.294 192.988 175.223 192.988C173.152 192.988 171.474 194.667 171.474 196.738C171.474 198.808 173.152 200.487 175.223 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 200.487C167.215 200.487 168.893 198.808 168.893 196.738C168.893 194.667 167.215 192.988 165.144 192.988C163.073 192.988 161.395 194.667 161.395 196.738C161.395 198.808 163.073 200.487 165.144 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 200.487C157.14 200.487 158.819 198.808 158.819 196.738C158.819 194.667 157.14 192.988 155.07 192.988C152.999 192.988 151.32 194.667 151.32 196.738C151.32 198.808 152.999 200.487 155.07 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 200.487C147.067 200.487 148.746 198.808 148.746 196.738C148.746 194.667 147.067 192.988 144.996 192.988C142.926 192.988 141.247 194.667 141.247 196.738C141.247 198.808 142.926 200.487 144.996 200.487Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 190.368C288.131 190.368 289.809 188.689 289.809 186.618C289.809 184.548 288.131 182.869 286.06 182.869C283.989 182.869 282.311 184.548 282.311 186.618C282.311 188.689 283.989 190.368 286.06 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 190.368C278.056 190.368 279.735 188.689 279.735 186.618C279.735 184.548 278.056 182.869 275.986 182.869C273.915 182.869 272.236 184.548 272.236 186.618C272.236 188.689 273.915 190.368 275.986 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 190.368C267.978 190.368 269.657 188.689 269.657 186.618C269.657 184.548 267.978 182.869 265.908 182.869C263.837 182.869 262.158 184.548 262.158 186.618C262.158 188.689 263.837 190.368 265.908 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 190.368C257.904 190.368 259.583 188.689 259.583 186.618C259.583 184.548 257.904 182.869 255.833 182.869C253.763 182.869 252.084 184.548 252.084 186.618C252.084 188.689 253.763 190.368 255.833 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 190.368C247.826 190.368 249.505 188.689 249.505 186.618C249.505 184.548 247.826 182.869 245.755 182.869C243.684 182.869 242.006 184.548 242.006 186.618C242.006 188.689 243.684 190.368 245.755 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 190.368C237.752 190.368 239.43 188.689 239.43 186.618C239.43 184.548 237.752 182.869 235.681 182.869C233.61 182.869 231.932 184.548 231.932 186.618C231.932 188.689 233.61 190.368 235.681 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 190.368C217.598 190.368 219.277 188.689 219.277 186.618C219.277 184.548 217.598 182.869 215.528 182.869C213.457 182.869 211.778 184.548 211.778 186.618C211.778 188.689 213.457 190.368 215.528 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 190.368C207.52 190.368 209.199 188.689 209.199 186.618C209.199 184.548 207.52 182.869 205.45 182.869C203.379 182.869 201.7 184.548 201.7 186.618C201.7 188.689 203.379 190.368 205.45 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 190.368C197.446 190.368 199.125 188.689 199.125 186.618C199.125 184.548 197.446 182.869 195.375 182.869C193.305 182.869 191.626 184.548 191.626 186.618C191.626 188.689 193.305 190.368 195.375 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 190.368C187.368 190.368 189.047 188.689 189.047 186.618C189.047 184.548 187.368 182.869 185.297 182.869C183.226 182.869 181.548 184.548 181.548 186.618C181.548 188.689 183.226 190.368 185.297 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 190.368C177.294 190.368 178.972 188.689 178.972 186.618C178.972 184.548 177.294 182.869 175.223 182.869C173.152 182.869 171.474 184.548 171.474 186.618C171.474 188.689 173.152 190.368 175.223 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 190.368C167.215 190.368 168.893 188.689 168.893 186.618C168.893 184.548 167.215 182.869 165.144 182.869C163.073 182.869 161.395 184.548 161.395 186.618C161.395 188.689 163.073 190.368 165.144 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 190.368C157.14 190.368 158.819 188.689 158.819 186.618C158.819 184.548 157.14 182.869 155.07 182.869C152.999 182.869 151.32 184.548 151.32 186.618C151.32 188.689 152.999 190.368 155.07 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 190.368C147.067 190.368 148.746 188.689 148.746 186.618C148.746 184.548 147.067 182.869 144.996 182.869C142.926 182.869 141.247 184.548 141.247 186.618C141.247 188.689 142.926 190.368 144.996 190.368Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 180.251C288.131 180.251 289.809 178.572 289.809 176.501C289.809 174.431 288.131 172.752 286.06 172.752C283.989 172.752 282.311 174.431 282.311 176.501C282.311 178.572 283.989 180.251 286.06 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 180.251C278.056 180.251 279.735 178.572 279.735 176.501C279.735 174.431 278.056 172.752 275.986 172.752C273.915 172.752 272.236 174.431 272.236 176.501C272.236 178.572 273.915 180.251 275.986 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 180.251C267.978 180.251 269.657 178.572 269.657 176.501C269.657 174.431 267.978 172.752 265.908 172.752C263.837 172.752 262.158 174.431 262.158 176.501C262.158 178.572 263.837 180.251 265.908 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 180.251C257.904 180.251 259.583 178.572 259.583 176.501C259.583 174.431 257.904 172.752 255.833 172.752C253.763 172.752 252.084 174.431 252.084 176.501C252.084 178.572 253.763 180.251 255.833 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 180.251C247.826 180.251 249.505 178.572 249.505 176.501C249.505 174.431 247.826 172.752 245.755 172.752C243.684 172.752 242.006 174.431 242.006 176.501C242.006 178.572 243.684 180.251 245.755 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 180.251C237.752 180.251 239.43 178.572 239.43 176.501C239.43 174.431 237.752 172.752 235.681 172.752C233.61 172.752 231.932 174.431 231.932 176.501C231.932 178.572 233.61 180.251 235.681 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 180.251C217.598 180.251 219.277 178.572 219.277 176.501C219.277 174.431 217.598 172.752 215.528 172.752C213.457 172.752 211.778 174.431 211.778 176.501C211.778 178.572 213.457 180.251 215.528 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 180.251C207.52 180.251 209.199 178.572 209.199 176.501C209.199 174.431 207.52 172.752 205.45 172.752C203.379 172.752 201.7 174.431 201.7 176.501C201.7 178.572 203.379 180.251 205.45 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 180.251C197.446 180.251 199.125 178.572 199.125 176.501C199.125 174.431 197.446 172.752 195.375 172.752C193.305 172.752 191.626 174.431 191.626 176.501C191.626 178.572 193.305 180.251 195.375 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 180.251C187.368 180.251 189.047 178.572 189.047 176.501C189.047 174.431 187.368 172.752 185.297 172.752C183.226 172.752 181.548 174.431 181.548 176.501C181.548 178.572 183.226 180.251 185.297 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 180.251C177.294 180.251 178.972 178.572 178.972 176.501C178.972 174.431 177.294 172.752 175.223 172.752C173.152 172.752 171.474 174.431 171.474 176.501C171.474 178.572 173.152 180.251 175.223 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 180.251C167.215 180.251 168.893 178.572 168.893 176.501C168.893 174.431 167.215 172.752 165.144 172.752C163.073 172.752 161.395 174.431 161.395 176.501C161.395 178.572 163.073 180.251 165.144 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 180.251C157.14 180.251 158.819 178.572 158.819 176.501C158.819 174.431 157.14 172.752 155.07 172.752C152.999 172.752 151.32 174.431 151.32 176.501C151.32 178.572 152.999 180.251 155.07 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 180.251C147.067 180.251 148.746 178.572 148.746 176.501C148.746 174.431 147.067 172.752 144.996 172.752C142.926 172.752 141.247 174.431 141.247 176.501C141.247 178.572 142.926 180.251 144.996 180.251Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 170.133C288.131 170.133 289.809 168.455 289.809 166.384C289.809 164.313 288.131 162.635 286.06 162.635C283.989 162.635 282.311 164.313 282.311 166.384C282.311 168.455 283.989 170.133 286.06 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 170.133C278.056 170.133 279.735 168.455 279.735 166.384C279.735 164.313 278.056 162.635 275.986 162.635C273.915 162.635 272.236 164.313 272.236 166.384C272.236 168.455 273.915 170.133 275.986 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 170.133C267.978 170.133 269.657 168.455 269.657 166.384C269.657 164.313 267.978 162.635 265.908 162.635C263.837 162.635 262.158 164.313 262.158 166.384C262.158 168.455 263.837 170.133 265.908 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 170.133C257.904 170.133 259.583 168.455 259.583 166.384C259.583 164.313 257.904 162.635 255.833 162.635C253.763 162.635 252.084 164.313 252.084 166.384C252.084 168.455 253.763 170.133 255.833 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M245.755 170.133C247.826 170.133 249.505 168.455 249.505 166.384C249.505 164.313 247.826 162.635 245.755 162.635C243.684 162.635 242.006 164.313 242.006 166.384C242.006 168.455 243.684 170.133 245.755 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M235.681 170.133C237.752 170.133 239.43 168.455 239.43 166.384C239.43 164.313 237.752 162.635 235.681 162.635C233.61 162.635 231.932 164.313 231.932 166.384C231.932 168.455 233.61 170.133 235.681 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M215.528 170.133C217.598 170.133 219.277 168.455 219.277 166.384C219.277 164.313 217.598 162.635 215.528 162.635C213.457 162.635 211.778 164.313 211.778 166.384C211.778 168.455 213.457 170.133 215.528 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 170.133C207.52 170.133 209.199 168.455 209.199 166.384C209.199 164.313 207.52 162.635 205.45 162.635C203.379 162.635 201.7 164.313 201.7 166.384C201.7 168.455 203.379 170.133 205.45 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 170.133C197.446 170.133 199.125 168.455 199.125 166.384C199.125 164.313 197.446 162.635 195.375 162.635C193.305 162.635 191.626 164.313 191.626 166.384C191.626 168.455 193.305 170.133 195.375 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 170.133C187.368 170.133 189.047 168.455 189.047 166.384C189.047 164.313 187.368 162.635 185.297 162.635C183.226 162.635 181.548 164.313 181.548 166.384C181.548 168.455 183.226 170.133 185.297 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 170.133C177.294 170.133 178.972 168.455 178.972 166.384C178.972 164.313 177.294 162.635 175.223 162.635C173.152 162.635 171.474 164.313 171.474 166.384C171.474 168.455 173.152 170.133 175.223 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 170.133C167.215 170.133 168.893 168.455 168.893 166.384C168.893 164.313 167.215 162.635 165.144 162.635C163.073 162.635 161.395 164.313 161.395 166.384C161.395 168.455 163.073 170.133 165.144 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 170.133C157.14 170.133 158.819 168.455 158.819 166.384C158.819 164.313 157.14 162.635 155.07 162.635C152.999 162.635 151.32 164.313 151.32 166.384C151.32 168.455 152.999 170.133 155.07 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 170.133C147.067 170.133 148.746 168.455 148.746 166.384C148.746 164.313 147.067 162.635 144.996 162.635C142.926 162.635 141.247 164.313 141.247 166.384C141.247 168.455 142.926 170.133 144.996 170.133Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 160.018C288.131 160.018 289.809 158.34 289.809 156.269C289.809 154.198 288.131 152.52 286.06 152.52C283.989 152.52 282.311 154.198 282.311 156.269C282.311 158.34 283.989 160.018 286.06 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 160.018C278.056 160.018 279.735 158.34 279.735 156.269C279.735 154.198 278.056 152.52 275.986 152.52C273.915 152.52 272.236 154.198 272.236 156.269C272.236 158.34 273.915 160.018 275.986 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 160.018C267.978 160.018 269.657 158.34 269.657 156.269C269.657 154.198 267.978 152.52 265.908 152.52C263.837 152.52 262.158 154.198 262.158 156.269C262.158 158.34 263.837 160.018 265.908 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 160.018C257.904 160.018 259.583 158.34 259.583 156.269C259.583 154.198 257.904 152.52 255.833 152.52C253.763 152.52 252.084 154.198 252.084 156.269C252.084 158.34 253.763 160.018 255.833 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 160.018C197.446 160.018 199.125 158.34 199.125 156.269C199.125 154.198 197.446 152.52 195.375 152.52C193.305 152.52 191.626 154.198 191.626 156.269C191.626 158.34 193.305 160.018 195.375 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 160.018C187.368 160.018 189.047 158.34 189.047 156.269C189.047 154.198 187.368 152.52 185.297 152.52C183.226 152.52 181.548 154.198 181.548 156.269C181.548 158.34 183.226 160.018 185.297 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 160.018C177.294 160.018 178.972 158.34 178.972 156.269C178.972 154.198 177.294 152.52 175.223 152.52C173.152 152.52 171.474 154.198 171.474 156.269C171.474 158.34 173.152 160.018 175.223 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 160.018C167.215 160.018 168.893 158.34 168.893 156.269C168.893 154.198 167.215 152.52 165.144 152.52C163.073 152.52 161.395 154.198 161.395 156.269C161.395 158.34 163.073 160.018 165.144 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 160.018C157.14 160.018 158.819 158.34 158.819 156.269C158.819 154.198 157.14 152.52 155.07 152.52C152.999 152.52 151.32 154.198 151.32 156.269C151.32 158.34 152.999 160.018 155.07 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 160.018C147.067 160.018 148.746 158.34 148.746 156.269C148.746 154.198 147.067 152.52 144.996 152.52C142.926 152.52 141.247 154.198 141.247 156.269C141.247 158.34 142.926 160.018 144.996 160.018Z"
                fill="#DAFDA3"
            />
            <path
                d="M286.06 149.897C288.131 149.897 289.809 148.218 289.809 146.148C289.809 144.077 288.131 142.398 286.06 142.398C283.989 142.398 282.311 144.077 282.311 146.148C282.311 148.218 283.989 149.897 286.06 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M275.986 149.897C278.056 149.897 279.735 148.218 279.735 146.148C279.735 144.077 278.056 142.398 275.986 142.398C273.915 142.398 272.236 144.077 272.236 146.148C272.236 148.218 273.915 149.897 275.986 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M265.908 149.897C267.978 149.897 269.657 148.218 269.657 146.148C269.657 144.077 267.978 142.398 265.908 142.398C263.837 142.398 262.158 144.077 262.158 146.148C262.158 148.218 263.837 149.897 265.908 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M255.833 149.897C257.904 149.897 259.583 148.218 259.583 146.148C259.583 144.077 257.904 142.398 255.833 142.398C253.763 142.398 252.084 144.077 252.084 146.148C252.084 148.218 253.763 149.897 255.833 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M205.45 149.897C207.52 149.897 209.199 148.218 209.199 146.148C209.199 144.077 207.52 142.398 205.45 142.398C203.379 142.398 201.7 144.077 201.7 146.148C201.7 148.218 203.379 149.897 205.45 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M195.375 149.897C197.446 149.897 199.125 148.218 199.125 146.148C199.125 144.077 197.446 142.398 195.375 142.398C193.305 142.398 191.626 144.077 191.626 146.148C191.626 148.218 193.305 149.897 195.375 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M185.297 149.897C187.368 149.897 189.047 148.218 189.047 146.148C189.047 144.077 187.368 142.398 185.297 142.398C183.226 142.398 181.548 144.077 181.548 146.148C181.548 148.218 183.226 149.897 185.297 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M175.223 149.897C177.294 149.897 178.972 148.218 178.972 146.148C178.972 144.077 177.294 142.398 175.223 142.398C173.152 142.398 171.474 144.077 171.474 146.148C171.474 148.218 173.152 149.897 175.223 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M165.144 149.897C167.215 149.897 168.893 148.218 168.893 146.148C168.893 144.077 167.215 142.398 165.144 142.398C163.073 142.398 161.395 144.077 161.395 146.148C161.395 148.218 163.073 149.897 165.144 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M155.07 149.897C157.14 149.897 158.819 148.218 158.819 146.148C158.819 144.077 157.14 142.398 155.07 142.398C152.999 142.398 151.32 144.077 151.32 146.148C151.32 148.218 152.999 149.897 155.07 149.897Z"
                fill="#DAFDA3"
            />
            <path
                d="M144.996 149.897C147.067 149.897 148.746 148.218 148.746 146.148C148.746 144.077 147.067 142.398 144.996 142.398C142.926 142.398 141.247 144.077 141.247 146.148C141.247 148.218 142.926 149.897 144.996 149.897Z"
                fill="#DAFDA3"
            />
        </g>
        <defs>
            <clipPath id="clip0_3759_1600">
                <rect width="289.632" height="290.789" fill="white" transform="translate(0.177734 0.755859)" />
            </clipPath>
        </defs>
    </svg>
);

export default SmileyIcon;
