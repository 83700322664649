import { IconSVGProps } from "./types";

const DownloadIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.94062 11.5594C4.35515 10.9734 4.35515 10.0242 4.94062 9.43828C5.52656 8.85234 6.47625 8.85234 7.06172 9.43828L10.5 12.8812V1.5C10.5 0.671719 11.1717 0 12 0C12.8283 0 13.5 0.671719 13.5 1.5V12.8812L16.9397 9.44156C17.5252 8.85563 18.4748 8.85563 19.0608 9.44156C19.6462 10.0275 19.6462 10.9767 19.0608 11.5627L13.0608 17.5627C12.7687 17.8547 12.3844 18 12 18C11.6156 18 11.2322 17.8535 10.9397 17.5605L4.94062 11.5594Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M22.5 16.5H16.2422L14.1211 18.6211C13.5562 19.1859 12.8016 19.5 12 19.5C11.1984 19.5 10.4456 19.188 9.87891 18.6211L7.75781 16.5H1.5C0.671719 16.5 0 17.1717 0 18V22.5C0 23.3283 0.671719 24 1.5 24H22.5C23.3283 24 24 23.3283 24 22.5V18C24 17.1703 23.3297 16.5 22.5 16.5ZM20.25 21.375C19.6313 21.375 19.125 20.8688 19.125 20.25C19.125 19.6312 19.6313 19.125 20.25 19.125C20.8687 19.125 21.375 19.6312 21.375 20.25C21.375 20.8688 20.8687 21.375 20.25 21.375Z"
            fill="#002649"
        />
    </svg>
);

export default DownloadIcon;
