import { useState, useRef, useEffect, useCallback, ReactNode } from "react";
import classNames from "classnames";

import "./baseInputWrapper.scss";

type InputELement = Omit<React.InputHTMLAttributes<HTMLInputElement>, "size">;
type BaseInputWrapperProps = InputELement & {
    label?: ReactNode;
    invalid?: string | undefined | boolean;
    children: ReactNode;
    autoFilled?: boolean;
    info?: string | undefined | boolean;
};

const BaseInputWrapper: React.FC<BaseInputWrapperProps> = ({
    label,
    value,
    invalid,
    info,
    children,
    autoFilled = false,
}) => {
    const [labelWidth, setLabelWidth] = useState(0);
    const [active, setActive] = useState(false);
    const labelReference = useRef<HTMLLabelElement>(null);

    const inputWrapperClasses = classNames("base-input-wrapper", active && "active");

    /** 
    This useEffect controls active state which is responsible for label position (active ? in top border : inside input field)
    */
    useEffect(() => {
        if (value === undefined) {
            setActive(false);
            return;
        }
        value.toString().length > 0 ? setActive(true) : setActive(false);
    }, [value]);

    /**
    This useeffect controls the active state and depends on auto-populated values
     */
    useEffect(() => {
        if (autoFilled) {
            setActive(true);
        }
    }, [autoFilled]);

    /** 
     Function calculates how big gap in top border of the input must be so label can fit in
     */
    const setWidth = useCallback(() => {
        if (labelReference.current?.clientWidth) {
            setLabelWidth(labelReference.current.clientWidth * 0.8 + 8);
        }
    }, [labelReference]);

    useEffect(() => {
        setWidth();
    }, [labelReference.current?.clientWidth, setWidth]);

    /**
     This handle blur controls active state which is responsible for label position (active ? in top border : inside input field)
     */
    const handleBlur = useCallback(() => {
        if (value !== undefined && value.toString().length > 0) {
            setActive(true);
        } else {
            setActive(false);
        }
    }, [value]);

    return (
        <>
            <div className={inputWrapperClasses} onBlur={handleBlur} onFocus={setWidth}>
                {children}
                {label && (
                    <label className="form-label" ref={labelReference}>
                        {label}
                    </label>
                )}
                <div className="form-notch">
                    <div className="form-notch-leading"></div>
                    <div className="form-notch-middle" style={{ width: labelWidth }}></div>
                    <div className="form-notch-trailing"></div>
                </div>
            </div>
            {invalid && <p className="form-control-error">{invalid}</p>}
            {info && <p className="form-control-info">{info}</p>}
        </>
    );
};

export default BaseInputWrapper;
