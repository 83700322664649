// We can add any other this that we want to format and just return string (without JSX component). JSX should implement this approach.
export function formatter(
    value: string | number | null | undefined,
    format: Datetime | Currency,
    options?: DatetimeOptions | CurrencyOptions
): string {
    if (value === null || value === undefined) {
        return defaultValue("-");
    }

    if (isDatetimeFormat(format)) {
        if (typeof value !== "string") {
            return defaultValue(value);
        }
        // assuming all dates that comes from backend are UTC (Z at the end of date string)
        return formatDatetime(new Date(value), format as Datetime, options as CurrencyOptions);
    }

    if (isCurrencyFormat(format)) {
        if (typeof value !== "number") {
            return defaultValue(value);
        }
        return formatCurrency(value, format as Currency, options as CurrencyOptions);
    }

    return defaultValue(value);
}

function defaultValue(value: string | number): string {
    return value.toString();
}

//we are formating only in following format: dd/MM/yyyy hh:mm
function formatDatetime(value: Date, format: Datetime, options?: DatetimeOptions): string {
    let year = value.getFullYear();
    let month = (value.getMonth() + 1).toString().padStart(2, "0"); // month is zero-based. if it's March, padStart will add zero at the beginning so it's 03
    let date = value.getDate().toString().padStart(2, "0");
    let hour = value.getHours().toString().padStart(2, "0");
    let minute = value.getMinutes().toString().padStart(2, "0");

    switch (format) {
        case "long":
            return `${date}.${month}.${year} ${hour}:${minute}`;
        case "time":
            return `${hour}:${minute}`;
        case "date":
            return `${date}.${month}.${year}`;
    }
}

export function isDatetimeFormat(format: unknown): boolean {
    return typeof format === "string" && DatetimeFormats.includes(format as Datetime);
}

export const DatetimeFormats = ["long", "time", "date"] as const;
export type Datetime = typeof DatetimeFormats[number];
export interface DatetimeOptions {}

function formatCurrency(
    value: number,
    format: Currency,
    options: CurrencyOptions = { formatOptions: "Symbol", specialRules: [] }
): string {
    if (options.specialRules && options.specialRules.includes("NoZeroValue") && value === 0) {
        return defaultValue("-");
    }

    const noRoundingRule = options.specialRules && options.specialRules.includes("NoRounding");
    let formattedValue = noRoundingRule ? value.toString() : value.toFixed(2).toString();

    // e.g. -12345.678 -> ["-12345", "678"]
    // e.g. 12345.678 -> ["12345", "678"]
    // e.g. 12345 -> ["12345"]
    // e.g. -123 -> ["-123"]
    // e.g. 123 -> ["123"]
    // e.g. 12 -> ["12"]
    const splittedValues = formattedValue.split(".");

    let sign = "";
    let integralPart = splittedValues[0];
    let fractionalPart = splittedValues.length === 2 ? splittedValues[1] : "";

    // e.g. -12345 -> 12345
    // e.g. -123 -> 123
    if (splittedValues[0].startsWith("-")) {
        sign = "-";
        integralPart = integralPart.replace("-", "");
    }

    const twoDigitRule = options.specialRules && options.specialRules.includes("TwoDigits");
    const maxSingleDigitRule = options.specialRules && options.specialRules.includes("MaxSingleDigits");

    // e.g. 12345 -> 12.345
    // e.g. 12345 -> 12.345
    // e.g. 123 -> 123
    // e.g. 12 -> 12
    integralPart = formatIntegralPart(integralPart);
    fractionalPart = formatFractionalPart(fractionalPart, format, noRoundingRule, twoDigitRule, maxSingleDigitRule);

    const formattedValueWithDotsAndCommas = `${sign}${integralPart}${fractionalPart}`;

    if (options.formatOptions === "None") {
        return formattedValueWithDotsAndCommas;
    }

    const currencyConfig = CurrenciesConfig.find(x => x.name === format)!;

    if (options.formatOptions === "Symbol") {
        return currencyConfig.formatWithSymbol(formattedValueWithDotsAndCommas);
    } else {
        return currencyConfig.formatWithCode(formattedValueWithDotsAndCommas);
    }
}

function formatIntegralPart(str: string): string {
    if (str.length <= 3) {
        return str;
    }

    let reverseStr = str.split("").reverse().join("");
    let strWithDots = addDotAsThousandSeparator(reverseStr);
    return strWithDots.split("").reverse().join("");
}

function formatFractionalPart(
    str: string,
    format: Currency,
    noRoundingRule: boolean,
    isTwoDigitRule?: boolean,
    maxSingleDigitRule?: boolean
): string {
    // In case of special rule, we return whatever we have in the string.
    if (noRoundingRule) {
        if (str.length === 0) {
            return "";
        }

        return `,${str}`;
    }

    if (maxSingleDigitRule) {
        if (str.length === 0 || str.split("").every(x => x === "0")) {
            return "";
        }

        const fractionalNumber = parseFloat(`0.${str}`);
        const roundedNumber = fractionalNumber.toFixed(1);

        return `,${roundedNumber.toString().split(".")[1]}`;
    }

    if (isTwoDigitRule) {
        if (str.length === 0 || str.split("").every(x => x === "0")) {
            return ",00";
        }

        const fractionalNumber = parseFloat(`0.${str}`);
        const roundedNumber = fractionalNumber.toFixed(2);

        return `,${roundedNumber.toString().split(".")[1]}`;
    }

    if (format === "ISK" || format === "DKK" || format === "NOK" || format === "SEK") {
        return "";
    }

    if (str.length === 0) {
        return ",00";
    }

    if (str.split("").every(x => x === "0")) {
        return ",00";
    }

    return `,${str}`;
}

function addDotAsThousandSeparator(str: string): string {
    let ret = [];
    const everyXcharacters = 3;

    for (let i = 0; i < str.length; i += everyXcharacters) {
        ret.push(str.substring(i, i + everyXcharacters));
    }

    return ret.join(".");
}

export function isCurrencyFormat(format: unknown): boolean {
    return typeof format === "string" && CurrencyFormats.includes(format as Currency);
}

export const CurrencyFormats = ["ISK", "USD", "GBP", "DKK", "CAD", "NOK", "SEK", "EUR"] as const;
export type Currency = typeof CurrencyFormats[number];
export type CurrencyFormatOptions = "Code" | "Symbol" | "None";
export type CurrencySpecialRules = "NoRounding" | "NoZeroValue" | "TwoDigits" | "MaxSingleDigits";
export interface CurrencyOptions {
    formatOptions: CurrencyFormatOptions;
    specialRules: Array<CurrencySpecialRules>;
}

export interface CurrencyConfig {
    name: Currency;
    symbol: string;
    code: string;
    decimalLimit: number;
    formatWithCode: (value: string) => string;
    formatWithSymbol: (value: string) => string;
}
export const CurrenciesConfig: Array<CurrencyConfig> = [
    {
        name: "ISK",
        symbol: "kr.",
        code: "ISK",
        decimalLimit: 0,
        formatWithCode: (value: string) => `ISK ${value}`,
        formatWithSymbol: (value: string) => `${value} kr.`,
    },
    {
        name: "USD",
        symbol: "$",
        code: "USD",
        decimalLimit: 2,
        formatWithCode: (value: string) => `USD ${value}`,
        formatWithSymbol: (value: string) => `$${value}`,
    },
    {
        name: "GBP",
        symbol: "£",
        code: "GBP",
        decimalLimit: 2,
        formatWithCode: (value: string) => `GBP ${value}`,
        formatWithSymbol: (value: string) => `£${value}`,
    },
    {
        name: "DKK",
        symbol: "kr.",
        code: "DKK",
        decimalLimit: 2,
        formatWithCode: (value: string) => `DKK ${value}`,
        formatWithSymbol: (value: string) => `${value} kr.`,
    },
    {
        name: "CAD",
        symbol: "$",
        code: "CAD",
        decimalLimit: 2,
        formatWithCode: (value: string) => `CAD ${value}`,
        formatWithSymbol: (value: string) => `$${value}`,
    },
    {
        name: "NOK",
        symbol: "kr",
        code: "NOK",
        decimalLimit: 2,
        formatWithCode: (value: string) => `NOK ${value}`,
        formatWithSymbol: (value: string) => `${value} kr`,
    },
    {
        name: "SEK",
        symbol: "kr",
        code: "SEK",
        decimalLimit: 2,
        formatWithCode: (value: string) => `SEK ${value}`,
        formatWithSymbol: (value: string) => `${value} kr`,
    },
    {
        name: "EUR",
        symbol: "€",
        code: "EUR",
        decimalLimit: 2,
        formatWithCode: (value: string) => `EUR ${value}`,
        formatWithSymbol: (value: string) => `${value}€`,
    },
];
