import "./createEmployeeModal.scss";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { StringResources } from "utils/language/languageResource";
import { ModalTypeEnum } from "utils/enums";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaseInput from "components/baseInput/baseInput";
import BaseMultiselect, { Option } from "components/baseMultiselect/baseMultiselect";
import { RootState, useAppDispatch } from "redux/store";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import { employeeCreateEmployee } from "redux/actions/employeeActions";
import { executeAxiosRequestWithRefresh } from "redux/services";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import { useSelector } from "react-redux";
import { ILookupResponse } from "utils/models";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface ICreateEmployeeForm {
    username: string;
    contracts: Array<number>;
    permissions: Array<number>;
}

interface MerchantDropdown {
    id: number;
    name: string;
    contracts: ILookupResponse<number>[];
}

const CreateEmployeeModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [errorMessage, setErrorMessage] = useState("");
    const [merchant, setMerchant] = useState<MerchantDropdown>();
    const permissionTypes = useSelector((state: RootState) => state.global.filterTypes.employeePermissionTypes);

    useEffect(() => {
        async function fetchMerchants() {
            const response = await executeAxiosRequestWithRefresh({
                url: "/api/merchants/active-merchant",
                method: "GET",
            });

            setMerchant(response.data as MerchantDropdown);
        }
        fetchMerchants();
    }, []);

    const errorMessageRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (errorMessage) {
            errorMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [errorMessage]);

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnMount: true,
        initialValues: {
            username: "",
            contracts: [],
            permissions: permissionTypes.filter(x => !x.name.includes("Write")).map(x => x.value),
        },
        validationSchema: Yup.object({
            username: Yup.string()
                .email(t(StringResources.pages.employees.create.formEmailValidationMsg))
                .test(
                    "checkUsername",
                    t(StringResources.pages.employees.create.formUsernameAlreadyHasAccessValidationMsg),
                    function (value) {
                        return new Promise(async (resolve, _) => {
                            if (value) {
                                const response = await executeAxiosRequestWithRefresh({
                                    url: "/api/employees/has-access-to-merchant",
                                    method: "GET",
                                    params: {
                                        username: value,
                                    },
                                });

                                resolve(!response.data.hasAccess);
                            }
                        });
                    }
                )
                .required(),
            contracts: Yup.array().min(1).required(),
            permissions: Yup.array().min(1).required(),
        }),
        onSubmit: async (value: ICreateEmployeeForm) => {
            setErrorMessage("");

            const model = {
                username: value.username,
                contracts: value.contracts,
                permissions: value.permissions,
            };

            try {
                await dispatch(employeeCreateEmployee(model)).unwrap();
                dispatch(confirmModal({ modalType: ModalTypeEnum.CreateEmployee }));
                toast.success(t(StringResources.pages.employees.create.successMsg).toString());
            } catch (error: any) {
                setErrorMessage(error?.message);
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.CreateEmployee }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.pages.employees.create.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="create-employee__modal-body">
                    <Form.Group className="create-employee__form-row">
                        <BaseInput
                            type={"email"}
                            name={"username"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.employees.create.formEmailInputLabel)}`}
                            value={formValidation.values["username"]}
                            invalid={formValidation.touched["username"] && formValidation.errors["username"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="create-employee__form-row">
                        <BaseMultiselect
                            options={
                                merchant?.contracts.map(x => {
                                    return {
                                        value: x.value,
                                        label: x.name,
                                        selected: formValidation.values["contracts"]?.some(y => y === x.value) ?? false,
                                    };
                                }) ?? []
                            }
                            label={`${t(StringResources.pages.employees.create.formContractInputLabel)}`}
                            onChange={(selectedItems: Option[]) => {
                                formValidation.setFieldValue(
                                    "contracts",
                                    selectedItems.map(x => x.value),
                                    true
                                );
                            }}
                            showClearAll={true}
                            showSelectAll={true}
                        ></BaseMultiselect>
                    </Form.Group>

                    <div className="create-employee__permission-title">
                        {`${t(StringResources.pages.employees.create.permissions)}`}
                    </div>

                    <div className="create-employee__permission-table">
                        <span>{`${t(StringResources.pages.employees.create.permissionsPage)}`}</span>
                        <span>
                            <BaseCheckbox
                                id="all"
                                checked={formValidation.values["permissions"].length === permissionTypes.length}
                                intermediate={
                                    formValidation.values["permissions"].length !== 0 &&
                                    formValidation.values["permissions"].length !== permissionTypes.length
                                }
                                onChange={(id, checked) => {
                                    if (checked) {
                                        formValidation.setFieldValue(
                                            "permissions",
                                            permissionTypes.map(x => x.value),
                                            true
                                        );
                                    } else {
                                        formValidation.setFieldValue("permissions", [], true);
                                    }
                                }}
                                label={"Active"}
                                labelBefore={true}
                            />
                        </span>
                    </div>

                    {permissionTypes.map(x => {
                        return (
                            <PermissionRow
                                id={x.value}
                                key={`${x.value}_${x.name}`}
                                name={x.name}
                                values={formValidation.values["permissions"]}
                                onChange={(newArray: Array<number>) =>
                                    formValidation.setFieldValue("permissions", newArray, true)
                                }
                            />
                        );
                    })}

                    {errorMessage && (
                        <p ref={errorMessageRef} className="create-employee__form-error">
                            {errorMessage}
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={`${t(StringResources.pages.employees.create.confirm)}`}
                        styleType="solid"
                        disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

interface PermissionRowProps {
    id: number;
    name: string;
    values: Array<number>;
    onChange: (newArray: Array<number>) => {};
}

const PermissionRow = ({ id, name, values, onChange }: PermissionRowProps) => {
    const handleChange = (id: string, checked: boolean) => {
        if (checked) {
            if (!values.some(x => x === Number(id))) {
                onChange([...values, Number(id)]);
            }
        } else {
            if (values.some(x => x === Number(id))) {
                onChange(values.filter(x => x !== Number(id)));
            }
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{name}</span>
            <span>
                <BaseCheckbox
                    id={id.toString()}
                    checked={values.some(x => x == id)}
                    onChange={handleChange}
                    label={""}
                />
            </span>
        </div>
    );
};

export default CreateEmployeeModal;
