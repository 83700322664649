import { DownloadIcon } from "components/icons";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RootState } from "redux/store";
import { saveFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import variables from "../../../style/colors.module.scss";
import { getElectronicDocumentsDetailsReport } from "../api/electronicDocumentsApi";
import { IGetElectronicDocumentsResponseListData } from "../api/electronicDocumentsModels";
import "./electronicDocumentsHeader.scss";
import { PageTypeEnum } from "utils/reportDefinitions";

const ElectronicDocumentsHeader = () => {
    const activeRowData: IGetElectronicDocumentsResponseListData = useSelector(
        (state: RootState) => state.report.activeRow
    );

    if (!activeRowData) {
        return <></>;
    }

    const handleClick = async () => {
        try {
            const response = await getElectronicDocumentsDetailsReport(activeRowData.documentId);
            saveFile(response.data, response.filename, response.contentType);
        } catch (error) {
            toast.error(
                t(StringResources.pages[PageTypeEnum.ElectronicDocuments].details.fileDownloadError).toString()
            );
        }
    };

    return (
        <div className="report-details__title">
            <div className="report-details__title--label">
                {`${t(StringResources.pages[PageTypeEnum.ElectronicDocuments].title)}`}
            </div>
            <div className="report-details__title--action" role="button" onClick={handleClick}>
                <DownloadIcon color={variables.colorPrimaryAlpha} />
            </div>
        </div>
    );
};

export default ElectronicDocumentsHeader;
