import { FC } from "react";
import { IconSVGProps } from "./types";
import AngleIcon from "./angleIcon";
import DashboardIcon from "./dashboardIcon";
import PaymentsIcon from "./paymentsIcon";
import TerminalsIcon from "./terminalsIcon";
import AgreementsIcon from "./agreementsIcon";
import SettlementsIcon from "./settlementsIcon";
import BatchesIcon from "./batchesIcon";
import TransactionsIcon from "./transactionsIcon";
import AuthorizationsIcon from "./authorizationsIcon";
import DocumentsIcon from "./documentsIcon";
import UsersIcon from "./usersIcon";
import DailyBalancesIcon from "./dailyBalancesIcon";
import NotificationDotIcon from "./notificationDotIcon";
import SettingsIcon from "./settingsIcon";
import DisputesIcon from "./disputesIcon";
import LogoutIcon from "./logoutIcon";
import HamburgerMenuIcon from "./hamburgerMenuIcon";
import CancelIcon from "./cancelIcon";
import CancelSmallIcon from "./cancelSmallIcon";
import FilterSmallIcon from "./filterSmallIcon";
import ColumnsSmallIcon from "./columnsSmallIcon";
import GripIcon from "./gripIcon";
import ErrorWarningIcon from "./errorWarningIcon";
import CheckIcon from "./checkIcon";
import FilterClearIcon from "./filterClearIcon";
import DownloadIcon from "components/icons/downloadIcon";
import SortIcon from "components/icons/sortIcon";
import LockIcon from "components/icons/lockIcon";
import TrashIcon from "components/icons/trashIcon";
import InfoIcon from "components/icons/infoIcon";
import DotsIcon from "components/icons/dotsIcon";
import SearchIcon from "components/icons/searchIcon";
import ArrowIcon from "components/icons/arrowIcon";
import PlusIcon from "components/icons/plusIcon";
import ToolTipIcon from "components/icons/toolTipIcon";
import FeedbackIcon from "./feedbackIcon";
import SmileyIcon from "./smileyIcon";
import KnightIcon from "./knightIcon";
import CloseIcon from "./closeIcon";
import ExclamationIcon from "./exclamationIcon";
import WarningIcon from "./warningIcon";
import OverviewIcon from "./overviewIcon";
import PayLinkIcon from "./payLinkIcon";
import MoneyBillTransferIcon from "./moneyBillTransferIcon";
import EcomThemesIcon from "./ecomThemesIcon";
import NoImageIcon from "./noImageIcon";
import CopyToClipboardIcon from "./copyToClipboardIcon";
import ApiKeysIcon from "components/icons/apiKeysIcon";
import WebhooksIcon from "components/icons/webhooksIcon";

const Icons: Record<string, FC<IconSVGProps>> = {
    ArrowIcon: ArrowIcon,
    AngleIcon: AngleIcon,
    DashboardIcon: DashboardIcon,
    PaymentsIcon: PaymentsIcon,
    TerminalsIcon: TerminalsIcon,
    AgreementsIcon: AgreementsIcon,
    SettlementsIcon: SettlementsIcon,
    BatchesIcon: BatchesIcon,
    TransactionsIcon: TransactionsIcon,
    AuthorizationsIcon: AuthorizationsIcon,
    DocumentsIcon: DocumentsIcon,
    UsersIcon: UsersIcon,
    DailyBalancesIcon: DailyBalancesIcon,
    NotificationDotIcon: NotificationDotIcon,
    SettingsIcon: SettingsIcon,
    DisputesIcon: DisputesIcon,
    LogoutIcon: LogoutIcon,
    HamburgerMenuIcon: HamburgerMenuIcon,
    CancelIcon: CancelIcon,
    CancelSmallIcon: CancelSmallIcon,
    FilterSmallIcon: FilterSmallIcon,
    ColumnsSmallIcon: ColumnsSmallIcon,
    FilterClearIcon: FilterClearIcon,
    CheckIcon: CheckIcon,
    ErrorWarningIcon: ErrorWarningIcon,
    GripIcon: GripIcon,
    DownloadIcon: DownloadIcon,
    SortIcon: SortIcon,
    LockIcon: LockIcon,
    TrashIcon: TrashIcon,
    InfoIcon: InfoIcon,
    DotsIcon: DotsIcon,
    SearchIcon: SearchIcon,
    PlusIcon: PlusIcon,
    ToolTipIcon: ToolTipIcon,
    FeedbackIcon: FeedbackIcon,
    SmileyIcon: SmileyIcon,
    KnightIcon: KnightIcon,
    CloseIcon: CloseIcon,
    ExclamationIcon: ExclamationIcon,
    WarningIcon: WarningIcon,
    OverviewIcon: OverviewIcon,
    MoneyBillTransferIcon: MoneyBillTransferIcon,
    PayLinkIcon: PayLinkIcon,
    EcomThemesIcon: EcomThemesIcon,
    NoImageIcon: NoImageIcon,
    CopyToClipboardIcon: CopyToClipboardIcon,
};

export {
    ArrowIcon,
    AngleIcon,
    DashboardIcon,
    PaymentsIcon,
    TerminalsIcon,
    AgreementsIcon,
    SettlementsIcon,
    BatchesIcon,
    TransactionsIcon,
    AuthorizationsIcon,
    DocumentsIcon,
    UsersIcon,
    DailyBalancesIcon,
    NotificationDotIcon,
    SettingsIcon,
    OverviewIcon,
    DisputesIcon,
    LogoutIcon,
    HamburgerMenuIcon,
    CancelIcon,
    CancelSmallIcon,
    FilterSmallIcon,
    ColumnsSmallIcon,
    FilterClearIcon,
    CheckIcon,
    ErrorWarningIcon,
    GripIcon,
    DownloadIcon,
    SortIcon,
    LockIcon,
    TrashIcon,
    InfoIcon,
    DotsIcon,
    SearchIcon,
    PlusIcon,
    ToolTipIcon,
    FeedbackIcon,
    SmileyIcon,
    KnightIcon,
    CloseIcon,
    ExclamationIcon,
    WarningIcon,
    MoneyBillTransferIcon,
    PayLinkIcon,
    EcomThemesIcon,
    NoImageIcon,
    CopyToClipboardIcon,
    ApiKeysIcon,
    WebhooksIcon,
};

export default Icons;
