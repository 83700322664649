import { useEffect, useRef, useState } from "react";
import { NoImageIcon } from "components/icons";
import "./logoUploader.scss";
import BaseButton from "components/baseButton/baseButton";
import { t } from "i18next";
import { StringResources } from "utils/language/languageResource";

interface LogoUploaderProps {
    onLogoUpload: (file: File) => void;
    onLogoRemove: (file: File) => void;
    maxWidth: number;
    maxHeigth: number;
    maxFileSize: number;
    uploadedLogo?: File;
}

const LogoUploader: React.FC<LogoUploaderProps> = ({
    onLogoUpload,
    onLogoRemove,
    maxWidth,
    maxHeigth,
    maxFileSize,
    uploadedLogo,
}) => {
    const inputRefUpload = useRef<HTMLInputElement | null>(null);
    const inputRefReupload = useRef<HTMLInputElement | null>(null);
    const [imagePreview, setImagePreview] = useState<string | null>(null);
    const [image, setImage] = useState<File | null>(null);
    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        if (uploadedLogo) {
            const img = new Image();
            img.src = URL.createObjectURL(uploadedLogo);
            setImagePreview(img.src);

            const dataTransfer = new DataTransfer();
            dataTransfer.items.add(uploadedLogo);

            if (inputRefUpload.current) {
                inputRefUpload.current.files = dataTransfer.files;
            }

            if (inputRefReupload.current) {
                inputRefReupload.current.files = dataTransfer.files;
            }
        }
    }, [uploadedLogo]);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const logo = event.target.files?.[0];

        if (logo) {
            const validTypes = ["image/jpg", "image/jpeg", "image/png", "image/svg+xml"];
            if (!validTypes.includes(logo.type)) {
                setErrorMessage(`${t(StringResources.pages.ecomThemes.create.error.invalidType)}`);
                onRemoveLogoRestInput(false);

                return;
            }

            const maxSize = maxFileSize * 1024;
            if (logo.size > maxSize) {
                setErrorMessage(`${t(StringResources.pages.ecomThemes.create.error.wrongSize)}`);
                onRemoveLogoRestInput(false);

                return;
            }

            const img = new Image();
            img.src = URL.createObjectURL(logo);
            img.onload = () => {
                const { width, height } = img;
                if (width > maxWidth || height > maxHeigth) {
                    setErrorMessage(`${t(StringResources.pages.ecomThemes.create.error.wrongDimensions)}`);
                    onRemoveLogoRestInput(false);
                } else {
                    onLogoUpload(logo);
                    setImagePreview(img.src);
                    setImage(logo);
                    setErrorMessage("");
                }
            };
        }
    };

    const resetInputValue = (inputRef: React.RefObject<HTMLInputElement>) => {
        if (inputRef.current) {
            inputRef.current.value = "";
        }
    };

    const onRemoveLogoRestInput = (isRemove: boolean) => {
        if (isRemove || (inputRefUpload.current?.value === "" && inputRefReupload.current?.value === "")) {
            resetInputValue(inputRefUpload);
            resetInputValue(inputRefReupload);
        }
    };

    const onUploadFile = () => {
        inputRefUpload.current?.click();
    };

    const onReuploadFile = () => {
        inputRefReupload.current?.click();
    };

    const onRemove = () => {
        if (image) {
            onLogoRemove(image);
            setImagePreview(null);
            onRemoveLogoRestInput(true);
            setErrorMessage("");
        }
    };

    return (
        <div>
            <input
                type="file"
                accept=".jpeg,.jpg,.svg,.png"
                onChange={handleFileChange}
                ref={inputRefUpload}
                style={{ display: "none" }}
            />

            {!imagePreview && (
                <button className="logo-uploader" type="button" onClick={onUploadFile}>
                    <div className="logo-uploader__icon">
                        <NoImageIcon />
                    </div>
                    <div className="logo-uploader__restrictions">
                        <div>
                            {`${t(StringResources.pages.ecomThemes.create.maxWidth)}`} <strong>{maxWidth}px</strong>
                        </div>
                        <div>
                            {`${t(StringResources.pages.ecomThemes.create.maxHeight)}`} <strong>{maxHeigth}px</strong>
                        </div>
                        <div>
                            {`${t(StringResources.pages.ecomThemes.create.maxFileSize)}`}{" "}
                            <strong>{maxFileSize}KB</strong>
                        </div>
                        <div>
                            <strong>.jpg, .jpeg, .svg, .png</strong>
                        </div>
                    </div>
                    <div className="logo-uploader__upload">{`${t(
                        StringResources.pages.ecomThemes.create.upload
                    )}`}</div>
                </button>
            )}

            {imagePreview && (
                <div className="logo-uploader__image">
                    <div className="logo-uploader__logo">
                        <img
                            src={imagePreview}
                            alt="Selected"
                            style={{ maxWidth: maxWidth + "px", maxHeight: maxHeigth + "px" }}
                        />
                    </div>
                    <div className="logo-uploader__logo-name">{image?.name}</div>
                    <div className="logo-uploader__buttons">
                        <BaseButton
                            handleClick={onRemove}
                            text={`${t(StringResources.pages.ecomThemes.create.remove)}`}
                            styleType="line"
                        />

                        <div>
                            <input
                                type="file"
                                accept=".jpeg,.jpg,.svg,.png"
                                onChange={handleFileChange}
                                ref={inputRefReupload}
                                style={{ display: "none" }}
                            />

                            <button type="button" className="logo-uploader__reupload" onClick={onReuploadFile}>
                                <div className="logo-uploader__reupload-button">{`${t(
                                    StringResources.pages.ecomThemes.create.upload
                                )}`}</div>
                            </button>
                        </div>
                    </div>
                </div>
            )}
            {errorMessage && <p className="logo-uploader__error">{errorMessage}</p>}
        </div>
    );
};

export default LogoUploader;
