import { IconSVGProps } from "./types";

const PayLinkIcon = ({ height = 20, width = 20, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${20} ${20}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M4.51875 4.16699C2.02187 4.16699 0 6.18887 0 8.68574C0 10.8951 1.59688 12.7795 3.775 13.142L3.83437 13.1514C4.37812 13.242 4.89375 12.8732 4.98437 12.3295C5.075 11.7857 4.70625 11.2701 4.1625 11.1795L4.10312 11.1701C2.89062 10.967 2 9.91699 2 8.68574C2 7.29512 3.12812 6.16699 4.51875 6.16699H9.48125C10.8719 6.16699 12 7.29512 12 8.68574C12 9.91699 11.1094 10.967 9.89375 11.1701L9.83437 11.1795C9.29062 11.2701 8.92187 11.7857 9.0125 12.3295C9.10312 12.8732 9.61875 13.242 10.1625 13.1514L10.2219 13.142C12.4 12.7795 13.9969 10.892 13.9969 8.68574C14 6.18887 11.9781 4.16699 9.48125 4.16699H4.51875Z"
            fill="#002649"
        />
        <path
            d="M15.4812 16.1651C17.9781 16.1651 20 14.1432 20 11.6463C20 9.43694 18.4031 7.55256 16.225 7.19006L16.1656 7.18069C15.6219 7.09006 15.1062 7.45881 15.0156 8.00256C14.925 8.54631 15.2937 9.06194 15.8375 9.15256L15.8969 9.16194C17.1125 9.36506 18.0031 10.4151 18.0031 11.6463C18.0031 13.0369 16.875 14.1651 15.4844 14.1651H10.5187C9.12812 14.1651 8 13.0369 8 11.6463C8 10.4151 8.89062 9.36506 10.1062 9.16194L10.1656 9.15256C10.7094 9.06194 11.0781 8.54631 10.9875 8.00256C10.8969 7.45881 10.3812 7.09006 9.8375 7.18069L9.77812 7.19006C7.59687 7.55256 6 9.43694 6 11.6463C6 14.1432 8.02187 16.1651 10.5187 16.1651H15.4812Z"
            fill="#002649"
        />
    </svg>
);

export default PayLinkIcon;
