import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
    getBatchDetails,
    getTransactionBatchesInSettlement,
    getTransactionRefundBreakdown,
    paymentSettlementBreakdownGet,
} from "redux/actions/reportDetailSectionActions";
import {
    IGetBatchDetailsResponse,
    IGetBatchDetailsResponseMap,
    IPaymentGetSettlementBreakdownResponse,
    IReportDetailSectionsState,
    ISettlementTransactionBatchesResponse,
    ITransactionRefundBreakdownResponse,
} from "redux/models/reportDetailsSectionModels";
import { PayloadData } from "redux/models/userModels";

const initialState: IReportDetailSectionsState = {
    settlementIsLoading: false,
    batchesByDba: [],
    batchesMap: [],
    paymentIsLoading: false,
    settlementsBreakdown: [],
    settlementsBreakdownMap: [],
    transactionRefundIsLoading: false,
    transactionRefundBreakdown: [],
    transactionRefundBreakdownMap: [],
    batchesDetailsBreakdown: undefined,
    batchesDetailsBreakdownMap: [],
    batchDetailsIsLoading: false,
};

export const reportDetailSectionReducer = createSlice({
    name: "reportDetailSection",
    initialState,
    reducers: {
        setBatchesDataFromMap(
            state: IReportDetailSectionsState,
            action: PayloadAction<ISettlementTransactionBatchesResponse>
        ) {
            state.batchesByDba = action.payload.batchesByDba;
        },
        setBreakdownsDataFromMap(
            state: IReportDetailSectionsState,
            action: PayloadAction<IPaymentGetSettlementBreakdownResponse>
        ) {
            state.settlementsBreakdown = action.payload.settlementsBreakdown;
        },
        setTransactionBreakdownsDataFromMap(
            state: IReportDetailSectionsState,
            action: PayloadAction<ITransactionRefundBreakdownResponse>
        ) {
            state.transactionRefundBreakdown = action.payload.refundRequests;
        },
        setBatchDetailsDataFromMap(state: IReportDetailSectionsState, action: PayloadAction<IGetBatchDetailsResponse>) {
            state.batchesDetailsBreakdown = action.payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(getTransactionBatchesInSettlement.pending, state => {
            state.settlementIsLoading = true;
        });
        builder.addCase(
            getTransactionBatchesInSettlement.fulfilled,
            (state, action: PayloadAction<PayloadData<ISettlementTransactionBatchesResponse>>) => {
                state.batchesByDba = action.payload.data.batchesByDba;

                let data = {} as ISettlementTransactionBatchesResponse;
                data.settlementId = action.payload.data.settlementId;
                data.batchesByDba = action.payload.data.batchesByDba;

                state.settlementIsLoading = false;
                state.batchesMap.push(data);
                if (state.batchesMap.length > 25) {
                    state.batchesMap.shift();
                }
            }
        );
        builder.addCase(getTransactionBatchesInSettlement.rejected, state => {
            state.settlementIsLoading = false;
        });

        builder.addCase(paymentSettlementBreakdownGet.pending, state => {
            state.paymentIsLoading = true;
        });
        builder.addCase(
            paymentSettlementBreakdownGet.fulfilled,
            (state, action: PayloadAction<PayloadData<IPaymentGetSettlementBreakdownResponse>>) => {
                state.settlementsBreakdown = action.payload.data.settlementsBreakdown;

                let data = {} as IPaymentGetSettlementBreakdownResponse;
                data.paymentId = action.payload.data.paymentId;
                data.settlementsBreakdown = action.payload.data.settlementsBreakdown;

                state.paymentIsLoading = false;
                state.settlementsBreakdownMap.push(data);
                if (state.settlementsBreakdownMap.length > 25) {
                    state.settlementsBreakdownMap.shift();
                }
            }
        );
        builder.addCase(getTransactionRefundBreakdown.pending, state => {
            state.transactionRefundIsLoading = true;
        });
        builder.addCase(
            getTransactionRefundBreakdown.fulfilled,
            (state, action: PayloadAction<PayloadData<ITransactionRefundBreakdownResponse>>) => {
                state.transactionRefundIsLoading = false;
                state.transactionRefundBreakdown = action.payload.data.refundRequests;

                let data = {} as ITransactionRefundBreakdownResponse;
                data.transactionId = action.payload.data.transactionId;
                data.refundRequests = action.payload.data.refundRequests;

                state.transactionRefundBreakdownMap.push(data);
                if (state.transactionRefundBreakdownMap.length > 25) {
                    state.transactionRefundBreakdownMap.shift();
                }
            }
        );
        builder.addCase(paymentSettlementBreakdownGet.rejected, state => {
            state.paymentIsLoading = false;
        });

        builder.addCase(getBatchDetails.pending, state => {
            state.batchDetailsIsLoading = true;
        });
        builder.addCase(getBatchDetails.fulfilled, (state, action: PayloadAction<IGetBatchDetailsResponseMap>) => {
            state.batchDetailsIsLoading = false;
            state.batchesDetailsBreakdown = action.payload.data;

            state.batchesDetailsBreakdownMap.push(action.payload);
            if (state.batchesDetailsBreakdownMap.length > 25) {
                state.batchesDetailsBreakdownMap.shift();
            }
        });
        builder.addCase(getBatchDetails.rejected, state => {
            state.batchDetailsIsLoading = false;
        });
    },
});

export const {
    setBatchesDataFromMap,
    setBreakdownsDataFromMap,
    setTransactionBreakdownsDataFromMap,
    setBatchDetailsDataFromMap,
} = reportDetailSectionReducer.actions;

const { reducer } = reportDetailSectionReducer;
export default reducer;
