import "./editEmployeeModal.scss";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { StringResources } from "utils/language/languageResource";
import { ModalTypeEnum, RoleType } from "utils/enums";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import BaseMultiselect, { Option } from "components/baseMultiselect/baseMultiselect";
import { RootState, useAppDispatch } from "redux/store";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import { employeeDeactivateEmployee, employeeEditEmployee } from "redux/actions/employeeActions";
import { executeAxiosRequestWithRefresh } from "redux/services";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import { useSelector } from "react-redux";
import { TrashIcon } from "components/icons";
import variable from "../../../style/colors.module.scss";
import { useModalManagement } from "utils/customHooks";
import { ILookupResponse } from "utils/models";
import { IGetEmployeeResponseListData } from "pages/employees/api/employeeModels";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { EmployeeRole } from "utils/enums";
import BaseRadio from "components/baseRadio/baseRadio";
import { getUser } from "utils/tokenActions";
import { orderBy } from "lodash";


interface IEditEmployeeForm {
    employeeRole: EmployeeRole;
    contracts: Array<number>;
    permissions: Array<number>;
}

interface MerchantDropdown {
    id: number;
    name: string;
    contracts: Array<ILookupResponse<number>>;
}

const EditEmployeeModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const activeRow: IGetEmployeeResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const [errorMessage, setErrorMessage] = useState("");
    const [merchant, setMerchant] = useState<MerchantDropdown>();
    const permissionTypes = useSelector((state: RootState) => state.global.filterTypes.employeePermissionTypes);
    const modalManagement = useModalManagement();
    const currentUserRole = getUser().employeeRole;

    if (!activeRow) {
        dispatch(declineModal({ modalType: ModalTypeEnum.EditEmployee }));
    }

    useEffect(() => {
        async function fetchMerchants() {
            const response = await executeAxiosRequestWithRefresh({
                url: "/api/merchants/active-merchant",
                method: "GET",
            });

            setMerchant(response.data as MerchantDropdown);
        }
        fetchMerchants();
    }, []);

    const errorMessageRef = useRef<null | HTMLDivElement>(null);
    useEffect(() => {
        if (errorMessage) {
            errorMessageRef.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [errorMessage]);

    const formValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            employeeRole: activeRow.employeeRole.value,
            contracts: orderBy(activeRow.contracts?.map(x => x.value)) ?? [],
            permissions: orderBy(activeRow.permissions?.map(x => x.value)) ?? [],
        },
        validationSchema: Yup.object({
            contracts: Yup.array().of(Yup.number().integer()).min(1),
            permissions: Yup.array().of(Yup.number().integer()).min(1),
            employeeRole: Yup.number(),
        }),
        onSubmit: async (value: IEditEmployeeForm) => {
            setErrorMessage("");

            const model = {
                employeeRole: value.employeeRole,
                employeeId: activeRow.employeeId,
                contracts: value.contracts,
                permissions: value.permissions,
            };

            try {
                await dispatch(employeeEditEmployee(model)).unwrap();
                dispatch(confirmModal({ modalType: ModalTypeEnum.EditEmployee }));
                toast.success(t(StringResources.pages.employees.edit.successMsg).toString());
            } catch (error: any) {
                toast.error(t(StringResources.pages.employees.edit.errorMsg).toString());
                setErrorMessage(error?.message);
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const handleBaseRadioChange = async (role: EmployeeRole) => {
        await formValidation.setFieldValue("employeeRole", role, true);

        if (role == EmployeeRole.Admin) {
            await formValidation.setFieldValue("contracts", merchant?.contracts.map(x => x.value), true);
            await formValidation.setFieldValue("permissions", permissionTypes.map(x => x.value), true);

        } else if (role == EmployeeRole.User) {
            await formValidation.setFieldValue("permissions", activeRow.permissions.map(x => x.value) ?? [], true);
            await formValidation.setFieldValue("contracts", activeRow.contracts.map(x => x.value) ?? [], true);
        }
    };

    const onDeactiveClick = async () => {
        if (activeRow.employeeRole.value === RoleType.Admin) {
            return;
        }

        const modalData = await modalManagement.openModal({ modalType: ModalTypeEnum.DeleteModal });
        if (modalData !== null) {
            try {
                await dispatch(employeeDeactivateEmployee({ employeeId: activeRow.employeeId })).unwrap();
                dispatch(confirmModal({ modalType: ModalTypeEnum.EditEmployee }));
                toast.success(t(StringResources.pages.employees.edit.successDeactivateMsg).toString());
            } catch (error: any) {
                setErrorMessage(error?.message);
            }
        }
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.EditEmployee }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.pages.employees.edit.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="edit-employee__modal-body">
                    {currentUserRole === RoleType.Admin && (
                    <Form.Group className="edit-employee__form-row">
                        <div className="edit-employee__custom-title">
                            {`${t(StringResources.pages.employees.edit.formRoleLabel)}`}
                        </div>

                        <div className="d-flex">
                            <BaseRadio
                                label={t(StringResources.pages.employees.edit.formAdminLabel)}
                                name="radio_1"
                                value={EmployeeRole.Admin}
                                onChange={() => handleBaseRadioChange(EmployeeRole.Admin)}
                                checked={formValidation.values["employeeRole"] === EmployeeRole.Admin}
                                className="edit-employee__radio-group"
                            />

                            <BaseRadio
                                label={t(StringResources.pages.employees.edit.formUserLabel)}
                                name="radio_2"
                                value={EmployeeRole.User}
                                onChange={() => handleBaseRadioChange(EmployeeRole.User)}
                                checked={formValidation.values["employeeRole"] === EmployeeRole.User}
                                className="edit-employee__radio-group"
                            />
                        </div>
                    </Form.Group>
                    )}

                        <div className="edit-employee__contracts-title">
                            {`${t(StringResources.pages.employees.edit.contracts)}`}
                        </div>

                        {formValidation.values["employeeRole"] == EmployeeRole.Admin && (
                        <div>
                            {`${t(StringResources.pages.employees.edit.formAdminEmployeeHasAccessToAllContracts)}`}
                        </div>
                        )}

                        {formValidation.values["employeeRole"] == EmployeeRole.User && (
                            <Form.Group className="edit-employee__form-row">
                                <BaseMultiselect
                                    options={
                                        merchant?.contracts.map(x => {
                                            return {
                                                value: x.value,
                                                label: x.name,
                                                selected: formValidation.values["contracts"]?.some(y => y === x.value) ?? false,
                                            };
                                        }) ?? []
                                    }
                                    showClearAll={true}
                                    showSelectAll={true}
                                    label={`${t(StringResources.pages.employees.edit.formContractInputLabel)}`}
                                    onChange={(selectedItems: Option[]) => {
                                        formValidation.setFieldValue(
                                            "contracts",
                                            orderBy(selectedItems.map(x => x.value)),
                                            true
                                        );
                                    }}
                                ></BaseMultiselect>
                            </Form.Group>
                        )}

                    <div className="edit-employee__permission-title">
                        {`${t(StringResources.pages.employees.edit.permissions)}`}
                    </div>

                    {formValidation.values["employeeRole"] == EmployeeRole.Admin && (
                        <div>
                            {`${t(StringResources.pages.employees.edit.formAdminEmployeeHasAllPermissions)}`}
                        </div>
                    )}

                    {formValidation.values["employeeRole"] == EmployeeRole.User && (
                    <div className="edit-employee__permission-table">
                        <span>{`${t(StringResources.pages.employees.edit.permissionsPage)}`}</span>
                        <span>
                            <BaseCheckbox
                                id="all"
                                checked={formValidation.values["permissions"].length === permissionTypes.length}
                                intermediate={
                                    formValidation.values["permissions"].length !== 0 &&
                                    formValidation.values["permissions"].length !== permissionTypes.length
                                }
                                onChange={(_, checked) => {
                                    if (checked) {
                                        formValidation.setFieldValue(
                                            "permissions",
                                            orderBy(permissionTypes.map(x => x.value)),
                                            true
                                        );
                                    } else {
                                        formValidation.setFieldValue("permissions", [], true);
                                    }
                                }}
                                label={"Active"}
                                labelBefore={true}
                            />
                        </span>
                    </div>
                    )}

                    {formValidation.values["employeeRole"] == EmployeeRole.User &&
                        permissionTypes.map(x => {
                        return (
                            <PermissionRow
                                id={x.value}
                                key={`${x.value}_${x.name}`}
                                name={x.name}
                                values={formValidation.values["permissions"]}
                                onChange={(newArray: Array<number>) =>
                                    formValidation.setFieldValue("permissions", orderBy(newArray), true)
                                }
                            />
                        );
                    })}

                    {errorMessage && (
                        <p ref={errorMessageRef} className="edit-employee__form-error">
                            {errorMessage}
                        </p>
                    )}
                </Modal.Body>
                <Modal.Footer
                    className={classNames("edit-employee__footer", {
                        "modal-border-top": true,
                        "flex-end": activeRow.employeeRole.value === RoleType.Admin,
                    })}
                >
                    {activeRow.employeeRole.value !== RoleType.Admin && (
                        <BaseButton
                            handleClick={onDeactiveClick}
                            text={`${t(StringResources.pages.employees.edit.deactivate)}`}
                            styleType="text"
                            danger={true}
                            leftIcon={<TrashIcon color={variable.colorRedAlpha} />}
                        />
                    )}
                    <div className="edit-employee__footer-modal-actions">
                        <BaseButton
                            handleClick={onCancelClick}
                            text={`${t(StringResources.modal.close)}`}
                            styleType="line"
                            className="edit-employee__footer-modal-actions-first"
                        />

                        <BaseButton
                            handleClick={onOkClick}
                            text={`${t(StringResources.pages.employees.edit.confirm)}`}
                            styleType="solid"
                            disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                        />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

interface PermissionRowProps {
    id: number;
    name: string;
    values: Array<number>;
    onChange: (newArray: Array<number>) => {};
}

const PermissionRow = ({ id, name, values, onChange }: PermissionRowProps) => {
    const handleChange = (id: string, checked: boolean) => {
        if (checked) {
            if (!values.some(x => x === Number(id))) {
                onChange([...values, Number(id)]);
            }
        } else {
            if (values.some(x => x === Number(id))) {
                onChange(values.filter(x => x !== Number(id)));
            }
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
            <span>{name}</span>
            <span>
                <BaseCheckbox
                    id={id.toString()}
                    checked={values.some(x => x == id)}
                    onChange={handleChange}
                    label={""}
                />
            </span>
        </div>
    );
};

export default EditEmployeeModal;
