import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import {
    IGetSettlementsDetailsReportRequest,
    IGetSettlementsDetailsReportResponse,
    ISettlementsModels,
} from "pages/settlements/api/settlementsModels";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { getContentDispositionFilenameUtf8 } from "utils/helperFunctions";

const baseSettlementsRoute = "/api/settlements";
export const settlementsExportRoute = `${baseSettlementsRoute}/report`;

const settlementsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getSettlements: builder.query<ISettlementsModels, IQueryParams>({
            query: standardQueryTableRequest<ISettlementsModels>(baseSettlementsRoute),
        }),
    }),
    overrideExisting: false,
});

export const { useGetSettlementsQuery, endpoints } = settlementsApi;

export const getSettlementsDetailsReport = async (
    request: IGetSettlementsDetailsReportRequest
): Promise<IGetSettlementsDetailsReportResponse> => {
    const response = await executeAxiosRequestWithRefresh({
        url: `${baseSettlementsRoute}/details-report`,
        disableErrorToast: true,
        responseType: "blob",
        method: "GET",
        params: request,
    });

    var filename = getContentDispositionFilenameUtf8(response.headers);

    return {
        data: response.data,
        filename: filename,
        contentType: response.data.type,
    };
};
