import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import {
    IGetTransactionReceiptReportRequest,
    IGetTransactionReceiptReportResponse,
    IGetTransactionRefundReportRequest,
    IGetTransactionRefundReportResponse,
    ITransactionModel,
} from "./transactionModels";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { getContentDispositionFilenameUtf8 } from "utils/helperFunctions";

const baseTransactionsRoute = "/api/transactions";
export const transactionsExportRoute = `${baseTransactionsRoute}/report`;

const transactionsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getTransactions: builder.query<ITransactionModel, IQueryParams>({
            query: standardQueryTableRequest<ITransactionModel>(baseTransactionsRoute),
        }),
    }),
    overrideExisting: false,
});

export const getTransactionRefundReport = createAsyncThunk<
    IGetTransactionRefundReportResponse,
    IGetTransactionRefundReportRequest
>("transactions/refundReport", async request => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/transactions/refund-report",
        disableErrorToast: true,
        responseType: "blob",
        method: "GET",
        params: request,
    });

    return response;
});

export const getTransactionReceiptReport = async (
    request: IGetTransactionReceiptReportRequest
): Promise<IGetTransactionReceiptReportResponse> => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/transactions/transaction-receipt",
        disableErrorToast: true,
        responseType: "blob",
        method: "GET",
        params: request,
    });

    var filename = getContentDispositionFilenameUtf8(response.headers);

    return {
        data: response.data,
        filename: filename,
        contentType: response.data.type,
    };
};

export const { useGetTransactionsQuery, endpoints } = transactionsApi;
