import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import OpacityLoader from "components/baseLoader/opacityLoader";
import { BaseModalProps } from "components/baseModal/baseModal";
import FileIcon from "components/icons/fileIcon";
import TableCellHighlight from "components/tableCellComponents/tableCellHighlight/tableCellHighlight";
import { GetDisputeDetailsResponse } from "containers/modalContainers/disputeDetails/disputeDetailsModels";
import { IGetDisputesNewResponseListData } from "pages/newDisputes/api/disputeModel";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { setQueryFilters } from "redux/reducers/reportReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { DisputeStatusEnum, ModalTypeEnum, PermissionType } from "utils/enums";
import { formatter } from "utils/formatter";
import { getContentDispositionFilenameUtf8, getDisputeReasonCodeValue, saveFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import { ILookupResponse } from "utils/models";
import "./disputeDetails.scss";
import { baseDisputesRoute } from "pages/newDisputes/api/disputesApi";

const DisputeDetailsModal = (props: BaseModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const [isLoading, setIsLoading] = useState(true);
    const storeQueryParams = useSelector((state: RootState) => state.report.queryParams);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);
    const [disputeDetails, setDisputeDetails] = useState<GetDisputeDetailsResponse | undefined>();
    const activeRow: IGetDisputesNewResponseListData = useSelector((state: RootState) => state.report.activeRow);

    async function fetchDisputeDetails() {
        try {
            setIsLoading(true);

            const response = await executeAxiosRequestWithRefresh({
                url: "/api/disputes/details",
                method: "GET",
                params: {
                    id: activeRow.id,
                },
            });

            if (response) {
                setDisputeDetails(response.data as GetDisputeDetailsResponse);
            }
        } catch (error) {
            toast.error(t(StringResources.modal.disputeDetails.getError).toString());
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        fetchDisputeDetails();
    }, []);

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.DisputeDetails }));
    };

    const onOkClick = async () => {
        try {
            await executeAxiosRequestWithRefresh({
                url: "/api/disputes/accept",
                method: "GET",
                params: {
                    id: activeRow.id,
                },
            });

            toast.success(t(StringResources.modal.disputeDetails.acceptSuccess).toString());
            // we are adding random query params to invalidate redux cache, and initiate new call to backend for up-to-date data
            dispatch(setQueryFilters({ ...storeQueryParams.filters, random: new Date().toISOString() }));
            dispatch(confirmModal({ modalType: ModalTypeEnum.DisputeDetails }));
        } catch (error) {}
    };

    const onUploadClick = (id: number) => {
        return async () => {
            const documentation = disputeDetails?.chargebacks.find(x => x.id === id)?.documentation ?? [];

            const modalData = await modalManagement.openModal({
                modalType: ModalTypeEnum.UploadDisputeModal,
                data: {
                    disputeStepId: id,
                    notificationEmail: activeRow.notificationEmail,
                    documentation: documentation,
                    disputeId: activeRow.id,
                },
            });

            await fetchDisputeDetails();
        };
    };

    const onDownloadClick = (id: number) => {
        return async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `${baseDisputesRoute}/download`,
                    disableErrorToast: true,
                    responseType: "blob",
                    method: "GET",
                    params: {
                        DisputeDocumentId: id,
                    },
                });

                var filename = getContentDispositionFilenameUtf8(response.headers);

                saveFile(response.data, filename, response.contentType!);
            } catch (error) {
                toast.error(t(StringResources.pages.settlements.printReportError).toString());
            }
        };
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal dispute-details" size="xl">
            <Form>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    {isLoading || disputeDetails == undefined ? (
                        <DisputeDetailsModalTitle {...activeRow} />
                    ) : (
                        <DisputeDetailsModalTitle {...disputeDetails} />
                    )}
                </Modal.Header>
                <Modal.Body className="dispute-details__modal-body">
                    {isLoading ? (
                        <div className="dispute-details__loader">
                            <OpacityLoader />
                        </div>
                    ) : disputeDetails == undefined ? (
                        <div>{`${t(StringResources.modal.disputeDetails.noData)}`}</div>
                    ) : (
                        <>
                            <div className="dispute-details__transaction">
                                <h3>{`${t(StringResources.modal.disputeDetails.originalTransaction)}`}</h3>
                                <section className="dispute-details__transaction-body">
                                    <div className="dispute-details__transaction-row">
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.transactionType
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.cardNumber}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.cardNumber
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.cardNumber}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.amount
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {formatter(
                                                disputeDetails.originalTransaction.amount,
                                                disputeDetails.originalTransaction.currencyId
                                            )}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.purchaseDate
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {formatter(disputeDetails.originalTransaction.purchaseDate, "date")}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">
                                            {`${t(StringResources.modal.disputeDetails.authorizationNumber)}`}
                                        </div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.authorizationNumber}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.originalBatchId
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.batchNumber}
                                        </div>
                                    </div>
                                    <div className="dispute-details__transaction-row">
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.settlement
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.settlementId}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.dbaName
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.dbaName}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.agreement
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.contractNumber}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">
                                            {`${t(StringResources.modal.disputeDetails.transactionNumber)}`}
                                        </div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.transactionNumber}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">{`${t(
                                            StringResources.modal.disputeDetails.arn
                                        )}`}</div>
                                        <div className="dispute-details__transaction-row--value">
                                            {disputeDetails.originalTransaction.arn}
                                        </div>
                                        <div className="dispute-details__transaction-row--title">
                                            {`${t(StringResources.modal.disputeDetails.originalTransactionDate)}`}
                                        </div>
                                        <div className="dispute-details__transaction-row--value">
                                            {formatter(
                                                disputeDetails.originalTransaction.originalTransactionDate,
                                                "date"
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                            <section className="dispute-details__chargeback">
                                {disputeDetails.chargebacks.map((x, index) => {
                                    return (
                                        <div key={index} className="dispute-details__chargeback-single">
                                            <h3>{`${x.disputeType.name} ${
                                                x.isReversal ? t(StringResources.modal.disputeDetails.reversal) : ""
                                            }`}</h3>
                                            <section className="dispute-details__chargeback-body">
                                                <div className="dispute-details__chargeback-row">
                                                    <div className="dispute-details__chargeback-row--title">{`${t(
                                                        StringResources.modal.disputeDetails.date
                                                    )}`}</div>
                                                    <div className="dispute-details__chargeback-row--title">
                                                        {`${t(StringResources.modal.disputeDetails.reasonCode)}`}
                                                    </div>
                                                    <div className="dispute-details__chargeback-row--title">
                                                        {`${t(
                                                            StringResources.modal.disputeDetails.reasonCodeDescription
                                                        )}`}
                                                    </div>
                                                    <div className="dispute-details__chargeback-row--title">
                                                        {`${t(
                                                            StringResources.modal.disputeDetails.reasonCodeCategory
                                                        )}`}
                                                    </div>
                                                    <div className="dispute-details__chargeback-row--title">{`${t(
                                                        StringResources.modal.disputeDetails.amount
                                                    )}`}</div>
                                                </div>
                                                <div className="dispute-details__chargeback-row">
                                                    <div>{formatter(x.date, "date")}</div>
                                                    <div>{x.transactionCode}</div>
                                                    <div>{getDisputeReasonCodeValue(x.transactionCode, false)}</div>
                                                    <div>{getDisputeReasonCodeValue(x.transactionCode, true)}</div>
                                                    <div>{formatter(x.amount, x.currencyId)}</div>
                                                </div>
                                                {x.documentation && x.documentation.length > 0 ? (
                                                    <div className="dispute-details__response-wrapper">
                                                        <h3>{`${t(StringResources.modal.disputeDetails.response)}`}</h3>
                                                        <div className="dispute-details__response">
                                                            <div className="dispute-details__response-row">
                                                                <div className="dispute-details__response-row--title">
                                                                    {`${t(StringResources.modal.disputeDetails.date)}`}
                                                                </div>
                                                                <div className="dispute-details__response-row--title">
                                                                    {`${t(
                                                                        StringResources.modal.disputeDetails.username
                                                                    )}`}
                                                                </div>
                                                                <div className="dispute-details__response-row--title">
                                                                    {`${t(
                                                                        StringResources.modal.disputeDetails
                                                                            .disputeEmailAddress
                                                                    )}`}
                                                                </div>
                                                                <div className="dispute-details__response-row--title">
                                                                    {`${t(
                                                                        StringResources.modal.disputeDetails.response
                                                                    )}`}
                                                                </div>
                                                                <div className="dispute-details__response-row--title">
                                                                    {`${t(
                                                                        StringResources.modal.disputeDetails.attachement
                                                                    )}`}
                                                                </div>
                                                            </div>
                                                            {x.documentation.map((y, index) => {
                                                                const fileNameFromPath = y.documentPath
                                                                    .split("/")
                                                                    .pop();
                                                                return (
                                                                    <div
                                                                        key={index}
                                                                        className="dispute-details__response-row"
                                                                    >
                                                                        <div>{formatter(y.date, "date")}</div>
                                                                        <div>{y.username}</div>
                                                                        <div>{y.notificationEmail}</div>
                                                                        <div>{y.response}</div>
                                                                        <div className="dispute-details__response-attachement">
                                                                            <div className="dispute-details__response-attachement-body">
                                                                                <span>
                                                                                    <FileIcon />
                                                                                </span>
                                                                                <span>
                                                                                    <a
                                                                                        className="dispute-details__response-attachement-link"
                                                                                        onClick={onDownloadClick(
                                                                                            y.documentId
                                                                                        )}
                                                                                    >
                                                                                        {fileNameFromPath}
                                                                                    </a>
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}

                                                            {userPermissions.length > 0 &&
                                                            userPermissions.some(
                                                                x => x === PermissionType[PermissionType.DisputesWrite]
                                                            ) &&
                                                            x.disputeStepAllowUpload ? (
                                                                <div className="dispute-details__response action-wrapper">
                                                                    <div>{`${t(
                                                                        StringResources.modal.disputeDetails
                                                                            .responseText
                                                                    )}`}</div>
                                                                    <BaseButton
                                                                        handleClick={onUploadClick(x.id)}
                                                                        text={`${t(
                                                                            StringResources.modal.disputeDetails.respond
                                                                        )}`}
                                                                        styleType="solid"
                                                                    />
                                                                </div>
                                                            ) : (
                                                                <></>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : userPermissions.length > 0 &&
                                                  userPermissions.some(
                                                      x => x === PermissionType[PermissionType.DisputesWrite]
                                                  ) &&
                                                  x.disputeStepAllowUpload ? (
                                                    <div className="dispute-details__response-wrapper">
                                                        <h3>{`${t(StringResources.modal.disputeDetails.response)}`}</h3>
                                                        <div className="dispute-details__response action-wrapper">
                                                            <div>{`${t(
                                                                StringResources.modal.disputeDetails.responseText
                                                            )}`}</div>
                                                            <BaseButton
                                                                handleClick={onUploadClick(x.id)}
                                                                text={`${t(
                                                                    StringResources.modal.disputeDetails.respond
                                                                )}`}
                                                                styleType="solid"
                                                            />
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <></>
                                                )}
                                            </section>
                                        </div>
                                    );
                                })}
                            </section>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    {userPermissions.length > 0 &&
                        userPermissions.some(x => x === PermissionType[PermissionType.DisputesWrite]) && (
                            <BaseButton
                                handleClick={onOkClick}
                                text={`${t(StringResources.modal.disputeDetails.acceptDispute)}`}
                                styleType="line"
                                disabled={
                                    isLoading ||
                                    disputeDetails == undefined ||
                                    disputeDetails.status.value !== DisputeStatusEnum.NotResponded
                                }
                            />
                        )}

                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

interface DisputeDetailsModalTitleProps {
    caseNumber?: string;
    status?: ILookupResponse<number>;
    reasonCode?: string;
}

const DisputeDetailsModalTitle = (props: DisputeDetailsModalTitleProps) => {
    const { t } = useTranslation();
    return (
        <Modal.Title className="dispute-details__header">
            {props.caseNumber && (
                <div>
                    {`${t(StringResources.modal.disputeDetails.case)}`} {props.caseNumber}
                </div>
            )}
            {props.status && (
                <span className="dispute-details__header-status">
                    {TableCellHighlight({
                        value: props.status,
                        conditions: [
                            {
                                condition: x => x == DisputeStatusEnum.Closed,
                                className: "highlight-grey",
                            },
                            {
                                condition: x =>
                                    x == DisputeStatusEnum.Represented ||
                                    x == DisputeStatusEnum.ChargebackReversed ||
                                    x == DisputeStatusEnum.CaseWon ||
                                    x == DisputeStatusEnum.IncomingFunds ||
                                    x == DisputeStatusEnum.IncomingFee,
                                className: "highlight-green",
                            },
                            {
                                condition: x => x == DisputeStatusEnum.NotResponded,
                                className: "highlight-orange",
                            },
                            {
                                condition: x =>
                                    x == DisputeStatusEnum.Declined ||
                                    x == DisputeStatusEnum.CaseLost ||
                                    x == DisputeStatusEnum.OutgoingFee ||
                                    x == DisputeStatusEnum.OutgoingFunds,
                                className: "highlight-red",
                            },
                            {
                                condition: x => x == DisputeStatusEnum.Info,
                                className: "highlight-light-blue",
                            },
                            {
                                condition: x => x == DisputeStatusEnum.Responded,
                                className: "highlight-purple",
                            },
                        ],
                    })}
                </span>
            )}
            {props.reasonCode && (
                <span className="dispute-details__header-reason">
                    {[
                        props.reasonCode,
                        getDisputeReasonCodeValue(props.reasonCode, true),
                        getDisputeReasonCodeValue(props.reasonCode, false),
                    ].join(" | ")}
                </span>
            )}
        </Modal.Title>
    );
};

export default DisputeDetailsModal;
