import BaseModal from "components/baseModal/baseModal";
import { useTranslation } from "react-i18next";
import { ModalActionButtonTypeEnum, ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";

const DeleteModal = () => {
    const { t } = useTranslation();

    return (
        <BaseModal
            title={t(StringResources.modal.deleteModal.title)}
            modalType={ModalTypeEnum.DeleteModal}
            modalActionButtonType={ModalActionButtonTypeEnum.Delete}
            actionButtonText={t(StringResources.modal.confirm)}
            closeButtonText={t(StringResources.modal.cancel)}
        >
            <div className="export-label">{`${t(StringResources.modal.deleteModal.message)}`}</div>
        </BaseModal>
    );
};

export default DeleteModal;
