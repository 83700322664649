import { IColumnVisibility } from "redux/models/reportModels";
import { PageTypeEnum, ReportType } from "./reportDefinitions";

export interface ILookupResponse<T> {
    value: T;
    name: string;
}

export class IPageSettings {
    columns!: IColumnVisibility[];
}

export class IPageSettingsRequest {
    reportType!: ReportType;
    pageType!: PageTypeEnum;
    columns!: IColumnVisibility[];
}

export interface ReportColumnData {
    name: string;
    prop: string;
}

export interface IBreakdownColumn {
    title: string;
    prop: string;
    align_center?: boolean;
    align_right?: boolean;
    align?: boolean;
    currency?: boolean;
    currencyId?: string;
}
