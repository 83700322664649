import { IconSVGProps } from "./types";

const DashboardIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.5781 5.55858C12.7891 5.08593 13.3398 4.87108 13.8164 5.07811C14.2891 5.28905 14.5039 5.83983 14.2617 6.31639L11.793 12.0078C12.2305 12.457 12.4648 13.0703 12.4648 13.75C12.4648 15.1289 11.3789 16.25 9.96484 16.25C8.61719 16.25 7.46484 15.1289 7.46484 13.75C7.46484 12.3711 8.61719 11.25 9.96484 11.25C10.0234 11.25 10.0508 11.25 10.0742 11.25L12.5781 5.55858Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M20 10C20 15.5234 15.5234 20 10 20C4.47656 20 0 15.5234 0 10C0 4.47656 4.47656 0 10 0C15.5234 0 20 4.47656 20 10ZM10 2.5C9.30859 2.5 8.75 3.05977 8.75 3.75C8.75 4.44141 9.30859 5 10 5C10.6914 5 11.25 4.44141 11.25 3.75C11.25 3.05977 10.6914 2.5 10 2.5ZM10 16.25C11.3789 16.25 12.5 15.1289 12.5 13.75C12.5 13.0703 12.2305 12.457 11.793 12.0078L14.2617 6.31641C14.5039 5.83984 14.2891 5.28906 13.8164 5.07812C13.3398 4.87109 12.7891 5.08594 12.5781 5.55859L10.0742 11.25C10.0508 11.25 10.0234 11.2148 10 11.2148C8.62109 11.2148 7.5 12.3711 7.5 13.75C7.5 15.1289 8.62109 16.25 10 16.25ZM5.625 4.375C4.93359 4.375 4.375 4.93359 4.375 5.625C4.375 6.31641 4.93359 6.875 5.625 6.875C6.31641 6.875 6.875 6.31641 6.875 5.625C6.875 4.93359 6.31641 4.375 5.625 4.375ZM3.75 11.25C4.44141 11.25 5 10.6914 5 10C5 9.30859 4.44141 8.75 3.75 8.75C3.05977 8.75 2.5 9.30859 2.5 10C2.5 10.6914 3.05977 11.25 3.75 11.25ZM16.25 8.75C15.5586 8.75 15 9.30859 15 10C15 10.6914 15.5586 11.25 16.25 11.25C16.9414 11.25 17.5 10.6914 17.5 10C17.5 9.30859 16.9414 8.75 16.25 8.75Z"
            fill="#002649"
        />
    </svg>
);

export default DashboardIcon;
