import "./exportModal.scss";
import BaseModal from "components/baseModal/baseModal";
import { useEffect, useState } from "react";
import { StringResources } from "utils/language/languageResource";
import { ExportType, ExportColumn, ModalTypeEnum } from "utils/enums";
import BaseRadio from "components/baseRadio/baseRadio";
import { RootState, useAppDispatch } from "redux/store";
import { setModalData } from "redux/reducers/modalReducer";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReportColumnData } from "utils/models";

export interface IExportModal {
    availableExportTypes: Array<ExportType>;
    selectedExportType?: ExportType;
    selectedExportColumn?: ExportColumn;
    selectedExportColumns?: Array<ReportColumnData>;
}

interface IExportModalProps {
    data: IExportModal;
}

const ExportModal = ({ data }: IExportModalProps) => {
    // there must be at least one option to export, we have that check before we show modal
    const [localExportType, setExportType] = useState<ExportType>(
        data.selectedExportType ?? data.availableExportTypes[0]
    );
    const [localExportColumn, setExportColumn] = useState<ExportColumn>(data.selectedExportColumn ?? ExportColumn.All);
    const columnVisibility = useSelector((state: RootState) => state.report.columnVisibility);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        let exportColumns: Array<ReportColumnData> = [];
        exportColumns = columnVisibility
            .filter(x => (localExportColumn === ExportColumn.All ? true : x.visible))
            .map(x => {
                return { name: x.label, prop: x.id };
            });

        const model: IExportModal = {
            availableExportTypes: data.availableExportTypes,
            selectedExportType: localExportType,
            selectedExportColumn: localExportColumn,
            selectedExportColumns: exportColumns,
        };

        dispatch(
            setModalData({
                modalType: ModalTypeEnum.ExportModal,
                data: model,
            })
        );
    }, [localExportType, localExportColumn]);

    const onExportTypeChange = (key: ExportType) => {
        setExportType(key);

        if (key === ExportType.PDF) {
            setExportColumn(ExportColumn.All);
        }
    };

    const onExportColumnChange = (key: ExportColumn) => {
        setExportColumn(key);
    };

    function getLabel(value: ExportType): string {
        switch (value) {
            case ExportType.CSV:
                return StringResources.export.exportTypeCSV;
            case ExportType.XLSX:
                return StringResources.export.exportTypeXLSX;
            case ExportType.PDF:
                return StringResources.export.exportTypePDF;
        }
    }

    return (
        <BaseModal
            title={t(StringResources.export.title)}
            modalType={ModalTypeEnum.ExportModal}
            actionButtonText={t(StringResources.export.confirm)}
            closeButtonText={t(StringResources.export.close)}
        >
            <div className="export-label">{`${t(StringResources.export.exportType)}`}</div>
            <div className="export-label"></div>
            <div className="d-flex mb-4">
                {Object.values(data.availableExportTypes).map((value: ExportType) => {
                    const labelStringResource = getLabel(value);

                    return (
                        <BaseRadio
                            key={`group1_${value}`}
                            label={t(labelStringResource)}
                            name="group1"
                            value={value}
                            onChange={onExportTypeChange}
                            checked={localExportType === value}
                            className="export-radio-button-group"
                        />
                    );
                })}
            </div>
            <div className="export-label">{`${t(StringResources.export.exportColumn)}`}</div>
            <div className="d-flex flex-column">
                {Object.values(ExportColumn).map((value: ExportColumn, index: number) => {
                    return (
                        <BaseRadio
                            key={`group2_${value}`}
                            label={
                                value == ExportColumn.All
                                    ? t(StringResources.export.exportAllColumns, {
                                          numberOfAvailableColumns: columnVisibility.length,
                                      })
                                    : columnVisibility.filter(x => x.visible).length == 1
                                    ? t(StringResources.export.exportVisibleColumn, {
                                          numberOfVisibleColumns: columnVisibility.filter(x => x.visible).length,
                                      })
                                    : t(StringResources.export.exportVisibleColumns, {
                                          numberOfVisibleColumns: columnVisibility.filter(x => x.visible).length,
                                      })
                            }
                            name="group2"
                            value={value}
                            onChange={onExportColumnChange}
                            checked={localExportColumn === value}
                            className={`export-radio-button-group ${index === 0 ? "mb-2" : ""}`}
                            disabled={localExportType === ExportType.PDF && value === ExportColumn.VisibleColumns} // SD-1920
                        />
                    );
                })}
            </div>
        </BaseModal>
    );
};

export default ExportModal;
