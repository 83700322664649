import { IconSVGProps } from "./types";

const ExclamationIcon = ({ height = 32, width = 32, color = "#002649" }: IconSVGProps) => (
    <svg width={width} height={height} viewBox={`0 0 ${32} ${32}`} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_4275_25122)">
            <path
                d="M16.0002 19C16.8283 19 17.5002 18.3281 17.5002 17.5V9.5C17.5002 8.675 16.8314 8 16.0002 8C15.1689 8 14.5002 8.675 14.5002 9.5V17.5C14.5002 18.3313 15.1752 19 16.0002 19ZM16.0002 21.0688C14.9152 21.0688 14.0352 21.9488 14.0352 23.0338C14.0377 24.1188 14.9127 25 16.0002 25C17.0877 25 17.9652 24.12 17.9652 23.035C17.9627 21.95 17.0877 21.0688 16.0002 21.0688Z"
                fill={color}
            />
            <path
                opacity="0.4"
                d="M16 0C7.1625 0 0 7.1625 0 16C0 24.8375 7.1625 32 16 32C24.8375 32 32 24.8375 32 16C32 7.1625 24.8375 0 16 0ZM14.5 9.5C14.5 8.675 15.175 8 16 8C16.825 8 17.5 8.67188 17.5 9.5V17.5C17.5 18.3281 16.8281 19 16 19C15.1719 19 14.5 18.3312 14.5 17.5V9.5ZM16 25C14.915 25 14.035 24.12 14.035 23.035C14.035 21.95 14.9144 21.07 16 21.07C17.0856 21.07 17.965 21.95 17.965 23.035C17.9625 24.1187 17.0875 25 16 25Z"
                fill={color}
            />
        </g>
    </svg>
);

export default ExclamationIcon;
