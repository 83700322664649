import { IconSVGProps } from "./types";

const FeedbackIcon = ({ height = 16, width = 16 }: IconSVGProps) => (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0 9C0 7.34531 1.34297 6 3 6H9V22.5C9 23.3297 8.32969 24 7.5 24H4.5C3.67172 24 3 23.3297 3 22.5V16.5C1.34297 16.5 0 15.1547 0 13.5V9ZM24 11.25C24 12.5578 23.3719 13.6687 22.5 14.0812V8.41875C23.3719 8.83125 24 9.94219 24 11.25Z"
            fill="#DAFDA3"
        />
        <path
            opacity="0.4"
            d="M22.5 21C22.5 21.6047 22.1344 22.1531 21.5719 22.3875C21.0141 22.6172 20.3672 22.4906 19.9406 22.0594L17.8922 19.9734C15.6422 17.7234 12.5906 16.5 9.40781 16.5H9V5.99999H9.40781C12.5906 5.99999 15.6422 4.73437 17.8922 2.48531L19.9406 0.439354C20.3672 0.0103774 21.0141 -0.117943 21.5719 0.114229C22.1344 0.346401 22.5 0.893432 22.5 1.49999V21Z"
            fill="#DAFDA3"
        />
    </svg>
);
export default FeedbackIcon;
