import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import { IElectronicDocumentsModel } from "pages/electronicDocuments/api/electronicDocumentsModels";
import { IGetPaymentsDetailsReportResponse } from "pages/payments/api/paymentsModels";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { getContentDispositionFilenameUtf8, saveFile } from "utils/helperFunctions";

const baseElectronicDocumentsRoute = "/api/documents";
export const electronicDocumentDownloadRoute = `${baseElectronicDocumentsRoute}/download`;

export const getElectronicDocumentsDetailsReport = async (
    documentId: number
): Promise<IGetPaymentsDetailsReportResponse> => {
    const response = await executeAxiosRequestWithRefresh({
        url: electronicDocumentDownloadRoute,
        disableErrorToast: true,
        responseType: "blob",
        method: "GET",
        params: {
            documentId: documentId,
        },
    });

    var filename = getContentDispositionFilenameUtf8(response.headers);

    return {
        data: response.data,
        filename: filename,
        contentType: response.data.type,
    };
};

export const saveElectronicDocumentDetailReport = async (documentId: number) => {
    const response = await getElectronicDocumentsDetailsReport(documentId);

    await saveFile(response.data, response.filename, response.contentType);
};

const electronicDocumentsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getElectronicDocuments: builder.query<IElectronicDocumentsModel, IQueryParams>({
            query: standardQueryTableRequest<IElectronicDocumentsModel>(baseElectronicDocumentsRoute),
        }),
    }),
    overrideExisting: false,
});

export const { useGetElectronicDocumentsQuery, endpoints } = electronicDocumentsApi;
