import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IAudkenniLoginData, ICompanySchema, IKycState } from "redux/models/kycModels";

const initialState: IKycState = {
    authorizationToken: undefined,
    employeeSsn: undefined,
    employeePhone: undefined,
    legalEntity: undefined,
    legalEntities: undefined,
    finished: false,
};

const kycReducer = createSlice({
    name: "kyc",
    initialState,
    reducers: {
        setAudkenniLoginResponse(state: IKycState, action: PayloadAction<IAudkenniLoginData>) {
            state.authorizationToken = action.payload.authorizationToken;
            state.employeeSsn = action.payload.employeeSsn;
            state.employeePhone = action.payload.employeePhone;
        },
        setLegalEntity(state: IKycState, action: PayloadAction<ICompanySchema | undefined>) {
            state.legalEntity = action.payload;
        },
        setLegalEntities(state: IKycState, action: PayloadAction<ICompanySchema[]>) {
            state.legalEntities = action.payload;
        },
        setFinished(state: IKycState) {
            state.finished = true;
        },
        resetKycStore(state: IKycState) {
            state.authorizationToken = undefined;
            state.legalEntity = undefined;
            state.legalEntities = undefined;
            state.employeeSsn = undefined;
            state.employeePhone = undefined;
            state.finished = false;
        },
    },
});

const { reducer } = kycReducer;
export const { setAudkenniLoginResponse, setLegalEntity, setLegalEntities, setFinished, resetKycStore } =
    kycReducer.actions;
export default reducer;
