import "./opacityLoader.scss";

interface IOpacityLoaderProps {
    height?: string;
    width?: string;
    relativePosition?: boolean;
}

const OpacityLoader = (props: IOpacityLoaderProps) => {
    const customStyles = {
        height: props.height ?? "inherit",
        width: props.width ?? "inherit",
    };

    return (
        <div
            className="opacity-loader"
            style={{ ...customStyles, position: props.relativePosition ? "relative" : "absolute" }}
        >
            <div className="opacity-loader-slider">
                <div className="spinner-sector__circle"></div>
                <div className="spinner-sector__line spinner-sector__line--first"></div>
                <div className="spinner-sector__circle"></div>
                <div className="spinner-sector__line spinner-sector__line--second"></div>
                <div className="spinner-sector__circle"></div>
            </div>
        </div>
    );
};

export default OpacityLoader;
