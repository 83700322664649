import { IconSVGProps } from "./types";

const CopyToClipboardIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M8.95703 0H13.8047C14.3008 0 14.7773 0.199219 15.1289 0.550781L17.7812 3.20312C18.1328 3.55469 18.332 4.03125 18.332 4.52734V13.125C18.332 14.1602 17.4922 15 16.457 15H8.95703C7.92188 15 7.08203 14.1602 7.08203 13.125V1.875C7.08203 0.839844 7.92188 0 8.95703 0ZM2.70703 5H5.83203V7.5H3.33203V17.5H10.832V16.25H13.332V18.125C13.332 19.1602 12.4922 20 11.457 20H2.70703C1.67188 20 0.832031 19.1602 0.832031 18.125V6.875C0.832031 5.83984 1.67188 5 2.70703 5Z"
            fill="#002649"
        />
    </svg>
);

export default CopyToClipboardIcon;
