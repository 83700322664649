import { useEffect } from "react";
import { getRefreshToken, getToken, setUserLocalStorageData } from "utils/storageActions";
import { parseJwt } from "utils/tokenActions";
import { useAppDispatch } from "redux/store";
import { DateTime } from "luxon";
import { debounce } from "lodash";
import { executeAxiosRequest } from "redux/services";
import { adminLogoutThunk } from "redux/actions/authActions";
import { toast } from "react-toastify";
import { lastActiveTimeString } from "utils/constants";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";

export const ActivityTracker = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    let interval: NodeJS.Timeout | undefined;

    useEffect(() => {
        localStorage.setItem(lastActiveTimeString, DateTime.now().toSeconds().toString());

        // add event listeners
        window.addEventListener("mousemove", handleInteraction);
        window.addEventListener("scroll", handleInteraction);
        window.addEventListener("keydown", handleInteraction);
        window.addEventListener("click", handleInteraction);
        window.addEventListener("touchstart", handleInteraction);

        // add interval that will check every 10sec if user was active in last 30 min or 1800sec.
        //  * if he was active, we will check if token is close to expire (in the last 60sec).
        //      ** If yes we will try to extend it by calling refreshToken
        //      ** if token is not close to expire, continue with interval
        //  * if he wasn't active, it will be logged out immediately.
        interval = setInterval(handleUserSessionBasedOnActivity, 10_000);

        return () => {
            // remove event listener
            window.removeEventListener("mousemove", handleInteraction);
            window.removeEventListener("scroll", handleInteraction);
            window.removeEventListener("keydown", handleInteraction);
            window.removeEventListener("click", handleInteraction);
            window.removeEventListener("touchstart", handleInteraction);

            // remove interval
            clearInterval(interval);
        };
    }, []);

    // save last user interaction in local storage.
    // we are using 1sec debounce to not overkill local storage.
    const handleInteraction = debounce(() => {
        localStorage.setItem(lastActiveTimeString, DateTime.now().toSeconds().toString());
    }, 1000);

    async function handleUserSessionBasedOnActivity(): Promise<void> {
        const token = getToken();
        const parsedToken = parseJwt(token);
        const lastActiveTime = +localStorage.getItem(lastActiveTimeString)!;

        // when was user last active (mousemove, scroll, keydown, click, touchstart)
        const diffFromNowToLastActiveTime = DateTime.now().diff(
            DateTime.fromSeconds(lastActiveTime),
            "seconds"
        ).seconds;

        // if user was active in last 30min or 1800 sec
        if (diffFromNowToLastActiveTime < 1800) {
            // how many seconds will token still be valid
            const diffFromNowToTokenExp = DateTime.fromSeconds(parsedToken.exp).diffNow("seconds").seconds;

            // if token expires in next 60sec
            if (diffFromNowToTokenExp > 0 && diffFromNowToTokenExp < 60) {
                clearInterval(interval);

                try {
                    // call refresh token url to get new token
                    const refreshResult = await executeAxiosRequest({
                        url: "/api/account/refresh-token",
                        method: "POST",
                        data: {
                            refreshToken: getRefreshToken(),
                        },
                        disableErrorToast: true,
                    });

                    if (refreshResult.data) {
                        // save token and refresh token
                        setUserLocalStorageData(refreshResult.data);
                        // restart interval
                        interval = setInterval(handleUserSessionBasedOnActivity, 10_000);
                    }
                } catch (error) {
                    // should never happen but just in case
                    dispatch(adminLogoutThunk());
                    toast.error(`${t(StringResources.activityTracker.somethingWentWrong)}`);
                }
            }
        }
        // if user wasn't active in last 30min or 1800sec, we will do logout
        else {
            // remove interval
            clearInterval(interval);

            // logout user
            dispatch(adminLogoutThunk());

            toast.info(`${t(StringResources.activityTracker.loggedOutInactivtiy)}`, {
                autoClose: false,
                closeOnClick: true,
            });
        }
    }

    return <></>;
};
