import { createAsyncThunk } from "@reduxjs/toolkit";
import { IReverseAuthorization } from "redux/models/authorizationModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const authorizationReverse = createAsyncThunk<any, IReverseAuthorization>(
    "authorizations/reverse",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/authorizations/reverse",
            method: "POST",
            data: request,
        });

        return response.data;
    }
);
