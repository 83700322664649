export const PATHS = {
    Global: {
        Login: "/login",
        ForgotPassword: "/forgot-password",
        ResetPassword: "/reset-password",
        ConfirmAccount: "/confirm-account",
        Kyc: "/kyc",
        KycLegalEntity: "/kyc/legal-entity",
        KycSurvey: "/kyc/legal-entity/survey",
        KycSuccess: "/kyc/success",
        NotFound: "/not-found",
    },
    Portal: {
        Index: "/",
        Dashboard: "/",
        Payments: "/payments",
        Terminals: "/terminals",
        Agreements: "/agreements",
        DailyBalance: "/daily-balance",
        Settlements: "/settlements",
        Batches: "/batches",
        Transactions: "/transactions",
        Authorizations: "/authorizations",
        Disputes: "/disputes",
        Users: "/users",
        EcomThemes: "/ecom-themes",
        ElectronicDocuments: "/electronic-documents",
        Notifications: "/notifications",
        Settings: "/settings",
        DisputesNew: "/disputesNew",
        Overview: "/overview",
        PayByLink: "/pay-by-link",
        ApiKeys: "/api-keys",
        Webhooks: "/webhooks",
    },
};
