import { IconSVGProps } from "./types";

const ProgressBarIcon = ({ height = 8, width = 76, className }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <svg width="76" height="8" viewBox="0 0 76 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect opacity="0.4" width="76" height="8" rx="4" fill="#CDD8E2" />
            <rect width="34" height="8" rx="4" fill="#002649" />
        </svg>
    </svg>
);

export default ProgressBarIcon;
