import { FC } from "react";
import { Currency, formatter, CurrencyOptions } from "utils/formatter";

interface ITableCellCurrencyProps {
    value: number | null | undefined;
    format?: Currency;
    options?: CurrencyOptions;
}

const TableCellCurrency: FC<ITableCellCurrencyProps> = ({
    value,
    format = "ISK",
    options = { formatOptions: "None" },
}) => {
    return <div style={{ textAlign: "right", paddingRight: "5px" }}>{formatter(value, format, options)} </div>;
};

export default TableCellCurrency;
