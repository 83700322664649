import { t } from "i18next";
import { useSelector } from "react-redux";
import { clearActiveRow, setQueryFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { ModalTypeEnum, PermissionType, TransactionType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import "./transactionsHeader.scss";
import BaseButton from "components/baseButton/baseButton";
import { IGetTransactionsResponseListData } from "../api/transactionModels";
import { DownloadIcon } from "components/icons";
import { getTransactionReceiptReport, getTransactionRefundReport } from "../api/transactionApi";
import { getDownloadPdfFileMetadata, saveFile } from "utils/helperFunctions";
import { toast } from "react-toastify";

const TransactionsHeader = () => {
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const activeRowData: IGetTransactionsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);
    const queryFilters = useSelector((state: RootState) => state.report.queryParams.filters);

    if (!activeRowData) {
        return <></>;
    }

    const handleRefundClick = async () => {
        const modalData = await modalManagement.openModal({ modalType: ModalTypeEnum.RefundTransaction });
        if (modalData) {
            // we are adding random query params to invalidate redux cache, and initiate new call to backend for up-to-date data
            dispatch(setQueryFilters({ ...queryFilters, random: new Date().toISOString() }));
            // we are clearing active row because if right panel is opened and table re-fetched it will not update the content of the right panel (drawer)
            dispatch(clearActiveRow());
        }
    };

    const printReportRefund = async () => {
        try {
            const response = await dispatch(
                getTransactionRefundReport({ transactionId: activeRowData.transactionId })
            ).unwrap();
            // todo: filename is written in content-disposition header, but we are returning only data from axios so i don't have access to that header here
            const metadata = getDownloadPdfFileMetadata();
            saveFile(response.data, metadata.filename, metadata.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.transactions.details.refundReportError).toString());
        }
    };

    const handlePrintClick = async () => {
        if (activeRowData.isPrintRefundEnabled) {
            await printReportRefund();
            return;
        }
        if (activeRowData.transactionType.value === TransactionType.Purchase) {
            await printTransactionReceipt();
            return;
        }
    };

    const printTransactionReceipt = async () => {
        try {
            const response = await getTransactionReceiptReport({ transactionId: activeRowData.transactionId });

            saveFile(response.data, response.filename, response.contentType);
        } catch (error) {
            toast.error(t(StringResources.pages.transactions.details.refundReportError).toString());
        }
    };

    const hasTransactionsWritePermission = userPermissions.some(
        x => x === PermissionType[PermissionType.TransactionsWrite]
    );

    const isRefundButtonVisible = hasTransactionsWritePermission && activeRowData.isRefundEnabled;

    return (
        <div className="transaction-header report-details__title">
            <div className="report-details__title--label">{`${t(
                StringResources.pages.transactions.details.title
            )}`}</div>

            <div className="transaction-header__title--body">
                {isRefundButtonVisible && (
                    <BaseButton
                        handleClick={handleRefundClick}
                        text={`${t(StringResources.pages.transactions.details.refund)}`}
                        styleType="line"
                        size="small"
                    />
                )}

                <BaseButton
                    handleClick={handlePrintClick}
                    text={`${t(StringResources.pages.transactions.details.print)}`}
                    leftIcon={<DownloadIcon />}
                    styleType="line"
                    size="small"
                    className="report-details__title--action"
                />
            </div>
        </div>
    );
};

export default TransactionsHeader;
