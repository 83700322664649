import { IReportFilter } from "api/requestModels";
import BaseCreatableSelect from "components/baseCreatableSelect/baseCreatableSelect";
import BaseDatepicker from "components/baseDatepicker/baseDatepicker";
import BaseDateRange from "components/baseDateRange/baseDateRange";
import BaseInput from "components/baseInput/baseInput";
import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { IFilterTypesData } from "redux/models/globalModels";
import { RootState } from "redux/store";
import { DropdownFilterNameEnum, FilterTypeEnum, SettlementCardTypeRule } from "utils/enums";
import { ILookupResponse } from "utils/models";

interface IFilterInputProps {
    filterConfig: IReportFilter;
    onValueChange: (newValue: any, field: string) => void;
    value?: any;
    secondaryValue?: any;
}

const FilterInputMapper = ({ filterConfig, onValueChange, value, secondaryValue }: IFilterInputProps) => {
    const filterTypesValues = useSelector((state: RootState) => state.global.filterTypes);

    const onInputChange = (e: any) => {
        onValueChange(e.target.value, filterConfig.field);
    };

    const onDatePickerChange = (valueDate: Date | undefined) => {
        onValueChange(valueDate?.toISOString(), filterConfig.field);
    };

    const onSelectChange = (selectedItem?: Option) => {
        onValueChange(selectedItem?.value, filterConfig.field);
    };

    const getFilterTypeValues = (filterName: string | undefined): ILookupResponse<number | string>[] => {
        if (filterName === undefined) {
            return [];
        }

        // special case for settlement card type rule
        if (filterName == DropdownFilterNameEnum.SettlementTypes) {
            const filterValues = filterTypesValues[filterName as keyof IFilterTypesData];
            return (filterValues as Array<any>).filter(
                x => x.value != SettlementCardTypeRule.Credit
            ) as ILookupResponse<number | string>[];
        }

        try {
            const filterValues = filterTypesValues[filterName as keyof IFilterTypesData];
            if (filterValues.length > 0 && typeof filterValues[0] === "string") {
                return (filterValues as Array<string>).map(x => {
                    return { value: x, name: x };
                });
            }

            return filterValues as ILookupResponse<number | string>[];
        } catch (error) {
            return [];
        }
    };

    const selectValues = (filterName: string | undefined): Array<Option> => {
        return getFilterTypeValues(filterName).map((filter: ILookupResponse<number | string>, index: number) => {
            return {
                value: filter.value,
                label: filter.name,
                selected: filter.value == value,
            };
        });
    };

    const onDateRangeChange = (startDate: Date | undefined, endDate: Date | undefined) => {
        onValueChange(startDate?.toISOString(), filterConfig.field);
        if (filterConfig.secondaryField) {
            onValueChange(endDate?.toISOString(), filterConfig.secondaryField);
        }
    };

    switch (filterConfig.filterType) {
        case FilterTypeEnum.TextFilter:
            return (
                <BaseInput
                    type={"text"}
                    label={`${t(filterConfig.title)}`}
                    onChange={onInputChange}
                    value={value}
                    toolTipDefinitions={filterConfig.toolTipDefinitions?.map(x => `${t(x)}`)}
                />
            );
        case FilterTypeEnum.NumberFilter:
            return (
                <BaseInput type={"number"} label={`${t(filterConfig.title)}`} onChange={onInputChange} value={value} />
            );
        case FilterTypeEnum.DateFilter:
            return (
                <BaseDatepicker
                    value={value ? new Date(value) : undefined}
                    onDateChange={onDatePickerChange}
                    label={`${t(filterConfig.title)}`}
                    isClearable={true}
                />
            );
        case FilterTypeEnum.DropdownFilter:
            return (
                <BaseSelect
                    label={`${t(filterConfig.title)}`}
                    options={selectValues(filterConfig.dropdownFilterName)}
                    onChange={onSelectChange}
                    isClearable={true}
                ></BaseSelect>
            );
        case FilterTypeEnum.DropdownClearableFilter:
            return (
                <BaseCreatableSelect
                    value={value}
                    label={`${t(filterConfig.title)}`}
                    options={selectValues(filterConfig.dropdownFilterName)}
                    onChange={onSelectChange}
                />
            );
        case FilterTypeEnum.DateRangeFilter:
            return (
                <BaseDateRange
                    value={value ? new Date(value) : undefined}
                    secondaryValue={secondaryValue ? new Date(secondaryValue) : undefined}
                    onDateChange={onDateRangeChange}
                    label={`${t(filterConfig.title)}`}
                />
            );
        default:
            return <></>;
    }
};

export default FilterInputMapper;
