export interface ErrorCodeReasons {
    code: string;
    description: string;
    category: ErrorCodeCategoriesEnum;
}
enum ErrorCodeCategoriesEnum {
    VisaFraud = "Fraud",
    VisaAuthorization = "Authorization",
    VisaProcessingErrors = "Processing Errors",
    VisaConsumerDisputes = "Consumer Disputes",
    MatercardFraud = "Fraud related chargebacks",
    MastercardCardholderDispute = "Cardholder Dispute",
    MastercardPointOfInteractionError = "Point of interaction error",
    MastercardAuthorizationCharback = "Authorization-related Chargeback",
    Amex = "Amex",
    Visa = "Visa",
    MasterCard = "MasterCard",
}

export const errorCodeReasons: ErrorCodeReasons[] = [
    {
        code: "0",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Services Not Provided or Merchandise Not Received",
    },
    {
        code: "10",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Fraud",
    },
    { code: "10.1", description: "EMV liability shift counterfeit fraud", category: ErrorCodeCategoriesEnum.VisaFraud },
    {
        code: "10.2",
        description: "EMV liability shift non-counterfeit fraud",
        category: ErrorCodeCategoriesEnum.VisaFraud,
    },
    {
        code: "10.3",
        description: "Other fraud - Card present environment",
        category: ErrorCodeCategoriesEnum.VisaFraud,
    },
    { code: "10.4", description: "Other fraud - Card absent environment", category: ErrorCodeCategoriesEnum.VisaFraud },
    { code: "10.5", description: "Visa fraud monitoring program", category: ErrorCodeCategoriesEnum.VisaFraud },
    {
        code: "11",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Authorization",
    },
    { code: "11.1", description: "Card recovery bulletin", category: ErrorCodeCategoriesEnum.VisaAuthorization },
    { code: "11.2", description: "Declined authorization", category: ErrorCodeCategoriesEnum.VisaAuthorization },
    { code: "11.3", description: "No authorization", category: ErrorCodeCategoriesEnum.VisaAuthorization },
    {
        code: "12",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Processing error",
    },
    { code: "12.1", description: "Late presentment", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    { code: "12.2", description: "Incorrect transaction code", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    { code: "12.3", description: "Incorrect currency", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    { code: "12.4", description: "Incorrect account number", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    { code: "12.5", description: "Incorrect amount", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    {
        code: "12.6",
        description: "Duplicate processing/Paid by other means",
        category: ErrorCodeCategoriesEnum.VisaProcessingErrors,
    },
    { code: "12.7", description: "Invalid data", category: ErrorCodeCategoriesEnum.VisaProcessingErrors },
    {
        code: "13",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Consumer dispute",
    },
    {
        code: "13.1",
        description: "Merchandise/services not received",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    {
        code: "13.2",
        description: "Cancelled recurring transaction",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    {
        code: "13.3",
        description: "Not as described or defective merchandise/services",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    { code: "13.4", description: "Counterfeit merchandise", category: ErrorCodeCategoriesEnum.VisaConsumerDisputes },
    { code: "13.5", description: "Misrepresentation", category: ErrorCodeCategoriesEnum.VisaConsumerDisputes },
    { code: "13.6", description: "Credit not processed", category: ErrorCodeCategoriesEnum.VisaConsumerDisputes },
    {
        code: "13.7",
        description: "Cancelled merchandise/services",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    {
        code: "13.8",
        description: "Original credit transaction not accepted",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    {
        code: "13.9",
        description: "Non-receipt of cash or load transaction value",
        category: ErrorCodeCategoriesEnum.VisaConsumerDisputes,
    },
    {
        code: "20",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Services Not Provided or Merchanise Not Received",
    },
    {
        code: "30",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Services Not Provided or Merchanise Not Received",
    },
    {
        code: "33",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Non-Matching Merchant Category Code in V.I.P. System Authorization",
    },
    {
        code: "41",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Cancelled Recurring Transaction",
    },
    {
        code: "53",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Not as Described or Defective Merchandise",
    },
    {
        code: "57",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Fraudulent Multiple Transactions",
    },
    {
        code: "60",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Illegible Fulfillment",
    },
    {
        code: "62",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Counterfeit Transaction",
    },
    {
        code: "70",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Card Recovery Bulletin or Exception File",
    },
    {
        code: "71",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Declined Authorization",
    },
    {
        code: "72",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "No Authorization",
    },
    {
        code: "73",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Expired Card",
    },
    {
        code: "74",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Late Presentment",
    },
    {
        code: "75",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Transaction Not Recognized",
    },
    {
        code: "76",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Incorrect Currency or Transaction Code or Domestic Transaction",
    },
    {
        code: "77",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Non-Matching Account Number",
    },
    {
        code: "78",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Service Code Violation",
    },
    {
        code: "79",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Requested Transaction Information Not Received",
    },
    {
        code: "80",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Incorrect Transaction Amount or Account Number",
    },
    {
        code: "81",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Fraud - Card-Present Environment",
    },
    {
        code: "82",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Duplicate Processing",
    },
    {
        code: "83",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Fraud - Card-Absent Environment",
    },
    {
        code: "85",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Credit Not Processed",
    },
    {
        code: "86",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Paid By other Means",
    },
    {
        code: "90",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Non-Receipt of Cash or Load Transaction Value at ATM or Load Device",
    },
    {
        code: "93",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Merchant Fraud Performance",
    },
    {
        code: "96",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Transaction Exceeds Limited Amount",
    },
    {
        code: "100",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Telex, Telephone and Cable Charges",
    },
    {
        code: "110",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Auto-Telex Charges",
    },
    {
        code: "130",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Lost/Stolen Card Report Fees",
    },
    {
        code: "140",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Merchant Service Fees",
    },
    {
        code: "150",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Recovered Card Handling Fees/Rewards",
    },
    {
        code: "170",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Recovery of Microfilm Copy/Original Fees",
    },
    {
        code: "200",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Emergency Replacement Card Distribution Fee",
    },
    {
        code: "210",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Emergency Cash Disbursement Handling Fee",
    },
    {
        code: "220",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Arbitration Case Decision and/or Request Fee",
    },
    {
        code: "230",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Incorrect Merchant Identification/Transaction Date Handling Fee",
    },
    {
        code: "240",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Funds Disbursement Transactions",
    },
    {
        code: "250",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "id Chargeback Handling Fee",
    },
    {
        code: "300",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Bill Payment Service (Canada and Brazil only)",
    },
    {
        code: "350",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Pre-arbitration Settlement Funds Disbursement",
    },
    {
        code: "390",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa TravelMoney Fees",
    },
    {
        code: "401",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Prepaid Revenue Allocation",
    },
    {
        code: "402",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Prepaid Funds Collection",
    },
    {
        code: "4507",
        description: "Incorrect transaction amount or primary account number presented",
        category: ErrorCodeCategoriesEnum.Amex,
    },
    {
        code: "2001",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid Acquirer Reference Data",
    },
    {
        code: "2002",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Nonreceipt of required documentation to support chargeback",
    },
    {
        code: "2003",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Correct transaction date provided",
    },
    {
        code: "2004",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid Acquirer Reference Data on chargeback",
    },
    {
        code: "2005",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Correct merchant location/description provided Issuer authorized transaction",
    },
    {
        code: "2008",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Issuer authorized transaction",
    },
    {
        code: "2011",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Credit previously issued",
    },
    {
        code: "2700",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "See Corresponding Documentation/Chargeback Remedied",
    },
    {
        code: "2701",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Duplicate Chargeback",
    },
    {
        code: "2702",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Past Chargeback Time Limit",
    },
    {
        code: "2703",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Requested transaction document provided (requires hardship variance)",
    },
    {
        code: "2704",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid Data Record Text",
    },
    {
        code: "2705",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Correct MCC provided",
    },
    {
        code: "2706",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Authorization advised suspicious",
    },
    {
        code: "2707",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "No authorization request required or attempted",
    },
    {
        code: "2708",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Account was not listed on the applicable Electronic Warning Bulletin as of the transaction date",
    },
    {
        code: "2709",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Documentation received was illegible",
    },
    {
        code: "2710",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Scanning error-unrelated documents or partial scan",
    },
    {
        code: "2713",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid chargeback",
    },
    {
        code: "2870",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Chip Liability Shift",
    },
    {
        code: "2871",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Chip/PIN Liability Shift",
    },
    { code: "4512", description: "Multiple processing", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4513", description: "Credit not presented", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4515", description: "Paid through other means", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4516", description: "No reply to disputes enquiry letter", category: ErrorCodeCategoriesEnum.Amex },
    {
        code: "4517",
        description: "Insufficient or unclear reply to disputes enquiry letter",
        category: ErrorCodeCategoriesEnum.Amex,
    },
    { code: "4521", description: "Invalid authorization", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4523", description: "Unassigned Cardmember account number", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4527", description: "Missing imprint", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4530", description: "Currency discrepancy", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4534", description: "Multiple ROCs", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4536", description: "Late presentment", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4540", description: "Card not present", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4544", description: "Cancellation of recurring goods/services", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4553", description: "Not as described", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4554", description: "Goods/services ordered but not received", category: ErrorCodeCategoriesEnum.Amex },
    {
        code: "4750",
        description: "Car rental charge non-qualified/unsubstantiated",
        category: ErrorCodeCategoriesEnum.Amex,
    },
    { code: "4752", description: "Credit/debit presentment error", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4754", description: "Local regulatory/legal dispute", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4755", description: "No valid authorization", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4763", description: "Fraud full recourse", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4798", description: "Fraud liability shift - counterfeit", category: ErrorCodeCategoriesEnum.Amex },
    { code: "4799", description: "Fraud liability shift - lost/stolen", category: ErrorCodeCategoriesEnum.Amex },
    {
        code: "4801",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Requested transaction data was not received within maximum time frame",
    },
    {
        code: "4802",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Requested information illegible or missing within maximum time frame",
    },
    {
        code: "4804",
        description: "Multiple Processing",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4807",
        description: "Warning Bulletin",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4808",
        description: "Authorization-related Chargeback",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4809",
        description: "Transaction not reconciled",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4811",
        description: "Stale Transaction",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4812",
        description: "Account Number Not on File",
        category: ErrorCodeCategoriesEnum.MastercardAuthorizationCharback,
    },
    {
        code: "4831",
        description: "Transaction Amount Differs",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4834",
        description: "Cardholder Debited More than Once for the Same Goods or Services",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4834",
        description: "Duplicate Processing",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4835",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Card not valid or expired",
    },
    {
        code: "4837",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Fraudulent transaction, no cardholder authorization",
    },
    {
        code: "4840",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Fraudulent processing of transaction",
    },
    {
        code: "4841",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Canceled recurring transaction",
    },
    {
        code: "4842",
        description: "Late Presentment",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4846",
        description: "Currency related dispute",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4846",
        description: "POI Currency Conversion (Dynamic Currency Conversion)",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4847",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Fraudulent transaction, exceeds qualified limit and not authorized",
    },
    {
        code: "4849",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Questionable card acceptor activity",
    },
    {
        code: "4850",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Credit posted as purchase",
    },
    {
        code: "4853",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Cardholder Dispute Defective/Not as Credited",
    },
    {
        code: "4854",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Cardholder Dispute not elsewhere classified (U.S. only)",
    },
    {
        code: "4855",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Non-receipt of merchandise",
    },
    {
        code: "4857",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Card-activated phone transaction",
    },
    {
        code: "4859",
        description: "Services not rendered",
        category: ErrorCodeCategoriesEnum.MastercardPointOfInteractionError,
    },
    {
        code: "4860",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Credit not processed",
    },
    {
        code: "4862",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Counterfeit transaction, magnetic stripe POI fraud",
    },
    {
        code: "4863",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Cardholder does not recognize-Potential fraud",
    },
    {
        code: "4870",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Chip Liability Shift",
    },
    {
        code: "4871",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Chip/PIN Liability Shift",
    },
    {
        code: "4880",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Maestro Late Presentment",
    },
    {
        code: "4899",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Domestic Chargeback Dispute",
    },
    {
        code: "4900",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description:
            "General, invalid Second Presentment/1240, Second Presentment/1240 did not remedy First Chargeback/1442",
    },
    {
        code: "4902",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Documentation received was illegible",
    },
    {
        code: "4903",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Scanning error-unrelated documents or partial scan",
    },
    {
        code: "4905",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid Acquirer Reference Data in Second Presentment/1240, no documentation required",
    },
    {
        code: "4908",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Invalid Acquirer Reference Data in Second Presentment/1240, documentation was required",
    },
    {
        code: "4999",
        category: ErrorCodeCategoriesEnum.MasterCard,
        description: "Domestic Chargeback Dispute",
    },
    {
        code: "5000",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Card Recovery Bulletin",
    },
    {
        code: "5010",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Integrated Billing Statement",
    },
    {
        code: "5015",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Supermarket Incentive Program Fee",
    },
    {
        code: "5020",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Arbitration Request/Review",
    },
    {
        code: "5030",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "BASE II Access Fee",
    },
    {
        code: "5040",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Miscellaneous Fees or Charges",
    },
    {
        code: "5050",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Issuers' Clearinghouse Service Fee",
    },
    {
        code: "5060",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Risk Identification Service Merchant Fees",
    },
    {
        code: "5080",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Late Settlement Fee",
    },
    {
        code: "5090",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Account Tracking Service",
    },
    {
        code: "5100",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Emergency Travelers Cheque Refund Handling Fee",
    },
    {
        code: "5110",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Returned Guaranteed Check",
    },
    {
        code: "5120",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Value Added Tax",
    },
    {
        code: "5130",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "VSIL Fee Collection",
    },
    {
        code: "5140",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "ISO/Non-member Agent Registration Fee/Annual Fee",
    },
    {
        code: "5150",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Chargeback Handling Fee",
    },
    {
        code: "5160",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Fulfillment Incentive Fee",
    },
    {
        code: "5165",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "VisaNet Copy Request and Fulfillment Service Monthly Access and Activity Charges",
    },
    {
        code: "5170",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Non-Fulfillment Incentive Fee",
    },
    {
        code: "5180",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Merchant Review Fee",
    },
    {
        code: "5185",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Membership Compliance Fees and Penalties",
    },
    {
        code: "5190",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "BIN Licensing and Administration Program Fees",
    },
    {
        code: "5195",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Sponsored Member Registration Fees",
    },
    {
        code: "5210",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Merchant Data Inaccuracy",
    },
    {
        code: "5240",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Interchange Data Forms",
    },
    {
        code: "5245",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Service Fees/Late Payments",
    },
    {
        code: "5250",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Indemnification",
    },
    {
        code: "5260",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa TravelMoney Issuer Reimbursement",
    },
    {
        code: "5270",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Collateral Funds",
    },
    {
        code: "5280",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Stored Value Card Purchase Settlement",
    },
    {
        code: "5282",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Stored Value Card Allocated Discrepancies",
    },
    {
        code: "5284",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Stored Value Card Manual Adjustment Settlement",
    },
    {
        code: "5286",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Stored Value Card Reserved",
    },
    {
        code: "5290",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Corporate Indemnification of Settlement Risk",
    },
    {
        code: "5300",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Promotion Credit Reward Funding",
    },
    {
        code: "5310",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "ATM Cash Disbursement Issuer Credit",
    },
    {
        code: "5320",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Member Provided Reimbursement",
    },
    {
        code: "5330",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Sweepstakes Award",
    },
    {
        code: "5340",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Sweepstakes Award Reversal",
    },
    {
        code: "5350",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Sweepstakes Summary",
    },
    {
        code: "5360",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Sweepstakes Summary Reversal",
    },
    {
        code: "6000",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Extras Original Transaction - Future use only",
    },
    {
        code: "6010",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Extras Reversal Transaction - Future use only",
    },
    {
        code: "6020",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Extras",
    },
    {
        code: "6030",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Extras Reversal Summary - Future use only",
    },
    {
        code: "6040",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Award",
    },
    {
        code: "6050",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Award Reversal",
    },
    {
        code: "6060",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Award Offset Summary",
    },
    {
        code: "6070",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Award Reversal Offset Summary",
    },
    {
        code: "6080",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Reward",
    },
    {
        code: "6085",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Visa Reward Reversal",
    },
    {
        code: "6100",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Cardholder Fee",
    },
    {
        code: "6110",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Cardholder Fee Reversal",
    },
    {
        code: "6120",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Cardholder Credit/Rebate",
    },
    {
        code: "6130",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "Cardholder Credit/Rebate Reversal",
    },
    {
        code: "6140",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "STAR Manual Adjustments",
    },
    {
        code: "6150",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "PULSE Manual Adjustments",
    },
    {
        code: "6160",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "NYCE Manual Adjustments",
    },
    {
        code: "6170",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "VISA Network Adjustments",
    },
    {
        code: "6181",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "ACCEL Manual Adjustments",
    },
    {
        code: "6182",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "AFFN Manual Adjustments",
    },
    {
        code: "6183",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "ALASKA Option Manual Adjustments",
    },
    {
        code: "6184",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "CU-24 Network Adjustments",
    },
    {
        code: "6185",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "EBT Manual Adjustments",
    },
    {
        code: "6186",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "MAESTRO Manual Adjustments",
    },
    {
        code: "6187",
        category: ErrorCodeCategoriesEnum.Visa,
        description: "NETS Manual Adjustments",
    },
];
