import classNames from "classnames";
import { t } from "i18next";
import { IBatchesFilter, IGetBatchesResponseListData } from "pages/batches/api/batchesModels";
import { IGetSettlementsResponseListData } from "pages/settlements/api/settlementsModels";
import { useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getTransactionBatchesInSettlement } from "redux/actions/reportDetailSectionActions";
import { IBatchListData, IBatchesByDbaData } from "redux/models/reportDetailsSectionModels";
import { setBatchesDataFromMap } from "redux/reducers/reportDetailSectionReducer";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { customTableCell } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import { IBreakdownColumn } from "utils/models";
import { PATHS } from "utils/routing/paths";
import "./settlementsBatches.scss";

const settlementsBatches = () => {
    const activeRowData = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const batchesMap = useSelector((state: RootState) => state.reportDetailSection.batchesMap);
    const batches = useSelector((state: RootState) => state.reportDetailSection.batchesByDba);
    const isLoading = useSelector((state: RootState) => state.reportDetailSection.settlementIsLoading);

    useEffect(() => {
        const data = activeRowData as IGetSettlementsResponseListData;

        const request = {
            settlementId: data.id,
        };

        const settlementData = batchesMap.find(x => x.settlementId === request.settlementId);

        if (settlementData === undefined) {
            dispatch(getTransactionBatchesInSettlement(request));
        } else {
            dispatch(setBatchesDataFromMap(settlementData));
        }
    }, [activeRowData]);

    const onRedirectClickWithDrill = async () => {
        await dispatch(
            setDrillFilters([
                {
                    field: nameof<IBatchesFilter>(x => x.searchString),
                    value: (activeRowData as IGetBatchesResponseListData).settlementNumber,
                },
            ])
        );

        navigate(PATHS.Portal.Batches);
    };

    const dbaColumns: IBreakdownColumn[] = [
        {
            title: t(StringResources.pages.settlements.dbaName),
            prop: nameof<IBatchesByDbaData>(x => x.dba),
        },
    ];

    const batchColumns: IBreakdownColumn[] = [
        {
            title: t(StringResources.pages.settlements.date),
            prop: nameof<IBatchListData>(x => x.date),
        },
        {
            title: t(StringResources.pages.settlements.batchNumber),
            prop: nameof<IBatchListData>(x => x.batchNumber),
            align_center: true,
        },
        {
            title: t(StringResources.pages.settlements.batchTotal),
            prop: nameof<IBatchListData>(x => x.batchTotal),
            align_right: true,
            currency: true,
            currencyId: nameof<IBatchListData>(x => x.currency),
        },
    ];

    return (
        <div className="settlement-batches">
            <div className="settlement-batches__content">
                <div className="settlement-batches__content__title">{`${t(
                    StringResources.pages.settlements.batches
                )}`}</div>
                <div className="settlement-batches__content__link" onClick={onRedirectClickWithDrill}>
                    {`${t(StringResources.pages.settlements.viewBatches)}`}
                </div>
            </div>

            {isLoading && <BatchesLoadingWrapper dbaColumns={dbaColumns} batchColumns={batchColumns} />}

            {!isLoading && batches.length === 0 && (
                <span className="settlement-batches__content__no-records">
                    {`${t(StringResources.pages.settlements.batchNoRecords)}`}
                </span>
            )}

            {!isLoading && <BatchesWrapper batches={batches} dbaColumns={dbaColumns} batchColumns={batchColumns} />}
        </div>
    );
};

interface BatchesWrapperProps {
    batches: IBatchesByDbaData[];
    dbaColumns: IBreakdownColumn[];
    batchColumns: IBreakdownColumn[];
}

const BatchesWrapper: React.FC<BatchesWrapperProps> = ({ batches, dbaColumns, batchColumns }) => {
    return (
        <div className="batches-wrapper-container">
            {batches.map(dbaBatch => {
                return (
                    <div key={dbaBatch.dba} className="batches-single">
                        <DbaColumn dbaBatch={dbaBatch} dbaColumns={dbaColumns} />
                        {dbaBatch.batchListForDba.length > 0 && (
                            <div className="settlement-batches__content__row date-row">
                                {batchColumns.map((column, index) => (
                                    <div
                                        key={`${column.title}_${index}`}
                                        className={classNames("settlement-batches__content__item", {
                                            "align-right": column.align_right,
                                            "align-center": column.align_center,
                                        })}
                                    >
                                        <div className="settlement-batches__content__item-label">{column.title}</div>
                                    </div>
                                ))}
                            </div>
                        )}
                        {dbaBatch.batchListForDba.map(batch => {
                            return <BatchColumn key={batch.batchNumber} batch={batch} batchColumns={batchColumns} />;
                        })}
                    </div>
                );
            })}
        </div>
    );
};

interface DbaColumnProps {
    dbaBatch?: IBatchesByDbaData;
    dbaColumns: IBreakdownColumn[];
    isLoading?: boolean;
}

const DbaColumn: React.FC<DbaColumnProps> = ({ dbaBatch = null, dbaColumns, isLoading = false }) => {
    return (
        <div className="settlement-batches__content__header">
            {dbaColumns.map((column, index) => {
                return (
                    <div
                        key={`${column.title}_${index}`}
                        className={classNames("settlement-batches__content__item", {
                            "align-right": column.align_right,
                            "align-center": column.align_center,
                        })}
                    >
                        <div className="settlement-batches__content__item-label">{column.title}</div>
                        <div>
                            {isLoading ? <Skeleton width={100} /> : customTableCell((dbaBatch as any)[column.prop])}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

interface BatchColumnProps {
    batch?: IBatchListData;
    batchColumns: IBreakdownColumn[];
    isLoading?: boolean;
}

const BatchColumn: React.FC<BatchColumnProps> = ({ batch = null, batchColumns, isLoading = false }) => {
    return (
        <div className="settlement-batches__content__row date-row">
            {batchColumns.map((column, index) => {
                return (
                    <div
                        key={`${column.title}_${index}`}
                        className={classNames("settlement-batches__content__item", {
                            "align-right": column.align_right,
                            "align-center": column.align_center,
                        })}
                    >
                        <div className="settlement-batches--item-value">
                            {isLoading ? (
                                <Skeleton width={100} />
                            ) : column.currency ? (
                                customTableCell((batch as any)[column.prop], (batch as any)[column.currencyId!])
                            ) : (
                                customTableCell((batch as any)[column.prop] as string)
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

interface BatchesLoadingWrapperProps {
    dbaColumns: IBreakdownColumn[];
    batchColumns: IBreakdownColumn[];
}

const BatchesLoadingWrapper: React.FC<BatchesLoadingWrapperProps> = ({ dbaColumns, batchColumns }) => {
    return (
        <div className="batches-wrapper-container">
            <div className="batches-single">
                <DbaColumn dbaColumns={dbaColumns} isLoading={true} />
                <div className="settlement-batches__content__row date-row">
                    {batchColumns.map((column, index) => (
                        <div
                            key={`${column.title}_${index}`}
                            className={classNames("settlement-batches__content__item", {
                                "align-right": column.align_right,
                                "align-center": column.align_center,
                            })}
                        >
                            <div className="settlement-batches__content__item-label">{column.title}</div>
                        </div>
                    ))}
                </div>
                <BatchColumn batchColumns={batchColumns} isLoading={true} />
            </div>
        </div>
    );
};

export default settlementsBatches;
