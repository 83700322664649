import { IconSVGProps } from "./types";

const PlussAddIcon = ({ height = 20, width = 20, color }: IconSVGProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
        <path
            d="M8.83398 2.5C8.83398 1.94687 8.38711 1.5 7.83398 1.5C7.28086 1.5 6.83398 1.94687 6.83398 2.5V7H2.33398C1.78086 7 1.33398 7.44688 1.33398 8C1.33398 8.55312 1.78086 9 2.33398 9H6.83398V13.5C6.83398 14.0531 7.28086 14.5 7.83398 14.5C8.38711 14.5 8.83398 14.0531 8.83398 13.5V9H13.334C13.8871 9 14.334 8.55312 14.334 8C14.334 7.44688 13.8871 7 13.334 7H8.83398V2.5Z"
            fill="#002649"
        />
    </svg>
);

export default PlussAddIcon;
