import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { useRef } from "react";
import DatePicker from "react-datepicker";
import calendar from "assets/svgs/calendar.svg";
import "react-datepicker/dist/react-datepicker.css";
import "./baseUtcDatePicker.scss";
import { convertLocalToUTCDate, convertUTCToLocalDate } from "utils/helperFunctions";

// Well known issue where datepicker cannot support UTC as default timezone
// https://github.com/Hacker0x01/react-datepicker/issues/1787#issuecomment-770313939

interface BaseUtcDatePickerProps {
    label?: string;
    disabled?: boolean;
    onDateChange: (newDate?: Date) => void;
    value?: Date;
    minDate?: Date;
}

const BaseUtcDatePicker: React.FC<BaseUtcDatePickerProps> = ({ disabled, label, value, onDateChange, minDate }) => {
    const datePickerReference = useRef<any>(null);
    const defaultMinDate = new Date("01/01/2000");

    const scrollToSelectedDate = () => {
        const datepickerDropdown = document.querySelector(".react-datepicker__month-year-dropdown");
        if (datepickerDropdown) {
            const scrollTo = datepickerDropdown.querySelector('[aria-selected="true"]');

            if (!scrollTo) {
                return;
            }

            datepickerDropdown.scroll(0, scrollTo!.getBoundingClientRect().y - 280);
        }
    };

    const handleFocus = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.add("is-datepicker-active");
        }

        if (datePickerReference?.current?.state.open) {
            scrollToSelectedDate();
        }
    };

    const handleBlur = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.remove("is-datepicker-active");
        }
    };

    const dateChangeHandler = (date?: Date) => {
        onDateChange(date);
    };

    return (
        <BaseInputWrapper label={label} value={value?.toString()} disabled={disabled}>
            <label className="dateonlypicker-label-wrapper">
                <DatePicker
                    className={"form-control"}
                    showMonthYearDropdown={true}
                    showTimeSelect={false}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    scrollableMonthYearDropdown
                    dateFormat="dd/MM/yyyy"
                    disabled={disabled}
                    ref={datePickerReference}
                    calendarStartDay={1}
                    formatWeekDay={nameOfDay => nameOfDay.substring(0, 1)}
                    minDate={minDate ?? defaultMinDate}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                    selected={convertUTCToLocalDate(value)}
                    onChange={date => dateChangeHandler(convertLocalToUTCDate(date))}
                    popperPlacement="bottom-end"
                />
                <img height="16" width="16" className="form-icon" src={calendar} />
            </label>
        </BaseInputWrapper>
    );
};
export default BaseUtcDatePicker;
