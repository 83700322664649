import React, { useEffect, useRef } from "react";
import "./baseToggleSwithc.scss";
import classNames from "classnames";

export interface IToggleOption<T> {
    label: string;
    value: T;
}

interface IBaseToggleSwitchProps<T> {
    options: Array<IToggleOption<T>>;
    activeValue: T;
    isDisabled?: boolean;
    onChange: (selected: IToggleOption<T>) => void;
}

function BaseToggleSwitch<T>({ options, activeValue, isDisabled = false, onChange }: IBaseToggleSwitchProps<T>) {
    return (
        <div
            className={classNames("base-toggle-switch", {
                "base-toggle-switch-grid-2": options.length === 2,
                "base-toggle-switch-grid-3": options.length === 3,
                "base-toggle-switch-grid-4": options.length === 4,
                "base-toggle-switch-is-disabled": isDisabled,
            })}
        >
            <div
                className={`base-toggle-switch--label background-${options.length}-${
                    options.findIndex(x => x.value === activeValue) + 1
                }`}
            />

            {options.map(x => {
                return (
                    <div
                        onClick={() => onChange(x)}
                        className={classNames("base-toggle-switch__button", {
                            "base-toggle-switch__button--active": x.value === activeValue,
                        })}
                    >
                        {x.label}
                    </div>
                );
            })}
        </div>
    );
}

export default BaseToggleSwitch;
