import { IconSVGProps } from "./types";

const NoImageIcon = ({ height = 80, width = 80 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0 15C0 9.48438 4.48438 5 10 5H70C75.5156 5 80 9.48438 80 15V65C80 70.5156 75.5156 75 70 75H10C4.48438 75 0 70.5156 0 65V15ZM50.5938 31.6406C49.8906 30.6094 48.7344 30 47.5 30C46.2656 30 45.0938 30.6094 44.4062 31.6406L30.8125 51.5781L26.6719 46.4062C25.9531 45.5156 24.875 45 23.75 45C22.625 45 21.5312 45.5156 20.8281 46.4062L10.8281 58.9062C9.92188 60.0312 9.75 61.5781 10.375 62.875C11 64.1719 12.3125 65 13.75 65H28.75H33.75H66.25C67.6406 65 68.9219 64.2344 69.5625 63C70.2031 61.7656 70.125 60.2812 69.3438 59.1406L50.5938 31.6406ZM17.5 30C19.4891 30 21.3968 29.2098 22.8033 27.8033C24.2098 26.3968 25 24.4891 25 22.5C25 20.5109 24.2098 18.6032 22.8033 17.1967C21.3968 15.7902 19.4891 15 17.5 15C15.5109 15 13.6032 15.7902 12.1967 17.1967C10.7902 18.6032 10 20.5109 10 22.5C10 24.4891 10.7902 26.3968 12.1967 27.8033C13.6032 29.2098 15.5109 30 17.5 30Z"
            fill="#CDD8E2"
        />
    </svg>
);

export default NoImageIcon;
