import { IconSVGProps } from "./types";

const ApiKeysIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_6879_30081)">
            <path
                d="M6.25 6.875C6.25 3.07812 9.32812 0 13.125 0C16.9219 0 20 3.07812 20 6.875C20 10.6719 16.9219 13.75 13.125 13.75C12.3945 13.75 11.6875 13.6367 11.0273 13.4258L6.57422 8.97266C6.36328 8.3125 6.25 7.60547 6.25 6.875ZM14.6875 3.75C13.8242 3.75 13.125 4.44922 13.125 5.3125C13.125 6.17578 13.8242 6.875 14.6875 6.875C15.5508 6.875 16.25 6.17578 16.25 5.3125C16.25 4.44922 15.5508 3.75 14.6875 3.75Z"
                fill="#002649"
            />
            <path
                opacity="0.4"
                d="M6.57422 8.97266L11.0273 13.4258L9.69141 14.6914C9.55078 14.9023 9.3125 15 9.0625 15H7.5V16.5625C7.5 17.082 7.08203 17.5 6.5625 17.5H5V19.0625C5 19.582 4.58203 20 4.0625 20H0.9375C0.419922 20 0 19.582 0 19.0625V15.9375C0 15.6875 0.0987891 15.4492 0.27457 15.2734L6.57422 8.97266Z"
                fill="#002649"
            />
        </g>
    </svg>
);

export default ApiKeysIcon;
