import { createAsyncThunk } from "@reduxjs/toolkit";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { GetDashboardTotalSalesTodayRequest } from "redux/models/dashboardModels";
import { GetDashboardSalesAmountByCardPresentRequest } from "redux/models/dashboardModels";
import { GetDashboardSalesPerDayRequest } from "redux/models/dashboardModels";
import { GetDashboardSalesByOutletRequest } from "redux/models/dashboardModels";
import { GetDashboardDccTransactionsCountRequest } from "redux/models/dashboardModels";
import { GetDashboardMonthlySalesTargetRequest } from "redux/models/dashboardModels";
import { GetDashboardUnpaidSettlementsDataRequest } from "redux/models/dashboardModels";
import { GetDashboardDccCommissionAmountRequest } from "redux/models/dashboardModels";
import { ISetSalesTarget } from "redux/models/dashboardModels";

export const dashboardDataTotalSalesToday = createAsyncThunk<any, GetDashboardTotalSalesTodayRequest>(
    "dashboard/getTotalSalesToday",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-total-sales-today",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
            },
        });
    }
);

export const dashboardDataSalesAmountByCardPresent = createAsyncThunk<any, GetDashboardSalesAmountByCardPresentRequest>(
    "dashboard/getSalesAmountByCardPresent",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-sales-amount-by-card-present",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
                requestedMonth: request.requestedMonth,
            },
        });
    }
);

export const dashboardSalesPerDay = createAsyncThunk<any, GetDashboardSalesPerDayRequest>(
    "dashboard/getSalesPerDay",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-sales-per-day",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
                requestedMonth: request.requestedMonth,
            },
        });
    }
);

export const dashboardSalesByOutlet = createAsyncThunk<any, GetDashboardSalesByOutletRequest>(
    "dashboard/getSalesByOutlet",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-sales-by-outlet",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
                requestedMonth: request.requestedMonth,
            },
        });
    }
);

export const dashboardDccTransactionsCount = createAsyncThunk<any, GetDashboardDccTransactionsCountRequest>(
    "dashboard/getDccTransactionsCount",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-dcc-transactions-count",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
                requestedMonth: request.requestedMonth,
            },
        });
    }
);

export const dashboardMonthlySalesTarget = createAsyncThunk<any, GetDashboardMonthlySalesTargetRequest>(
    "dashboard/getMonthlySalesTarget",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-monthly-sales-target",
            method: "GET",
            params: {},
        });
    }
);

export const dashboardUnpaidSettlementsData = createAsyncThunk<any, GetDashboardUnpaidSettlementsDataRequest>(
    "dashboard/getUnpaidSettlementsData",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-unpaid-settlements-data",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                contractNumber: request.contractNumber,
            },
        });
    }
);

export const dashboardDccCommissionAmount = createAsyncThunk<any, GetDashboardDccCommissionAmountRequest>(
    "dashboard/getDccCommissionAmount",
    async request => {
        return await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/get-dcc-commission-amount",
            method: "GET",
            params: {
                currencyId: request.currencyId,
                requestedMonth: request.requestedMonth,
            },
        });
    }
);

export const dashboardSetSalesTarget = createAsyncThunk<any, ISetSalesTarget>(
    "dashboard/setSalesTarget",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/dashboard/set-sales-target",
            method: "POST",
            data: {
                salesTargetAmount: request.salesTargetAmount,
            },
        });

        return response.data;
    }
);
