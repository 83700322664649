import { createAsyncThunk } from "@reduxjs/toolkit";
import { FeedbackMessage } from "redux/models/modalModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const feedbackMessage = createAsyncThunk<any, FeedbackMessage>("modal/feedback", async message => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/feedback",
        method: "POST",
        data: message,
    });

    return response;
});
