import { IconSVGProps } from "./types";

const MoneyBillTransferIcon = ({ height = 24, width = 24 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M20.9287 4.36961L20.0625 3.53855C19.7138 3.18668 19.7138 2.61587 20.0625 2.26399C20.415 1.91201 20.985 1.91201 21.3037 2.26396L23.7038 4.66703C23.9062 4.83602 24 5.06547 24 5.30431C24 5.54352 23.9062 5.7741 23.7038 5.90929L21.3037 8.3127C20.985 8.69574 20.415 8.69574 20.0625 8.3127C19.7138 7.9935 19.7138 7.42269 20.0625 7.06969L20.9287 6.17216H14.4C13.9013 6.17216 13.5 5.80039 13.5 5.30431C13.5 4.80673 13.9013 4.40303 14.4 4.40303L20.9287 4.36961ZM9.56625 16.9875C10.0988 16.9875 10.4663 17.4231 10.4663 17.8888C10.4663 18.4183 10.0988 18.7901 9.56625 18.7901L3.07313 18.8239L3.90375 19.6876C4.28625 20.0406 4.28625 20.6114 3.90375 20.9306C3.585 21.3136 3.015 21.3136 2.66363 20.9306L0.263588 18.5272C0.0948 18.392 -1.1249e-05 18.1629 1.00105e-09 17.8888C1.00105e-09 17.6822 0.0948375 17.4532 0.263625 17.2842L2.66363 14.8808C3.015 14.5315 3.585 14.5315 3.90375 14.8808C4.28625 15.2338 4.28625 15.8046 3.90375 16.1238L3.07275 17.0213L9.56625 16.9875Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M12.4492 5.30422C12.4492 6.38238 13.323 7.22357 14.3992 7.22357H18.0405C18.168 7.91455 18.558 8.47785 19.1017 8.83085C19.1655 8.92098 19.2405 9.00735 19.3192 9.08997C20.0805 9.8523 21.318 9.8523 22.0792 9.08997L22.7992 8.36519V16.4204C22.7992 17.746 21.723 18.8238 20.3992 18.8238H11.328C11.4705 18.5534 11.5155 18.2492 11.5155 17.8887C11.5155 16.8447 10.6755 15.9359 9.56547 15.9359H5.95797C5.83047 15.3125 5.44047 14.7492 4.89672 14.3962C4.83297 14.3061 4.75797 14.2197 4.67922 14.1371C3.91797 13.3748 2.68197 13.3748 1.92034 14.1371L1.19922 14.8619V6.80673C1.19922 5.47922 2.27359 4.40332 3.59922 4.40332H12.6705C12.528 4.67295 12.4492 4.97939 12.4492 5.30422ZM3.59922 6.77293V9.17634C4.92297 9.17634 5.99922 8.13236 5.99922 6.77293H3.59922ZM20.3992 13.9832C19.0755 13.9832 17.9992 15.0947 17.9992 16.4204H20.3992V13.9832ZM11.9992 7.97464C9.97797 7.97464 8.39922 9.58943 8.39922 11.5797C8.39922 13.6039 9.97797 15.2187 11.9992 15.2187C13.9867 15.2187 15.5992 13.6039 15.5992 11.5797C15.5992 9.58943 13.9867 7.97464 11.9992 7.97464Z"
            fill="#002649"
        />
    </svg>
);

export default MoneyBillTransferIcon;
