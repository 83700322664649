import classNames from "classnames";
import BaseBanner from "components/baseBanner/baseBanner";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import BaseTextArea from "components/baseTextarea/baseTextarea";
import FileIcon from "components/icons/fileIcon";
import ProgressBarIcon from "components/icons/progressBarIcon";
import { DisputeDetailsChargebackDetailsData } from "containers/modalContainers/disputeDetails/disputeDetailsModels";
import { useFormik } from "formik";
import { baseDisputesRoute } from "pages/newDisputes/api/disputesApi";
import { useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { useModalManagement } from "utils/customHooks";
import { ModalActionButtonTypeEnum, ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./uploadDisputeModal.scss";

interface IUploadDisputeForm {
    emailAddress?: string;
    comment?: string;
    files: File[];
}

interface IUploadDisputeModalProps {
    data: {
        disputeId: number;
        disputeStepId: number;
        notificationEmail?: string;
        documentation: DisputeDetailsChargebackDetailsData[];
    };
}

interface IFileUploadData {
    fileUploadInProgress: boolean;
    fileUploadFailed: boolean;
    file: File;
    index: number;
    errorMessage?: string;
}

const UploadDisputeModal = ({ data }: IUploadDisputeModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const [fileUploadData, setFileUploadData] = useState<IFileUploadData[]>([]);
    const [localDocumentation, setLocalDocumentation] = useState<DisputeDetailsChargebackDetailsData[]>(
        data.documentation
    );

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnMount: true,
        validateOnBlur: true,
        initialValues: {
            emailAddress: data.notificationEmail ?? undefined,
            comment: undefined,
            files: [],
        },
        validationSchema: Yup.object({
            emailAddress: Yup.string()
                .email(t(StringResources.pages.disputes.emailInvalid))
                .required(t(StringResources.pages.disputes.emailRequired)),
            comment: Yup.string().required(t(StringResources.pages.disputes.commentRequired)),
            file: Yup.array().of(Yup.mixed().required()),
        }),
        onSubmit: async (value: IUploadDisputeForm) => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `${baseDisputesRoute}/submit-defense`,
                    method: "POST",
                    data: {
                        disputeStepId: data.disputeStepId,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.UploadDisputeModal }));
                toast.success(t(StringResources.pages.disputes.acceptDisputeSuccess).toString());
            } catch (error: any) {
                toast.error(t(StringResources.pages.disputes.acceptDisputeError).toString());
            }
        },
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.UploadDisputeModal }));
    };

    const onOkClick = async () => {
        const modalData = await modalManagement.openModal({
            modalType: ModalTypeEnum.Confirm,
            props: {
                title: t(StringResources.pages.disputes.confirmSubmitTitle),
                children: (
                    <>
                        <BaseBanner text={t(StringResources.pages.disputes.confirmSubmit)}></BaseBanner>
                    </>
                ),
                modalType: ModalTypeEnum.Confirm,
                modalActionButtonType: ModalActionButtonTypeEnum.Confirm,
                actionButtonText: t(StringResources.modal.confirm),
                closeButtonText: t(StringResources.modal.cancel),
            },
        });

        if (modalData !== null) {
            await formValidation.handleSubmit();
        }
    };

    const onFileUpload = async (e: React.ChangeEvent<HTMLInputElement>, replace: boolean = false) => {
        if (e.currentTarget.files?.length) {
            const newFile = e.currentTarget.files[0];

            // 10 MB limit.
            if (newFile.size > 10485760) {
                toast.error(t(StringResources.pages.disputes.fileSizeError).toString());
                return;
            }

            const model = {
                disputeStepId: data.disputeStepId,
                comment: formValidation.values["comment"]!,
                file: newFile!,
                emailAddress: formValidation.values["emailAddress"]!,
            };

            const newFileUploadData = replace ? fileUploadData.filter(x => x.file != newFile) : fileUploadData;
            const newFormValidationValue = replace
                ? formValidation.values["files"]?.filter(x => x != newFile)
                : formValidation.values["files"];

            const currentFileIndex = newFormValidationValue?.length;

            setFileUploadData([
                ...newFileUploadData,
                {
                    index: currentFileIndex,
                    fileUploadInProgress: true,
                    fileUploadFailed: false,
                    file: newFile,
                },
            ]);

            await formValidation.setFieldValue(
                "files",
                newFormValidationValue ? [...newFormValidationValue, newFile] : [newFile]
            );

            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `${baseDisputesRoute}/upload`,
                    method: "POST",
                    data: model,
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                toast.success(t(StringResources.pages.disputes.uploadDisputeSuccess).toString());
                setFileUploadData([
                    ...newFileUploadData,
                    {
                        index: currentFileIndex,
                        fileUploadInProgress: false,
                        fileUploadFailed: false,
                        file: newFile,
                    },
                ]);
            } catch (error: any) {
                toast.error(t(StringResources.pages.disputes.uploadDisputeError).toString());

                setFileUploadData([
                    ...newFileUploadData,
                    {
                        index: currentFileIndex,
                        fileUploadInProgress: false,
                        fileUploadFailed: true,
                        file: newFile,
                        errorMessage: error?.response?.data?.message,
                    },
                ]);
            }
        }
    };

    const smartTrim = (value: string, maxLength: number): string => {
        // If the value length is less than or equal to maxLength, return it as is
        if (value.length <= maxLength) {
            return value;
        }

        // Determine the number of characters to show at the start and end
        const startLength = Math.ceil(maxLength / 2);
        const endLength = maxLength - startLength - 3; // 3 for the '...'

        // Extract the start and end parts of the string
        const start = value.substring(0, startLength);
        const end = value.substring(value.length - endLength);

        // Combine the start, '...', and end parts
        return `${start}...${end}`;
    };

    const onRemoveAllClick = async () => {
        const modalData = await modalManagement.openModal({
            modalType: ModalTypeEnum.Confirm,
            props: {
                title: t(StringResources.pages.disputes.confirmRemoveTitle),
                text: t(StringResources.pages.disputes.confirmRemoveText),
                modalType: ModalTypeEnum.Confirm,
                modalActionButtonType: ModalActionButtonTypeEnum.Delete,
                actionButtonText: t(StringResources.pages.disputes.confirmRemoveButton),
                closeButtonText: t(StringResources.modal.cancel),
            },
        });

        if (modalData == null) {
            return;
        }

        try {
            const response = await executeAxiosRequestWithRefresh({
                url: `${baseDisputesRoute}/remove-documents`,
                method: "POST",
                data: {
                    disputeStepId: data.disputeStepId,
                },
            });

            setFileUploadData([]);
            await formValidation.setFieldValue("files", []);
            setLocalDocumentation([]);

            toast.success(t(StringResources.pages.disputes.removeDocumentsSuccess).toString());
        } catch (error: any) {
            toast.error(t(StringResources.pages.disputes.removeDocumentsError).toString());
        }
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.pages.disputes.uploadDispute)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="upload-dispute__modal-body">
                    <Form.Group className="upload-dispute__form-row">
                        <BaseBanner
                            type={"info"}
                            text={`${t(StringResources.pages.disputes.confirmationEmailWillBeSent)}`}
                            className={"upload-dispute__info-banner"}
                        />
                        <BaseInput
                            label={`${t(StringResources.pages.disputes.emailAddress)}`}
                            type={"email"}
                            name={nameof<IUploadDisputeForm>(x => x.emailAddress)}
                            autocomplete={"off"}
                            value={formValidation.values["emailAddress"]}
                            invalid={formValidation.errors["emailAddress"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>
                    <Form.Group className="upload-dispute__form-row">
                        <BaseTextArea
                            label={`${t(StringResources.pages.disputes.comment)}`}
                            className="upload-dispute__textarea"
                            name={"comment"}
                            rows={5}
                            value={formValidation.values["comment"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                            maxLength={140}
                            invalid={formValidation.errors["comment"]}
                        />
                    </Form.Group>
                    <Form.Group className="upload-dispute__form-row upload-dispute__file-form">
                        {localDocumentation.length > 0 &&
                            localDocumentation.map((doc, index) => {
                                const fileNameFromPath = doc.documentPath.split("/").pop();
                                return (
                                    <div key={`${index}-local`} className="upload-dispute__file-row">
                                        <div className="upload-dispute__file-name">
                                            <FileIcon /> <section>{smartTrim(fileNameFromPath!, 30)}</section>
                                        </div>
                                    </div>
                                );
                            })}
                        {formValidation.values["files"] &&
                            formValidation.values["files"]?.length > 0 &&
                            formValidation.values["files"]?.map((file: File, index: number) => {
                                const fileUpload = fileUploadData.find(x => x.index == index);
                                const uploadFailed = fileUpload && fileUpload.fileUploadFailed;
                                const uploadInProgress = fileUpload && fileUpload.fileUploadInProgress;
                                return (
                                    <div>
                                        <div
                                            key={`${index}-form`}
                                            className={classNames("upload-dispute__file-row", {
                                                "upload-dispute__file-row-failed": uploadFailed,
                                                "upload-dispute__file-row-in-progress": uploadInProgress,
                                            })}
                                        >
                                            <div className={"upload-dispute__file-name"}>
                                                <FileIcon /> <section>{smartTrim(file.name!, 30)}</section>
                                            </div>
                                            {uploadInProgress && (
                                                <>
                                                    <div></div>

                                                    <div>
                                                        <ProgressBarIcon className="upload-dispute__upload-progress" />
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                        {uploadFailed && (
                                            <BaseBanner
                                                type="error"
                                                title={t(StringResources.pages.disputes.fileUploadErrorTitle)}
                                                text={
                                                    fileUpload?.errorMessage ??
                                                    t(StringResources.pages.disputes.fileUploadErrorDefault)
                                                }
                                                actions={[
                                                    {
                                                        label: t(StringResources.pages.disputes.retry),
                                                        onClick: async () => {
                                                            await onFileUpload(
                                                                {
                                                                    currentTarget: { files: [file] },
                                                                } as any,
                                                                true
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: t(StringResources.pages.disputes.remove),
                                                        onClick: async () => {
                                                            setFileUploadData(
                                                                fileUploadData.filter(x => x.file != file)
                                                            );
                                                            await formValidation.setFieldValue(
                                                                "files",
                                                                formValidation.values["files"]?.filter(x => x != file)
                                                            );
                                                        },
                                                    },
                                                ]}
                                            />
                                        )}
                                    </div>
                                );
                            })}
                        <div className="upload-dispute__upload-buttons">
                            <input
                                type="file"
                                className="upload-dispute__file-input"
                                name="dispute"
                                accept=".jpg,.jpeg,.pdf,.tiff"
                                onChange={e => {
                                    onFileUpload(e);
                                }}
                                onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                                    // Reset the value of the input to allow the same file to be uploaded again
                                    const element = event.target as HTMLInputElement;
                                    element.value = "";
                                }}
                                disabled={
                                    fileUploadData.some(x => x.fileUploadInProgress) ||
                                    formValidation.errors["comment"] != undefined ||
                                    formValidation.errors["emailAddress"] != undefined
                                }
                            ></input>
                            <BaseButton
                                styleType="text"
                                danger={true}
                                text={t(StringResources.pages.disputes.removeDocuments)}
                                handleClick={onRemoveAllClick}
                                disabled={
                                    fileUploadData.some(x => x.fileUploadInProgress) ||
                                    (formValidation.values["files"]?.length == 0 && localDocumentation.length == 0)
                                }
                            />
                        </div>
                        <span className="upload-dispute__upload-text">
                            {t(StringResources.pages.disputes.fileLimitations).toString()}
                        </span>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.pages.disputes.submitLater)}`}
                        styleType="line"
                        className="upload-dispute__cancel-button"
                    />

                    <BaseButton
                        className="upload-dispute__submit-button"
                        handleClick={onOkClick}
                        text={`${t(StringResources.pages.disputes.submit)}`}
                        styleType="solid"
                        disabled={
                            !(formValidation.isValid && formValidation.dirty && !formValidation.isValidating) ||
                            fileUploadData.some(x => x.fileUploadInProgress) ||
                            fileUploadData.some(x => x.fileUploadFailed) ||
                            (formValidation.values["files"]?.length == 0 && localDocumentation.length == 0)
                        }
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default UploadDisputeModal;
