import classNames from "classnames";
import TableCellCurrency from "components/tableCellComponents/tableCellCurrency/tableCellCurrency";
import { t } from "i18next";
import { IGetBatchesResponseListData } from "pages/batches/api/batchesModels";
import { ISettlementsFilter } from "pages/settlements/api/settlementsModels";
import { ITransactionsFilter } from "pages/transactions/api/transactionModels";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getBatchDetails } from "redux/actions/reportDetailSectionActions";
import {
    IBatchDetailsTransactionData,
    IGetBatchDetailsRequest,
    IGetBatchDetailsResponse,
} from "redux/models/reportDetailsSectionModels";
import { setBatchDetailsDataFromMap } from "redux/reducers/reportDetailSectionReducer";
import { setDrillFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { customTableCell, propOf } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import { PATHS } from "utils/routing/paths";
import "./batchDetails.scss";
import OpacityLoader from "components/baseLoader/opacityLoader";

const BatchDetails = () => {
    const activeRowData: IGetBatchesResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const batchDetailsMap = useSelector((state: RootState) => state.reportDetailSection.batchesDetailsBreakdownMap);
    const batchDetails = useSelector((state: RootState) => state.reportDetailSection.batchesDetailsBreakdown);
    const isLoading = useSelector((state: RootState) => state.reportDetailSection.batchDetailsIsLoading);

    useEffect(() => {
        const request: IGetBatchDetailsRequest = {
            id: activeRowData.id,
            batchNumber: activeRowData.batch,
        };

        const batchDetailsData = batchDetailsMap.find(x => x.batchNumber === activeRowData.batch);

        if (batchDetailsData === undefined) {
            dispatch(getBatchDetails(request));
        } else {
            dispatch(setBatchDetailsDataFromMap(batchDetailsData.data));
        }
    }, [activeRowData]);

    const breakdownRows = [
        {
            title: t(StringResources.pages.batches.details.grossPurchase),
            prop: propOf<IGetBatchDetailsResponse>(x => x.grossPurchase),
            important: true,
        },
        {
            title: t(StringResources.pages.batches.details.refundAmount),
            prop: propOf<IGetBatchDetailsResponse>(x => x.refundAmount),
            important: true,
        },
        {
            title: t(StringResources.pages.batches.details.reversals),
            prop: propOf<IGetBatchDetailsResponse>(x => x.reversals),
            important: true,
        },
        {
            title: t(StringResources.pages.batches.details.grossBatch),
            prop: propOf<IGetBatchDetailsResponse>(x => x.grossBatch),
            important: true,
            dashedBorderTop: true,
        },
        {
            title: t(StringResources.pages.batches.details.feeAmount),
            prop: propOf<IGetBatchDetailsResponse>(x => x.feeAmount),
            important: true,
        },
        {
            title: t(StringResources.pages.batches.details.transactionFee),
            prop: propOf<IGetBatchDetailsResponse>(x => x.transactionFee),
            indent: true,
            noRounding: true,
        },
        {
            title: t(StringResources.pages.batches.details.joiningFee),
            prop: propOf<IGetBatchDetailsResponse>(x => x.joiningFee),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.chargebackFees),
            prop: propOf<IGetBatchDetailsResponse>(x => x.chargebackFees),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.swiftFees),
            prop: propOf<IGetBatchDetailsResponse>(x => x.swiftFees),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.rejected),
            prop: propOf<IGetBatchDetailsResponse>(x => x.rejected),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.chargeback),
            prop: propOf<IGetBatchDetailsResponse>(x => x.chargeback),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.representment),
            prop: propOf<IGetBatchDetailsResponse>(x => x.representment),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.posRentalFee),
            prop: propOf<IGetBatchDetailsResponse>(x => x.posRentalFee),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.preArbitration),
            prop: propOf<IGetBatchDetailsResponse>(x => x.preArbitration),
            indent: true,
        },
        {
            title: t(StringResources.pages.batches.details.netBatch),
            prop: propOf<IGetBatchDetailsResponse>(x => x.netBatch),
            important: true,
            dashedBorderTop: true,
        },
    ];

    const onTransactionClick = async () => {
        await dispatch(
            setDrillFilters([
                {
                    field: nameof<ITransactionsFilter>(x => x.settlementNumber),
                    value: activeRowData.settlementNumber,
                },
            ])
        );

        navigate(PATHS.Portal.Transactions);
    };

    const onSettlementClick = async () => {
        await dispatch(
            setDrillFilters([
                {
                    field: nameof<ISettlementsFilter>(x => x.searchString),
                    value: activeRowData.settlementNumber,
                },
            ])
        );

        navigate(PATHS.Portal.Settlements);
    };

    if (isLoading) {
        return (
            <div className="batch-breakdown">
                <OpacityLoader width="100%" />
            </div>
        );
    }

    if (batchDetails === undefined) {
        return <></>;
    }

    const batchTransactionsColumn = [
        {
            title: t(StringResources.pages.batches.details.brandType),
            prop: nameof<IBatchDetailsTransactionData>(x => x.brandType),
            object: true,
        },
        {
            title: t(StringResources.pages.batches.transactions),
            prop: nameof<IBatchDetailsTransactionData>(x => x.count),
            align_center: true,
            string: true,
        },
        {
            title: t(StringResources.pages.batches.batchTotal),
            prop: nameof<IBatchDetailsTransactionData>(x => x.totalAmount),
            align_right: true,
            currency: true,
            currencyId: nameof<IBatchDetailsTransactionData>(x => x.currencyId),
        },
    ];

    return (
        <>
            <div className="batch-breakdown">
                <div className="batch-breakdown__header">
                    <div className="batch-breakdown__title">{`${t(
                        StringResources.pages.batches.details.breakdown
                    )}`}</div>
                    <div className="batch-breakdown__link" onClick={onSettlementClick}>
                        {`${t(StringResources.pages.batches.details.viewSettlement)}`}
                    </div>
                </div>
                <div className="batch-breakdown__body">
                    {breakdownRows.map((row, index) => {
                        if (!row.important && batchDetails[row.prop] == 0) {
                            return <React.Fragment key={index}></React.Fragment>;
                        }

                        return (
                            <div
                                key={index}
                                className={classNames("batch-breakdown__row", {
                                    "dashed-border-top": row.dashedBorderTop,
                                })}
                            >
                                <div
                                    className={classNames("batch-breakdown__row--item-label", {
                                        important: row.important,
                                        indent: row.indent,
                                        "last-child": breakdownRows.length === index + 1,
                                    })}
                                >
                                    {row.title}
                                </div>
                                <div
                                    className={classNames("batch-breakdown__row--item-value", {
                                        indent: row.indent,
                                        "last-child": breakdownRows.length === index + 1,
                                    })}
                                >
                                    {TableCellCurrency({
                                        value: +batchDetails[row.prop]!,
                                        format: activeRowData.currencyId,
                                        options: {
                                            formatOptions: activeRowData.currencyId === "ISK" ? "Symbol" : "Code",
                                            specialRules: row.noRounding ? ["NoRounding"] : [],
                                        },
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div>
                <div className="batch-transactions__header">
                    <div className="batch-transactions__title">{`${t(
                        StringResources.pages.batches.details.transactions
                    )}`}</div>
                    <div className="batch-transactions__link" onClick={onTransactionClick}>
                        {`${t(StringResources.pages.batches.viewTransactions)}`}
                    </div>
                </div>
                <div className="batch-transactions__content">
                    {batchTransactionsColumn.map((column, index) => {
                        return (
                            <div
                                key={`${column.title}_${index}`}
                                className={classNames("batch-transactions__content__item", {
                                    "align-right": column.align_right,
                                    "align-center": column.align_center,
                                })}
                            >
                                <div className="batch-transactions__content__item-label">{column.title}</div>
                            </div>
                        );
                    })}
                    {batchDetails.transactionsData.map((row, rowIndex) => {
                        return (
                            <>
                                {batchTransactionsColumn.map((column, columnIndex) => {
                                    return (
                                        <div
                                            key={`${row.brandType}-${row.currencyId}_${columnIndex}`}
                                            className={classNames("batch-transactions__content__item", {
                                                "align-right": column.align_right,
                                                "align-center": column.align_center,
                                            })}
                                        >
                                            <div className="batch-transactions--item-value">
                                                {column.currency
                                                    ? customTableCell(
                                                          (row as any)[column.prop],
                                                          (row as any)[column.currencyId!]
                                                      )
                                                    : column.string
                                                    ? customTableCell((row as any)[column.prop].toString())
                                                    : customTableCell((row as any)[column.prop].name)}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default BatchDetails;
