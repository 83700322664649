import { FC } from "react";
import Select, { ActionMeta, OnChangeValue, SingleValue, StylesConfig, components } from "react-select";
import "./baseCreatableSelect.scss";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { AngleIcon } from "components/icons";
import variables from "../../style/colors.module.scss";
import CreatableSelect from "react-select/creatable";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";

interface Option {
    value: number | string | boolean;
    label: string;
    selected: boolean;
}

interface IBaseCreatableSelectProps {
    options: Array<Option>;
    label?: React.ReactNode;
    selectedValueLabel?: string | number;
    customCreateLabel?: (inputValue: string) => string;
    isDisabled?: boolean;
    value?: Option | string;

    // Allow user to create new options outside of the list.
    isCreatableDropdown?: boolean;
    onChange: (selectedItem?: Option) => void;
}

const BaseCreatableSelect: FC<IBaseCreatableSelectProps> = ({
    label,
    isDisabled = false,
    options,
    onChange,
    value,
    selectedValueLabel,
    customCreateLabel,
}) => {
    const { t } = useTranslation();
    const getValueAsOption = () => {
        if (typeof value === "string" || value instanceof String || typeof value === "number") {
            return {
                value: value,
                label: selectedValueLabel ?? value,
                selected: true,
            } as Option;
        } else {
            return value;
        }
    };

    const localValue = getValueAsOption();

    const handleCreateableChange = (newValue: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
        if (!newValue) {
            onChange(undefined);
            return;
        }

        const newOption = {
            value: newValue?.value ?? "",
            label: newValue?.label ?? "",
            selected: true,
        } as Option;

        onChange(newOption);
    };

    return (
        <BaseInputWrapper label={label} value={localValue?.label}>
            <div className="select-wrapper">
                <CreatableSelect
                    key={(localValue?.value as string) ?? "empty-key"}
                    styles={customStyles}
                    className="form-control"
                    closeMenuOnSelect={true}
                    isClearable={localValue !== undefined}
                    isDisabled={isDisabled}
                    isMulti={false}
                    options={options}
                    value={localValue}
                    formatCreateLabel={(inputValue: string) => {
                        if (customCreateLabel) {
                            return customCreateLabel(inputValue);
                        }

                        return t(StringResources.input.add).toString() + ' "' + inputValue + '"';
                    }}
                    onChange={handleCreateableChange}
                    placeholder=""
                    menuPlacement="auto"
                    menuPosition={"fixed"}
                    components={{
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => (
                            <AngleIcon height={14} width={10} className="select-wrapper__toggle-icon" />
                        ),
                        ClearIndicator: (props: any) => (
                            <div
                                onClick={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                onMouseDown={(e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                            >
                                <components.ClearIndicator {...props} />
                            </div>
                        ),
                    }}
                />
            </div>
        </BaseInputWrapper>
    );
};

// programatically style for react-select component
const customStyles: StylesConfig<Option, boolean> = {
    control: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled ? variables.colorGrayEpsilon : variables.colorTransparent,
            border: "0",
            boxShadow: "none",
            height: "100%",
            padding: "7px",
        };
    },
    option: (styles, state) => {
        return {
            ...styles,
            display: "flex",
            cursor: state.isDisabled ? "not-allowed" : "default",
            marginTop: "4px",
            marginBottom: "4px",
            padding: "12px 16px",
            fontSize: "1rem",
            lineHeight: 1.5,
            borderRadius: "4px",
            backgroundColor: state.isDisabled
                ? undefined
                : state.isSelected
                ? variables.colorBlueGamma
                : state.isFocused
                ? variables.colorGrayBeta
                : undefined,
            color: state.isDisabled ? undefined : state.isSelected ? variables.colorSecondaryDelta : undefined,
            ":active": {
                ...styles[":active"],
                backgroundColor: variables.colorGrayBeta,
            },
        };
    },
    menuList: styles => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: "4px",
        marginRight: "4px",
    }),
    menu: styles => ({ ...styles, zIndex: 500 }),
    menuPortal: styles => ({ ...styles, zIndex: 500 }),
    clearIndicator: styles => ({
        ...styles,
        paddingRight: "16px",
        color: variables.colorPrimaryAlpha,
        ":hover": { ...styles[":hover"], color: variables.colorPrimaryAlpha, cursor: "pointer" },
    }),
};

export default BaseCreatableSelect;
