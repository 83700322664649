import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseCheckbox from "components/baseCheckbox/baseCheckbox";
import BaseCreatableSelect from "components/baseCreatableSelect/baseCreatableSelect";
import BaseInput from "components/baseInput/baseInput";
import BaseRadio from "components/baseRadio/baseRadio";
import BaseSelect, { Option } from "components/baseSelect/baseSelect";
import BaseUtcDatePicker from "components/baseUtcDatePicker/baseUtcDatePicker";
import { useFormik } from "formik";
import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { useAppDispatch } from "redux/store";
import { ModalTypeEnum, TerminalRequestDeliveryType as TerminalRequestDeliveryTypeEnum } from "utils/enums";
import { formatter } from "utils/formatter";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import "./requestTerminalModal.scss";
import {
    IGetBasicAgreementsReponseListData,
    IGetMccCodesResponseListData,
    IGetMerchantStoresResponseListData,
} from "./requestTerminalModels";

interface IRequestTerminalForm {
    contractId?: number;
    storeId?: number;
    typeOfBusiness?: string;
    isWifi: boolean;
    is4G: boolean;
    printer: boolean;
    ecrIntegrated: boolean;
    quantityOfTerminals?: number;
    ecrSystem?: string;
    name?: string;
    streetAndNumber?: string;
    city?: string;
    postalCode?: string;
    country?: string;
    averageDeliveryInDays?: number;
    annualVolumeCardPresent?: number;
    averageMonthlyVolume?: number;
    maxTransactionAmount?: number;
    annualVolumeMoto?: number;
    averageTransactionAmount?: number;
    annualVolumeTotal?: number;
    averageMonthlyNumberOfTransactions?: number;
    comment?: string;
    deliveryType?: number;
    terminalDeliveryDate?: string;
}

const today = DateTime.now().startOf("day");

const RequestTerminalModal = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [contractsOptions, setContractsOptions] = useState<Array<Option>>([]);
    const [contracts, setContracts] = useState<Array<IGetBasicAgreementsReponseListData>>([]);
    const [storesOptions, setStoresOptions] = useState<Array<Option>>([]);
    const [stores, setStores] = useState<Array<IGetMerchantStoresResponseListData>>([]);
    const [mccCodesOptions, setTypeOfBusinessOptions] = useState<Array<Option>>([]);
    const [mccCodes, setTypeOfBusiness] = useState<Array<IGetMccCodesResponseListData>>([]);

    useEffect(() => {
        const fetchContracts = async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/agreements/get-basic-agreements`,
                    method: "GET",
                    params: {},
                });

                if (response && response.data) {
                    const models = response.data.data;

                    setContracts(models);

                    const options: Array<Option> = models.map((x: IGetBasicAgreementsReponseListData) => {
                        return {
                            label: `${x.contractNumber}${x.description ? ` - ${x.description}` : ""}`,
                            value: x.contractId,
                        };
                    });

                    options.unshift({
                        label: t(StringResources.pages.terminals.create.newAgreement),
                        value: 0,
                        selected: false,
                    });

                    setContractsOptions(options);
                }
            } catch (error) {
                toast.error(t(StringResources.pages.terminals.error.fetchError).toString());
            }
        };

        const fetchTypeOfBusiness = async () => {
            try {
                const response = await executeAxiosRequestWithRefresh({
                    url: `/api/stores/get-mcc-codes`,
                    method: "GET",
                    params: {},
                });

                if (response && response.data) {
                    const models = response.data.data;

                    setTypeOfBusiness(models);

                    const options: Array<Option> = models.map((x: IGetMccCodesResponseListData) => {
                        return {
                            label: `${x.mcc}${x.description ? ` - ${x.description}` : ""}`,
                            value: x.mccId,
                        };
                    });

                    setTypeOfBusinessOptions(options);
                }
            } catch (error) {
                toast.error(t(StringResources.pages.terminals.error.fetchError).toString());
            }
        };

        fetchContracts();
        fetchTypeOfBusiness();
    }, []);

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnChange: true,
        validateOnMount: false,
        validateOnBlur: true,
        initialValues: {
            contractId: undefined,
            storeId: undefined,
            typeOfBusiness: undefined,
            isWifi: false,
            is4G: false,
            printer: true,
            ecrIntegrated: true,
            quantityOfTerminals: undefined,
            name: "",
            streetAndNumber: "",
            city: "",
            postalCode: "",
            country: "",
            averageDeliveryInDays: undefined,
            annualVolumeCardPresent: undefined,
            averageMonthlyVolume: undefined,
            maxTransactionAmount: undefined,
            annualVolumeMoto: undefined,
            averageTransactionAmount: undefined,
            annualVolumeTotal: undefined,
            averageMonthlyNumberOfTransactions: undefined,
        },
        validationSchema: Yup.object().shape({
            quantityOfTerminals: Yup.number()
                .min(1, t(StringResources.pages.terminals.error.quantityOfTerminalsMustBeLarger))
                .required(t(StringResources.pages.terminals.error.quantityOfTerminalsRequired)),
            ecrSystem: Yup.string().when("ecrIntegrated", {
                is: true,
                then: Yup.string().required(t(StringResources.pages.terminals.error.ecrSystemRequired)),
            }),
            contractId: Yup.number().required(t(StringResources.pages.terminals.error.agreementNumberRequired)),
            storeId: Yup.number().required(t(StringResources.pages.terminals.error.storeIsRequired)),
            typeOfBusiness: Yup.string().required(t(StringResources.pages.terminals.error.typeOfBusinessIsRequired)),
            name: Yup.string().required(t(StringResources.pages.terminals.error.nameIsRequired)),
            streetAndNumber: Yup.string().required(t(StringResources.pages.terminals.error.streetAndNumberIsRequired)),
            city: Yup.string().required(t(StringResources.pages.terminals.error.cityIsRequired)),
            postalCode: Yup.string().required(t(StringResources.pages.terminals.error.postalCodeIsRequired)),
            country: Yup.string().required(t(StringResources.pages.terminals.error.countryIsRequired)),
            averageDeliveryInDays: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.averageDeliveryInDaysIsRequired)),
            }),
            annualVolumeCardPresent: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.annualVolumeCardPresentIsRequired)),
            }),
            averageMonthlyVolume: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.averageMonthlyVolumeIsRequired)),
            }),
            maxTransactionAmount: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.maxTransactionAmountIsRequired)),
            }),
            annualVolumeMoto: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.annualVolumeMotoIsRequired)),
            }),
            averageTransactionAmount: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(
                    t(StringResources.pages.terminals.error.averageTransactionAmountIsRequired)
                ),
            }),
            annualVolumeTotal: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(t(StringResources.pages.terminals.error.annualVolumeTotalIsRequired)),
            }),
            averageMonthlyNumberOfTransactions: Yup.string().when("storeId", {
                is: (value: number) => value == 0,
                then: Yup.string().required(
                    t(StringResources.pages.terminals.error.averageMonthlyNumberOfTransactionsIsRequired)
                ),
            }),
            comment: Yup.string().optional(),
            deliveryType: Yup.number().required(t(StringResources.pages.terminals.error.deliveryTypeIsRequired)),
            terminalDeliveryDate: Yup.string().required(
                t(StringResources.pages.terminals.error.terminalDeliveryDateIsRequired)
            ),
        }),
        onSubmit: async (value: IRequestTerminalForm) => {
            try {
                await executeAxiosRequestWithRefresh({
                    url: `/api/terminals`,
                    method: "POST",
                    data: {
                        contractId: value.contractId === 0 ? undefined : value.contractId,
                        storeId: value.storeId === 0 ? undefined : value.storeId,
                        typeOfBusiness: Number(value.typeOfBusiness)
                            ? mccCodes.find(x => x.mccId === Number(value.typeOfBusiness))?.mcc
                            : value.typeOfBusiness,
                        hasWifi: value.isWifi,
                        has4G: value.is4G,
                        hasPrinter: value.printer,
                        isEcrIntegrated: value.ecrIntegrated,
                        quantityOfTerminals: value.quantityOfTerminals,
                        ecrSystem: value.ecrSystem,
                        name: value.name,
                        streetAndNumber: value.streetAndNumber,
                        city: value.city,
                        postalCode: value.postalCode,
                        country: value.country,
                        averageDeliveryInDays: value.averageDeliveryInDays,
                        annualVolumeCardPresent: value.annualVolumeCardPresent,
                        averageMonthlyVolume: value.averageMonthlyVolume,
                        maxTransactionAmount: value.maxTransactionAmount,
                        annualVolumeMoto: value.annualVolumeMoto,
                        averageTransactionAmount: value.averageTransactionAmount,
                        annualVolumeTotal: value.annualVolumeTotal,
                        averageMonthlyNumberOfTransactions: value.averageMonthlyNumberOfTransactions,
                        terminalDeliveryDate: value.terminalDeliveryDate,
                        comment: value.comment,
                        deliveryType: value.deliveryType,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.RequestTerminal }));
                toast.success(t(StringResources.pages.terminals.create.successMsg).toString());
            } catch (error: any) {
                toast.error(t(StringResources.pages.terminals.error.submitError).toString());
            }
        },
    });

    useEffect(() => {
        const fetchStores = async () => {
            const agreementNumber =
                formValidation.values["contractId"] && formValidation.values["contractId"] > 0
                    ? contracts.find(x => x.contractId === formValidation.values["contractId"])?.contractNumber
                    : undefined;

            const response = await executeAxiosRequestWithRefresh({
                url: `/api/stores`,
                method: "GET",
                params: {
                    agreementNumber: agreementNumber,
                },
            });

            if (response && response.data) {
                const models = response.data.data;

                setStores(models);

                const options: Array<Option> = models.map((x: IGetMerchantStoresResponseListData) => {
                    return {
                        label: `${x.mid} - ${x.mcc}${x.description ? ` - ${x.description}` : ""}`,
                        value: x.storeId,
                    };
                });

                options.unshift({
                    label: t(StringResources.pages.terminals.create.newStore),
                    value: 0,
                    selected: false,
                });

                setStoresOptions(options);
            }
        };

        if (formValidation.values["contractId"] != undefined) {
            fetchStores();
        }
    }, [formValidation.values["contractId"]]);

    const updateCheckbox = (id: string, checked: boolean) => {
        formValidation.setFieldValue(id, checked);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.RequestTerminal }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    const calculateSum = (annualVolumeCardPresent?: number, annualVolumeMoto?: number) => {
        const annVolumeCardPresent = Number(annualVolumeCardPresent) || 0;
        const annVolumeMoto = Number(annualVolumeMoto) || 0;

        if (annVolumeMoto == 0 && annVolumeCardPresent == 0) {
            return undefined;
        }

        return annVolumeCardPresent + annVolumeMoto;
    };

    const calculateAverage = (averageMonthlyVolume?: number, averageTransactionAmount?: number) => {
        const avgMonthlyVolume = Number(averageMonthlyVolume) || 0;

        if (!Number(averageTransactionAmount?.toString() || "")) {
            averageTransactionAmount = Number(averageTransactionAmount?.toString().replace(/\D/g, "") || 0);
        }

        const avgTransactionAmount = Number(averageTransactionAmount) || 0;

        if (avgTransactionAmount == 0 || avgMonthlyVolume == 0) {
            return undefined;
        }

        let avg = avgMonthlyVolume / avgTransactionAmount;

        return Math.ceil(avg);
    };

    const handleNumberInputChange = (field: keyof IRequestTerminalForm, event: any) => {
        let value;

        if (event.target.value == "") {
            value = undefined;
        } else {
            value = parseInt(event.target.value, 10);
        }

        formValidation.setFieldValue(field, value, true);

        if (field === "annualVolumeCardPresent") {
            formValidation.setFieldValue(
                "annualVolumeTotal",
                calculateSum(value, formValidation.values["annualVolumeMoto"]),
                true
            );
        } else if (field === "annualVolumeMoto") {
            formValidation.setFieldValue(
                "annualVolumeTotal",
                calculateSum(formValidation.values["annualVolumeCardPresent"], value),
                true
            );
        } else if (field === "averageMonthlyVolume") {
            formValidation.setFieldValue(
                "averageMonthlyNumberOfTransactions",
                calculateAverage(value, formValidation.values["averageTransactionAmount"]),
                true
            );
        } else if (field === "averageTransactionAmount") {
            formValidation.setFieldValue(
                "averageMonthlyNumberOfTransactions",
                calculateAverage(formValidation.values["averageMonthlyVolume"], value),
                true
            );
        }
    };

    const handleNumberToTextChange = (field: keyof IRequestTerminalForm, event: any) => {
        let value = event.target.value;

        if (!Number(value) || value !== "") {
            value = value.replace(/\D/g, "");
        }

        value = Number(value) || "";

        value = formatter(value, "ISK");

        formValidation.setFieldValue(field, value, true);
    };

    const onAgreementChange = (selectedItem: Option) => {
        if (!selectedItem) {
            formValidation.setFieldValue("contractId", undefined);
            formValidation.setFieldValue("storeId", undefined);
        }

        formValidation.setFieldValue("contractId", selectedItem?.value, true);

        onStoreChange(undefined);
    };

    const onStoreChange = (selectedItem: Option | undefined) => {
        if (selectedItem && selectedItem.value !== 0) {
            let store = stores.find(x => x.storeId === selectedItem.value);

            formValidation.setFieldValue("name", store?.name, true);
            formValidation.setFieldValue("streetAndNumber", store?.streetAndNumber, true);
            formValidation.setFieldValue("city", store?.city, true);
            formValidation.setFieldValue("postalCode", store?.postalCode, true);
            formValidation.setFieldValue("country", store?.country, true);
        } else {
            formValidation.setFieldValue("name", undefined);
            formValidation.setFieldValue("streetAndNumber", undefined);
            formValidation.setFieldValue("city", undefined);
            formValidation.setFieldValue("postalCode", undefined);
            formValidation.setFieldValue("country", undefined);

            formValidation.setFieldValue("typeOfBusiness", undefined);
        }

        formValidation.setFieldValue("storeId", selectedItem?.value, true);
    };

    const filterMccOptions = () => {
        const storeId = formValidation.values["storeId"];

        if (storeId == undefined) {
            return [];
        }

        if (storeId > 0) {
            let store = stores.find(x => x.storeId === storeId);

            let mccs = mccCodes.filter(x => x.mcc === store?.mcc);

            const options: Array<Option> = mccs.map((x: IGetMccCodesResponseListData) => {
                return {
                    label: `${x.mcc} - ${x.description}`,
                    value: x.mccId,
                    selected: false,
                };
            });

            return options;
        }

        let mccList: IGetMccCodesResponseListData[] = [];

        stores.forEach(store => {
            let mcc = mccCodes.filter(x => x.mcc === store?.mcc);
            mccList.push(...mcc);
        });

        mccList = mccList.filter((item, index, self) => index === self.findIndex(t => t.mcc === item.mcc));

        const options: Array<Option> = mccList.map((x: IGetMccCodesResponseListData) => {
            return {
                label: `${x.mcc} - ${x.description}`,
                value: x.mccId,
                selected: false,
            };
        });

        return options;
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal" size={"xl"}>
            <Form>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.pages.terminals.create.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="request-terminal__modal-body">
                    <Form onSubmit={handleSubmit}>
                        <div>
                            <h6>{`${t(StringResources.pages.terminals.generalInfo)}`}</h6>
                            <hr />
                            <div className="request-terminal__general-info">
                                <BaseSelect
                                    label={`${t(StringResources.pages.terminals.agreementNumber)}`}
                                    options={contractsOptions.map(x => {
                                        return { ...x, selected: x.value == formValidation.values["contractId"] };
                                    })}
                                    onChange={option => onAgreementChange(option)}
                                    isClearable={true}
                                />

                                <BaseSelect
                                    label={`${t(StringResources.pages.terminals.create.store)}`}
                                    options={storesOptions.map(x => {
                                        return { ...x, selected: x.value == formValidation.values["storeId"] };
                                    })}
                                    onChange={option => onStoreChange(option)}
                                    isClearable={true}
                                    isDisabled={formValidation.values["contractId"] === undefined}
                                />

                                <BaseCreatableSelect
                                    value={formValidation.values["typeOfBusiness"]}
                                    label={`${t(StringResources.pages.terminals.create.typeOfBusiness)}`}
                                    options={filterMccOptions()}
                                    onChange={option =>
                                        formValidation.setFieldValue("typeOfBusiness", option?.value, true)
                                    }
                                    selectedValueLabel={
                                        formValidation.values["typeOfBusiness"] &&
                                        mccCodesOptions.find(x => x.value === formValidation.values["typeOfBusiness"])
                                            ?.label
                                    }
                                    isDisabled={formValidation.values["storeId"] === undefined}
                                    customCreateLabel={inputValue =>
                                        t(StringResources.pages.terminals.create.newMccLabel)
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <h6>{`${t(StringResources.pages.terminals.terminalFunctionalities)}`}</h6>
                            <hr />
                            <div className="request-terminal__terminal-functionalities">
                                <div className="request-terminal__terminal-functionalities-checkbox">
                                    <BaseCheckbox
                                        id={"isWifi"}
                                        checked={formValidation.values["isWifi"]}
                                        onChange={updateCheckbox}
                                        label={`${t(StringResources.pages.terminals.create.wifi)}`}
                                    />

                                    <BaseCheckbox
                                        id={"is4G"}
                                        checked={formValidation.values["is4G"]}
                                        onChange={updateCheckbox}
                                        label={`${t(StringResources.pages.terminals.create.fourG)}`}
                                    />

                                    <BaseInput
                                        type={"number"}
                                        name={"quantityOfTerminals"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.quantityOfTerminals)}`}
                                        value={formValidation.values["quantityOfTerminals"]}
                                        invalid={
                                            formValidation.touched["quantityOfTerminals"] &&
                                            formValidation.errors["quantityOfTerminals"]
                                        }
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />
                                </div>
                                <div className="request-terminal__terminal-functionalities-radio">
                                    <BaseRadio
                                        label={t(StringResources.pages.terminals.create.printer)}
                                        name="radio_1"
                                        value={true}
                                        onChange={() => formValidation.setFieldValue("printer", true, true)}
                                        checked={formValidation.values["printer"]}
                                        className="request-terminal__radio-group"
                                    />

                                    <BaseRadio
                                        label={t(StringResources.pages.terminals.create.noPrinter)}
                                        name="radio_2"
                                        value={false}
                                        onChange={() => formValidation.setFieldValue("printer", false, true)}
                                        checked={!formValidation.values["printer"]}
                                        className="request-terminal__radio-group"
                                    />
                                </div>
                                <div className="request-terminal__terminal-functionalities-radio">
                                    <BaseRadio
                                        label={t(StringResources.pages.terminals.create.ecrIntegrated)}
                                        name="radio_3"
                                        value={true}
                                        onChange={() => formValidation.setFieldValue("ecrIntegrated", true, true)}
                                        checked={formValidation.values["ecrIntegrated"]}
                                        className="request-terminal__radio-group"
                                    />

                                    <BaseRadio
                                        label={t(StringResources.pages.terminals.create.standalone)}
                                        name="radio_4"
                                        value={false}
                                        onChange={() => formValidation.setFieldValue("ecrIntegrated", false, true)}
                                        checked={!formValidation.values["ecrIntegrated"]}
                                        className="request-terminal__radio-group"
                                    />

                                    {formValidation.values["ecrIntegrated"] && (
                                        <BaseInput
                                            type={"text"}
                                            name={"ecrSystem"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.ecrSystem)}`}
                                            value={formValidation.values["ecrSystem"]}
                                            invalid={
                                                formValidation.touched["ecrSystem"] &&
                                                formValidation.errors["ecrSystem"]
                                            }
                                            onBlur={formValidation.handleBlur}
                                            onChange={formValidation.handleChange}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <h6>{`${t(StringResources.pages.terminals.dba)}`}</h6>
                            <hr />
                            <div className="request-terminal__dba">
                                <div>
                                    <BaseInput
                                        type={"text"}
                                        name={"name"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.name)}`}
                                        value={formValidation.values["name"]}
                                        invalid={formValidation.touched["name"] && formValidation.errors["name"]}
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />

                                    <BaseInput
                                        type={"text"}
                                        name={"postalCode"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.postalCode)}`}
                                        value={formValidation.values["postalCode"]}
                                        invalid={
                                            formValidation.touched["postalCode"] && formValidation.errors["postalCode"]
                                        }
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />
                                </div>
                                <div>
                                    <BaseInput
                                        type={"text"}
                                        name={"streetAndNumber"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.streetAndNumber)}`}
                                        value={formValidation.values["streetAndNumber"]}
                                        invalid={
                                            formValidation.touched["streetAndNumber"] &&
                                            formValidation.errors["streetAndNumber"]
                                        }
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />

                                    <BaseInput
                                        type={"text"}
                                        name={"country"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.country)}`}
                                        value={formValidation.values["country"]}
                                        invalid={formValidation.touched["country"] && formValidation.errors["country"]}
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />
                                </div>
                                <div>
                                    <BaseInput
                                        type={"text"}
                                        name={"city"}
                                        autocomplete={"off"}
                                        label={`${t(StringResources.pages.terminals.create.city)}`}
                                        value={formValidation.values["city"]}
                                        invalid={formValidation.touched["city"] && formValidation.errors["city"]}
                                        onBlur={formValidation.handleBlur}
                                        onChange={formValidation.handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        {formValidation.values["storeId"] === 0 && (
                            <div>
                                <h6>{`${t(StringResources.pages.terminals.expectedStoreTurnoverInIsk)}`}</h6>
                                <hr />
                                <div className="request-terminal__turnover">
                                    <div>
                                        <BaseInput
                                            type={"number"}
                                            name={"averageMonthlyVolume"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.averageMonthlyVolume)}`}
                                            value={formValidation.values["averageMonthlyVolume"]}
                                            invalid={
                                                formValidation.touched["averageMonthlyVolume"] &&
                                                formValidation.errors["averageMonthlyVolume"]
                                            }
                                            onBlur={formValidation.handleBlur}
                                            onChange={e => handleNumberInputChange("averageMonthlyVolume", e)}
                                        />

                                        <BaseInput
                                            type={"number"}
                                            name={"annualVolumeCardPresent"}
                                            autocomplete={"off"}
                                            label={`${t(
                                                StringResources.pages.terminals.create.annualVolumeCardPresent
                                            )}`}
                                            value={formValidation.values["annualVolumeCardPresent"]}
                                            invalid={
                                                formValidation.touched["annualVolumeCardPresent"] &&
                                                formValidation.errors["annualVolumeCardPresent"]
                                            }
                                            onBlur={formValidation.handleBlur}
                                            onChange={e => handleNumberInputChange("annualVolumeCardPresent", e)}
                                        />

                                        <BaseInput
                                            type={"number"}
                                            name={"averageDeliveryInDays"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.averageDeliveryInDays)}`}
                                            value={formValidation.values["averageDeliveryInDays"]}
                                            invalid={
                                                formValidation.touched["averageDeliveryInDays"] &&
                                                formValidation.errors["averageDeliveryInDays"]
                                            }
                                            onBlur={formValidation.handleBlur}
                                            onChange={e => handleNumberInputChange("averageDeliveryInDays", e)}
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            type={"text"}
                                            name={"averageTransactionAmount"}
                                            autocomplete={"off"}
                                            label={`${t(
                                                StringResources.pages.terminals.create.averageTransactionAmount
                                            )}`}
                                            value={formValidation.values["averageTransactionAmount"]}
                                            invalid={
                                                formValidation.touched["averageTransactionAmount"] &&
                                                formValidation.errors["averageTransactionAmount"]
                                            }
                                            onBlur={e => handleNumberToTextChange("averageTransactionAmount", e)}
                                            onFocus={e => handleNumberToTextChange("averageTransactionAmount", e)}
                                            onChange={e => handleNumberInputChange("averageTransactionAmount", e)}
                                        />

                                        <BaseInput
                                            type={"number"}
                                            name={"annualVolumeMoto"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.anualVolumeMoto)}`}
                                            value={formValidation.values["annualVolumeMoto"]}
                                            invalid={
                                                formValidation.touched["annualVolumeMoto"] &&
                                                formValidation.errors["annualVolumeMoto"]
                                            }
                                            onBlur={formValidation.handleBlur}
                                            onChange={e => handleNumberInputChange("annualVolumeMoto", e)}
                                        />

                                        <BaseInput
                                            type={"text"}
                                            name={"maxTransactionAmount"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.maxTransactionAmount)}`}
                                            value={formValidation.values["maxTransactionAmount"]}
                                            invalid={
                                                formValidation.touched["maxTransactionAmount"] &&
                                                formValidation.errors["maxTransactionAmount"]
                                            }
                                            onBlur={e => handleNumberToTextChange("maxTransactionAmount", e)}
                                            onFocus={e => handleNumberToTextChange("maxTransactionAmount", e)}
                                            onChange={e => handleNumberInputChange("maxTransactionAmount", e)}
                                        />
                                    </div>
                                    <div>
                                        <BaseInput
                                            type={"number"}
                                            name={"averageMonthlyNumberOfTransactions"}
                                            autocomplete={"off"}
                                            label={`${t(
                                                StringResources.pages.terminals.create
                                                    .averageMonthlyNumberOfTransactions
                                            )}`}
                                            value={formValidation.values["averageMonthlyNumberOfTransactions"]}
                                            invalid={formValidation.errors["averageMonthlyNumberOfTransactions"]}
                                            onBlur={formValidation.handleBlur}
                                            disabled={true}
                                        />

                                        <BaseInput
                                            type={"text"}
                                            name={"annualVolumeTotal"}
                                            autocomplete={"off"}
                                            label={`${t(StringResources.pages.terminals.create.annualVolumeTotal)}`}
                                            value={formatter(formValidation.values["annualVolumeTotal"], "ISK")}
                                            invalid={formValidation.errors["annualVolumeTotal"]}
                                            onBlur={formValidation.handleBlur}
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        <div>
                            <h6>{`${t(StringResources.pages.terminals.create.delivery)}`}</h6>
                            <hr />
                            <div className="request-terminal__delivery">
                                <BaseSelect
                                    label={`${t(StringResources.pages.terminals.create.deliveryType)}`}
                                    options={Object.entries(TerminalRequestDeliveryTypeEnum)
                                        .filter(([key, value]) => !isNaN(Number(value)))
                                        .map(([key, value]) => {
                                            return {
                                                label: key,
                                                value: value,
                                                selected: value == formValidation.values["deliveryType"],
                                            };
                                        })}
                                    onChange={option => {
                                        formValidation.setFieldValue("deliveryType", option.value).then(() => {
                                            formValidation.validateField("deliveryType");
                                        });
                                    }}
                                />
                                <BaseUtcDatePicker
                                    label={`${t(StringResources.pages.terminals.create.terminalDeliveryDate)}`}
                                    value={
                                        formValidation.values["terminalDeliveryDate"]
                                            ? new Date(formValidation.values["terminalDeliveryDate"])
                                            : undefined
                                    }
                                    onDateChange={date =>
                                        formValidation.setFieldValue("terminalDeliveryDate", date, true)
                                    }
                                    minDate={today.toJSDate()}
                                />
                                <BaseInput
                                    type={"text"}
                                    name={"comment"}
                                    autocomplete={"off"}
                                    label={`${t(StringResources.pages.terminals.create.comment)}`}
                                    value={formValidation.values["comment"]}
                                    invalid={formValidation.touched["comment"] && formValidation.errors["comment"]}
                                    onBlur={formValidation.handleBlur}
                                    onChange={formValidation.handleChange}
                                    maxLength={50}
                                />
                            </div>
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.close)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onOkClick}
                        text={`${t(StringResources.modal.confirm)}`}
                        styleType="solid"
                        disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default RequestTerminalModal;
