import { createAsyncThunk } from "@reduxjs/toolkit";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { IPageSettings, IPageSettingsRequest } from "utils/models";
import { setPageSettings, setPageSize } from "utils/storageActions";

export const setColumnVisibilityThunk = createAsyncThunk<any, IPageSettingsRequest>(
    "report/setColumnVisibility",
    request => {
        setPageSettings(request.pageType, request.columns, request.reportType);

        return request;
    }
);

export const setQueryPageSizeThunk = createAsyncThunk<any, number>("report/setQueryPageSize", (request, thunkApi) => {
    setPageSize(request);
    return request;
});

export const getContractData = createAsyncThunk("report/getContractData", async () => {
    const response = await executeAxiosRequestWithRefresh({
        url: `/api/agreements/get-contract-data`,
        method: "GET",
    });

    return response.data;
});
