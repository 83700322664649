import { IconSVGProps } from "./types";

const AuthorizationsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.6992 4.31246L15.3242 0.249964C15.0511 -0.00331772 14.6535 -0.071013 14.3113 0.077776C13.9692 0.226839 13.7484 0.564495 13.7484 0.937151L13.7461 3.74996H1.25C0.558984 3.74996 0 4.30856 0 4.96481C0 5.62106 0.558984 6.24996 1.25 6.24996H13.7461L13.7492 9.06246C13.7492 9.43539 13.97 9.77301 14.3121 9.92184C14.654 10.0707 15.0515 10.003 15.325 9.74973L19.7 5.68723C20.1016 5.31637 20.1016 4.68356 19.6992 4.31246Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M20 14.9648C20 15.6914 19.4414 16.25 18.75 16.25H6.25389L6.24999 19.0625C6.24999 19.4354 6.02921 19.773 5.6871 19.9219C5.34514 20.0708 4.94764 20.003 4.67421 19.7498L0.299207 15.6873C-0.100793 15.3162 -0.100793 14.6837 0.299207 14.3127L4.67421 10.2502C4.94733 9.99687 5.34491 9.9291 5.6871 10.078C6.02921 10.227 6.24999 10.5644 6.24999 10.9374L6.25389 13.75H18.75C19.4414 13.75 20 14.3086 20 14.9648Z"
            fill="#002649"
        />
    </svg>
);

export default AuthorizationsIcon;
