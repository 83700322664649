import classNames from "classnames";
import { t } from "i18next";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { nameof } from "ts-simple-nameof";
import { StringResources } from "utils/language/languageResource";
import { IBreakdownColumn } from "utils/models";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./paymentsSettlementsBreakdown.scss";
import TableCellCurrency from "components/tableCellComponents/tableCellCurrency/tableCellCurrency";
import { Currency } from "utils/formatter";
import { IGetPaymentsResponseListData } from "pages/payments/api/paymentsModels";
import { paymentSettlementBreakdownGet } from "redux/actions/reportDetailSectionActions";
import { IPaymentSettlementBreakdown } from "redux/models/reportDetailsSectionModels";
import { setBreakdownsDataFromMap } from "redux/reducers/reportDetailSectionReducer";

const PaymentSettlementsBreakdown = () => {
    const activeRowData: IGetPaymentsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const dispatch = useAppDispatch();
    const breakdownMap = useSelector((state: RootState) => state.reportDetailSection.settlementsBreakdownMap);
    const breakdowns = useSelector((state: RootState) => state.reportDetailSection.settlementsBreakdown);
    const isLoading = useSelector((state: RootState) => state.reportDetailSection.paymentIsLoading);

    useEffect(() => {
        const request = {
            paymentId: activeRowData.id,
        };

        const breakdownData = breakdownMap.find(obj => obj.paymentId === request.paymentId);

        if (breakdownData === undefined) {
            dispatch(paymentSettlementBreakdownGet(request));
        } else {
            dispatch(setBreakdownsDataFromMap(breakdownData));
        }
    }, [activeRowData]);

    const columns: IBreakdownColumn[] = [
        {
            title: t(StringResources.pages.payments.settlement),
            prop: nameof<IPaymentSettlementBreakdown>(x => x.settlementNumber),
            align: true,
            currency: false,
        },
        {
            title: t(StringResources.pages.payments.payout),
            prop: nameof<IPaymentSettlementBreakdown>(x => x.payout),
            currency: true,
            currencyId: activeRowData.currencyId,
        },
        {
            title: t(StringResources.pages.payments.feeAmounts),
            prop: nameof<IPaymentSettlementBreakdown>(x => x.feeAmount),
            currency: true,
            currencyId: activeRowData.currencyId,
        },
        {
            title: t(StringResources.pages.payments.grossAmounts),
            prop: nameof<IPaymentSettlementBreakdown>(x => x.grossAmount),
            currency: true,
            currencyId: activeRowData.currencyId,
        },
    ];

    return (
        <div className="payments-settlement-breakdown">
            <div className="payments-settlement-breakdown__title">
                {`${t(StringResources.pages.payments.breakdown)}`}
            </div>

            {isLoading && <PaymentSettlementRow columns={columns} isLoading={true} />}

            {!isLoading && breakdowns.length === 0 && (
                <span className="settlement-batches__no-records">
                    {`${t(StringResources.pages.payments.breakdownNoRecords)}`}
                </span>
            )}

            {!isLoading &&
                breakdowns.map(x => {
                    return <PaymentSettlementRow key={x.settlementNumber} breakdown={x} columns={columns} />;
                })}
        </div>
    );
};

interface PaymentSettlementRowProps {
    breakdown?: IPaymentSettlementBreakdown;
    columns: IBreakdownColumn[];
    isLoading?: boolean;
}

const PaymentSettlementRow: React.FC<PaymentSettlementRowProps> = ({
    breakdown = null,
    columns,
    isLoading = false,
}) => {
    return (
        <div className="payments-settlement-breakdown__row">
            {columns.map((column, index) => {
                return (
                    <div
                        key={`${column.title}_${index}`}
                        className={classNames("payments-settlement-breakdown__row--item", {
                            align: column.align,
                        })}
                    >
                        <div className="payments-settlement-breakdown__row--item-label">{column.title}</div>
                        <div className="payments-settlement-breakdown__row--item-value">
                            {isLoading ? (
                                <Skeleton width={100} />
                            ) : column.currency ? (
                                TableCellCurrency({
                                    value: (breakdown as any)[column.prop],
                                    format: (column.currencyId as Currency) ?? "ISK",
                                    options: { formatOptions: "None", specialRules: ["NoRounding"] },
                                })
                            ) : (
                                (breakdown as any)[column.prop]
                            )}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default PaymentSettlementsBreakdown;
