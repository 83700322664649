import { t } from "i18next";
import { useSelector } from "react-redux";
import { clearActiveRow, setQueryFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { ModalActionButtonTypeEnum, ModalTypeEnum, PermissionType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import { toast } from "react-toastify";
import "./authorizationsHeader.scss";
import BaseButton from "components/baseButton/baseButton";
import { authorizationReverse } from "redux/actions/authorizationActions";
import { IGetAuthorizationsResponseListData } from "../api/authorizationsModels";
import { formatter } from "utils/formatter";

const AuthorizationsHeader = () => {
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const queryFilters = useSelector((state: RootState) => state.report.queryParams.filters);
    const activeRowData: IGetAuthorizationsResponseListData = useSelector((state: RootState) => state.report.activeRow);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);

    if (!activeRowData) {
        return <></>;
    }

    const handleClick = async () => {
        const modalData = await modalManagement.openModal({
            modalType: ModalTypeEnum.Confirm,
            props: {
                title: t(StringResources.modal.reversalAuthorization.reversal),
                text: t(StringResources.modal.reversalAuthorization.confirmReversalMessage, {
                    amount: formatter(activeRowData.amount, activeRowData!.currencyId!),
                }),
                modalType: ModalTypeEnum.Confirm,
                modalActionButtonType: ModalActionButtonTypeEnum.Confirm,
                actionButtonText: t(StringResources.modal.confirm),
                closeButtonText: t(StringResources.modal.cancel),
            },
        });

        if (modalData !== null) {
            try {
                await dispatch(authorizationReverse({ authorizationId: activeRowData.authorizationId })).unwrap();
                toast.success(t(StringResources.modal.reversalAuthorization.success).toString());
                // we are adding random query params to invalidate redux cache, and initiate new call to backend for up-to-date data
                dispatch(setQueryFilters({ ...queryFilters, random: new Date().toISOString() }));
                // we are clearing active row because if right panel is opened and table re-fetched it will not update the content of the right panel (drawer)
                dispatch(clearActiveRow());
            } catch (_) {
                toast.error(t(StringResources.modal.reversalAuthorization.error).toString());
            }
        }
    };

    const hasAuthorizationWritePermission = userPermissions.some(
        x => x === PermissionType[PermissionType.AuthorizationsWrite]
    );

    return (
        <div className="authorizations-header report-details__title">
            <div className="report-details__title--label">{`${t(
                StringResources.pages.authorizations.details.title
            )}`}</div>
            {hasAuthorizationWritePermission && activeRowData.isRefundable && (
                <div className="report-details__title--action">
                    <BaseButton
                        handleClick={handleClick}
                        text={`${t(StringResources.pages.authorizations.details.reverse)}`}
                        styleType="line"
                        size="small"
                    />
                </div>
            )}
        </div>
    );
};

export default AuthorizationsHeader;
