import { SupportedLanguageEnum, ShopperInfoRequirementType, ShopperInfoFieldEnum } from "utils/enums";
import { Currency } from "utils/formatter";
import { StringResources } from "utils/language/languageResource";

export interface IGetPayByLinkRequestDataResponse {
    ecomResponseData: Array<IEcomResponseData>;
    paymentLinkThemeResponseData: Array<IPaymentLinkThemeResponseData>;
}

export interface IEcomResponseData {
    dbaName: string;
    contractNumber: string;
    contractId: number;
    currencyId: string;
    tid: string;
    ecomSolutionId: number;
}

export interface IPaymentLinkThemeResponseData {
    themeId: number;
    themeName: string;
    themeDescription?: string;
}

export interface IBuyerInfoData {
    id: number;
    shopperInfoFieldEnum: number;
    shopperInfoRequirementType: number;
}

export interface IProductItemsData {
    id: number;
    description: string;
    quantity: number;
    amountWithoutDiscount: number;
    amount: number;
    unitPrice: number;
}

export interface IProductRow {
    id: string;
    description?: string;
    quantity?: number;
    amountWithoutDiscount?: number;
    unitPrice?: number;
    amount?: number;
    productDetailsId?: number;
    currencyId: Currency;
}

export interface ILanguageOptions {
    description: string;
    languageId: SupportedLanguageEnum;
}

export interface IExpirationDateOptions {
    description: string;
    hasExpirationDate: boolean;
}

export interface IPaymentLinkShopperInfo {
    id?: number | undefined;
    label: string;
    shopperInfoRequirementType: ShopperInfoRequirementType;
    field: ShopperInfoFieldEnum;
}

export interface ICreatePaymentLinkRequestForm {
    paymentPage?: number;
    paymentLinkTheme?: number;
    language?: SupportedLanguageEnum;
    payByLinkDescription?: string;
    purchaseReturnUrl?: string;
    dateTo?: Date;
    salesType?: number;
    exactSalesCount?: number;
    productValues: IProductRow[];
    shopperInfoData: Array<IPaymentLinkShopperInfo>;
    hasExpirationDate?: boolean;
    totalAmount: number;
    permissions: Array<number>;
}

export interface CreatePaymentLinkModalProps {
    title?: string;
    actionButtonText?: string;
    data: {
        isUpdate: boolean;
        isCopy: boolean;
        paymentLinkId?: number | undefined;
    };
}

export const shopperInfoDataInitialValues: Array<IPaymentLinkShopperInfo> = [
    {
        label: StringResources.modal.createPaymentLink.ssn,
        field: ShopperInfoFieldEnum.Ssn,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.fullName,
        field: ShopperInfoFieldEnum.FullName,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.address,
        field: ShopperInfoFieldEnum.Address,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.postCode,
        field: ShopperInfoFieldEnum.PostCode,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.city,
        field: ShopperInfoFieldEnum.City,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.country,
        field: ShopperInfoFieldEnum.Country,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.telephone,
        field: ShopperInfoFieldEnum.Telephone,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.emailAddress,
        field: ShopperInfoFieldEnum.EmailAddress,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
    {
        label: StringResources.modal.createPaymentLink.comments,
        field: ShopperInfoFieldEnum.Comments,
        shopperInfoRequirementType: ShopperInfoRequirementType.Hidden,
    },
];

export enum CreatePaymentLinkSalesType {
    SingleSales = 1,
    UnlimitedSales = 2,
    ExactSales = 3,
}
