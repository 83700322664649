import { IconSVGProps } from "./types";

const SettlementsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.3406 6.03515C16.79 4.6582 15.2119 4.65312 14.6572 6.03515C11.9666 12.8437 12.0291 12.5156 12.0291 13.125C12.0291 14.8488 13.8181 16.25 16.0291 16.25C18.24 16.25 20.0009 14.8484 20.0009 13.125C19.9719 12.4961 20.0437 12.7852 17.3406 6.03515ZM13.7219 12.5L15.9706 6.875L18.2259 12.5H13.7219ZM2.65625 6.03515C-0.0624373 12.8437 5.97111e-05 12.5156 5.97111e-05 13.125C5.97111e-05 14.8488 1.78912 16.25 3.97193 16.25C6.15474 16.25 7.97193 14.8484 7.97193 13.125C7.97193 12.4953 8.0149 12.7832 5.31193 6.03515C4.79062 4.66015 3.2125 4.65234 2.65625 6.03515ZM1.74875 12.5L3.9975 6.875L6.25312 12.5H1.74875Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M15.4719 17.5H11V5.98828C11.7344 5.58594 12.2844 4.75781 12.45 3.75H16C16.5522 3.75 16.9719 3.19023 16.9719 2.5C16.9719 1.80977 16.5241 1.25 16 1.25H11.9875C11.5306 0.492969 10.8156 0 9.99938 0C9.18313 0 8.44063 0.492969 8.01251 1.25H4.00001C3.44688 1.25 3.00001 1.80977 3.00001 2.5C3.00001 3.19023 3.44688 3.75 3.97188 3.75H7.52188C7.71563 4.75781 8.26563 5.58594 8.97188 5.98828V17.5H4.50001C3.67157 17.5 2.99969 18.3395 2.99969 19.375C2.99969 19.7202 3.2236 20 3.49969 20H16.4997C16.7758 20 16.9997 19.7202 16.9997 19.375C17 18.3398 16.3281 17.5 15.4719 17.5Z"
            fill="#002649"
        />
    </svg>
);

export default SettlementsIcon;
