import { IconSVGProps } from "./types";

const OverviewIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            opacity="0.4"
            d="M7.08398 0H14.584V2.5C14.584 3.19141 15.1426 3.75 15.834 3.75H18.334V13.75C18.334 15.1289 17.2129 16.25 15.834 16.25H7.08398C5.70508 16.25 4.58398 15.1289 4.58398 13.75V2.5C4.58398 1.12109 5.70508 0 7.08398 0Z"
            fill="#002649"
        />
        <path
            d="M14.584 0V2.5C14.584 3.19141 15.1426 3.75 15.834 3.75H18.334L14.584 0ZM2.70898 4.6875C2.70898 4.16797 2.29102 3.75 1.77148 3.75C1.25195 3.75 0.833984 4.16797 0.833984 4.6875V14.6875C0.833984 17.6211 3.21289 20 6.14648 20H13.6465C14.166 20 14.584 19.582 14.584 19.0625C14.584 18.543 14.166 18.125 13.6465 18.125H6.14648C4.24805 18.125 2.70898 16.5859 2.70898 14.6875V4.6875Z"
            fill="#002649"
        />
    </svg>
);

export default OverviewIcon;
