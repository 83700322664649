import { IconSVGProps } from "./types";

const DocumentsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M19.8819 10.7743L16.5486 17.441C16.3611 17.8194 15.9757 18.0555 15.5243 18.0555H0L4.13889 9.78125C4.32639 9.40278 4.7118 9.16666 5.13194 9.16666H18.8889C19.7118 9.16666 20.25 10.0347 19.8819 10.7743Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M16.6667 6.38888V9.16666H5.13195C4.71181 9.16666 4.32639 9.40277 4.13889 9.78124L0 18.0555V4.16666C0 3.24618 0.746181 2.5 1.66667 2.5H7.22223L9.44445 4.72222H15C15.9201 4.72222 16.6667 5.46875 16.6667 6.38888Z"
            fill="#002649"
        />
    </svg>
);

export default DocumentsIcon;
