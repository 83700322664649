import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import ColorPicker from "components/colorPicker/colorPicker";
import { CopyToClipboardIcon, TrashIcon } from "components/icons";
import LogoUploader from "components/logoUploader/logoUploader";
import { useFormik } from "formik";
import { IGetEcomThemesResponseListData } from "pages/ecomThemes/api/ecomThemesModels";
import React, { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { LogoStatusEnum, ModalActionButtonTypeEnum, ModalTypeEnum } from "utils/enums";
import { getContentDispositionFilenameUtf8, getFile } from "utils/helperFunctions";
import { StringResources } from "utils/language/languageResource";
import * as Yup from "yup";
import variable from "../../../style/colors.module.scss";
import "./addNewEcomThemeModal.scss";

interface IAddNewEcomThemeForm {
    themeKey: string;
    name: string;
    description: string;
    colorPalette: string;
    logo: File | null;
    merchantId?: string;
}

interface IAddEcomThemesModalProps {
    data: IGetEcomThemesResponseListData;
}

const AddNewEcomThemeModal = (props: IAddEcomThemesModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const [fetchedLogo, setLogo] = useState<File | null>(null);
    const activeMerchant = useSelector((state: RootState) => state.user.activeMerchant);
    const ecomTheme = props.data;
    const [userRemovedLogo, setUserRemovedLogo] = useState(false);
    const [userUploadedLogo, setUserUploadLogo] = useState(false);

    if (ecomTheme && ecomTheme.logoPath) {
        useEffect(() => {
            const fetchEcomThemeLogo = async () => {
                try {
                    const response = await executeAxiosRequestWithRefresh({
                        url: `/api/ecom/fetch`,
                        responseType: "blob",
                        method: "GET",
                        params: {
                            themeKey: ecomTheme.themeKey,
                            documentType: getFileExtension(ecomTheme.logoPath),
                        },
                    });

                    const filename = getContentDispositionFilenameUtf8(response.headers);
                    const logoFile = getFile(response.data, filename, response.contentType!);
                    setLogo(logoFile);
                } catch (error: any) {}
            };

            fetchEcomThemeLogo();
        }, []);
    }

    const getFileExtension = (filePath: string | undefined) => {
        if (filePath) {
            const parts = filePath.split(".");
            return parts.length > 1 ? parts.pop() : "";
        }
    };

    const formValidation = useFormik({
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        validateOnMount: true,
        initialValues: {
            themeKey: ecomTheme?.themeKey ?? "",
            name: ecomTheme?.name ?? "",
            description: ecomTheme?.description ?? "",
            colorPalette: ecomTheme?.colorPalette ?? "",
            logo: fetchedLogo ?? null,
            merchantId: activeMerchant?.id,
        },
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t(StringResources.pages.ecomThemes.error.nameIsRequired)),
            description: Yup.string().optional(),
            colorPalette: Yup.string().required(t(StringResources.pages.ecomThemes.error.colorIsRequired)),
            logo: Yup.mixed().optional(),
        }),
        onSubmit: async (value: IAddNewEcomThemeForm) => {
            try {
                if (props.data) {
                    await executeAxiosRequestWithRefresh({
                        url: "/api/ecom/update",
                        method: "POST",
                        data: {
                            id: props.data?.id,
                            name: value.name,
                            description: value.description,
                            colorPalette: value.colorPalette,
                            logo: value.logo,
                            LogoStatus: userUploadedLogo
                                ? LogoStatusEnum.Upload
                                : userRemovedLogo
                                ? LogoStatusEnum.Remove
                                : LogoStatusEnum.NoChange,
                        },
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    });

                    dispatch(confirmModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
                    toast.success(t(StringResources.pages.ecomThemes.edit.successMsg).toString());
                    return;
                }

                await executeAxiosRequestWithRefresh({
                    url: "/api/ecom/create",
                    method: "POST",
                    data: {
                        name: value.name,
                        description: value.description,
                        colorPalette: value.colorPalette,
                        logo: value.logo,
                        merchantId: activeMerchant?.id,
                    },
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
                toast.success(t(StringResources.pages.ecomThemes.create.successMsg).toString());
            } catch (error: any) {
                toast.error(t(StringResources.pages.terminals.error.submitError).toString());
            }
        },
    });

    const copyToClipboard = async () => {
        try {
            navigator.clipboard.writeText(formValidation.values["themeKey"]);
            toast.success(t(StringResources.pages.ecomThemes.edit.copyToClipboard).toString());
        } catch (error: any) {
            toast.error(error.message);
        }
    };

    const handleColorChange = async (color: string) => {
        await formValidation.setFieldValue("colorPalette", color).then(() => {
            formValidation.validateForm();
        });
    };

    const handleLogoUpload = async (file: File) => {
        await formValidation.setFieldValue("logo", file);
        setUserUploadLogo(true);
        setUserRemovedLogo(false);
    };

    const handleLogoRemove = async () => {
        await formValidation.setFieldValue("logo", null);
        setUserUploadLogo(false);
        setUserRemovedLogo(true);
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const onDeleteClick = async () => {
        const modalData = await modalManagement.openModal({
            modalType: ModalTypeEnum.Confirm,
            props: {
                title: `${t(StringResources.pages.ecomThemes.delete.deleteTitle)}`,
                text: `${t(StringResources.pages.ecomThemes.delete.deleteMessage)}`,
                modalType: ModalTypeEnum.Confirm,
                modalActionButtonType: ModalActionButtonTypeEnum.Delete,
                actionButtonText: t(StringResources.modal.delete),
                closeButtonText: t(StringResources.modal.cancel),
            },
        });

        if (modalData !== null) {
            try {
                await executeAxiosRequestWithRefresh({
                    url: "/api/ecom/delete",
                    method: "POST",
                    data: {
                        ecomThemeId: props.data.id,
                    },
                });

                dispatch(confirmModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
                toast.success(t(StringResources.pages.ecomThemes.delete.successMsg).toString());
            } catch (error: any) {}
        }
    };

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.AddNewEcomTheme }));
    };

    const onOkClick = async () => {
        await formValidation.handleSubmit();
    };

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>
                        {!props.data
                            ? `${t(StringResources.pages.ecomThemes.create.title)}`
                            : `${t(StringResources.pages.ecomThemes.edit.editTheme)}`}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="add-new-ecom-theme__modal-body">
                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"themeKey"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.themeKey)}`}
                            value={formValidation.values["themeKey"]}
                            invalid={formValidation.touched["themeKey"] && formValidation.errors["themeKey"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                            disabled={true}
                        />

                        <button
                            type="button"
                            onClick={copyToClipboard}
                            className="add-new-ecom-theme__clipboard-button"
                        >
                            <CopyToClipboardIcon />
                        </button>
                    </Form.Group>

                    <div className="add-new-ecom-theme__general-theme-info">
                        <p className="add-new-ecom-theme__general-theme-info-title">{`${t(
                            StringResources.pages.ecomThemes.create.generalThemeInfo
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"name"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.name)}`}
                            value={formValidation.values["name"]}
                            invalid={formValidation.touched["name"] && formValidation.errors["name"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <BaseInput
                            type={"text"}
                            name={"description"}
                            autocomplete={"off"}
                            label={`${t(StringResources.pages.ecomThemes.create.description)}`}
                            value={formValidation.values["description"]}
                            invalid={formValidation.touched["description"] && formValidation.errors["description"]}
                            onBlur={formValidation.handleBlur}
                            onChange={formValidation.handleChange}
                        />
                    </Form.Group>

                    <div className="add-new-ecom-theme__design">
                        <p className="add-new-ecom-theme__design-title">{`${t(
                            StringResources.pages.ecomThemes.create.design
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <ColorPicker colorPalette={props.data?.colorPalette ?? ""} onColorChange={handleColorChange} />
                    </Form.Group>

                    <div className="add-new-ecom-theme__logo">
                        <p className="add-new-ecom-theme__logo-title">{`${t(
                            StringResources.pages.ecomThemes.create.logo
                        )}`}</p>
                    </div>

                    <Form.Group className="add-new-ecom-theme__form-row">
                        <div>
                            <LogoUploader
                                onLogoUpload={handleLogoUpload}
                                onLogoRemove={handleLogoRemove}
                                maxFileSize={50}
                                maxWidth={280}
                                maxHeigth={64}
                                uploadedLogo={fetchedLogo ?? undefined}
                            />
                        </div>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer className={classNames("add-new-ecom-theme__footer", { "modal-border-top": true })}>
                    {props.data && (
                        <BaseButton
                            handleClick={onDeleteClick}
                            text={`${t(StringResources.modal.delete)}`}
                            styleType="text"
                            danger={true}
                            leftIcon={<TrashIcon color={variable.colorRedAlpha} />}
                        />
                    )}
                    <div className="add-new-ecom-theme__footer-form-buttons">
                        <BaseButton
                            handleClick={onCancelClick}
                            text={`${t(StringResources.modal.close)}`}
                            styleType="line"
                        />

                        <BaseButton
                            handleClick={onOkClick}
                            text={
                                !props.data
                                    ? `${t(StringResources.pages.ecomThemes.create.create)}`
                                    : `${t(StringResources.pages.ecomThemes.edit.save)}`
                            }
                            styleType="solid"
                            disabled={!(formValidation.isValid && formValidation.dirty && !formValidation.isValidating)}
                        />
                    </div>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default AddNewEcomThemeModal;
