import "./baseDateRange.scss";

import BaseInput from "components/baseInput/baseInput";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { DateTime } from "luxon";
import { useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { SupportedLanguageEnum } from "utils/enums";
import i18n from "utils/language/languageClient";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import { convertLocalToUTCDate } from "utils/helperFunctions";

interface IBaseDateRangeProps {
    label?: string;
    disabled?: boolean;
    onDateChange: (startDate?: Date, endDate?: Date) => void;
    value?: Date;
    secondaryValue?: Date;
    popperPlacement?:
        | "top-start"
        | "top-end"
        | "bottom-start"
        | "bottom-end"
        | "right-start"
        | "right-end"
        | "left-start"
        | "left-end";
}

enum DateRangeAutofillEnum {
    Today = "today",
    Yesterday = "yesterday",
    LastWeek = "lastWeek",
    LastMonth = "lastMonth",
    ThisWeek = "thisWeek",
    ThisMonth = "thisMonth",
    ThisYear = "thisYear",
}

const BaseDateRange = (props: IBaseDateRangeProps) => {
    const { t } = useTranslation();
    const datePickerReference = useRef<any>(null);
    const minDate = "01/01/2020";
    const [focusSelectedMonth, setFocusSelectedMonth] = useState(false);

    const language = i18n.resolvedLanguage;

    const scrollToSelectedDate = () => {
        const datepickerDropdown = document.querySelector(".react-datepicker__month-year-dropdown");
        if (datepickerDropdown) {
            const scrollTo = datepickerDropdown.querySelector('[aria-selected="true"]');
            datepickerDropdown.scroll(0, scrollTo!.getBoundingClientRect().y - 200);
        }
    };

    const handleFocus = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.add("is-datepicker-active");
        }

        if (datePickerReference?.current?.state.open) {
            scrollToSelectedDate();
        }
    };

    const handleBlur = (e: any) => {
        const menuParentEl = e.target.closest(".special-menus.menu-open");
        if (menuParentEl) {
            menuParentEl.classList.remove("is-datepicker-active");
        }
    };

    const dateChangeHandler = (dates: any, event: any) => {
        const [start, end] = dates;
        props.onDateChange(convertLocalToUTCDate(start), convertLocalToUTCDate(end));
        setTimeout(() => setFocusSelectedMonth(true), 1);
    };

    const getPrefillDateValues = (dateRange: DateRangeAutofillEnum) => {
        switch (dateRange) {
            case DateRangeAutofillEnum.Today:
                return {
                    startDate: DateTime.local().startOf("day").toJSDate(),
                    endDate: DateTime.local().endOf("day").toJSDate(),
                };
            case DateRangeAutofillEnum.Yesterday:
                return {
                    startDate: DateTime.local().minus({ days: 1 }).startOf("day").toJSDate(),
                    endDate: DateTime.local().minus({ days: 1 }).endOf("day").toJSDate(),
                };
            case DateRangeAutofillEnum.LastWeek:
                return {
                    startDate: DateTime.local().startOf("week").minus({ weeks: 1 }).toJSDate(),
                    endDate: DateTime.local().endOf("week").minus({ weeks: 1 }).toJSDate(),
                };
            case DateRangeAutofillEnum.LastMonth:
                return {
                    startDate: DateTime.local().startOf("month").minus({ months: 1 }).toJSDate(),
                    endDate: DateTime.local().endOf("month").minus({ months: 1 }).toJSDate(),
                };
            case DateRangeAutofillEnum.ThisWeek:
                return {
                    startDate: DateTime.local().startOf("week").toJSDate(),
                    endDate: DateTime.local().endOf("week").toJSDate(),
                };
            case DateRangeAutofillEnum.ThisMonth:
                return {
                    startDate: DateTime.local().startOf("month").toJSDate(),
                    endDate: DateTime.local().endOf("month").toJSDate(),
                };
            case DateRangeAutofillEnum.ThisYear:
                return {
                    startDate: DateTime.local().startOf("year").toJSDate(),
                    endDate: DateTime.local().endOf("day").toJSDate(),
                };
            default:
                return {
                    startDate: DateTime.local().startOf("day").toJSDate(),
                    endDate: DateTime.local().endOf("day").toJSDate(),
                };
        }
    };

    // Function to get the start and end date of today
    function selectPrefillValues(dateRange: DateRangeAutofillEnum) {
        const { startDate, endDate } = getPrefillDateValues(dateRange);
        return (e: any) => {
            datePickerReference?.current?.setSelected(startDate, e);

            props.onDateChange(convertLocalToUTCDate(startDate), convertLocalToUTCDate(endDate));
        };
    }

    const isActiveDateRange = (dateRange: DateRangeAutofillEnum) => {
        const { startDate, endDate } = getPrefillDateValues(dateRange);

        function areDatesEqualIgnoringTime(date1?: Date, date2?: Date) {
            if (!date1 || !date2) {
                return false;
            }

            const luxonDate1 = DateTime.fromJSDate(date1).startOf("day");
            const luxonDate2 = DateTime.fromJSDate(date2).startOf("day");

            return luxonDate1.equals(luxonDate2);
        }

        return areDatesEqualIgnoringTime(startDate, props.value) &&
            areDatesEqualIgnoringTime(endDate, props.secondaryValue)
            ? "datepicker-range__prefill-item datepicker-range__prefill--active"
            : "datepicker-range__prefill-item";
    };

    return (
        <BaseInputWrapper
            label={props.label}
            value={
                props.value
                    ? `${props.value?.toString()}${props.secondaryValue ? `- ${props.secondaryValue?.toString()}` : ""}`
                    : undefined
            }
        >
            <label className="datepicker-range">
                <DatePicker
                    locale={language === SupportedLanguageEnum[SupportedLanguageEnum.Icelandic] ? "is" : "en"}
                    className={"form-control"}
                    showMonthDropdown
                    showYearDropdown
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    dateFormat="dd.MM.yyyy"
                    ref={datePickerReference}
                    calendarStartDay={1}
                    formatWeekDay={(nameOfDay: any) => nameOfDay.substring(0, 3)}
                    minDate={new Date(minDate)}
                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                    startDate={props.value}
                    endDate={props.secondaryValue}
                    onChange={dateChangeHandler}
                    isClearable={true}
                    selectsRange
                    monthsShown={2}
                    focusSelectedMonth={focusSelectedMonth}
                    disabledKeyboardNavigation={true}
                    showDisabledMonthNavigation={false}
                    peekNextMonth={false}
                    dropdownMode="select"
                    popperPlacement={props.popperPlacement ?? "bottom-start"}
                >
                    <div className="datepicker-range__prefill">
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.Today)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.Today)}
                        >
                            {`${t(StringResources.filter.today)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.Yesterday)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.Yesterday)}
                        >
                            {`${t(StringResources.filter.yesterday)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.ThisWeek)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.ThisWeek)}
                        >
                            {`${t(StringResources.filter.thisWeek)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.LastWeek)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.LastWeek)}
                        >
                            {`${t(StringResources.filter.lastWeek)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.ThisMonth)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.ThisMonth)}
                        >
                            {`${t(StringResources.filter.thisMonth)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.LastMonth)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.LastMonth)}
                        >
                            {`${t(StringResources.filter.lastMonth)}`}
                        </div>
                        <div
                            className={isActiveDateRange(DateRangeAutofillEnum.ThisYear)}
                            onClick={selectPrefillValues(DateRangeAutofillEnum.ThisYear)}
                        >
                            {`${t(StringResources.filter.thisYear)}`}
                        </div>
                    </div>
                    <div className="datepicker-range__absolute-inputs">
                        <BaseInput
                            value={props.value ? DateTime.fromJSDate(props.value).toFormat("dd.MM.yyyy") : undefined}
                            className="datepicker-range__absolute-inputs--active"
                        />
                        <BaseInput
                            value={
                                props.secondaryValue
                                    ? DateTime.fromJSDate(props.secondaryValue).toFormat("dd.MM.yyyy")
                                    : undefined
                            }
                        />
                    </div>
                </DatePicker>
            </label>
        </BaseInputWrapper>
    );
};

export default BaseDateRange;
