import { IconSVGProps } from "./types";

const ToolTipIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg 
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none" 
        xmlns="http://www.w3.org/2000/svg"
        >
        <g 
            opacity="0.6" 
            clipPath="url(#clip0_2465_1090)"
        >
            <path 
                d="M10 13.125C9.29688 13.125 8.75 13.6719 8.75 14.375C8.75 15.0781 9.26172 15.625 10 15.625C10.668 15.625 11.25 15.1133 11.25 14.375C11.25 13.6719 10.668 13.125 10 13.125ZM11.293 5H9.29688C7.73828 5 6.5625 6.17578 6.5625 7.73438C6.5625 8.20703 6.99219 8.67188 7.5 8.67188C8.00781 8.67188 8.4375 8.24219 8.4375 7.73438C8.4375 7.26562 8.79297 6.875 9.26172 6.875H11.2578C11.7617 6.875 12.1875 7.26562 12.1875 7.73438C12.1875 8.04688 12.0313 8.28516 11.7578 8.44141L9.53125 9.80469C9.21875 10 9.0625 10.3125 9.0625 10.625V11.25C9.0625 11.7578 9.49219 12.1875 10 12.1875C10.5078 12.1875 10.9375 11.7578 10.9375 11.25V11.1719L12.6992 10.0781C13.5195 9.57031 14.0273 8.67188 14.0273 7.73438C14.0625 6.17578 12.8164 5 11.293 5Z" 
                fill="#002649"
            />
            <path
                opacity="0.4" 
                d="M10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0ZM10 15.625C9.29688 15.625 8.75 15.0781 8.75 14.375C8.75 13.6719 9.26172 13.125 10 13.125C10.668 13.125 11.25 13.6719 11.25 14.375C11.25 15.0781 10.668 15.625 10 15.625ZM12.6992 10.0781L10.9375 11.1719V11.25C10.9375 11.7578 10.5078 12.1875 10 12.1875C9.49219 12.1875 9.0625 11.7578 9.0625 11.25V10.625C9.0625 10.3125 9.21875 10 9.53125 9.80469L11.7578 8.47656C12.0312 8.32031 12.1875 8.04688 12.1875 7.73438C12.1875 7.26562 11.7617 6.875 11.293 6.875H9.29688C8.79297 6.875 8.4375 7.26562 8.4375 7.73438C8.4375 8.24219 8.00781 8.67188 7.5 8.67188C6.99219 8.67188 6.5625 8.24219 6.5625 7.73438C6.5625 6.21094 7.77344 5 9.26172 5H11.2578C12.8516 5 14.0625 6.21094 14.0625 7.73438C14.0625 8.67188 13.5547 9.57031 12.6992 10.0781Z" 
                fill="#002649"
            />
        </g>
    </svg>
);


export default ToolTipIcon;