import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { baseApi } from "api/baseApi";
import { useDispatch } from "react-redux";
import { AnyAction, combineReducers } from "redux";
import authReducer from "redux/reducers/authReducer";
import dashboardReducer from "redux/reducers/dashboardReducer";
import globalReducer from "redux/reducers/globalReducer";
import modalReducer from "redux/reducers/modalReducer";
import reportReducer from "redux/reducers/reportReducer";
import userReducer from "redux/reducers/userReducer";
import { authMiddleware, loaderMiddleware } from "redux/storeActionMiddleware";
import kycReducer from "./reducers/kycReducer";
import reportDetailSectionReducer from "./reducers/reportDetailSectionReducer";
import announcementReducer from "./reducers/announcementReducer";

const rootReducer = (state: ReturnType<typeof appReducer> | undefined, action: AnyAction) => {
    if (action.type === "store/reset") {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

const appReducer = combineReducers({
    auth: authReducer,
    modal: modalReducer,
    report: reportReducer,
    user: userReducer,
    global: globalReducer,
    kyc: kycReducer,
    announcement: announcementReducer,
    dashboard: dashboardReducer,
    reportDetailSection: reportDetailSectionReducer,
    [baseApi.reducerPath]: baseApi.reducer,
});

const store = configureStore({
    reducer: rootReducer,
    middleware: () =>
        getDefaultMiddleware().prepend(loaderMiddleware).concat(authMiddleware).concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
