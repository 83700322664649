// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"colorCosmosBlueAlpha": "#f5f8fa",
	"colorGrayBeta": "#fafafa",
	"colorBlueGamma": "#eff8fa",
	"colorPrimaryAlpha": "#002649",
	"colorCosmosBlueDelta": "#cdd8e2",
	"colorSecondaryDelta": "#262b31",
	"colorGrayEpsilon": "#e7e7e7",
	"colorWhite": "#fff",
	"colorTransparent": "\"transparent\"",
	"colorRedAlpha": "#d03e00",
	"colorRedBeta": "#ffede5",
	"colorBlueAlpha": "#08576f",
	"colorBlueBeta": "#bce6f3",
	"colorBlueEpsilon": "#e2f9fe",
	"colorYellowAlpha": "#fff7db",
	"colorYellowBeta": "#dfae00",
	"colorYellowGamma": "#775d00"
};
export default ___CSS_LOADER_EXPORT___;
