import React from "react";
import "./tableCellHighlight.scss";
import { ILookupResponse } from "utils/models";
import classNames from "classnames";

interface ITableHighlightDefaultProps {
    value: ILookupResponse<number>;
    conditions: Array<ITableHighlightCondition>;
}

interface ITableHighlightCondition {
    condition: (value: number) => boolean;
    className: string;
}

const TableCellHighlight = (props: ITableHighlightDefaultProps) => {
    const { value } = props;

    if (value === null || value === undefined || value.value === null || value.value === undefined) {
        return "-";
    }

    return (
        <div
            className={classNames(
                "table-cell-highlight",
                props.conditions.map(x => (x.condition(value.value) ? x.className : null))
            )}
        >
            {value.name}
        </div>
    );
};

export default TableCellHighlight;
