import { FC } from "react";
import Select, {
    components,
    GroupBase,
    GroupHeadingProps,
    OnChangeValue,
    OptionProps,
    StylesConfig,
} from "react-select";
import "./baseMultiselect.scss";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";
import { AngleIcon } from "components/icons";
import variables from "../../style/colors.module.scss";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import BaseButton from "components/baseButton/baseButton";

export interface Option {
    value: number;
    label: string;
    selected: boolean;
}

interface GroupedOption {
    label: string;
    showSelectAll?: boolean;
    options: Array<Option>;
}

interface IBaseMultiselectProps {
    options: Array<Option>;
    label?: React.ReactNode;
    disabled?: boolean;
    showSelectAll?: boolean;
    showClearAll?: boolean;
    onChange: (selectedItems: Option[]) => void;
}

const BaseMultiselect: FC<IBaseMultiselectProps> = ({
    label,
    disabled,
    options,
    onChange,
    showSelectAll = false,
    showClearAll = false,
}) => {
    const { t } = useTranslation();

    const handleChange = (selectedItems: OnChangeValue<Option, boolean>) => {
        // we are casting to Option[] because we will always use this component as isMulti so it will return array of selected options
        onChange(selectedItems as Option[]);
    };

    // Override to show checkboxes in dropdown menu
    const Option = (props: OptionProps<Option, boolean>) => {
        return (
            <div>
                <components.Option {...props}>
                    <input type="checkbox" checked={props.isSelected} onChange={() => {}} />{" "}
                    <label className="option-label">{props.label} </label>
                </components.Option>
            </div>
        );
    };

    // Override to show button in group header menu to select all
    const OptionHeader = (props: GroupHeadingProps<Option>) => {
        return (
            <div className="group-option" style={{ display: "flex", justifyContent: "space-between" }}>
                <components.GroupHeading {...props} />
                {props.data.label === t(StringResources.multiselect.selected) && showClearAll && (
                    <BaseButton
                        styleType="line"
                        handleClick={() => handleChange([])}
                        text={`${t(StringResources.multiselect.clearAll)}`}
                        size="small"
                    ></BaseButton>
                )}
                {props.data.label === t(StringResources.multiselect.options) && showSelectAll && (
                    <BaseButton
                        styleType="line"
                        handleClick={() => handleChange(options)}
                        text={`${t(StringResources.multiselect.selectAll)}`}
                        size="small"
                    ></BaseButton>
                )}
            </div>
        );
    };

    const groupedOptions: Array<GroupedOption> = [
        {
            label: t(StringResources.multiselect.selected),
            options: options
                .filter(x => x.selected)
                .map(x => ({ label: x.label, value: x.value, selected: x.selected })),
        },
        {
            label: t(StringResources.multiselect.options),
            options: options
                .filter(x => !x.selected)
                .map(x => ({ label: x.label, value: x.value, selected: x.selected })),
        },
    ];

    return (
        <BaseInputWrapper label={label} value={options.filter(x => x.selected).map(x => x.label)}>
            <div className="multiselect-wrapper">
                <Select
                    isDisabled={disabled}
                    styles={customStyles}
                    className="form-control"
                    closeMenuOnSelect={false}
                    isMulti={true}
                    options={groupedOptions}
                    placeholder={""}
                    onChange={handleChange}
                    hideSelectedOptions={false}
                    value={options.filter(x => x.selected)}
                    components={{
                        GroupHeading: OptionHeader,
                        Option: (props: OptionProps<Option, boolean>) => <Option {...props} />,
                        IndicatorSeparator: () => null,
                        DropdownIndicator: () => <AngleIcon className="base-dropdown__toggle-action-icon" />,
                    }}
                    isClearable={false}
                />
            </div>
        </BaseInputWrapper>
    );
};

// programatically style for react-select component
const customStyles: StylesConfig<Option, boolean> = {
    control: (styles, state) => {
        return {
            ...styles,
            backgroundColor: state.isDisabled ? variables.colorGrayEpsilon : variables.colorTransparent,
            border: "0",
            boxShadow: "none",
            height: "100%",
            padding: "7px",
        };
    },
    option: (styles, state) => {
        return {
            ...styles,
            display: "flex",
            cursor: state.isDisabled ? "not-allowed" : "default",
            marginTop: "4px",
            marginBottom: "4px",
            padding: "12px 16px",
            fontSize: "1rem",
            lineHeight: 1.5,
            borderRadius: "4px",
            backgroundColor: state.isDisabled
                ? undefined
                : state.isSelected
                ? variables.colorBlueGamma
                : state.isFocused
                ? variables.colorGrayBeta
                : undefined,
            color: state.isDisabled ? undefined : state.isSelected ? variables.colorSecondaryDelta : undefined,
            ":active": {
                ...styles[":active"],
                backgroundColor: variables.colorGrayBeta,
            },
        };
    },
    group: styles => ({
        ...styles,
        paddingBottom: "0px",
    }),
    groupHeading: styles => ({
        ...styles,
        color: variables.colorPrimaryAlpha,
        textTransform: "capitalize",
        fontSize: "0.8125rem",
    }),
    menuList: styles => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
        marginLeft: "4px",
        marginRight: "4px",
    }),
    multiValue: styles => {
        return {
            ...styles,
            backgroundColor: variables.colorWhite,
            border: "solid",
            borderWidth: "1px",
            borderColor: variables.colorCosmosBlueDelta,
            borderRadius: "4px",
        };
    },
    multiValueLabel: styles => ({
        ...styles,
        color: variables.colorSecondaryDelta,
    }),
    multiValueRemove: styles => ({
        ...styles,
        ":hover": {
            backgroundColor: variables.colorWhite,
            color: variables.colorSecondaryDelta,
        },
    }),
};

export default BaseMultiselect;
