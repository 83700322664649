import { createAsyncThunk } from "@reduxjs/toolkit";
import { IDismissAnnouncementRequest, IDismissAnnouncementResponse } from "redux/models/announcementModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

const baseAnnouncementsRoute = "/api/announcements";

export const getUnreadAnnouncements = createAsyncThunk("announcement/getIUnreadAnnouncements", async () => {
    return await executeAxiosRequestWithRefresh({
        url: `${baseAnnouncementsRoute}/get-unread`,
        method: "GET",
    });
});

export const dismissAnnouncement = createAsyncThunk<IDismissAnnouncementResponse, IDismissAnnouncementRequest>(
    "announcement/dismissAnnouncement",
    async request => {
        await executeAxiosRequestWithRefresh({
            url: `${baseAnnouncementsRoute}/dismiss`,
            method: "POST",
            data: request,
        });

        return { announcementId: request.announcementId };
    }
);
