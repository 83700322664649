import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseInput from "components/baseInput/baseInput";
import BaseModal, { BaseModalProps } from "components/baseModal/baseModal";
import { useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { declineModal, setModalData } from "redux/reducers/modalReducer";
import { useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";

import "./setSalesTargetModal.scss";

const SetSalesTargetModal = (props: BaseModalProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [salesTargetAmount, setsaleTargetAmount] = useState(0);

    useEffect(() => {
        dispatch(
            setModalData({
                modalType: ModalTypeEnum.SetSalesTargetModal,
                data: { salesTargetAmount: salesTargetAmount },
            })
        );
    }, [salesTargetAmount]);


    const handleChangeSalesTargetAmountEvent = (event: any) => {
        setsaleTargetAmount(event.target.value);
    };

    return (
        <BaseModal
            title={t(StringResources.pages.dashboard.setSalesTarget)}
            modalType={ModalTypeEnum.SetSalesTargetModal}
            actionButtonText={t(StringResources.modal.set)}
            closeButtonText={t(StringResources.modal.cancel)}
        >
            <BaseInput
                type={"number"}
                name={"number"}
                autocomplete={"off"}
                onChange={evt => handleChangeSalesTargetAmountEvent(evt)}
                label={`${t(StringResources.pages.dashboard.targetAmount)}`}
                value={salesTargetAmount}
            />
        </BaseModal>
    );
};

export default SetSalesTargetModal;