import classNames from "classnames";
import { ExclamationIcon, InfoIcon, WarningIcon } from "components/icons";
import variables from "../../style/colors.module.scss";
import "./baseBanner.scss";

interface IBaseBannerProps {
    text: string;
    type?: "info" | "warning" | "error";
    title?: string;
    actions?: {
        label: string;
        onClick: () => void;
    }[];
    className?: string;
}

const BaseBanner = (props: IBaseBannerProps) => {
    const toastType = props.type || "info";

    return (
        <div
            className={classNames("base-banner", {
                [props.className || ""]: true,
            })}
        >
            <div
                className={classNames("base-banner__toast", {
                    "base-banner__toast--info": toastType === "info",
                    "base-banner__toast--warning": toastType === "warning",
                    "base-banner__toast--error": toastType === "error",
                })}
            >
                {toastType === "info" ? (
                    <InfoIcon color={variables.colorPrimaryAlpha} />
                ) : toastType === "warning" ? (
                    <WarningIcon width={24} height={24} color={variables.colorYellowBeta} />
                ) : toastType === "error" ? (
                    <ExclamationIcon width={24} height={24} color={variables.colorRedAlpha} />
                ) : null}
                <div className="base-banner__body">
                    {props.title && <div className="base-banner__title">{props.title}</div>}
                    <div className="base-banner__text">{props.text}</div>
                    {props.actions && props.actions.length > 0 && (
                        <div className="base-banner__actions">
                            {props.actions.map((action, index) => {
                                return (
                                    <div key={index} className="base-banner__action" onClick={action.onClick}>
                                        {action.label}
                                    </div>
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BaseBanner;
