import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import {
    IGetPaymentsDetailsReportRequest,
    IGetPaymentsDetailsReportResponse,
    IPaymentModels,
} from "pages/payments/api/paymentsModels";
import { executeAxiosRequestWithRefresh } from "redux/services";
import { getContentDispositionFilenameUtf8 } from "utils/helperFunctions";

const basePaymentsRoute = "/api/payments";
export const paymentsExportRoute = `${basePaymentsRoute}/report`;

const paymentsApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getPayments: builder.query<IPaymentModels, IQueryParams>({
            query: standardQueryTableRequest<IPaymentModels>(basePaymentsRoute),
        }),
    }),
    overrideExisting: false,
});

export const { useGetPaymentsQuery, endpoints } = paymentsApi;

export const getPaymentsDetailsReport = async (
    request: IGetPaymentsDetailsReportRequest
): Promise<IGetPaymentsDetailsReportResponse> => {
    const response = await executeAxiosRequestWithRefresh({
        url: `${basePaymentsRoute}/details-report`,
        disableErrorToast: true,
        responseType: "blob",
        method: "GET",
        params: request,
    });

    var filename = getContentDispositionFilenameUtf8(response.headers);

    return {
        data: response.data,
        filename: filename,
        contentType: response.data.type,
    };
};
