import { IconSVGProps } from "./types";

const EditUserIcon = ({ height = 16, width = 16, color }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M9.1596 12.34C9.09559 12.404 9.05197 12.4855 9.03422 12.5744L8.67058 14.3931C8.63855 14.5533 8.7799 14.6946 8.94025 14.6628L10.7589 14.2989C10.8476 14.281 10.9291 14.2376 10.9931 14.1735L14.1838 10.9823L12.3501 9.14827L9.1596 12.34ZM15.732 8.1402L15.1947 7.60255C14.8366 7.24446 14.2563 7.24446 13.8983 7.60255L12.9273 8.57393L14.761 10.4079L15.732 9.43681C16.0885 9.07744 16.0885 8.49676 15.732 8.1402Z"
            fill={color}
        />
        <path
            opacity="0.4"
            d="M5.65588 8.33333C7.44822 8.33333 8.90085 6.91551 8.90085 5.16667C8.90085 3.41783 7.44822 2 5.65588 2C3.88636 2 2.43373 3.41783 2.43373 5.16667C2.43373 6.91551 3.88636 8.33333 5.65588 8.33333ZM6.96401 9.52083H4.39338C1.96752 9.52083 0 11.4431 0 13.8107C0 14.2832 0.393452 14.6667 0.878677 14.6667H8.13929C8.10771 14.5298 8.10304 14.3869 8.13212 14.2451L8.49413 12.4785C8.54339 12.2379 8.66338 12.0193 8.84094 11.8461L10 10.715C9.21013 9.97604 8.14284 9.52083 6.96401 9.52083Z"
            fill={color}
        />
    </svg>
);

export default EditUserIcon;
