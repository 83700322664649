import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { DetailHeaderEnum } from "utils/enums";
import "./reportDetailsHeader.scss";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";
import EmployeeHeader from "pages/employees/details/employeeHeader";
import { IReportDetailsDefinition } from "api/requestModels";
import TransactionsHeader from "pages/transactions/details/transactionsHeader";
import ElectronicDocumentsHeader from "pages/electronicDocuments/details/electronicDocumentsHeader";
import AuthorizationsHeader from "pages/authorizations/details/authorizationsHeader";
import SettlementsHeader from "pages/settlements/details/settlementsHeader";
import PaymentsHeader from "pages/payments/details/paymentsHeader";

interface IReportDetailsHeaderProps {
    detailsDefinitions: IReportDetailsDefinition<any>;
}

const ReportDetailsHeader = ({ detailsDefinitions }: IReportDetailsHeaderProps) => {
    return <ReportDetailsHeaderHandler header={detailsDefinitions.header || DetailHeaderEnum.Default} />;
};

interface IReportDetailsHeaderHandler {
    header: DetailHeaderEnum;
}

const ReportDetailsHeaderHandler = (props: IReportDetailsHeaderHandler) => {
    const pageType = useSelector((state: RootState) => state.report.pageType);
    const { t } = useTranslation();

    switch (props.header) {
        case DetailHeaderEnum.Settlements:
            return <SettlementsHeader />;
        case DetailHeaderEnum.ElectronicDocuments:
            return <ElectronicDocumentsHeader />;
        case DetailHeaderEnum.Employees:
            return <EmployeeHeader />;
        case DetailHeaderEnum.Transactions:
            return <TransactionsHeader />;
        case DetailHeaderEnum.Authorizations:
            return <AuthorizationsHeader />;
        case DetailHeaderEnum.Default:
            return (
                <div className="report-details__title">
                    <div className="report-details__title--label">{`${t(StringResources.pages[pageType].title)}`}</div>
                    <div className="report-details__title--action">
                        <>{/* {t(StringResources.report.print)} <DownloadIcon /> */}</>
                    </div>
                </div>
            );
        case DetailHeaderEnum.Payments:
            return <PaymentsHeader />;
        case DetailHeaderEnum.None:
            return <></>;
    }
};

export default ReportDetailsHeader;
