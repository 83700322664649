import { IconSVGProps } from "./types";

const EcomThemesIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16.902 8.15234L11.8239 3.07422L2.28872 12.6133C1.88247 13.0195 1.58559 13.5234 1.42153 14.0742L0.0387177 18.7773C-0.0589386 19.1055 0.0309051 19.4609 0.276999 19.7031C0.523093 19.9453 0.874655 20.0352 1.20278 19.9414L5.902 18.5586C6.45278 18.3945 6.95669 18.0977 7.36294 17.6914L16.902 8.15234ZM3.48794 14.082L4.37466 13.7266V14.9766C4.37466 15.3203 4.65591 15.6016 4.99966 15.6016H6.24965L5.89419 16.4883C5.73794 16.6094 5.56216 16.6992 5.37466 16.7578L2.31997 17.6562L3.21841 14.6055C3.27309 14.4141 3.36684 14.2383 3.48794 14.0859V14.082ZM12.3161 6.41016C12.5582 6.65234 12.5582 7.05078 12.3161 7.29297L6.69106 12.918C6.44887 13.1602 6.05044 13.1602 5.80825 12.918C5.56606 12.6758 5.56606 12.2773 5.80825 12.0352L11.4332 6.41016C11.6754 6.16797 12.0739 6.16797 12.3161 6.41016Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M19.248 2.27344C20.2246 3.25 20.2246 4.83203 19.248 5.80859L16.9043 8.15234L11.8262 3.07422L14.1699 0.730469C15.1465 -0.246094 16.7285 -0.246094 17.7051 0.730469L19.2441 2.26953L19.248 2.27344ZM12.3184 7.29297L6.69336 12.918C6.45117 13.1602 6.05273 13.1602 5.81055 12.918C5.56836 12.6758 5.56836 12.2773 5.81055 12.0352L11.4355 6.41016C11.6777 6.16797 12.0762 6.16797 12.3184 6.41016C12.5605 6.65234 12.5605 7.05078 12.3184 7.29297Z"
            fill="#002649"
        />
    </svg>
);

export default EcomThemesIcon;
