import { IconSVGProps } from "./types";

const SearchIcon = ({ height = 16, width = 16, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g opacity="0.6" clipPath="url(#clip0_1129_2058)">
            <path
                d="M15.6344 13.8656L11.8938 10.125C11.4241 10.8219 10.8232 11.4231 10.126 11.8928L13.8666 15.6334C14.3547 16.1216 15.1463 16.1216 15.6344 15.6334C16.1219 15.1469 16.1219 14.3531 15.6344 13.8656Z"
                fill="#002649"
            />
            <path
                opacity="0.4"
                d="M6.47188 0C2.91001 0 0 2.91031 0 6.5C0 10.0897 2.91001 13 6.47188 13C10.0338 13 12.9719 10.0897 12.9719 6.5C12.9719 2.91031 10.0906 0 6.47188 0ZM6.47188 10.5C4.26626 10.5 2.47188 8.70563 2.47188 6.5C2.47188 4.29437 4.26626 2.5 6.47188 2.5C8.67751 2.5 10.4719 4.29437 10.4719 6.5C10.4719 8.70625 8.70626 10.5 6.47188 10.5Z"
                fill="#002649"
            />
        </g>
    </svg>
);

export default SearchIcon;
