import { IconSVGProps } from "./types";

const FilterClearIcon = ({ height = 24, width = 24, className, fill, style }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        style={style}
        fill={fill ?? "none"}
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_185_4098)">
            <path
                d="M0.162511 1.95417C0.437511 1.37083 1.02084 1 1.66668 1H19.6667C20.3125 1 20.8958 1.37083 21.1708 1.95417C21.4458 2.5375 21.3625 3.225 20.9542 3.725L16.5167 9.15C13.175 9.8375 10.6667 12.7917 10.6667 16.3333C10.6667 17.475 10.9292 18.5583 11.3958 19.5208C11.3292 19.4875 11.2625 19.4458 11.2 19.4L8.53334 17.4C8.19584 17.15 8.00001 16.7542 8.00001 16.3333V13.0375L0.375011 3.72083C-0.0291555 3.225 -0.116656 2.53333 0.162511 1.95417ZM18 22.3333C14.6875 22.3333 12 19.6458 12 16.3333C12 13.0208 14.6875 10.3333 18 10.3333C21.3125 10.3333 24 13.0208 24 16.3333C24 19.6458 21.3125 22.3333 18 22.3333ZM20.4708 14.8042C20.7292 14.5458 20.7292 14.1208 20.4708 13.8625C20.2125 13.6042 19.7875 13.6042 19.5292 13.8625L18 15.3917L16.4708 13.8625C16.2125 13.6042 15.7875 13.6042 15.5292 13.8625C15.2708 14.1208 15.2708 14.5458 15.5292 14.8042L17.0583 16.3333L15.5292 17.8625C15.2708 18.1208 15.2708 18.5458 15.5292 18.8042C15.7875 19.0625 16.2125 19.0625 16.4708 18.8042L18 17.275L19.5292 18.8042C19.7875 19.0625 20.2125 19.0625 20.4708 18.8042C20.7292 18.5458 20.7292 18.1208 20.4708 17.8625L18.9417 16.3333L20.4708 14.8042Z"
                fill="#002649"
            />
        </g>
    </svg>
);

export default FilterClearIcon;
