import { baseApi, standardQueryTableRequest } from "api/baseApi";
import { IQueryParams } from "api/requestModels";
import { IEmployeeModel } from "./employeeModels";

export const baseEmployeesRoute = "/api/employees";

const employeesApi = baseApi.injectEndpoints({
    endpoints: builder => ({
        getEmployees: builder.query<IEmployeeModel, IQueryParams>({
            query: standardQueryTableRequest<IEmployeeModel>(baseEmployeesRoute),
        }),
    }),
    overrideExisting: false,
});

export const { useGetEmployeesQuery, endpoints } = employeesApi;
