import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getLastPendingDispute, getFilterTypesThunk } from "redux/actions/globalActions";
import { getContractData } from "redux/actions/reportAction";
import { IContractData } from "redux/models/dashboardModels";
import { IGetLastPendingDisputeResponse, IGlobalReducerState } from "redux/models/globalModels";
import { PayloadData } from "redux/models/userModels";
import { ILookupResponse } from "utils/models";

const initialState: IGlobalReducerState = {
    lastPendingDispute: undefined,
    filterTypes: {
        agreementNumbers: [],
        cardIssuers: [],
        cardTypes: [],
        currencyIds: [],
        settlementTypes: [],
        userStatuses: [],
        employeePermissionTypes: [],
        cardEntryModeTypes: [],
        authenticationMethodTypes: [],
        authorizationMethodTypes: [],
        contractCurrencyIds: [],
        statementNotificationTypes: [],
        disputeType: [],
        contractStatuses: [],
        documentTypes: [],
        payByLinkStatuses: [],
    },
};

const reportReducer = createSlice({
    name: "global",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getFilterTypesThunk.fulfilled, (state, action) => {
            state.filterTypes = {
                ...state.filterTypes,
                ...action.payload,
            };
        });
        builder.addCase(
            getContractData.fulfilled,
            (state, action: PayloadAction<PayloadData<Array<IContractData>>>) => {
                const lookupData: ILookupResponse<string>[] = action.payload.data.map(x => {
                    const nonEmptyNames = x.dbaNames.filter(y => y.trim().length > 0);
                    const dbaName =
                        nonEmptyNames.length > 0
                            ? nonEmptyNames.length > 1
                                ? `${nonEmptyNames[0]} (+${x.dbaNames.length - 1}) `
                                : nonEmptyNames[0]
                            : undefined;
                    return {
                        value: x.contractNumber,
                        name: `${x.contractNumber} (${x.currencyId})${dbaName ? ` - ${dbaName}` : ""}`,
                    };
                });

                state.filterTypes = { ...state.filterTypes, contractCurrencyIds: lookupData };
            }
        );
        builder.addCase(getLastPendingDispute.pending, state => {});
        builder.addCase(
            getLastPendingDispute.fulfilled,
            (state, action: PayloadAction<IGetLastPendingDisputeResponse>) => {
                state.lastPendingDispute = action.payload.lastCreatedDate;
            }
        );
        builder.addCase(getLastPendingDispute.rejected, state => {
            state.lastPendingDispute = undefined;
        });
    },
});

export const {} = reportReducer.actions;
const { reducer } = reportReducer;
export default reducer;
