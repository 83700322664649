import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    IAcceptRefundTransactionRequest,
    IStartRefundTransactionRequest,
    IStartRefundTransactionResponse,
} from "redux/models/transactionModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const startTransactionRefund = createAsyncThunk<IStartRefundTransactionResponse, IStartRefundTransactionRequest>(
    "transactions/start-refund",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/transactions/start-refund",
            method: "POST",
            data: request,
        });

        return response.data;
    }
);

export const acceptTransactionRefund = createAsyncThunk<any, IAcceptRefundTransactionRequest>(
    "transactions/accept-refund",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: "/api/transactions/accept-refund",
            method: "POST",
            data: request,
        });

        return response.data;
    }
);
