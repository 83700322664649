import { IconSVGProps } from "./types";

const UsersIcon = ({ height = 20, width = 20, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12.3972 11.875H8.43622C4.69833 11.875 1.66669 14.9062 1.66669 18.6445C1.66669 19.3922 2.27294 19.9988 3.02059 19.9988H17.8136C18.5612 20 19.1667 19.3945 19.1667 18.6445C19.1667 14.9062 16.1354 11.875 12.3972 11.875Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M15 5C15 7.76133 12.7617 10 10 10C7.23828 10 5 7.76172 5 5C5 2.23828 7.23867 0 10 0C12.7617 0 15 2.23867 15 5Z"
            fill="#002649"
        />
    </svg>
);

export default UsersIcon;
