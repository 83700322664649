import { IconSVGProps } from "./types";

const GripIcon = ({ height = 24, width = 24, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17 8.25C17 9.49219 18.0078 10.5 19.25 10.5C20.4922 10.5 21.5 9.49219 21.5 8.25C21.5 7.00781 20.4922 6 19.25 6C18.0078 6 17 7.00781 17 8.25ZM9.5 8.25C9.5 9.49219 10.5078 10.5 11.75 10.5C12.9922 10.5 14 9.49219 14 8.25C14 7.00781 12.9922 6 11.75 6C10.5078 6 9.5 7.00781 9.5 8.25ZM4.25 10.5C5.49219 10.5 6.5 9.49219 6.5 8.25C6.5 7.00781 5.49219 6 4.25 6C3.00781 6 2 7.00781 2 8.25C2 9.49219 3.00781 10.5 4.25 10.5ZM17 15.75C17 16.9922 18.0078 18 19.25 18C20.4922 18 21.5 16.9922 21.5 15.75C21.5 14.5078 20.4922 13.5 19.25 13.5C18.0078 13.5 17 14.5078 17 15.75ZM11.75 18C12.9922 18 14 16.9922 14 15.75C14 14.5078 12.9922 13.5 11.75 13.5C10.5078 13.5 9.5 14.5078 9.5 15.75C9.5 16.9922 10.5078 18 11.75 18ZM2 15.75C2 16.9922 3.00781 18 4.25 18C5.49219 18 6.5 16.9922 6.5 15.75C6.5 14.5078 5.49219 13.5 4.25 13.5C3.00781 13.5 2 14.5078 2 15.75Z"
            fill="#002649"
        />
    </svg>
);

export default GripIcon;
