import BaseCurrencyInputATM from "components/baseCurrencyInput/baseCurrencyInputATM";
import BaseInput from "components/baseInput/baseInput";
import CancelInputIcon from "components/icons/cancelInputIcon";
import PlussAddIcon from "components/icons/plusAddIcon";
import { IProductRow } from "containers/modalContainers/createPaymentLinkModal/createPaymentLinkModel";
import { t } from "i18next";
import { StringResources } from "utils/language/languageResource";

interface IProductsRowProps extends IProductRow {
    isLast: boolean;
    onDeleteRow?: () => void;
    onAddRow?: () => void;
    onChange: (propName: keyof IProductRow, e: React.ChangeEvent<HTMLInputElement>) => void;
    onNumberValueChange: (propName: keyof IProductRow, value: number) => void;
    onBlur: () => void;
    checkInvalid: (propName: keyof IProductRow, value: string | number | undefined) => string | boolean | undefined;
}

const CreatePaymentLinkModalProductRow = (props: IProductsRowProps) => {
    return (
        <>
            <div className="create-payment-link__products-row-input">
                <BaseInput
                    type="text"
                    name="productValues.description"
                    value={props.description}
                    onBlur={props.onBlur}
                    onChange={e => props.onChange("description", e)}
                    label={`${t(StringResources.modal.createPaymentLink.productsDescription)}`}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseInput
                    type="number"
                    name="productValues.quantity"
                    value={props.quantity}
                    onBlur={props.onBlur}
                    onChange={e => props.onChange("quantity", e)}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency={props.currencyId}
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.unitPrice"
                    value={props.unitPrice}
                    onValueChange={e => props.onNumberValueChange("unitPrice", e)}
                    onBlur={props.onBlur}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency={props.currencyId}
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.amountWithoutDiscount"
                    value={props.amountWithoutDiscount}
                    onValueChange={() => {
                        return;
                    }}
                    disabled={true}
                />
            </div>
            <div className="create-payment-link__products-row-input">
                <BaseCurrencyInputATM
                    currency={props.currencyId}
                    hideCurrency={true}
                    hideSymbol={true}
                    name="productValues.amount"
                    value={props.amount}
                    onValueChange={e => props.onNumberValueChange("amount", e)}
                    onBlur={props.onBlur}
                />
            </div>
            {!props.isLast ? (
                <div
                    className="create-payment-link__products-row-input--small create-payment-link__products-row-input--clickable"
                    onClick={props.onDeleteRow}
                >
                    <CancelInputIcon />
                </div>
            ) : (
                <div
                    className="create-payment-link__products-row-input--small create-payment-link__products-row-input--clickable"
                    onClick={props.onAddRow}
                >
                    <PlussAddIcon />
                </div>
            )}
        </>
    );
};

export default CreatePaymentLinkModalProductRow;
