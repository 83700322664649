import AgreementsQuickLinks from "pages/agreements/details/agreementsQuickLinks";
import SettlementsBreakdown from "pages/settlements/details/settlementsBreakdown";
import PaymentSettlementsBreakdown from "pages/payments/details/paymentsSettlementsBreakdown";
import { DetailSectionEnum } from "utils/enums";
import "./reportDetailsSections.scss";
import SettlementsBatches from "pages/settlements/details/settlementsBatches";
import EmployeePermissions from "pages/employees/details/employeePermissions";
import { IReportDetailsDefinition } from "api/requestModels";
import EmployeeApiKey from "pages/employees/details/employeeApiKey";
import { Fragment } from "react";
import TransactionRefundBreakdown from "pages/transactions/details/transactionRefundBreakdown";
import BatchDetails from "pages/batches/details/batchDetails";

interface IReportDetailsSectionsProps {
    detailsDefinitions: IReportDetailsDefinition<any>;
}

const ReportDetailsSections = ({ detailsDefinitions }: IReportDetailsSectionsProps) => {
    if (!detailsDefinitions.sections) {
        return <></>;
    }

    return (
        <>
            {detailsDefinitions.sections.map((detailSection, index) => (
                <Fragment key={index}>
                    {detailsDefinitions.sections!.length > 1 && index + 1 === detailsDefinitions.sections!.length && (
                        <div className="reportDetailsSections-separator"></div>
                    )}
                    <ReportDetailsSectionHandler section={detailSection} />
                </Fragment>
            ))}
        </>
    );
};

interface IReportDetailsSectionHandler {
    section: DetailSectionEnum;
}

const ReportDetailsSectionHandler = (props: IReportDetailsSectionHandler) => {
    switch (props.section) {
        case DetailSectionEnum.BatchDetails:
            return <BatchDetails />;
        case DetailSectionEnum.AgreementsQuickLinks:
            return <AgreementsQuickLinks />;
        case DetailSectionEnum.SettlementBreakdown:
            return <SettlementsBreakdown />;
        case DetailSectionEnum.PaymentsSettlementBreakdown:
            return <PaymentSettlementsBreakdown />;
        case DetailSectionEnum.SettlementBeaches:
            return <SettlementsBatches />;
        case DetailSectionEnum.EmployeeApiKey:
            return <EmployeeApiKey />;
        case DetailSectionEnum.EmployeePermissions:
            return <EmployeePermissions />;
        case DetailSectionEnum.TransactionRefundBreakdown:
            return <TransactionRefundBreakdown />;
    }
};

export default ReportDetailsSections;
