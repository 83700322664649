import { IconSVGProps } from "./types";

const PaymentsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1.68958 4.11083C4.45833 4.85492 7.22917 4.16073 10 3.46552C13.0208 2.70775 16.0417 1.94965 19.0625 3.0598C19.6493 3.27548 20 3.85414 20 4.47074V15.061C20 15.8779 19.1111 16.4179 18.309 16.2026C15.5417 15.4575 12.7708 16.1513 10 16.8452C6.94792 17.6039 3.92708 18.3627 0.936458 17.2519C0.349306 17.0366 0 16.4589 0 15.8403V5.2514C0 4.43417 0.889236 3.8955 1.68958 4.11083ZM17.7778 6.84418C16.6007 6.84418 15.6389 5.97601 15.559 4.83441C14.1701 4.86859 12.6632 5.19672 10.8715 5.62738C10.7396 5.66498 10.5764 5.70599 10.4097 5.74701C8.63889 6.18793 6.57639 6.69721 4.37847 6.72455C4.21875 7.7431 3.31181 8.4848 2.19097 8.4848V13.4374C3.39896 13.4374 4.36111 14.3364 4.44097 15.478C5.82986 15.4438 7.33681 15.1157 9.09722 14.685C9.26042 14.6474 9.42361 14.6064 9.59028 14.5346C11.3611 14.1245 13.4236 13.6152 15.5903 13.5878C15.7813 12.5693 16.6875 11.7968 17.7778 11.7968V6.84418Z"
            fill="#002649"
        />
        <path
            d="M17.7778 11.7968C16.6875 11.7968 15.7813 12.5693 15.5903 13.5879C13.4236 13.6152 11.3611 14.1245 9.59029 14.5346C9.42362 14.6064 9.26042 14.6474 9.09723 14.685C7.33681 15.1157 5.82987 15.4438 4.44098 15.478C4.36112 14.3364 3.39897 13.4375 2.22223 13.4375V8.51559C3.31181 8.51559 4.21876 7.74313 4.37848 6.72458C6.5764 6.69724 8.6389 6.18796 10.4097 5.74704C10.5764 5.70602 10.7396 5.66501 10.8715 5.62741C12.6632 5.19675 14.1701 4.86862 15.559 4.83444C15.6389 5.97604 16.6007 6.87497 17.7778 6.87497V11.7968ZM10 13.4375C11.5347 13.4375 12.7778 11.9677 12.7778 10.1562C12.7778 8.31393 11.5347 6.87497 10 6.87497C8.46528 6.87497 7.22223 8.31393 7.22223 10.1562C7.22223 11.9677 8.46528 13.4375 10 13.4375Z"
            opacity="0.4"
            fill="#002649"
        />
    </svg>
);

export default PaymentsIcon;
