import classNames from "classnames";
import BaseButton from "components/baseButton/baseButton";
import BaseTextArea from "components/baseTextarea/baseTextarea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { t } from "i18next";
import { Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { feedbackMessage } from "redux/actions/feedbackActions";
import { confirmModal, declineModal } from "redux/reducers/modalReducer";
import { useAppDispatch } from "redux/store";
import { ModalTypeEnum } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import "./feedbackFormModal.scss";

const FeedbackFormModal = () => {
    const dispatch = useAppDispatch();

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        formValidation.handleSubmit();
    };

    const formValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            message: "",
        },
        validationSchema: Yup.object({
            message: Yup.string().required(""),
        }),
        onSubmit: async (value: any) => {
            const model = {
                feedbackMessage: value.message,
            };

            try {
                const response = await dispatch(feedbackMessage(model));
                if (response.meta.requestStatus === "fulfilled") {
                    toast.success(t(StringResources.modal.feedback.success).toString());
                }
                dispatch(confirmModal({ modalType: ModalTypeEnum.FeedbackFormModal }));
            } catch (error: any) {
                toast.error(t(StringResources.modal.feedback.error).toString());
            }
        },
    });

    const onCancelClick = () => {
        dispatch(declineModal({ modalType: ModalTypeEnum.FeedbackFormModal }));
    };

    const onSubmit = async () => {
        await formValidation.handleSubmit();
    };

    const isFormValid = formValidation.dirty && formValidation.isValid;

    return (
        <Modal show={true} onHide={onCancelClick} dialogClassName="base-modal">
            <Form onSubmit={handleSubmit} noValidate>
                <Modal.Header closeButton className={classNames({ "modal-border-bottom": true })}>
                    <Modal.Title>{`${t(StringResources.modal.feedback.title)}`}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <BaseTextArea
                        name={"message"}
                        autocomplete={"off"}
                        rows={5}
                        placeholder={`${t(StringResources.modal.feedback.placeholder)}`}
                        label={`${t(StringResources.modal.feedback.label)}`}
                        value={formValidation.values["message"]}
                        invalid={formValidation.touched["message"] && formValidation.errors["message"]}
                        onBlur={formValidation.handleBlur}
                        onChange={formValidation.handleChange}
                    />
                    {<p className="base_modal--feedback__message">{`${t(StringResources.modal.feedback.message)}`}</p>}
                </Modal.Body>
                <Modal.Footer className={classNames({ "modal-border-top": true })}>
                    <BaseButton
                        handleClick={onCancelClick}
                        text={`${t(StringResources.modal.cancel)}`}
                        styleType="line"
                    />

                    <BaseButton
                        handleClick={onSubmit}
                        text={`${t(StringResources.modal.submit)}`}
                        styleType="solid"
                        disabled={!isFormValid}
                    />
                </Modal.Footer>
            </Form>
        </Modal>
    );
};

export default FeedbackFormModal;
