import { IconSVGProps } from "./types";

const DailyBalancesIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15 9.375C15 10.0781 14.4414 10.7266 13.5 11.25C12.1328 12.0078 9.95312 12.5 7.5 12.5C5.17188 12.5 3.08828 12.0586 1.7125 11.3633C1.63945 11.3242 1.56836 11.2539 1.49961 11.25C0.557812 10.7266 0 10.0781 0 9.375C0 7.64844 3.35781 6.25 7.5 6.25C11.6406 6.25 15 7.64844 15 9.375ZM7.5 13.75C9.69531 13.75 11.7422 13.3828 13.2852 12.7383C13.9219 12.4727 14.5117 12.1445 15 11.7422V13.125C15 13.8281 14.4414 14.4766 13.5 15C13.4297 15.0039 13.3594 15.0742 13.2891 15.1133C11.9102 15.8086 9.82812 16.25 7.5 16.25C5.04688 16.25 2.86758 15.7578 1.49961 15C0.557812 14.4766 0 13.8281 0 13.125V11.7422C0.486328 12.1445 1.07891 12.4727 1.71602 12.7383C3.25937 13.3828 5.30469 13.75 7.5 13.75ZM15 15.4922V16.875C15 18.6016 11.6406 20 7.5 20C3.35781 20 0 18.6016 0 16.875V15.4922C0.486328 15.8945 1.07891 16.2227 1.71602 16.4883C3.25937 17.1328 5.30469 17.5 7.5 17.5C9.69531 17.5 11.7422 17.1328 13.2852 16.4883C13.9219 16.2227 14.5117 15.8945 15 15.4922Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M20 3.125C20 3.82852 19.4414 4.47656 18.5 5C17.3633 5.62891 15.6719 6.07422 13.7227 6.20703C13.582 6.14062 13.4336 6.07422 13.2852 6.01172C11.7422 5.36719 9.69531 5 7.5 5C7.17578 5 6.85938 5.00781 6.54297 5.02344C6.52734 5.01562 6.51562 5.00781 6.5 5C5.55859 4.47656 5 3.82852 5 3.125C5 1.39922 8.32422 0 12.5 0C16.6406 0 20 1.39922 20 3.125ZM16.25 10.8633C16.957 10.668 17.6758 10.4922 18.2852 10.2383C18.9219 9.97266 19.5117 9.64453 20 9.24219V10.625C20 11.7812 18.4922 12.793 16.25 13.332V10.8633ZM16.25 9.375C16.25 8.51953 15.8359 7.81641 15.3086 7.28906C16.4141 7.11719 17.4258 6.84375 18.2852 6.48828C18.9219 6.22266 19.5117 5.89453 20 5.49219V6.875C20 7.57812 19.4414 8.22656 18.5 8.75C18.4297 8.75391 18.3594 8.82422 18.2891 8.86328C17.7188 9.14844 17.0273 9.39453 16.25 9.58203V9.375Z"
            fill="#002649"
        />
    </svg>
);

export default DailyBalancesIcon;
