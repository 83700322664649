import { IconSVGProps } from "./types";

const TerminalsIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5 3.38541C5 2.43293 5.51324 1.66666 6.14583 1.66666H13.0208C13.6558 1.66666 14.1667 2.43293 14.1667 3.38541V9.11457C14.1667 10.067 13.6558 10.8333 13.0208 10.8333H6.14583C5.51324 10.8333 5 10.067 5 9.11457V3.38541Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M3.33331 2.5C3.33331 1.11914 4.45245 0 5.83331 0H13.3333C14.7122 0 15.8333 1.11914 15.8333 2.5V17.5C15.8333 18.8789 14.7122 20 13.3333 20H5.83331C4.45245 20 3.33331 18.8789 3.33331 17.5V2.5ZM5.83331 9.0625C5.83331 9.58203 6.25323 10 6.77081 10H12.3958C12.9153 10 13.3333 9.58203 13.3333 9.0625V5.9375C13.3333 5.41797 12.9153 5 12.3958 5H6.77081C6.25323 5 5.83331 5.41797 5.83331 5.9375V9.0625ZM6.45831 13.75C6.97589 13.75 7.39581 13.332 7.39581 12.8125C7.39581 12.293 6.97589 11.875 6.45831 11.875C5.94073 11.875 5.52081 12.293 5.52081 12.8125C5.52081 13.332 5.94073 13.75 6.45831 13.75ZM6.45831 15C5.94073 15 5.52081 15.418 5.52081 15.9375C5.52081 16.457 5.94073 16.875 6.45831 16.875C6.97589 16.875 7.39581 16.457 7.39581 15.9375C7.39581 15.418 6.97589 15 6.45831 15ZM9.58331 13.75C10.1028 13.75 10.5208 13.332 10.5208 12.8125C10.5208 12.293 10.1028 11.875 9.58331 11.875C9.06378 11.875 8.64581 12.293 8.64581 12.8125C8.64581 13.332 9.06378 13.75 9.58331 13.75ZM9.58331 15C9.06378 15 8.64581 15.418 8.64581 15.9375C8.64581 16.457 9.06378 16.875 9.58331 16.875C10.1028 16.875 10.5208 16.457 10.5208 15.9375C10.5208 15.418 10.1028 15 9.58331 15ZM12.7083 13.75C13.2278 13.75 13.6458 13.332 13.6458 12.8125C13.6458 12.293 13.2278 11.875 12.7083 11.875C12.1888 11.875 11.7708 12.293 11.7708 12.8125C11.7708 13.332 12.1888 13.75 12.7083 13.75ZM12.7083 15C12.1888 15 11.7708 15.418 11.7708 15.9375C11.7708 16.457 12.1888 16.875 12.7083 16.875C13.2278 16.875 13.6458 16.457 13.6458 15.9375C13.6458 15.418 13.2278 15 12.7083 15ZM8.33331 1.875C7.98956 1.875 7.70831 2.15469 7.70831 2.5C7.70831 2.84531 7.98956 3.125 8.33331 3.125H10.8333C11.1771 3.125 11.4583 2.84531 11.4583 2.5C11.4583 2.15469 11.1771 1.875 10.8333 1.875H8.33331Z"
            fill="#002649"
        />
    </svg>
);

export default TerminalsIcon;
