import React, { useState } from "react";
import { ColorResult, SketchPicker } from "react-color";
import reactCSS from "reactcss";

interface ColorPickerInputProps {
    onColorChange: (color: string) => void;
    colorPalette: string;
}

const ColorPicker: React.FC<ColorPickerInputProps> = ({ colorPalette, onColorChange }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState<string>(colorPalette);

    const handleClick = () => {
        setDisplayColorPicker(!displayColorPicker);
    };

    const handleClose = () => {
        setDisplayColorPicker(false);
    };

    const handleColorChange = (colorResult: ColorResult) => {
        setColor(colorResult.hex);
        onColorChange(colorResult.hex);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setColor(value);
        if (value === "") {
            onColorChange("");
        }
    };

    const styles: { [key: string]: React.CSSProperties } = reactCSS({
        default: {
            colorSquare: {
                width: "22px",
                height: "20px",
                borderRadius: "12px",
                background: color,
                marginLeft: "10px",
                cursor: "pointer",
            },
            popover: {
                position: "fixed",
                zIndex: 2,
            },
            cover: {
                position: "fixed",
                top: "0px",
                right: "0px",
                bottom: "0px",
                left: "0px",
            },
            inputWrapper: {
                display: "flex",
                width: "100%",
                borderRadius: "4px",
                position: "relative",
                cursor: "pointer",
                gap: "12px",
                alignItems: "center",
                border: "1px solid transparent",
                background: "#f5f8fa",
                height: "fit-content",
                color: "#262b31",
                padding: "0.75rem",
                fontSize: "1rem",
                lineHeight: "1.5",
                transition: "border-color 0.25s ease-in-out",
            },
            input: {
                width: "100%",
                border: "none",
                outline: "none",
                fontSize: "14px",
                height: "24px",
                cursor: "pointer",
                background: "#f5f8fa",
            },
        },
    });

    return (
        <div>
            <div style={styles.inputWrapper} onClick={handleClick}>
                <input style={styles.input} value={color} onChange={handleInputChange} />
                <div style={styles.colorSquare} />
            </div>

            {displayColorPicker ? (
                <div style={styles.popover}>
                    <div style={styles.cover} onClick={handleClose} />
                    <SketchPicker color={color} onChange={handleColorChange} />
                </div>
            ) : null}
        </div>
    );
};

export default ColorPicker;
