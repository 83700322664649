import { IconSVGProps } from "./types";

const FilterSmallIcon = ({ height = 16, width = 16, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_127_580)">
            <path
                d="M15 2C15.5531 2 16 2.44781 16 3C16 3.55313 15.5531 4 15 4H13C12.4469 4 12 3.55313 12 3C12 2.44781 12.4469 2 13 2H15ZM10 8C10 7.44688 10.4469 7 11 7H15C15.5531 7 16 7.44688 16 8C16 8.55312 15.5531 9 15 9H11C10.4469 9 10 8.55312 10 8ZM10 13C10 12.4469 10.4469 12 11 12H15C15.5531 12 16 12.4469 16 13C16 13.5531 15.5531 14 15 14H11C10.4469 14 10 13.5531 10 13Z"
                fill="#002649"
            />
            <path
                opacity="0.4"
                d="M0.10809 2.74156C0.308934 2.29063 0.756246 2 1.22187 2H9.75C10.2437 2 10.6906 2.29063 10.8906 2.74156C11.0656 3.19375 11.0094 3.71875 10.6781 4.0875L7.5 7.61875V13C7.5 13.3781 7.2875 13.725 6.94687 13.8938C6.60937 14.0625 6.20312 14.0281 5.9 13.8L3.9 12.3C3.64687 12.1125 3.5 11.8156 3.5 11.5V7.61875L0.320934 4.0875C-0.00938508 3.71875 -0.0927538 3.19375 0.10809 2.74156Z"
                fill="#002649"
            />
        </g>
    </svg>
);

export default FilterSmallIcon;
