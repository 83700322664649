import { IconSVGProps } from "./types";

const LockIcon = ({ height = 281, width = 207, className }: IconSVGProps) => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g className="animated-part">
            <path
                d="M76.1976 6.77484C78.0679 6.77484 79.5842 5.25824 79.5842 3.38742C79.5842 1.5166 78.0679 0 76.1976 0C74.3273 0 72.811 1.5166 72.811 3.38742C72.811 5.25824 74.3273 6.77484 76.1976 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M85.2972 6.77484C87.1676 6.77484 88.6838 5.25824 88.6838 3.38742C88.6838 1.5166 87.1676 0 85.2972 0C83.4269 0 81.9106 1.5166 81.9106 3.38742C81.9106 5.25824 83.4269 6.77484 85.2972 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.4002 6.77484C96.2706 6.77484 97.7868 5.25824 97.7868 3.38742C97.7868 1.5166 96.2706 0 94.4002 0C92.5299 0 91.0137 1.5166 91.0137 3.38742C91.0137 5.25824 92.5299 6.77484 94.4002 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.5 6.77484C105.37 6.77484 106.886 5.25824 106.886 3.38742C106.886 1.5166 105.37 0 103.5 0C101.629 0 100.113 1.5166 100.113 3.38742C100.113 5.25824 101.629 6.77484 103.5 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M112.603 6.77484C114.474 6.77484 115.99 5.25824 115.99 3.38742C115.99 1.5166 114.474 0 112.603 0C110.733 0 109.217 1.5166 109.217 3.38742C109.217 5.25824 110.733 6.77484 112.603 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M121.703 6.77484C123.573 6.77484 125.09 5.25824 125.09 3.38742C125.09 1.5166 123.573 0 121.703 0C119.833 0 118.316 1.5166 118.316 3.38742C118.316 5.25824 119.833 6.77484 121.703 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M130.806 6.77484C132.676 6.77484 134.193 5.25824 134.193 3.38742C134.193 1.5166 132.676 0 130.806 0C128.936 0 127.419 1.5166 127.419 3.38742C127.419 5.25824 128.936 6.77484 130.806 6.77484Z"
                fill="#DAFDA3"
            />
            <path
                d="M57.995 15.9155C59.8653 15.9155 61.3815 14.3989 61.3815 12.528C61.3815 10.6572 59.8653 9.14062 57.995 9.14062C56.1246 9.14062 54.6084 10.6572 54.6084 12.528C54.6084 14.3989 56.1246 15.9155 57.995 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M67.0946 15.9155C68.9649 15.9155 70.4811 14.3989 70.4811 12.528C70.4811 10.6572 68.9649 9.14062 67.0946 9.14062C65.2242 9.14062 63.708 10.6572 63.708 12.528C63.708 14.3989 65.2242 15.9155 67.0946 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M76.1976 15.9155C78.0679 15.9155 79.5842 14.3989 79.5842 12.528C79.5842 10.6572 78.0679 9.14062 76.1976 9.14062C74.3273 9.14062 72.811 10.6572 72.811 12.528C72.811 14.3989 74.3273 15.9155 76.1976 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M85.2972 15.9155C87.1676 15.9155 88.6838 14.3989 88.6838 12.528C88.6838 10.6572 87.1676 9.14062 85.2972 9.14062C83.4269 9.14062 81.9106 10.6572 81.9106 12.528C81.9106 14.3989 83.4269 15.9155 85.2972 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M94.4002 15.9155C96.2706 15.9155 97.7868 14.3989 97.7868 12.528C97.7868 10.6572 96.2706 9.14062 94.4002 9.14062C92.5299 9.14062 91.0137 10.6572 91.0137 12.528C91.0137 14.3989 92.5299 15.9155 94.4002 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.5 15.9155C105.37 15.9155 106.886 14.3989 106.886 12.528C106.886 10.6572 105.37 9.14062 103.5 9.14062C101.629 9.14062 100.113 10.6572 100.113 12.528C100.113 14.3989 101.629 15.9155 103.5 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M112.603 15.9155C114.474 15.9155 115.99 14.3989 115.99 12.528C115.99 10.6572 114.474 9.14062 112.603 9.14062C110.733 9.14062 109.217 10.6572 109.217 12.528C109.217 14.3989 110.733 15.9155 112.603 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M121.703 15.9155C123.573 15.9155 125.09 14.3989 125.09 12.528C125.09 10.6572 123.573 9.14062 121.703 9.14062C119.833 9.14062 118.316 10.6572 118.316 12.528C118.316 14.3989 119.833 15.9155 121.703 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M130.806 15.9155C132.676 15.9155 134.193 14.3989 134.193 12.528C134.193 10.6572 132.676 9.14062 130.806 9.14062C128.936 9.14062 127.419 10.6572 127.419 12.528C127.419 14.3989 128.936 15.9155 130.806 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M139.906 15.9155C141.776 15.9155 143.292 14.3989 143.292 12.528C143.292 10.6572 141.776 9.14062 139.906 9.14062C138.035 9.14062 136.519 10.6572 136.519 12.528C136.519 14.3989 138.035 15.9155 139.906 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M149.005 15.9155C150.876 15.9155 152.392 14.3989 152.392 12.528C152.392 10.6572 150.876 9.14062 149.005 9.14062C147.135 9.14062 145.619 10.6572 145.619 12.528C145.619 14.3989 147.135 15.9155 149.005 15.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 25.0541C50.7618 25.0541 52.278 23.5375 52.278 21.6667C52.278 19.7959 50.7618 18.2793 48.8914 18.2793C47.0211 18.2793 45.5049 19.7959 45.5049 21.6667C45.5049 23.5375 47.0211 25.0541 48.8914 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M57.995 25.0541C59.8653 25.0541 61.3815 23.5375 61.3815 21.6667C61.3815 19.7959 59.8653 18.2793 57.995 18.2793C56.1246 18.2793 54.6084 19.7959 54.6084 21.6667C54.6084 23.5375 56.1246 25.0541 57.995 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M67.0946 25.0541C68.9649 25.0541 70.4811 23.5375 70.4811 21.6667C70.4811 19.7959 68.9649 18.2793 67.0946 18.2793C65.2242 18.2793 63.708 19.7959 63.708 21.6667C63.708 23.5375 65.2242 25.0541 67.0946 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M76.1976 25.0541C78.0679 25.0541 79.5842 23.5375 79.5842 21.6667C79.5842 19.7959 78.0679 18.2793 76.1976 18.2793C74.3273 18.2793 72.811 19.7959 72.811 21.6667C72.811 23.5375 74.3273 25.0541 76.1976 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M85.2972 25.0541C87.1676 25.0541 88.6838 23.5375 88.6838 21.6667C88.6838 19.7959 87.1676 18.2793 85.2972 18.2793C83.4269 18.2793 81.9106 19.7959 81.9106 21.6667C81.9106 23.5375 83.4269 25.0541 85.2972 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M103.5 25.0541C105.37 25.0541 106.886 23.5375 106.886 21.6667C106.886 19.7959 105.37 18.2793 103.5 18.2793C101.629 18.2793 100.113 19.7959 100.113 21.6667C100.113 23.5375 101.629 25.0541 103.5 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M112.603 25.0541C114.474 25.0541 115.99 23.5375 115.99 21.6667C115.99 19.7959 114.474 18.2793 112.603 18.2793C110.733 18.2793 109.217 19.7959 109.217 21.6667C109.217 23.5375 110.733 25.0541 112.603 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M121.703 25.0541C123.573 25.0541 125.09 23.5375 125.09 21.6667C125.09 19.7959 123.573 18.2793 121.703 18.2793C119.833 18.2793 118.316 19.7959 118.316 21.6667C118.316 23.5375 119.833 25.0541 121.703 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M130.806 25.0541C132.676 25.0541 134.193 23.5375 134.193 21.6667C134.193 19.7959 132.676 18.2793 130.806 18.2793C128.936 18.2793 127.419 19.7959 127.419 21.6667C127.419 23.5375 128.936 25.0541 130.806 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M139.906 25.0541C141.776 25.0541 143.292 23.5375 143.292 21.6667C143.292 19.7959 141.776 18.2793 139.906 18.2793C138.035 18.2793 136.519 19.7959 136.519 21.6667C136.519 23.5375 138.035 25.0541 139.906 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M149.005 25.0541C150.876 25.0541 152.392 23.5375 152.392 21.6667C152.392 19.7959 150.876 18.2793 149.005 18.2793C147.135 18.2793 145.619 19.7959 145.619 21.6667C145.619 23.5375 147.135 25.0541 149.005 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 25.0541C159.979 25.0541 161.495 23.5375 161.495 21.6667C161.495 19.7959 159.979 18.2793 158.108 18.2793C156.238 18.2793 154.722 19.7959 154.722 21.6667C154.722 23.5375 156.238 25.0541 158.108 25.0541Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 34.1987C41.6627 34.1987 43.1789 32.6821 43.1789 30.8112C43.1789 28.9404 41.6627 27.4238 39.7923 27.4238C37.922 27.4238 36.4058 28.9404 36.4058 30.8112C36.4058 32.6821 37.922 34.1987 39.7923 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 34.1987C50.7618 34.1987 52.278 32.6821 52.278 30.8112C52.278 28.9404 50.7618 27.4238 48.8914 27.4238C47.0211 27.4238 45.5049 28.9404 45.5049 30.8112C45.5049 32.6821 47.0211 34.1987 48.8914 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M57.995 34.1987C59.8653 34.1987 61.3815 32.6821 61.3815 30.8112C61.3815 28.9404 59.8653 27.4238 57.995 27.4238C56.1246 27.4238 54.6084 28.9404 54.6084 30.8112C54.6084 32.6821 56.1246 34.1987 57.995 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M67.0946 34.1987C68.9649 34.1987 70.4811 32.6821 70.4811 30.8112C70.4811 28.9404 68.9649 27.4238 67.0946 27.4238C65.2242 27.4238 63.708 28.9404 63.708 30.8112C63.708 32.6821 65.2242 34.1987 67.0946 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M139.906 34.1987C141.776 34.1987 143.292 32.6821 143.292 30.8112C143.292 28.9404 141.776 27.4238 139.906 27.4238C138.035 27.4238 136.519 28.9404 136.519 30.8112C136.519 32.6821 138.035 34.1987 139.906 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M149.005 34.1987C150.876 34.1987 152.392 32.6821 152.392 30.8112C152.392 28.9404 150.876 27.4238 149.005 27.4238C147.135 27.4238 145.619 28.9404 145.619 30.8112C145.619 32.6821 147.135 34.1987 149.005 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 34.1987C159.979 34.1987 161.495 32.6821 161.495 30.8112C161.495 28.9404 159.979 27.4238 158.108 27.4238C156.238 27.4238 154.722 28.9404 154.722 30.8112C154.722 32.6821 156.238 34.1987 158.108 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 34.1987C169.078 34.1987 170.594 32.6821 170.594 30.8112C170.594 28.9404 169.078 27.4238 167.208 27.4238C165.338 27.4238 163.821 28.9404 163.821 30.8112C163.821 32.6821 165.338 34.1987 167.208 34.1987Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 43.3393C32.5592 43.3393 34.0754 41.8227 34.0754 39.9519C34.0754 38.0811 32.5592 36.5645 30.6888 36.5645C28.8185 36.5645 27.3022 38.0811 27.3022 39.9519C27.3022 41.8227 28.8185 43.3393 30.6888 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 43.3393C41.6627 43.3393 43.1789 41.8227 43.1789 39.9519C43.1789 38.0811 41.6627 36.5645 39.7923 36.5645C37.922 36.5645 36.4058 38.0811 36.4058 39.9519C36.4058 41.8227 37.922 43.3393 39.7923 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 43.3393C50.7618 43.3393 52.278 41.8227 52.278 39.9519C52.278 38.0811 50.7618 36.5645 48.8914 36.5645C47.0211 36.5645 45.5049 38.0811 45.5049 39.9519C45.5049 41.8227 47.0211 43.3393 48.8914 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M57.995 43.3393C59.8653 43.3393 61.3815 41.8227 61.3815 39.9519C61.3815 38.0811 59.8653 36.5645 57.995 36.5645C56.1246 36.5645 54.6084 38.0811 54.6084 39.9519C54.6084 41.8227 56.1246 43.3393 57.995 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M149.005 43.3393C150.876 43.3393 152.392 41.8227 152.392 39.9519C152.392 38.0811 150.876 36.5645 149.005 36.5645C147.135 36.5645 145.619 38.0811 145.619 39.9519C145.619 41.8227 147.135 43.3393 149.005 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 43.3393C159.979 43.3393 161.495 41.8227 161.495 39.9519C161.495 38.0811 159.979 36.5645 158.108 36.5645C156.238 36.5645 154.722 38.0811 154.722 39.9519C154.722 41.8227 156.238 43.3393 158.108 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 43.3393C169.078 43.3393 170.594 41.8227 170.594 39.9519C170.594 38.0811 169.078 36.5645 167.208 36.5645C165.338 36.5645 163.821 38.0811 163.821 39.9519C163.821 41.8227 165.338 43.3393 167.208 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 43.3393C178.181 43.3393 179.697 41.8227 179.697 39.9519C179.697 38.0811 178.181 36.5645 176.311 36.5645C174.441 36.5645 172.924 38.0811 172.924 39.9519C172.924 41.8227 174.441 43.3393 176.311 43.3393Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 52.478C32.5592 52.478 34.0754 50.9614 34.0754 49.0906C34.0754 47.2197 32.5592 45.7031 30.6888 45.7031C28.8185 45.7031 27.3022 47.2197 27.3022 49.0906C27.3022 50.9614 28.8185 52.478 30.6888 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 88.7749C32.5592 88.7749 34.0754 87.2582 34.0754 85.3874C34.0754 83.5166 32.5592 82 30.6888 82C28.8185 82 27.3022 83.5166 27.3022 85.3874C27.3022 87.2582 28.8185 88.7749 30.6888 88.7749Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 52.478C41.6627 52.478 43.1789 50.9614 43.1789 49.0906C43.1789 47.2197 41.6627 45.7031 39.7923 45.7031C37.922 45.7031 36.4058 47.2197 36.4058 49.0906C36.4058 50.9614 37.922 52.478 39.7923 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 88.7749C41.6627 88.7749 43.1789 87.2582 43.1789 85.3874C43.1789 83.5166 41.6627 82 39.7923 82C37.922 82 36.4058 83.5166 36.4058 85.3874C36.4058 87.2582 37.922 88.7749 39.7923 88.7749Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 52.478C50.7618 52.478 52.278 50.9614 52.278 49.0906C52.278 47.2197 50.7618 45.7031 48.8914 45.7031C47.0211 45.7031 45.5049 47.2197 45.5049 49.0906C45.5049 50.9614 47.0211 52.478 48.8914 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 88.7749C50.7618 88.7749 52.278 87.2582 52.278 85.3874C52.278 83.5166 50.7618 82 48.8914 82C47.0211 82 45.5049 83.5166 45.5049 85.3874C45.5049 87.2582 47.0211 88.7749 48.8914 88.7749Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 52.478C159.979 52.478 161.495 50.9614 161.495 49.0906C161.495 47.2197 159.979 45.7031 158.108 45.7031C156.238 45.7031 154.722 47.2197 154.722 49.0906C154.722 50.9614 156.238 52.478 158.108 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 52.478C169.078 52.478 170.594 50.9614 170.594 49.0906C170.594 47.2197 169.078 45.7031 167.208 45.7031C165.338 45.7031 163.821 47.2197 163.821 49.0906C163.821 50.9614 165.338 52.478 167.208 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 52.478C178.181 52.478 179.697 50.9614 179.697 49.0906C179.697 47.2197 178.181 45.7031 176.311 45.7031C174.441 45.7031 172.924 47.2197 172.924 49.0906C172.924 50.9614 174.441 52.478 176.311 52.478Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 61.6186C32.5592 61.6186 34.0754 60.102 34.0754 58.2312C34.0754 56.3603 32.5592 54.8438 30.6888 54.8438C28.8185 54.8438 27.3022 56.3603 27.3022 58.2312C27.3022 60.102 28.8185 61.6186 30.6888 61.6186Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 97.9155C32.5592 97.9155 34.0754 96.3989 34.0754 94.528C34.0754 92.6572 32.5592 91.1406 30.6888 91.1406C28.8185 91.1406 27.3022 92.6572 27.3022 94.528C27.3022 96.3989 28.8185 97.9155 30.6888 97.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 61.6186C41.6627 61.6186 43.1789 60.102 43.1789 58.2312C43.1789 56.3603 41.6627 54.8438 39.7923 54.8438C37.922 54.8438 36.4058 56.3603 36.4058 58.2312C36.4058 60.102 37.922 61.6186 39.7923 61.6186Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 97.9155C41.6627 97.9155 43.1789 96.3989 43.1789 94.528C43.1789 92.6572 41.6627 91.1406 39.7923 91.1406C37.922 91.1406 36.4058 92.6572 36.4058 94.528C36.4058 96.3989 37.922 97.9155 39.7923 97.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 61.6186C50.7618 61.6186 52.278 60.102 52.278 58.2312C52.278 56.3603 50.7618 54.8438 48.8914 54.8438C47.0211 54.8438 45.5049 56.3603 45.5049 58.2312C45.5049 60.102 47.0211 61.6186 48.8914 61.6186Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 97.9155C50.7618 97.9155 52.278 96.3989 52.278 94.528C52.278 92.6572 50.7618 91.1406 48.8914 91.1406C47.0211 91.1406 45.5049 92.6572 45.5049 94.528C45.5049 96.3989 47.0211 97.9155 48.8914 97.9155Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 61.6186C159.979 61.6186 161.495 60.102 161.495 58.2312C161.495 56.3603 159.979 54.8438 158.108 54.8438C156.238 54.8438 154.722 56.3603 154.722 58.2312C154.722 60.102 156.238 61.6186 158.108 61.6186Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 61.6186C169.078 61.6186 170.594 60.102 170.594 58.2312C170.594 56.3603 169.078 54.8438 167.208 54.8438C165.338 54.8438 163.821 56.3603 163.821 58.2312C163.821 60.102 165.338 61.6186 167.208 61.6186Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 70.7612C32.5592 70.7612 34.0754 69.2446 34.0754 67.3737C34.0754 65.5029 32.5592 63.9863 30.6888 63.9863C28.8185 63.9863 27.3022 65.5029 27.3022 67.3737C27.3022 69.2446 28.8185 70.7612 30.6888 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 70.7612C41.6627 70.7612 43.1789 69.2446 43.1789 67.3737C43.1789 65.5029 41.6627 63.9863 39.7923 63.9863C37.922 63.9863 36.4058 65.5029 36.4058 67.3737C36.4058 69.2446 37.922 70.7612 39.7923 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 70.7612C50.7618 70.7612 52.278 69.2446 52.278 67.3737C52.278 65.5029 50.7618 63.9863 48.8914 63.9863C47.0211 63.9863 45.5049 65.5029 45.5049 67.3737C45.5049 69.2446 47.0211 70.7612 48.8914 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 70.7612C159.979 70.7612 161.495 69.2446 161.495 67.3737C161.495 65.5029 159.979 63.9863 158.108 63.9863C156.238 63.9863 154.722 65.5029 154.722 67.3737C154.722 69.2446 156.238 70.7612 158.108 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 70.7612C169.078 70.7612 170.594 69.2446 170.594 67.3737C170.594 65.5029 169.078 63.9863 167.208 63.9863C165.338 63.9863 163.821 65.5029 163.821 67.3737C163.821 69.2446 165.338 70.7612 167.208 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 70.7612C178.181 70.7612 179.697 69.2446 179.697 67.3737C179.697 65.5029 178.181 63.9863 176.311 63.9863C174.441 63.9863 172.924 65.5029 172.924 67.3737C172.924 69.2446 174.441 70.7612 176.311 70.7612Z"
                fill="#DAFDA3"
            />
            <path
                d="M30.6888 79.9018C32.5592 79.9018 34.0754 78.3852 34.0754 76.5144C34.0754 74.6436 32.5592 73.127 30.6888 73.127C28.8185 73.127 27.3022 74.6436 27.3022 76.5144C27.3022 78.3852 28.8185 79.9018 30.6888 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M39.7923 79.9018C41.6627 79.9018 43.1789 78.3852 43.1789 76.5144C43.1789 74.6436 41.6627 73.127 39.7923 73.127C37.922 73.127 36.4058 74.6436 36.4058 76.5144C36.4058 78.3852 37.922 79.9018 39.7923 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M48.8914 79.9018C50.7618 79.9018 52.278 78.3852 52.278 76.5144C52.278 74.6436 50.7618 73.127 48.8914 73.127C47.0211 73.127 45.5049 74.6436 45.5049 76.5144C45.5049 78.3852 47.0211 79.9018 48.8914 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 79.9018C159.979 79.9018 161.495 78.3852 161.495 76.5144C161.495 74.6436 159.979 73.127 158.108 73.127C156.238 73.127 154.722 74.6436 154.722 76.5144C154.722 78.3852 156.238 79.9018 158.108 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 79.9018C169.078 79.9018 170.594 78.3852 170.594 76.5144C170.594 74.6436 169.078 73.127 167.208 73.127C165.338 73.127 163.821 74.6436 163.821 76.5144C163.821 78.3852 165.338 79.9018 167.208 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 79.9018C178.181 79.9018 179.697 78.3852 179.697 76.5144C179.697 74.6436 178.181 73.127 176.311 73.127C174.441 73.127 172.924 74.6436 172.924 76.5144C172.924 78.3852 174.441 79.9018 176.311 79.9018Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 89.0424C159.979 89.0424 161.495 87.5258 161.495 85.655C161.495 83.7842 159.979 82.2676 158.108 82.2676C156.238 82.2676 154.722 83.7842 154.722 85.655C154.722 87.5258 156.238 89.0424 158.108 89.0424Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 106.775C159.979 106.775 161.495 105.258 161.495 103.387C161.495 101.517 159.979 100 158.108 100C156.238 100 154.722 101.517 154.722 103.387C154.722 105.258 156.238 106.775 158.108 106.775Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 89.0424C169.078 89.0424 170.594 87.5258 170.594 85.655C170.594 83.7842 169.078 82.2676 167.208 82.2676C165.338 82.2676 163.821 83.7842 163.821 85.655C163.821 87.5258 165.338 89.0424 167.208 89.0424Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 106.775C169.078 106.775 170.594 105.258 170.594 103.387C170.594 101.517 169.078 100 167.208 100C165.338 100 163.821 101.517 163.821 103.387C163.821 105.258 165.338 106.775 167.208 106.775Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 89.0424C178.181 89.0424 179.697 87.5258 179.697 85.655C179.697 83.7842 178.181 82.2676 176.311 82.2676C174.441 82.2676 172.924 83.7842 172.924 85.655C172.924 87.5258 174.441 89.0424 176.311 89.0424Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 106.775C178.181 106.775 179.697 105.258 179.697 103.387C179.697 101.517 178.181 100 176.311 100C174.441 100 172.924 101.517 172.924 103.387C172.924 105.258 174.441 106.775 176.311 106.775Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 98.1811C159.979 98.1811 161.495 96.6645 161.495 94.7937C161.495 92.9229 159.979 91.4062 158.108 91.4062C156.238 91.4062 154.722 92.9229 154.722 94.7937C154.722 96.6645 156.238 98.1811 158.108 98.1811Z"
                fill="#DAFDA3"
            />
            <path
                d="M158.108 115.914C159.979 115.914 161.495 114.397 161.495 112.526C161.495 110.655 159.979 109.139 158.108 109.139C156.238 109.139 154.722 110.655 154.722 112.526C154.722 114.397 156.238 115.914 158.108 115.914Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 98.1811C169.078 98.1811 170.594 96.6645 170.594 94.7937C170.594 92.9229 169.078 91.4062 167.208 91.4062C165.338 91.4062 163.821 92.9229 163.821 94.7937C163.821 96.6645 165.338 98.1811 167.208 98.1811Z"
                fill="#DAFDA3"
            />
            <path
                d="M167.208 115.914C169.078 115.914 170.594 114.397 170.594 112.526C170.594 110.655 169.078 109.139 167.208 109.139C165.338 109.139 163.821 110.655 163.821 112.526C163.821 114.397 165.338 115.914 167.208 115.914Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 98.1811C178.181 98.1811 179.697 96.6645 179.697 94.7937C179.697 92.9229 178.181 91.4062 176.311 91.4062C174.441 91.4062 172.924 92.9229 172.924 94.7937C172.924 96.6645 174.441 98.1811 176.311 98.1811Z"
                fill="#DAFDA3"
            />
            <path
                d="M176.311 115.914C178.181 115.914 179.697 114.397 179.697 112.526C179.697 110.655 178.181 109.139 176.311 109.139C174.441 109.139 172.924 110.655 172.924 112.526C172.924 114.397 174.441 115.914 176.311 115.914Z"
                fill="#DAFDA3"
            />
        </g>
        <path
            d="M12.4862 125.605C14.3565 125.605 15.8727 124.088 15.8727 122.218C15.8727 120.347 14.3565 118.83 12.4862 118.83C10.6158 118.83 9.09961 120.347 9.09961 122.218C9.09961 124.088 10.6158 125.605 12.4862 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 125.605C23.4595 125.605 24.9758 124.088 24.9758 122.218C24.9758 120.347 23.4595 118.83 21.5892 118.83C19.7189 118.83 18.2026 120.347 18.2026 122.218C18.2026 124.088 19.7189 125.605 21.5892 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 125.605C32.5592 125.605 34.0754 124.088 34.0754 122.218C34.0754 120.347 32.5592 118.83 30.6888 118.83C28.8185 118.83 27.3022 120.347 27.3022 122.218C27.3022 124.088 28.8185 125.605 30.6888 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 125.605C41.6627 125.605 43.1789 124.088 43.1789 122.218C43.1789 120.347 41.6627 118.83 39.7923 118.83C37.922 118.83 36.4058 120.347 36.4058 122.218C36.4058 124.088 37.922 125.605 39.7923 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 125.605C50.7618 125.605 52.278 124.088 52.278 122.218C52.278 120.347 50.7618 118.83 48.8914 118.83C47.0211 118.83 45.5049 120.347 45.5049 122.218C45.5049 124.088 47.0211 125.605 48.8914 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 125.605C59.8653 125.605 61.3815 124.088 61.3815 122.218C61.3815 120.347 59.8653 118.83 57.995 118.83C56.1246 118.83 54.6084 120.347 54.6084 122.218C54.6084 124.088 56.1246 125.605 57.995 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 125.605C68.9649 125.605 70.4811 124.088 70.4811 122.218C70.4811 120.347 68.9649 118.83 67.0946 118.83C65.2242 118.83 63.708 120.347 63.708 122.218C63.708 124.088 65.2242 125.605 67.0946 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 125.605C78.0679 125.605 79.5842 124.088 79.5842 122.218C79.5842 120.347 78.0679 118.83 76.1976 118.83C74.3273 118.83 72.811 120.347 72.811 122.218C72.811 124.088 74.3273 125.605 76.1976 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 125.605C87.1676 125.605 88.6838 124.088 88.6838 122.218C88.6838 120.347 87.1676 118.83 85.2972 118.83C83.4269 118.83 81.9106 120.347 81.9106 122.218C81.9106 124.088 83.4269 125.605 85.2972 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 125.605C96.2706 125.605 97.7868 124.088 97.7868 122.218C97.7868 120.347 96.2706 118.83 94.4002 118.83C92.5299 118.83 91.0137 120.347 91.0137 122.218C91.0137 124.088 92.5299 125.605 94.4002 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 125.605C105.37 125.605 106.886 124.088 106.886 122.218C106.886 120.347 105.37 118.83 103.5 118.83C101.629 118.83 100.113 120.347 100.113 122.218C100.113 124.088 101.629 125.605 103.5 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 125.605C114.474 125.605 115.99 124.088 115.99 122.218C115.99 120.347 114.474 118.83 112.603 118.83C110.733 118.83 109.217 120.347 109.217 122.218C109.217 124.088 110.733 125.605 112.603 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 125.605C123.573 125.605 125.09 124.088 125.09 122.218C125.09 120.347 123.573 118.83 121.703 118.83C119.833 118.83 118.316 120.347 118.316 122.218C118.316 124.088 119.833 125.605 121.703 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 125.605C132.676 125.605 134.193 124.088 134.193 122.218C134.193 120.347 132.676 118.83 130.806 118.83C128.936 118.83 127.419 120.347 127.419 122.218C127.419 124.088 128.936 125.605 130.806 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 125.605C141.776 125.605 143.292 124.088 143.292 122.218C143.292 120.347 141.776 118.83 139.906 118.83C138.035 118.83 136.519 120.347 136.519 122.218C136.519 124.088 138.035 125.605 139.906 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 125.605C150.876 125.605 152.392 124.088 152.392 122.218C152.392 120.347 150.876 118.83 149.005 118.83C147.135 118.83 145.619 120.347 145.619 122.218C145.619 124.088 147.135 125.605 149.005 125.605Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 125.605C159.979 125.605 161.495 124.088 161.495 122.218C161.495 120.347 159.979 118.83 158.108 118.83C156.238 118.83 154.722 120.347 154.722 122.218C154.722 124.088 156.238 125.605 158.108 125.605Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 125.605C169.078 125.605 170.594 124.088 170.594 122.218C170.594 120.347 169.078 118.83 167.208 118.83C165.338 118.83 163.821 120.347 163.821 122.218C163.821 124.088 165.338 125.605 167.208 125.605Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 125.605C178.181 125.605 179.697 124.088 179.697 122.218C179.697 120.347 178.181 118.83 176.311 118.83C174.441 118.83 172.924 120.347 172.924 122.218C172.924 124.088 174.441 125.605 176.311 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 125.605C187.281 125.605 188.797 124.088 188.797 122.218C188.797 120.347 187.281 118.83 185.41 118.83C183.54 118.83 182.024 120.347 182.024 122.218C182.024 124.088 183.54 125.605 185.41 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 125.605C196.384 125.605 197.901 124.088 197.901 122.218C197.901 120.347 196.384 118.83 194.514 118.83C192.644 118.83 191.127 120.347 191.127 122.218C191.127 124.088 192.644 125.605 194.514 125.605Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 134.746C5.25691 134.746 6.77313 133.229 6.77313 131.358C6.77313 129.487 5.25691 127.971 3.38656 127.971C1.51622 127.971 0 129.487 0 131.358C0 133.229 1.51622 134.746 3.38656 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 134.746C14.3565 134.746 15.8727 133.229 15.8727 131.358C15.8727 129.487 14.3565 127.971 12.4862 127.971C10.6158 127.971 9.09961 129.487 9.09961 131.358C9.09961 133.229 10.6158 134.746 12.4862 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 134.746C23.4595 134.746 24.9758 133.229 24.9758 131.358C24.9758 129.487 23.4595 127.971 21.5892 127.971C19.7189 127.971 18.2026 129.487 18.2026 131.358C18.2026 133.229 19.7189 134.746 21.5892 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 134.746C41.6627 134.746 43.1789 133.229 43.1789 131.358C43.1789 129.487 41.6627 127.971 39.7923 127.971C37.922 127.971 36.4058 129.487 36.4058 131.358C36.4058 133.229 37.922 134.746 39.7923 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 134.746C50.7618 134.746 52.278 133.229 52.278 131.358C52.278 129.487 50.7618 127.971 48.8914 127.971C47.0211 127.971 45.5049 129.487 45.5049 131.358C45.5049 133.229 47.0211 134.746 48.8914 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 134.746C59.8653 134.746 61.3815 133.229 61.3815 131.358C61.3815 129.487 59.8653 127.971 57.995 127.971C56.1246 127.971 54.6084 129.487 54.6084 131.358C54.6084 133.229 56.1246 134.746 57.995 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 134.746C68.9649 134.746 70.4811 133.229 70.4811 131.358C70.4811 129.487 68.9649 127.971 67.0946 127.971C65.2242 127.971 63.708 129.487 63.708 131.358C63.708 133.229 65.2242 134.746 67.0946 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 134.746C78.0679 134.746 79.5842 133.229 79.5842 131.358C79.5842 129.487 78.0679 127.971 76.1976 127.971C74.3273 127.971 72.811 129.487 72.811 131.358C72.811 133.229 74.3273 134.746 76.1976 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 134.746C87.1676 134.746 88.6838 133.229 88.6838 131.358C88.6838 129.487 87.1676 127.971 85.2972 127.971C83.4269 127.971 81.9106 129.487 81.9106 131.358C81.9106 133.229 83.4269 134.746 85.2972 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 134.746C96.2706 134.746 97.7868 133.229 97.7868 131.358C97.7868 129.487 96.2706 127.971 94.4002 127.971C92.5299 127.971 91.0137 129.487 91.0137 131.358C91.0137 133.229 92.5299 134.746 94.4002 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 134.746C105.37 134.746 106.886 133.229 106.886 131.358C106.886 129.487 105.37 127.971 103.5 127.971C101.629 127.971 100.113 129.487 100.113 131.358C100.113 133.229 101.629 134.746 103.5 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 134.746C114.474 134.746 115.99 133.229 115.99 131.358C115.99 129.487 114.474 127.971 112.603 127.971C110.733 127.971 109.217 129.487 109.217 131.358C109.217 133.229 110.733 134.746 112.603 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 134.746C123.573 134.746 125.09 133.229 125.09 131.358C125.09 129.487 123.573 127.971 121.703 127.971C119.833 127.971 118.316 129.487 118.316 131.358C118.316 133.229 119.833 134.746 121.703 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 134.746C132.676 134.746 134.193 133.229 134.193 131.358C134.193 129.487 132.676 127.971 130.806 127.971C128.936 127.971 127.419 129.487 127.419 131.358C127.419 133.229 128.936 134.746 130.806 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 134.746C141.776 134.746 143.292 133.229 143.292 131.358C143.292 129.487 141.776 127.971 139.906 127.971C138.035 127.971 136.519 129.487 136.519 131.358C136.519 133.229 138.035 134.746 139.906 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 134.746C150.876 134.746 152.392 133.229 152.392 131.358C152.392 129.487 150.876 127.971 149.005 127.971C147.135 127.971 145.619 129.487 145.619 131.358C145.619 133.229 147.135 134.746 149.005 134.746Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 134.746C159.979 134.746 161.495 133.229 161.495 131.358C161.495 129.487 159.979 127.971 158.108 127.971C156.238 127.971 154.722 129.487 154.722 131.358C154.722 133.229 156.238 134.746 158.108 134.746Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 134.746C169.078 134.746 170.594 133.229 170.594 131.358C170.594 129.487 169.078 127.971 167.208 127.971C165.338 127.971 163.821 129.487 163.821 131.358C163.821 133.229 165.338 134.746 167.208 134.746Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 134.746C178.181 134.746 179.697 133.229 179.697 131.358C179.697 129.487 178.181 127.971 176.311 127.971C174.441 127.971 172.924 129.487 172.924 131.358C172.924 133.229 174.441 134.746 176.311 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 134.746C187.281 134.746 188.797 133.229 188.797 131.358C188.797 129.487 187.281 127.971 185.41 127.971C183.54 127.971 182.024 129.487 182.024 131.358C182.024 133.229 183.54 134.746 185.41 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 134.746C196.384 134.746 197.901 133.229 197.901 131.358C197.901 129.487 196.384 127.971 194.514 127.971C192.644 127.971 191.127 129.487 191.127 131.358C191.127 133.229 192.644 134.746 194.514 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 134.746C205.483 134.746 207 133.229 207 131.358C207 129.487 205.483 127.971 203.613 127.971C201.743 127.971 200.227 129.487 200.227 131.358C200.227 133.229 201.743 134.746 203.613 134.746Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 143.89C5.25691 143.89 6.77313 142.373 6.77313 140.503C6.77313 138.632 5.25691 137.115 3.38656 137.115C1.51622 137.115 0 138.632 0 140.503C0 142.373 1.51622 143.89 3.38656 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 143.89C14.3565 143.89 15.8727 142.373 15.8727 140.503C15.8727 138.632 14.3565 137.115 12.4862 137.115C10.6158 137.115 9.09961 138.632 9.09961 140.503C9.09961 142.373 10.6158 143.89 12.4862 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 143.89C23.4595 143.89 24.9758 142.373 24.9758 140.503C24.9758 138.632 23.4595 137.115 21.5892 137.115C19.7189 137.115 18.2026 138.632 18.2026 140.503C18.2026 142.373 19.7189 143.89 21.5892 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 143.89C32.5592 143.89 34.0754 142.373 34.0754 140.503C34.0754 138.632 32.5592 137.115 30.6888 137.115C28.8185 137.115 27.3022 138.632 27.3022 140.503C27.3022 142.373 28.8185 143.89 30.6888 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 143.89C41.6627 143.89 43.1789 142.373 43.1789 140.503C43.1789 138.632 41.6627 137.115 39.7923 137.115C37.922 137.115 36.4058 138.632 36.4058 140.503C36.4058 142.373 37.922 143.89 39.7923 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 143.89C50.7618 143.89 52.278 142.373 52.278 140.503C52.278 138.632 50.7618 137.115 48.8914 137.115C47.0211 137.115 45.5049 138.632 45.5049 140.503C45.5049 142.373 47.0211 143.89 48.8914 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 143.89C59.8653 143.89 61.3815 142.373 61.3815 140.503C61.3815 138.632 59.8653 137.115 57.995 137.115C56.1246 137.115 54.6084 138.632 54.6084 140.503C54.6084 142.373 56.1246 143.89 57.995 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 143.89C68.9649 143.89 70.4811 142.373 70.4811 140.503C70.4811 138.632 68.9649 137.115 67.0946 137.115C65.2242 137.115 63.708 138.632 63.708 140.503C63.708 142.373 65.2242 143.89 67.0946 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 143.89C78.0679 143.89 79.5842 142.373 79.5842 140.503C79.5842 138.632 78.0679 137.115 76.1976 137.115C74.3273 137.115 72.811 138.632 72.811 140.503C72.811 142.373 74.3273 143.89 76.1976 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 143.89C87.1676 143.89 88.6838 142.373 88.6838 140.503C88.6838 138.632 87.1676 137.115 85.2972 137.115C83.4269 137.115 81.9106 138.632 81.9106 140.503C81.9106 142.373 83.4269 143.89 85.2972 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 143.89C96.2706 143.89 97.7868 142.373 97.7868 140.503C97.7868 138.632 96.2706 137.115 94.4002 137.115C92.5299 137.115 91.0137 138.632 91.0137 140.503C91.0137 142.373 92.5299 143.89 94.4002 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 143.89C105.37 143.89 106.886 142.373 106.886 140.503C106.886 138.632 105.37 137.115 103.5 137.115C101.629 137.115 100.113 138.632 100.113 140.503C100.113 142.373 101.629 143.89 103.5 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 143.89C114.474 143.89 115.99 142.373 115.99 140.503C115.99 138.632 114.474 137.115 112.603 137.115C110.733 137.115 109.217 138.632 109.217 140.503C109.217 142.373 110.733 143.89 112.603 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 143.89C123.573 143.89 125.09 142.373 125.09 140.503C125.09 138.632 123.573 137.115 121.703 137.115C119.833 137.115 118.316 138.632 118.316 140.503C118.316 142.373 119.833 143.89 121.703 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 143.89C132.676 143.89 134.193 142.373 134.193 140.503C134.193 138.632 132.676 137.115 130.806 137.115C128.936 137.115 127.419 138.632 127.419 140.503C127.419 142.373 128.936 143.89 130.806 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 143.89C141.776 143.89 143.292 142.373 143.292 140.503C143.292 138.632 141.776 137.115 139.906 137.115C138.035 137.115 136.519 138.632 136.519 140.503C136.519 142.373 138.035 143.89 139.906 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 143.89C150.876 143.89 152.392 142.373 152.392 140.503C152.392 138.632 150.876 137.115 149.005 137.115C147.135 137.115 145.619 138.632 145.619 140.503C145.619 142.373 147.135 143.89 149.005 143.89Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 143.89C159.979 143.89 161.495 142.373 161.495 140.503C161.495 138.632 159.979 137.115 158.108 137.115C156.238 137.115 154.722 138.632 154.722 140.503C154.722 142.373 156.238 143.89 158.108 143.89Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 143.89C169.078 143.89 170.594 142.373 170.594 140.503C170.594 138.632 169.078 137.115 167.208 137.115C165.338 137.115 163.821 138.632 163.821 140.503C163.821 142.373 165.338 143.89 167.208 143.89Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 143.89C178.181 143.89 179.697 142.373 179.697 140.503C179.697 138.632 178.181 137.115 176.311 137.115C174.441 137.115 172.924 138.632 172.924 140.503C172.924 142.373 174.441 143.89 176.311 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 143.89C187.281 143.89 188.797 142.373 188.797 140.503C188.797 138.632 187.281 137.115 185.41 137.115C183.54 137.115 182.024 138.632 182.024 140.503C182.024 142.373 183.54 143.89 185.41 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 143.89C196.384 143.89 197.901 142.373 197.901 140.503C197.901 138.632 196.384 137.115 194.514 137.115C192.644 137.115 191.127 138.632 191.127 140.503C191.127 142.373 192.644 143.89 194.514 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 143.89C205.483 143.89 207 142.373 207 140.503C207 138.632 205.483 137.115 203.613 137.115C201.743 137.115 200.227 138.632 200.227 140.503C200.227 142.373 201.743 143.89 203.613 143.89Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 153.029C5.25691 153.029 6.77313 151.512 6.77313 149.641C6.77313 147.771 5.25691 146.254 3.38656 146.254C1.51622 146.254 0 147.771 0 149.641C0 151.512 1.51622 153.029 3.38656 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 153.029C14.3565 153.029 15.8727 151.512 15.8727 149.641C15.8727 147.771 14.3565 146.254 12.4862 146.254C10.6158 146.254 9.09961 147.771 9.09961 149.641C9.09961 151.512 10.6158 153.029 12.4862 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 153.029C23.4595 153.029 24.9758 151.512 24.9758 149.641C24.9758 147.771 23.4595 146.254 21.5892 146.254C19.7189 146.254 18.2026 147.771 18.2026 149.641C18.2026 151.512 19.7189 153.029 21.5892 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 153.029C32.5592 153.029 34.0754 151.512 34.0754 149.641C34.0754 147.771 32.5592 146.254 30.6888 146.254C28.8185 146.254 27.3022 147.771 27.3022 149.641C27.3022 151.512 28.8185 153.029 30.6888 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 153.029C41.6627 153.029 43.1789 151.512 43.1789 149.641C43.1789 147.771 41.6627 146.254 39.7923 146.254C37.922 146.254 36.4058 147.771 36.4058 149.641C36.4058 151.512 37.922 153.029 39.7923 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 153.029C50.7618 153.029 52.278 151.512 52.278 149.641C52.278 147.771 50.7618 146.254 48.8914 146.254C47.0211 146.254 45.5049 147.771 45.5049 149.641C45.5049 151.512 47.0211 153.029 48.8914 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 153.029C59.8653 153.029 61.3815 151.512 61.3815 149.641C61.3815 147.771 59.8653 146.254 57.995 146.254C56.1246 146.254 54.6084 147.771 54.6084 149.641C54.6084 151.512 56.1246 153.029 57.995 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 153.029C68.9649 153.029 70.4811 151.512 70.4811 149.641C70.4811 147.771 68.9649 146.254 67.0946 146.254C65.2242 146.254 63.708 147.771 63.708 149.641C63.708 151.512 65.2242 153.029 67.0946 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 153.029C78.0679 153.029 79.5842 151.512 79.5842 149.641C79.5842 147.771 78.0679 146.254 76.1976 146.254C74.3273 146.254 72.811 147.771 72.811 149.641C72.811 151.512 74.3273 153.029 76.1976 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 153.029C87.1676 153.029 88.6838 151.512 88.6838 149.641C88.6838 147.771 87.1676 146.254 85.2972 146.254C83.4269 146.254 81.9106 147.771 81.9106 149.641C81.9106 151.512 83.4269 153.029 85.2972 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 153.029C96.2706 153.029 97.7868 151.512 97.7868 149.641C97.7868 147.771 96.2706 146.254 94.4002 146.254C92.5299 146.254 91.0137 147.771 91.0137 149.641C91.0137 151.512 92.5299 153.029 94.4002 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 153.029C105.37 153.029 106.886 151.512 106.886 149.641C106.886 147.771 105.37 146.254 103.5 146.254C101.629 146.254 100.113 147.771 100.113 149.641C100.113 151.512 101.629 153.029 103.5 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 153.029C114.474 153.029 115.99 151.512 115.99 149.641C115.99 147.771 114.474 146.254 112.603 146.254C110.733 146.254 109.217 147.771 109.217 149.641C109.217 151.512 110.733 153.029 112.603 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 153.029C123.573 153.029 125.09 151.512 125.09 149.641C125.09 147.771 123.573 146.254 121.703 146.254C119.833 146.254 118.316 147.771 118.316 149.641C118.316 151.512 119.833 153.029 121.703 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 153.029C132.676 153.029 134.193 151.512 134.193 149.641C134.193 147.771 132.676 146.254 130.806 146.254C128.936 146.254 127.419 147.771 127.419 149.641C127.419 151.512 128.936 153.029 130.806 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 153.029C141.776 153.029 143.292 151.512 143.292 149.641C143.292 147.771 141.776 146.254 139.906 146.254C138.035 146.254 136.519 147.771 136.519 149.641C136.519 151.512 138.035 153.029 139.906 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 153.029C150.876 153.029 152.392 151.512 152.392 149.641C152.392 147.771 150.876 146.254 149.005 146.254C147.135 146.254 145.619 147.771 145.619 149.641C145.619 151.512 147.135 153.029 149.005 153.029Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 153.029C159.979 153.029 161.495 151.512 161.495 149.641C161.495 147.771 159.979 146.254 158.108 146.254C156.238 146.254 154.722 147.771 154.722 149.641C154.722 151.512 156.238 153.029 158.108 153.029Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 153.029C169.078 153.029 170.594 151.512 170.594 149.641C170.594 147.771 169.078 146.254 167.208 146.254C165.338 146.254 163.821 147.771 163.821 149.641C163.821 151.512 165.338 153.029 167.208 153.029Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 153.029C178.181 153.029 179.697 151.512 179.697 149.641C179.697 147.771 178.181 146.254 176.311 146.254C174.441 146.254 172.924 147.771 172.924 149.641C172.924 151.512 174.441 153.029 176.311 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 153.029C187.281 153.029 188.797 151.512 188.797 149.641C188.797 147.771 187.281 146.254 185.41 146.254C183.54 146.254 182.024 147.771 182.024 149.641C182.024 151.512 183.54 153.029 185.41 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 153.029C196.384 153.029 197.901 151.512 197.901 149.641C197.901 147.771 196.384 146.254 194.514 146.254C192.644 146.254 191.127 147.771 191.127 149.641C191.127 151.512 192.644 153.029 194.514 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 153.029C205.483 153.029 207 151.512 207 149.641C207 147.771 205.483 146.254 203.613 146.254C201.743 146.254 200.227 147.771 200.227 149.641C200.227 151.512 201.743 153.029 203.613 153.029Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 162.169C5.25691 162.169 6.77313 160.653 6.77313 158.782C6.77313 156.911 5.25691 155.395 3.38656 155.395C1.51622 155.395 0 156.911 0 158.782C0 160.653 1.51622 162.169 3.38656 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 162.169C14.3565 162.169 15.8727 160.653 15.8727 158.782C15.8727 156.911 14.3565 155.395 12.4862 155.395C10.6158 155.395 9.09961 156.911 9.09961 158.782C9.09961 160.653 10.6158 162.169 12.4862 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 162.169C23.4595 162.169 24.9758 160.653 24.9758 158.782C24.9758 156.911 23.4595 155.395 21.5892 155.395C19.7189 155.395 18.2026 156.911 18.2026 158.782C18.2026 160.653 19.7189 162.169 21.5892 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 162.169C32.5592 162.169 34.0754 160.653 34.0754 158.782C34.0754 156.911 32.5592 155.395 30.6888 155.395C28.8185 155.395 27.3022 156.911 27.3022 158.782C27.3022 160.653 28.8185 162.169 30.6888 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 162.169C41.6627 162.169 43.1789 160.653 43.1789 158.782C43.1789 156.911 41.6627 155.395 39.7923 155.395C37.922 155.395 36.4058 156.911 36.4058 158.782C36.4058 160.653 37.922 162.169 39.7923 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 162.169C50.7618 162.169 52.278 160.653 52.278 158.782C52.278 156.911 50.7618 155.395 48.8914 155.395C47.0211 155.395 45.5049 156.911 45.5049 158.782C45.5049 160.653 47.0211 162.169 48.8914 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 162.169C59.8653 162.169 61.3815 160.653 61.3815 158.782C61.3815 156.911 59.8653 155.395 57.995 155.395C56.1246 155.395 54.6084 156.911 54.6084 158.782C54.6084 160.653 56.1246 162.169 57.995 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 162.169C68.9649 162.169 70.4811 160.653 70.4811 158.782C70.4811 156.911 68.9649 155.395 67.0946 155.395C65.2242 155.395 63.708 156.911 63.708 158.782C63.708 160.653 65.2242 162.169 67.0946 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 162.169C78.0679 162.169 79.5842 160.653 79.5842 158.782C79.5842 156.911 78.0679 155.395 76.1976 155.395C74.3273 155.395 72.811 156.911 72.811 158.782C72.811 160.653 74.3273 162.169 76.1976 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 162.169C87.1676 162.169 88.6838 160.653 88.6838 158.782C88.6838 156.911 87.1676 155.395 85.2972 155.395C83.4269 155.395 81.9106 156.911 81.9106 158.782C81.9106 160.653 83.4269 162.169 85.2972 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 162.169C96.2706 162.169 97.7868 160.653 97.7868 158.782C97.7868 156.911 96.2706 155.395 94.4002 155.395C92.5299 155.395 91.0137 156.911 91.0137 158.782C91.0137 160.653 92.5299 162.169 94.4002 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 162.169C105.37 162.169 106.886 160.653 106.886 158.782C106.886 156.911 105.37 155.395 103.5 155.395C101.629 155.395 100.113 156.911 100.113 158.782C100.113 160.653 101.629 162.169 103.5 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 162.169C114.474 162.169 115.99 160.653 115.99 158.782C115.99 156.911 114.474 155.395 112.603 155.395C110.733 155.395 109.217 156.911 109.217 158.782C109.217 160.653 110.733 162.169 112.603 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 162.169C123.573 162.169 125.09 160.653 125.09 158.782C125.09 156.911 123.573 155.395 121.703 155.395C119.833 155.395 118.316 156.911 118.316 158.782C118.316 160.653 119.833 162.169 121.703 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 162.169C132.676 162.169 134.193 160.653 134.193 158.782C134.193 156.911 132.676 155.395 130.806 155.395C128.936 155.395 127.419 156.911 127.419 158.782C127.419 160.653 128.936 162.169 130.806 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 162.169C141.776 162.169 143.292 160.653 143.292 158.782C143.292 156.911 141.776 155.395 139.906 155.395C138.035 155.395 136.519 156.911 136.519 158.782C136.519 160.653 138.035 162.169 139.906 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 162.169C150.876 162.169 152.392 160.653 152.392 158.782C152.392 156.911 150.876 155.395 149.005 155.395C147.135 155.395 145.619 156.911 145.619 158.782C145.619 160.653 147.135 162.169 149.005 162.169Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 162.169C159.979 162.169 161.495 160.653 161.495 158.782C161.495 156.911 159.979 155.395 158.108 155.395C156.238 155.395 154.722 156.911 154.722 158.782C154.722 160.653 156.238 162.169 158.108 162.169Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 162.169C169.078 162.169 170.594 160.653 170.594 158.782C170.594 156.911 169.078 155.395 167.208 155.395C165.338 155.395 163.821 156.911 163.821 158.782C163.821 160.653 165.338 162.169 167.208 162.169Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 162.169C178.181 162.169 179.697 160.653 179.697 158.782C179.697 156.911 178.181 155.395 176.311 155.395C174.441 155.395 172.924 156.911 172.924 158.782C172.924 160.653 174.441 162.169 176.311 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 162.169C187.281 162.169 188.797 160.653 188.797 158.782C188.797 156.911 187.281 155.395 185.41 155.395C183.54 155.395 182.024 156.911 182.024 158.782C182.024 160.653 183.54 162.169 185.41 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 162.169C196.384 162.169 197.901 160.653 197.901 158.782C197.901 156.911 196.384 155.395 194.514 155.395C192.644 155.395 191.127 156.911 191.127 158.782C191.127 160.653 192.644 162.169 194.514 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 162.169C205.483 162.169 207 160.653 207 158.782C207 156.911 205.483 155.395 203.613 155.395C201.743 155.395 200.227 156.911 200.227 158.782C200.227 160.653 201.743 162.169 203.613 162.169Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 171.31C5.25691 171.31 6.77313 169.793 6.77313 167.923C6.77313 166.052 5.25691 164.535 3.38656 164.535C1.51622 164.535 0 166.052 0 167.923C0 169.793 1.51622 171.31 3.38656 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 171.31C14.3565 171.31 15.8727 169.793 15.8727 167.923C15.8727 166.052 14.3565 164.535 12.4862 164.535C10.6158 164.535 9.09961 166.052 9.09961 167.923C9.09961 169.793 10.6158 171.31 12.4862 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 171.31C23.4595 171.31 24.9758 169.793 24.9758 167.923C24.9758 166.052 23.4595 164.535 21.5892 164.535C19.7189 164.535 18.2026 166.052 18.2026 167.923C18.2026 169.793 19.7189 171.31 21.5892 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 171.31C32.5592 171.31 34.0754 169.793 34.0754 167.923C34.0754 166.052 32.5592 164.535 30.6888 164.535C28.8185 164.535 27.3022 166.052 27.3022 167.923C27.3022 169.793 28.8185 171.31 30.6888 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 171.31C41.6627 171.31 43.1789 169.793 43.1789 167.923C43.1789 166.052 41.6627 164.535 39.7923 164.535C37.922 164.535 36.4058 166.052 36.4058 167.923C36.4058 169.793 37.922 171.31 39.7923 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 171.31C50.7618 171.31 52.278 169.793 52.278 167.923C52.278 166.052 50.7618 164.535 48.8914 164.535C47.0211 164.535 45.5049 166.052 45.5049 167.923C45.5049 169.793 47.0211 171.31 48.8914 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 171.31C59.8653 171.31 61.3815 169.793 61.3815 167.923C61.3815 166.052 59.8653 164.535 57.995 164.535C56.1246 164.535 54.6084 166.052 54.6084 167.923C54.6084 169.793 56.1246 171.31 57.995 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 171.31C68.9649 171.31 70.4811 169.793 70.4811 167.923C70.4811 166.052 68.9649 164.535 67.0946 164.535C65.2242 164.535 63.708 166.052 63.708 167.923C63.708 169.793 65.2242 171.31 67.0946 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 171.31C78.0679 171.31 79.5842 169.793 79.5842 167.923C79.5842 166.052 78.0679 164.535 76.1976 164.535C74.3273 164.535 72.811 166.052 72.811 167.923C72.811 169.793 74.3273 171.31 76.1976 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 171.31C87.1676 171.31 88.6838 169.793 88.6838 167.923C88.6838 166.052 87.1676 164.535 85.2972 164.535C83.4269 164.535 81.9106 166.052 81.9106 167.923C81.9106 169.793 83.4269 171.31 85.2972 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 171.31C96.2706 171.31 97.7868 169.793 97.7868 167.923C97.7868 166.052 96.2706 164.535 94.4002 164.535C92.5299 164.535 91.0137 166.052 91.0137 167.923C91.0137 169.793 92.5299 171.31 94.4002 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 171.31C105.37 171.31 106.886 169.793 106.886 167.923C106.886 166.052 105.37 164.535 103.5 164.535C101.629 164.535 100.113 166.052 100.113 167.923C100.113 169.793 101.629 171.31 103.5 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 171.31C114.474 171.31 115.99 169.793 115.99 167.923C115.99 166.052 114.474 164.535 112.603 164.535C110.733 164.535 109.217 166.052 109.217 167.923C109.217 169.793 110.733 171.31 112.603 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 171.31C123.573 171.31 125.09 169.793 125.09 167.923C125.09 166.052 123.573 164.535 121.703 164.535C119.833 164.535 118.316 166.052 118.316 167.923C118.316 169.793 119.833 171.31 121.703 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 171.31C132.676 171.31 134.193 169.793 134.193 167.923C134.193 166.052 132.676 164.535 130.806 164.535C128.936 164.535 127.419 166.052 127.419 167.923C127.419 169.793 128.936 171.31 130.806 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 171.31C141.776 171.31 143.292 169.793 143.292 167.923C143.292 166.052 141.776 164.535 139.906 164.535C138.035 164.535 136.519 166.052 136.519 167.923C136.519 169.793 138.035 171.31 139.906 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 171.31C150.876 171.31 152.392 169.793 152.392 167.923C152.392 166.052 150.876 164.535 149.005 164.535C147.135 164.535 145.619 166.052 145.619 167.923C145.619 169.793 147.135 171.31 149.005 171.31Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 171.31C159.979 171.31 161.495 169.793 161.495 167.923C161.495 166.052 159.979 164.535 158.108 164.535C156.238 164.535 154.722 166.052 154.722 167.923C154.722 169.793 156.238 171.31 158.108 171.31Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 171.31C169.078 171.31 170.594 169.793 170.594 167.923C170.594 166.052 169.078 164.535 167.208 164.535C165.338 164.535 163.821 166.052 163.821 167.923C163.821 169.793 165.338 171.31 167.208 171.31Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 171.31C178.181 171.31 179.697 169.793 179.697 167.923C179.697 166.052 178.181 164.535 176.311 164.535C174.441 164.535 172.924 166.052 172.924 167.923C172.924 169.793 174.441 171.31 176.311 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 171.31C187.281 171.31 188.797 169.793 188.797 167.923C188.797 166.052 187.281 164.535 185.41 164.535C183.54 164.535 182.024 166.052 182.024 167.923C182.024 169.793 183.54 171.31 185.41 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 171.31C196.384 171.31 197.901 169.793 197.901 167.923C197.901 166.052 196.384 164.535 194.514 164.535C192.644 164.535 191.127 166.052 191.127 167.923C191.127 169.793 192.644 171.31 194.514 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 171.31C205.483 171.31 207 169.793 207 167.923C207 166.052 205.483 164.535 203.613 164.535C201.743 164.535 200.227 166.052 200.227 167.923C200.227 169.793 201.743 171.31 203.613 171.31Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 180.453C5.25691 180.453 6.77313 178.936 6.77313 177.065C6.77313 175.194 5.25691 173.678 3.38656 173.678C1.51622 173.678 0 175.194 0 177.065C0 178.936 1.51622 180.453 3.38656 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 180.453C14.3565 180.453 15.8727 178.936 15.8727 177.065C15.8727 175.194 14.3565 173.678 12.4862 173.678C10.6158 173.678 9.09961 175.194 9.09961 177.065C9.09961 178.936 10.6158 180.453 12.4862 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 180.453C23.4595 180.453 24.9758 178.936 24.9758 177.065C24.9758 175.194 23.4595 173.678 21.5892 173.678C19.7189 173.678 18.2026 175.194 18.2026 177.065C18.2026 178.936 19.7189 180.453 21.5892 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 180.453C32.5592 180.453 34.0754 178.936 34.0754 177.065C34.0754 175.194 32.5592 173.678 30.6888 173.678C28.8185 173.678 27.3022 175.194 27.3022 177.065C27.3022 178.936 28.8185 180.453 30.6888 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 180.453C41.6627 180.453 43.1789 178.936 43.1789 177.065C43.1789 175.194 41.6627 173.678 39.7923 173.678C37.922 173.678 36.4058 175.194 36.4058 177.065C36.4058 178.936 37.922 180.453 39.7923 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 180.453C50.7618 180.453 52.278 178.936 52.278 177.065C52.278 175.194 50.7618 173.678 48.8914 173.678C47.0211 173.678 45.5049 175.194 45.5049 177.065C45.5049 178.936 47.0211 180.453 48.8914 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 180.453C59.8653 180.453 61.3815 178.936 61.3815 177.065C61.3815 175.194 59.8653 173.678 57.995 173.678C56.1246 173.678 54.6084 175.194 54.6084 177.065C54.6084 178.936 56.1246 180.453 57.995 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 180.453C68.9649 180.453 70.4811 178.936 70.4811 177.065C70.4811 175.194 68.9649 173.678 67.0946 173.678C65.2242 173.678 63.708 175.194 63.708 177.065C63.708 178.936 65.2242 180.453 67.0946 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 180.453C78.0679 180.453 79.5842 178.936 79.5842 177.065C79.5842 175.194 78.0679 173.678 76.1976 173.678C74.3273 173.678 72.811 175.194 72.811 177.065C72.811 178.936 74.3273 180.453 76.1976 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 180.453C87.1676 180.453 88.6838 178.936 88.6838 177.065C88.6838 175.194 87.1676 173.678 85.2972 173.678C83.4269 173.678 81.9106 175.194 81.9106 177.065C81.9106 178.936 83.4269 180.453 85.2972 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 180.453C123.573 180.453 125.09 178.936 125.09 177.065C125.09 175.194 123.573 173.678 121.703 173.678C119.833 173.678 118.316 175.194 118.316 177.065C118.316 178.936 119.833 180.453 121.703 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 180.453C132.676 180.453 134.193 178.936 134.193 177.065C134.193 175.194 132.676 173.678 130.806 173.678C128.936 173.678 127.419 175.194 127.419 177.065C127.419 178.936 128.936 180.453 130.806 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 180.453C141.776 180.453 143.292 178.936 143.292 177.065C143.292 175.194 141.776 173.678 139.906 173.678C138.035 173.678 136.519 175.194 136.519 177.065C136.519 178.936 138.035 180.453 139.906 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 180.453C150.876 180.453 152.392 178.936 152.392 177.065C152.392 175.194 150.876 173.678 149.005 173.678C147.135 173.678 145.619 175.194 145.619 177.065C145.619 178.936 147.135 180.453 149.005 180.453Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 180.453C159.979 180.453 161.495 178.936 161.495 177.065C161.495 175.194 159.979 173.678 158.108 173.678C156.238 173.678 154.722 175.194 154.722 177.065C154.722 178.936 156.238 180.453 158.108 180.453Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 180.453C169.078 180.453 170.594 178.936 170.594 177.065C170.594 175.194 169.078 173.678 167.208 173.678C165.338 173.678 163.821 175.194 163.821 177.065C163.821 178.936 165.338 180.453 167.208 180.453Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 180.453C178.181 180.453 179.697 178.936 179.697 177.065C179.697 175.194 178.181 173.678 176.311 173.678C174.441 173.678 172.924 175.194 172.924 177.065C172.924 178.936 174.441 180.453 176.311 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 180.453C187.281 180.453 188.797 178.936 188.797 177.065C188.797 175.194 187.281 173.678 185.41 173.678C183.54 173.678 182.024 175.194 182.024 177.065C182.024 178.936 183.54 180.453 185.41 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 180.453C196.384 180.453 197.901 178.936 197.901 177.065C197.901 175.194 196.384 173.678 194.514 173.678C192.644 173.678 191.127 175.194 191.127 177.065C191.127 178.936 192.644 180.453 194.514 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 180.453C205.483 180.453 207 178.936 207 177.065C207 175.194 205.483 173.678 203.613 173.678C201.743 173.678 200.227 175.194 200.227 177.065C200.227 178.936 201.743 180.453 203.613 180.453Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 189.593C5.25691 189.593 6.77313 188.077 6.77313 186.206C6.77313 184.335 5.25691 182.818 3.38656 182.818C1.51622 182.818 0 184.335 0 186.206C0 188.077 1.51622 189.593 3.38656 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 189.593C14.3565 189.593 15.8727 188.077 15.8727 186.206C15.8727 184.335 14.3565 182.818 12.4862 182.818C10.6158 182.818 9.09961 184.335 9.09961 186.206C9.09961 188.077 10.6158 189.593 12.4862 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 189.593C23.4595 189.593 24.9758 188.077 24.9758 186.206C24.9758 184.335 23.4595 182.818 21.5892 182.818C19.7189 182.818 18.2026 184.335 18.2026 186.206C18.2026 188.077 19.7189 189.593 21.5892 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 189.593C32.5592 189.593 34.0754 188.077 34.0754 186.206C34.0754 184.335 32.5592 182.818 30.6888 182.818C28.8185 182.818 27.3022 184.335 27.3022 186.206C27.3022 188.077 28.8185 189.593 30.6888 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 189.593C41.6627 189.593 43.1789 188.077 43.1789 186.206C43.1789 184.335 41.6627 182.818 39.7923 182.818C37.922 182.818 36.4058 184.335 36.4058 186.206C36.4058 188.077 37.922 189.593 39.7923 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 189.593C50.7618 189.593 52.278 188.077 52.278 186.206C52.278 184.335 50.7618 182.818 48.8914 182.818C47.0211 182.818 45.5049 184.335 45.5049 186.206C45.5049 188.077 47.0211 189.593 48.8914 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 189.593C59.8653 189.593 61.3815 188.077 61.3815 186.206C61.3815 184.335 59.8653 182.818 57.995 182.818C56.1246 182.818 54.6084 184.335 54.6084 186.206C54.6084 188.077 56.1246 189.593 57.995 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 189.593C68.9649 189.593 70.4811 188.077 70.4811 186.206C70.4811 184.335 68.9649 182.818 67.0946 182.818C65.2242 182.818 63.708 184.335 63.708 186.206C63.708 188.077 65.2242 189.593 67.0946 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 189.593C78.0679 189.593 79.5842 188.077 79.5842 186.206C79.5842 184.335 78.0679 182.818 76.1976 182.818C74.3273 182.818 72.811 184.335 72.811 186.206C72.811 188.077 74.3273 189.593 76.1976 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 189.593C132.676 189.593 134.193 188.077 134.193 186.206C134.193 184.335 132.676 182.818 130.806 182.818C128.936 182.818 127.419 184.335 127.419 186.206C127.419 188.077 128.936 189.593 130.806 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 189.593C141.776 189.593 143.292 188.077 143.292 186.206C143.292 184.335 141.776 182.818 139.906 182.818C138.035 182.818 136.519 184.335 136.519 186.206C136.519 188.077 138.035 189.593 139.906 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 189.593C150.876 189.593 152.392 188.077 152.392 186.206C152.392 184.335 150.876 182.818 149.005 182.818C147.135 182.818 145.619 184.335 145.619 186.206C145.619 188.077 147.135 189.593 149.005 189.593Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 189.593C159.979 189.593 161.495 188.077 161.495 186.206C161.495 184.335 159.979 182.818 158.108 182.818C156.238 182.818 154.722 184.335 154.722 186.206C154.722 188.077 156.238 189.593 158.108 189.593Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 189.593C169.078 189.593 170.594 188.077 170.594 186.206C170.594 184.335 169.078 182.818 167.208 182.818C165.338 182.818 163.821 184.335 163.821 186.206C163.821 188.077 165.338 189.593 167.208 189.593Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 189.593C178.181 189.593 179.697 188.077 179.697 186.206C179.697 184.335 178.181 182.818 176.311 182.818C174.441 182.818 172.924 184.335 172.924 186.206C172.924 188.077 174.441 189.593 176.311 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 189.593C187.281 189.593 188.797 188.077 188.797 186.206C188.797 184.335 187.281 182.818 185.41 182.818C183.54 182.818 182.024 184.335 182.024 186.206C182.024 188.077 183.54 189.593 185.41 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 189.593C196.384 189.593 197.901 188.077 197.901 186.206C197.901 184.335 196.384 182.818 194.514 182.818C192.644 182.818 191.127 184.335 191.127 186.206C191.127 188.077 192.644 189.593 194.514 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 189.593C205.483 189.593 207 188.077 207 186.206C207 184.335 205.483 182.818 203.613 182.818C201.743 182.818 200.227 184.335 200.227 186.206C200.227 188.077 201.743 189.593 203.613 189.593Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 198.732C5.25691 198.732 6.77313 197.215 6.77313 195.344C6.77313 193.474 5.25691 191.957 3.38656 191.957C1.51622 191.957 0 193.474 0 195.344C0 197.215 1.51622 198.732 3.38656 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 198.732C14.3565 198.732 15.8727 197.215 15.8727 195.344C15.8727 193.474 14.3565 191.957 12.4862 191.957C10.6158 191.957 9.09961 193.474 9.09961 195.344C9.09961 197.215 10.6158 198.732 12.4862 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 198.732C23.4595 198.732 24.9758 197.215 24.9758 195.344C24.9758 193.474 23.4595 191.957 21.5892 191.957C19.7189 191.957 18.2026 193.474 18.2026 195.344C18.2026 197.215 19.7189 198.732 21.5892 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 198.732C32.5592 198.732 34.0754 197.215 34.0754 195.344C34.0754 193.474 32.5592 191.957 30.6888 191.957C28.8185 191.957 27.3022 193.474 27.3022 195.344C27.3022 197.215 28.8185 198.732 30.6888 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 198.732C41.6627 198.732 43.1789 197.215 43.1789 195.344C43.1789 193.474 41.6627 191.957 39.7923 191.957C37.922 191.957 36.4058 193.474 36.4058 195.344C36.4058 197.215 37.922 198.732 39.7923 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 198.732C50.7618 198.732 52.278 197.215 52.278 195.344C52.278 193.474 50.7618 191.957 48.8914 191.957C47.0211 191.957 45.5049 193.474 45.5049 195.344C45.5049 197.215 47.0211 198.732 48.8914 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 198.732C59.8653 198.732 61.3815 197.215 61.3815 195.344C61.3815 193.474 59.8653 191.957 57.995 191.957C56.1246 191.957 54.6084 193.474 54.6084 195.344C54.6084 197.215 56.1246 198.732 57.995 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 198.732C68.9649 198.732 70.4811 197.215 70.4811 195.344C70.4811 193.474 68.9649 191.957 67.0946 191.957C65.2242 191.957 63.708 193.474 63.708 195.344C63.708 197.215 65.2242 198.732 67.0946 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 198.732C78.0679 198.732 79.5842 197.215 79.5842 195.344C79.5842 193.474 78.0679 191.957 76.1976 191.957C74.3273 191.957 72.811 193.474 72.811 195.344C72.811 197.215 74.3273 198.732 76.1976 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 198.732C132.676 198.732 134.193 197.215 134.193 195.344C134.193 193.474 132.676 191.957 130.806 191.957C128.936 191.957 127.419 193.474 127.419 195.344C127.419 197.215 128.936 198.732 130.806 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 198.732C141.776 198.732 143.292 197.215 143.292 195.344C143.292 193.474 141.776 191.957 139.906 191.957C138.035 191.957 136.519 193.474 136.519 195.344C136.519 197.215 138.035 198.732 139.906 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 198.732C150.876 198.732 152.392 197.215 152.392 195.344C152.392 193.474 150.876 191.957 149.005 191.957C147.135 191.957 145.619 193.474 145.619 195.344C145.619 197.215 147.135 198.732 149.005 198.732Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 198.732C159.979 198.732 161.495 197.215 161.495 195.344C161.495 193.474 159.979 191.957 158.108 191.957C156.238 191.957 154.722 193.474 154.722 195.344C154.722 197.215 156.238 198.732 158.108 198.732Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 198.732C169.078 198.732 170.594 197.215 170.594 195.344C170.594 193.474 169.078 191.957 167.208 191.957C165.338 191.957 163.821 193.474 163.821 195.344C163.821 197.215 165.338 198.732 167.208 198.732Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 198.732C178.181 198.732 179.697 197.215 179.697 195.344C179.697 193.474 178.181 191.957 176.311 191.957C174.441 191.957 172.924 193.474 172.924 195.344C172.924 197.215 174.441 198.732 176.311 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 198.732C187.281 198.732 188.797 197.215 188.797 195.344C188.797 193.474 187.281 191.957 185.41 191.957C183.54 191.957 182.024 193.474 182.024 195.344C182.024 197.215 183.54 198.732 185.41 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 198.732C196.384 198.732 197.901 197.215 197.901 195.344C197.901 193.474 196.384 191.957 194.514 191.957C192.644 191.957 191.127 193.474 191.127 195.344C191.127 197.215 192.644 198.732 194.514 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 198.732C205.483 198.732 207 197.215 207 195.344C207 193.474 205.483 191.957 203.613 191.957C201.743 191.957 200.227 193.474 200.227 195.344C200.227 197.215 201.743 198.732 203.613 198.732Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 207.873C5.25691 207.873 6.77313 206.356 6.77313 204.485C6.77313 202.614 5.25691 201.098 3.38656 201.098C1.51622 201.098 0 202.614 0 204.485C0 206.356 1.51622 207.873 3.38656 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 207.873C14.3565 207.873 15.8727 206.356 15.8727 204.485C15.8727 202.614 14.3565 201.098 12.4862 201.098C10.6158 201.098 9.09961 202.614 9.09961 204.485C9.09961 206.356 10.6158 207.873 12.4862 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 207.873C23.4595 207.873 24.9758 206.356 24.9758 204.485C24.9758 202.614 23.4595 201.098 21.5892 201.098C19.7189 201.098 18.2026 202.614 18.2026 204.485C18.2026 206.356 19.7189 207.873 21.5892 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 207.873C32.5592 207.873 34.0754 206.356 34.0754 204.485C34.0754 202.614 32.5592 201.098 30.6888 201.098C28.8185 201.098 27.3022 202.614 27.3022 204.485C27.3022 206.356 28.8185 207.873 30.6888 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 207.873C41.6627 207.873 43.1789 206.356 43.1789 204.485C43.1789 202.614 41.6627 201.098 39.7923 201.098C37.922 201.098 36.4058 202.614 36.4058 204.485C36.4058 206.356 37.922 207.873 39.7923 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 207.873C50.7618 207.873 52.278 206.356 52.278 204.485C52.278 202.614 50.7618 201.098 48.8914 201.098C47.0211 201.098 45.5049 202.614 45.5049 204.485C45.5049 206.356 47.0211 207.873 48.8914 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 207.873C59.8653 207.873 61.3815 206.356 61.3815 204.485C61.3815 202.614 59.8653 201.098 57.995 201.098C56.1246 201.098 54.6084 202.614 54.6084 204.485C54.6084 206.356 56.1246 207.873 57.995 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 207.873C68.9649 207.873 70.4811 206.356 70.4811 204.485C70.4811 202.614 68.9649 201.098 67.0946 201.098C65.2242 201.098 63.708 202.614 63.708 204.485C63.708 206.356 65.2242 207.873 67.0946 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 207.873C78.0679 207.873 79.5842 206.356 79.5842 204.485C79.5842 202.614 78.0679 201.098 76.1976 201.098C74.3273 201.098 72.811 202.614 72.811 204.485C72.811 206.356 74.3273 207.873 76.1976 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 207.873C87.1676 207.873 88.6838 206.356 88.6838 204.485C88.6838 202.614 87.1676 201.098 85.2972 201.098C83.4269 201.098 81.9106 202.614 81.9106 204.485C81.9106 206.356 83.4269 207.873 85.2972 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 207.873C123.573 207.873 125.09 206.356 125.09 204.485C125.09 202.614 123.573 201.098 121.703 201.098C119.833 201.098 118.316 202.614 118.316 204.485C118.316 206.356 119.833 207.873 121.703 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 207.873C132.676 207.873 134.193 206.356 134.193 204.485C134.193 202.614 132.676 201.098 130.806 201.098C128.936 201.098 127.419 202.614 127.419 204.485C127.419 206.356 128.936 207.873 130.806 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 207.873C141.776 207.873 143.292 206.356 143.292 204.485C143.292 202.614 141.776 201.098 139.906 201.098C138.035 201.098 136.519 202.614 136.519 204.485C136.519 206.356 138.035 207.873 139.906 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 207.873C150.876 207.873 152.392 206.356 152.392 204.485C152.392 202.614 150.876 201.098 149.005 201.098C147.135 201.098 145.619 202.614 145.619 204.485C145.619 206.356 147.135 207.873 149.005 207.873Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 207.873C159.979 207.873 161.495 206.356 161.495 204.485C161.495 202.614 159.979 201.098 158.108 201.098C156.238 201.098 154.722 202.614 154.722 204.485C154.722 206.356 156.238 207.873 158.108 207.873Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 207.873C169.078 207.873 170.594 206.356 170.594 204.485C170.594 202.614 169.078 201.098 167.208 201.098C165.338 201.098 163.821 202.614 163.821 204.485C163.821 206.356 165.338 207.873 167.208 207.873Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 207.873C178.181 207.873 179.697 206.356 179.697 204.485C179.697 202.614 178.181 201.098 176.311 201.098C174.441 201.098 172.924 202.614 172.924 204.485C172.924 206.356 174.441 207.873 176.311 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 207.873C187.281 207.873 188.797 206.356 188.797 204.485C188.797 202.614 187.281 201.098 185.41 201.098C183.54 201.098 182.024 202.614 182.024 204.485C182.024 206.356 183.54 207.873 185.41 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 207.873C196.384 207.873 197.901 206.356 197.901 204.485C197.901 202.614 196.384 201.098 194.514 201.098C192.644 201.098 191.127 202.614 191.127 204.485C191.127 206.356 192.644 207.873 194.514 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 207.873C205.483 207.873 207 206.356 207 204.485C207 202.614 205.483 201.098 203.613 201.098C201.743 201.098 200.227 202.614 200.227 204.485C200.227 206.356 201.743 207.873 203.613 207.873Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 217.017C5.25691 217.017 6.77313 215.5 6.77313 213.63C6.77313 211.759 5.25691 210.242 3.38656 210.242C1.51622 210.242 0 211.759 0 213.63C0 215.5 1.51622 217.017 3.38656 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 217.017C14.3565 217.017 15.8727 215.5 15.8727 213.63C15.8727 211.759 14.3565 210.242 12.4862 210.242C10.6158 210.242 9.09961 211.759 9.09961 213.63C9.09961 215.5 10.6158 217.017 12.4862 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 217.017C23.4595 217.017 24.9758 215.5 24.9758 213.63C24.9758 211.759 23.4595 210.242 21.5892 210.242C19.7189 210.242 18.2026 211.759 18.2026 213.63C18.2026 215.5 19.7189 217.017 21.5892 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 217.017C32.5592 217.017 34.0754 215.5 34.0754 213.63C34.0754 211.759 32.5592 210.242 30.6888 210.242C28.8185 210.242 27.3022 211.759 27.3022 213.63C27.3022 215.5 28.8185 217.017 30.6888 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 217.017C41.6627 217.017 43.1789 215.5 43.1789 213.63C43.1789 211.759 41.6627 210.242 39.7923 210.242C37.922 210.242 36.4058 211.759 36.4058 213.63C36.4058 215.5 37.922 217.017 39.7923 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 217.017C50.7618 217.017 52.278 215.5 52.278 213.63C52.278 211.759 50.7618 210.242 48.8914 210.242C47.0211 210.242 45.5049 211.759 45.5049 213.63C45.5049 215.5 47.0211 217.017 48.8914 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 217.017C59.8653 217.017 61.3815 215.5 61.3815 213.63C61.3815 211.759 59.8653 210.242 57.995 210.242C56.1246 210.242 54.6084 211.759 54.6084 213.63C54.6084 215.5 56.1246 217.017 57.995 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 217.017C68.9649 217.017 70.4811 215.5 70.4811 213.63C70.4811 211.759 68.9649 210.242 67.0946 210.242C65.2242 210.242 63.708 211.759 63.708 213.63C63.708 215.5 65.2242 217.017 67.0946 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 217.017C78.0679 217.017 79.5842 215.5 79.5842 213.63C79.5842 211.759 78.0679 210.242 76.1976 210.242C74.3273 210.242 72.811 211.759 72.811 213.63C72.811 215.5 74.3273 217.017 76.1976 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 217.017C87.1676 217.017 88.6838 215.5 88.6838 213.63C88.6838 211.759 87.1676 210.242 85.2972 210.242C83.4269 210.242 81.9106 211.759 81.9106 213.63C81.9106 215.5 83.4269 217.017 85.2972 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 217.017C123.573 217.017 125.09 215.5 125.09 213.63C125.09 211.759 123.573 210.242 121.703 210.242C119.833 210.242 118.316 211.759 118.316 213.63C118.316 215.5 119.833 217.017 121.703 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 217.017C132.676 217.017 134.193 215.5 134.193 213.63C134.193 211.759 132.676 210.242 130.806 210.242C128.936 210.242 127.419 211.759 127.419 213.63C127.419 215.5 128.936 217.017 130.806 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 217.017C141.776 217.017 143.292 215.5 143.292 213.63C143.292 211.759 141.776 210.242 139.906 210.242C138.035 210.242 136.519 211.759 136.519 213.63C136.519 215.5 138.035 217.017 139.906 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 217.017C150.876 217.017 152.392 215.5 152.392 213.63C152.392 211.759 150.876 210.242 149.005 210.242C147.135 210.242 145.619 211.759 145.619 213.63C145.619 215.5 147.135 217.017 149.005 217.017Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 217.017C159.979 217.017 161.495 215.5 161.495 213.63C161.495 211.759 159.979 210.242 158.108 210.242C156.238 210.242 154.722 211.759 154.722 213.63C154.722 215.5 156.238 217.017 158.108 217.017Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 217.017C169.078 217.017 170.594 215.5 170.594 213.63C170.594 211.759 169.078 210.242 167.208 210.242C165.338 210.242 163.821 211.759 163.821 213.63C163.821 215.5 165.338 217.017 167.208 217.017Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 217.017C178.181 217.017 179.697 215.5 179.697 213.63C179.697 211.759 178.181 210.242 176.311 210.242C174.441 210.242 172.924 211.759 172.924 213.63C172.924 215.5 174.441 217.017 176.311 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 217.017C187.281 217.017 188.797 215.5 188.797 213.63C188.797 211.759 187.281 210.242 185.41 210.242C183.54 210.242 182.024 211.759 182.024 213.63C182.024 215.5 183.54 217.017 185.41 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 217.017C196.384 217.017 197.901 215.5 197.901 213.63C197.901 211.759 196.384 210.242 194.514 210.242C192.644 210.242 191.127 211.759 191.127 213.63C191.127 215.5 192.644 217.017 194.514 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 217.017C205.483 217.017 207 215.5 207 213.63C207 211.759 205.483 210.242 203.613 210.242C201.743 210.242 200.227 211.759 200.227 213.63C200.227 215.5 201.743 217.017 203.613 217.017Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 226.156C5.25691 226.156 6.77313 224.639 6.77313 222.768C6.77313 220.897 5.25691 219.381 3.38656 219.381C1.51622 219.381 0 220.897 0 222.768C0 224.639 1.51622 226.156 3.38656 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 226.156C14.3565 226.156 15.8727 224.639 15.8727 222.768C15.8727 220.897 14.3565 219.381 12.4862 219.381C10.6158 219.381 9.09961 220.897 9.09961 222.768C9.09961 224.639 10.6158 226.156 12.4862 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 226.156C23.4595 226.156 24.9758 224.639 24.9758 222.768C24.9758 220.897 23.4595 219.381 21.5892 219.381C19.7189 219.381 18.2026 220.897 18.2026 222.768C18.2026 224.639 19.7189 226.156 21.5892 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 226.156C32.5592 226.156 34.0754 224.639 34.0754 222.768C34.0754 220.897 32.5592 219.381 30.6888 219.381C28.8185 219.381 27.3022 220.897 27.3022 222.768C27.3022 224.639 28.8185 226.156 30.6888 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 226.156C41.6627 226.156 43.1789 224.639 43.1789 222.768C43.1789 220.897 41.6627 219.381 39.7923 219.381C37.922 219.381 36.4058 220.897 36.4058 222.768C36.4058 224.639 37.922 226.156 39.7923 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 226.156C50.7618 226.156 52.278 224.639 52.278 222.768C52.278 220.897 50.7618 219.381 48.8914 219.381C47.0211 219.381 45.5049 220.897 45.5049 222.768C45.5049 224.639 47.0211 226.156 48.8914 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 226.156C59.8653 226.156 61.3815 224.639 61.3815 222.768C61.3815 220.897 59.8653 219.381 57.995 219.381C56.1246 219.381 54.6084 220.897 54.6084 222.768C54.6084 224.639 56.1246 226.156 57.995 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 226.156C68.9649 226.156 70.4811 224.639 70.4811 222.768C70.4811 220.897 68.9649 219.381 67.0946 219.381C65.2242 219.381 63.708 220.897 63.708 222.768C63.708 224.639 65.2242 226.156 67.0946 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 226.156C78.0679 226.156 79.5842 224.639 79.5842 222.768C79.5842 220.897 78.0679 219.381 76.1976 219.381C74.3273 219.381 72.811 220.897 72.811 222.768C72.811 224.639 74.3273 226.156 76.1976 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 226.156C87.1676 226.156 88.6838 224.639 88.6838 222.768C88.6838 220.897 87.1676 219.381 85.2972 219.381C83.4269 219.381 81.9106 220.897 81.9106 222.768C81.9106 224.639 83.4269 226.156 85.2972 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 226.156C123.573 226.156 125.09 224.639 125.09 222.768C125.09 220.897 123.573 219.381 121.703 219.381C119.833 219.381 118.316 220.897 118.316 222.768C118.316 224.639 119.833 226.156 121.703 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 226.156C132.676 226.156 134.193 224.639 134.193 222.768C134.193 220.897 132.676 219.381 130.806 219.381C128.936 219.381 127.419 220.897 127.419 222.768C127.419 224.639 128.936 226.156 130.806 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 226.156C141.776 226.156 143.292 224.639 143.292 222.768C143.292 220.897 141.776 219.381 139.906 219.381C138.035 219.381 136.519 220.897 136.519 222.768C136.519 224.639 138.035 226.156 139.906 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 226.156C150.876 226.156 152.392 224.639 152.392 222.768C152.392 220.897 150.876 219.381 149.005 219.381C147.135 219.381 145.619 220.897 145.619 222.768C145.619 224.639 147.135 226.156 149.005 226.156Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 226.156C159.979 226.156 161.495 224.639 161.495 222.768C161.495 220.897 159.979 219.381 158.108 219.381C156.238 219.381 154.722 220.897 154.722 222.768C154.722 224.639 156.238 226.156 158.108 226.156Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 226.156C169.078 226.156 170.594 224.639 170.594 222.768C170.594 220.897 169.078 219.381 167.208 219.381C165.338 219.381 163.821 220.897 163.821 222.768C163.821 224.639 165.338 226.156 167.208 226.156Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 226.156C178.181 226.156 179.697 224.639 179.697 222.768C179.697 220.897 178.181 219.381 176.311 219.381C174.441 219.381 172.924 220.897 172.924 222.768C172.924 224.639 174.441 226.156 176.311 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 226.156C187.281 226.156 188.797 224.639 188.797 222.768C188.797 220.897 187.281 219.381 185.41 219.381C183.54 219.381 182.024 220.897 182.024 222.768C182.024 224.639 183.54 226.156 185.41 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 226.156C196.384 226.156 197.901 224.639 197.901 222.768C197.901 220.897 196.384 219.381 194.514 219.381C192.644 219.381 191.127 220.897 191.127 222.768C191.127 224.639 192.644 226.156 194.514 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 226.156C205.483 226.156 207 224.639 207 222.768C207 220.897 205.483 219.381 203.613 219.381C201.743 219.381 200.227 220.897 200.227 222.768C200.227 224.639 201.743 226.156 203.613 226.156Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 235.296C5.25691 235.296 6.77313 233.78 6.77313 231.909C6.77313 230.038 5.25691 228.521 3.38656 228.521C1.51622 228.521 0 230.038 0 231.909C0 233.78 1.51622 235.296 3.38656 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 235.296C14.3565 235.296 15.8727 233.78 15.8727 231.909C15.8727 230.038 14.3565 228.521 12.4862 228.521C10.6158 228.521 9.09961 230.038 9.09961 231.909C9.09961 233.78 10.6158 235.296 12.4862 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 235.296C23.4595 235.296 24.9758 233.78 24.9758 231.909C24.9758 230.038 23.4595 228.521 21.5892 228.521C19.7189 228.521 18.2026 230.038 18.2026 231.909C18.2026 233.78 19.7189 235.296 21.5892 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 235.296C32.5592 235.296 34.0754 233.78 34.0754 231.909C34.0754 230.038 32.5592 228.521 30.6888 228.521C28.8185 228.521 27.3022 230.038 27.3022 231.909C27.3022 233.78 28.8185 235.296 30.6888 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 235.296C41.6627 235.296 43.1789 233.78 43.1789 231.909C43.1789 230.038 41.6627 228.521 39.7923 228.521C37.922 228.521 36.4058 230.038 36.4058 231.909C36.4058 233.78 37.922 235.296 39.7923 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 235.296C50.7618 235.296 52.278 233.78 52.278 231.909C52.278 230.038 50.7618 228.521 48.8914 228.521C47.0211 228.521 45.5049 230.038 45.5049 231.909C45.5049 233.78 47.0211 235.296 48.8914 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 235.296C59.8653 235.296 61.3815 233.78 61.3815 231.909C61.3815 230.038 59.8653 228.521 57.995 228.521C56.1246 228.521 54.6084 230.038 54.6084 231.909C54.6084 233.78 56.1246 235.296 57.995 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 235.296C68.9649 235.296 70.4811 233.78 70.4811 231.909C70.4811 230.038 68.9649 228.521 67.0946 228.521C65.2242 228.521 63.708 230.038 63.708 231.909C63.708 233.78 65.2242 235.296 67.0946 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 235.296C78.0679 235.296 79.5842 233.78 79.5842 231.909C79.5842 230.038 78.0679 228.521 76.1976 228.521C74.3273 228.521 72.811 230.038 72.811 231.909C72.811 233.78 74.3273 235.296 76.1976 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 235.296C87.1676 235.296 88.6838 233.78 88.6838 231.909C88.6838 230.038 87.1676 228.521 85.2972 228.521C83.4269 228.521 81.9106 230.038 81.9106 231.909C81.9106 233.78 83.4269 235.296 85.2972 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 235.296C123.573 235.296 125.09 233.78 125.09 231.909C125.09 230.038 123.573 228.521 121.703 228.521C119.833 228.521 118.316 230.038 118.316 231.909C118.316 233.78 119.833 235.296 121.703 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 235.296C132.676 235.296 134.193 233.78 134.193 231.909C134.193 230.038 132.676 228.521 130.806 228.521C128.936 228.521 127.419 230.038 127.419 231.909C127.419 233.78 128.936 235.296 130.806 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 235.296C141.776 235.296 143.292 233.78 143.292 231.909C143.292 230.038 141.776 228.521 139.906 228.521C138.035 228.521 136.519 230.038 136.519 231.909C136.519 233.78 138.035 235.296 139.906 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 235.296C150.876 235.296 152.392 233.78 152.392 231.909C152.392 230.038 150.876 228.521 149.005 228.521C147.135 228.521 145.619 230.038 145.619 231.909C145.619 233.78 147.135 235.296 149.005 235.296Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 235.296C159.979 235.296 161.495 233.78 161.495 231.909C161.495 230.038 159.979 228.521 158.108 228.521C156.238 228.521 154.722 230.038 154.722 231.909C154.722 233.78 156.238 235.296 158.108 235.296Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 235.296C169.078 235.296 170.594 233.78 170.594 231.909C170.594 230.038 169.078 228.521 167.208 228.521C165.338 228.521 163.821 230.038 163.821 231.909C163.821 233.78 165.338 235.296 167.208 235.296Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 235.296C178.181 235.296 179.697 233.78 179.697 231.909C179.697 230.038 178.181 228.521 176.311 228.521C174.441 228.521 172.924 230.038 172.924 231.909C172.924 233.78 174.441 235.296 176.311 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 235.296C187.281 235.296 188.797 233.78 188.797 231.909C188.797 230.038 187.281 228.521 185.41 228.521C183.54 228.521 182.024 230.038 182.024 231.909C182.024 233.78 183.54 235.296 185.41 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 235.296C196.384 235.296 197.901 233.78 197.901 231.909C197.901 230.038 196.384 228.521 194.514 228.521C192.644 228.521 191.127 230.038 191.127 231.909C191.127 233.78 192.644 235.296 194.514 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 235.296C205.483 235.296 207 233.78 207 231.909C207 230.038 205.483 228.521 203.613 228.521C201.743 228.521 200.227 230.038 200.227 231.909C200.227 233.78 201.743 235.296 203.613 235.296Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 244.437C5.25691 244.437 6.77313 242.92 6.77313 241.05C6.77313 239.179 5.25691 237.662 3.38656 237.662C1.51622 237.662 0 239.179 0 241.05C0 242.92 1.51622 244.437 3.38656 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 244.437C14.3565 244.437 15.8727 242.92 15.8727 241.05C15.8727 239.179 14.3565 237.662 12.4862 237.662C10.6158 237.662 9.09961 239.179 9.09961 241.05C9.09961 242.92 10.6158 244.437 12.4862 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 244.437C23.4595 244.437 24.9758 242.92 24.9758 241.05C24.9758 239.179 23.4595 237.662 21.5892 237.662C19.7189 237.662 18.2026 239.179 18.2026 241.05C18.2026 242.92 19.7189 244.437 21.5892 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 244.437C32.5592 244.437 34.0754 242.92 34.0754 241.05C34.0754 239.179 32.5592 237.662 30.6888 237.662C28.8185 237.662 27.3022 239.179 27.3022 241.05C27.3022 242.92 28.8185 244.437 30.6888 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 244.437C41.6627 244.437 43.1789 242.92 43.1789 241.05C43.1789 239.179 41.6627 237.662 39.7923 237.662C37.922 237.662 36.4058 239.179 36.4058 241.05C36.4058 242.92 37.922 244.437 39.7923 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 244.437C50.7618 244.437 52.278 242.92 52.278 241.05C52.278 239.179 50.7618 237.662 48.8914 237.662C47.0211 237.662 45.5049 239.179 45.5049 241.05C45.5049 242.92 47.0211 244.437 48.8914 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 244.437C59.8653 244.437 61.3815 242.92 61.3815 241.05C61.3815 239.179 59.8653 237.662 57.995 237.662C56.1246 237.662 54.6084 239.179 54.6084 241.05C54.6084 242.92 56.1246 244.437 57.995 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 244.437C68.9649 244.437 70.4811 242.92 70.4811 241.05C70.4811 239.179 68.9649 237.662 67.0946 237.662C65.2242 237.662 63.708 239.179 63.708 241.05C63.708 242.92 65.2242 244.437 67.0946 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 244.437C78.0679 244.437 79.5842 242.92 79.5842 241.05C79.5842 239.179 78.0679 237.662 76.1976 237.662C74.3273 237.662 72.811 239.179 72.811 241.05C72.811 242.92 74.3273 244.437 76.1976 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 244.437C87.1676 244.437 88.6838 242.92 88.6838 241.05C88.6838 239.179 87.1676 237.662 85.2972 237.662C83.4269 237.662 81.9106 239.179 81.9106 241.05C81.9106 242.92 83.4269 244.437 85.2972 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 244.437C96.2706 244.437 97.7868 242.92 97.7868 241.05C97.7868 239.179 96.2706 237.662 94.4002 237.662C92.5299 237.662 91.0137 239.179 91.0137 241.05C91.0137 242.92 92.5299 244.437 94.4002 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 244.437C105.37 244.437 106.886 242.92 106.886 241.05C106.886 239.179 105.37 237.662 103.5 237.662C101.629 237.662 100.113 239.179 100.113 241.05C100.113 242.92 101.629 244.437 103.5 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 244.437C114.474 244.437 115.99 242.92 115.99 241.05C115.99 239.179 114.474 237.662 112.603 237.662C110.733 237.662 109.217 239.179 109.217 241.05C109.217 242.92 110.733 244.437 112.603 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 244.437C123.573 244.437 125.09 242.92 125.09 241.05C125.09 239.179 123.573 237.662 121.703 237.662C119.833 237.662 118.316 239.179 118.316 241.05C118.316 242.92 119.833 244.437 121.703 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 244.437C132.676 244.437 134.193 242.92 134.193 241.05C134.193 239.179 132.676 237.662 130.806 237.662C128.936 237.662 127.419 239.179 127.419 241.05C127.419 242.92 128.936 244.437 130.806 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 244.437C141.776 244.437 143.292 242.92 143.292 241.05C143.292 239.179 141.776 237.662 139.906 237.662C138.035 237.662 136.519 239.179 136.519 241.05C136.519 242.92 138.035 244.437 139.906 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 244.437C150.876 244.437 152.392 242.92 152.392 241.05C152.392 239.179 150.876 237.662 149.005 237.662C147.135 237.662 145.619 239.179 145.619 241.05C145.619 242.92 147.135 244.437 149.005 244.437Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 244.437C159.979 244.437 161.495 242.92 161.495 241.05C161.495 239.179 159.979 237.662 158.108 237.662C156.238 237.662 154.722 239.179 154.722 241.05C154.722 242.92 156.238 244.437 158.108 244.437Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 244.437C169.078 244.437 170.594 242.92 170.594 241.05C170.594 239.179 169.078 237.662 167.208 237.662C165.338 237.662 163.821 239.179 163.821 241.05C163.821 242.92 165.338 244.437 167.208 244.437Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 244.437C178.181 244.437 179.697 242.92 179.697 241.05C179.697 239.179 178.181 237.662 176.311 237.662C174.441 237.662 172.924 239.179 172.924 241.05C172.924 242.92 174.441 244.437 176.311 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 244.437C187.281 244.437 188.797 242.92 188.797 241.05C188.797 239.179 187.281 237.662 185.41 237.662C183.54 237.662 182.024 239.179 182.024 241.05C182.024 242.92 183.54 244.437 185.41 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 244.437C196.384 244.437 197.901 242.92 197.901 241.05C197.901 239.179 196.384 237.662 194.514 237.662C192.644 237.662 191.127 239.179 191.127 241.05C191.127 242.92 192.644 244.437 194.514 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 244.437C205.483 244.437 207 242.92 207 241.05C207 239.179 205.483 237.662 203.613 237.662C201.743 237.662 200.227 239.179 200.227 241.05C200.227 242.92 201.743 244.437 203.613 244.437Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 253.58C5.25691 253.58 6.77313 252.063 6.77313 250.192C6.77313 248.321 5.25691 246.805 3.38656 246.805C1.51622 246.805 0 248.321 0 250.192C0 252.063 1.51622 253.58 3.38656 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 253.58C14.3565 253.58 15.8727 252.063 15.8727 250.192C15.8727 248.321 14.3565 246.805 12.4862 246.805C10.6158 246.805 9.09961 248.321 9.09961 250.192C9.09961 252.063 10.6158 253.58 12.4862 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 253.58C23.4595 253.58 24.9758 252.063 24.9758 250.192C24.9758 248.321 23.4595 246.805 21.5892 246.805C19.7189 246.805 18.2026 248.321 18.2026 250.192C18.2026 252.063 19.7189 253.58 21.5892 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 253.58C32.5592 253.58 34.0754 252.063 34.0754 250.192C34.0754 248.321 32.5592 246.805 30.6888 246.805C28.8185 246.805 27.3022 248.321 27.3022 250.192C27.3022 252.063 28.8185 253.58 30.6888 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 253.58C41.6627 253.58 43.1789 252.063 43.1789 250.192C43.1789 248.321 41.6627 246.805 39.7923 246.805C37.922 246.805 36.4058 248.321 36.4058 250.192C36.4058 252.063 37.922 253.58 39.7923 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 253.58C50.7618 253.58 52.278 252.063 52.278 250.192C52.278 248.321 50.7618 246.805 48.8914 246.805C47.0211 246.805 45.5049 248.321 45.5049 250.192C45.5049 252.063 47.0211 253.58 48.8914 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 253.58C59.8653 253.58 61.3815 252.063 61.3815 250.192C61.3815 248.321 59.8653 246.805 57.995 246.805C56.1246 246.805 54.6084 248.321 54.6084 250.192C54.6084 252.063 56.1246 253.58 57.995 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 253.58C68.9649 253.58 70.4811 252.063 70.4811 250.192C70.4811 248.321 68.9649 246.805 67.0946 246.805C65.2242 246.805 63.708 248.321 63.708 250.192C63.708 252.063 65.2242 253.58 67.0946 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 253.58C78.0679 253.58 79.5842 252.063 79.5842 250.192C79.5842 248.321 78.0679 246.805 76.1976 246.805C74.3273 246.805 72.811 248.321 72.811 250.192C72.811 252.063 74.3273 253.58 76.1976 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 253.58C87.1676 253.58 88.6838 252.063 88.6838 250.192C88.6838 248.321 87.1676 246.805 85.2972 246.805C83.4269 246.805 81.9106 248.321 81.9106 250.192C81.9106 252.063 83.4269 253.58 85.2972 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 253.58C96.2706 253.58 97.7868 252.063 97.7868 250.192C97.7868 248.321 96.2706 246.805 94.4002 246.805C92.5299 246.805 91.0137 248.321 91.0137 250.192C91.0137 252.063 92.5299 253.58 94.4002 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 253.58C105.37 253.58 106.886 252.063 106.886 250.192C106.886 248.321 105.37 246.805 103.5 246.805C101.629 246.805 100.113 248.321 100.113 250.192C100.113 252.063 101.629 253.58 103.5 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 253.58C114.474 253.58 115.99 252.063 115.99 250.192C115.99 248.321 114.474 246.805 112.603 246.805C110.733 246.805 109.217 248.321 109.217 250.192C109.217 252.063 110.733 253.58 112.603 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 253.58C123.573 253.58 125.09 252.063 125.09 250.192C125.09 248.321 123.573 246.805 121.703 246.805C119.833 246.805 118.316 248.321 118.316 250.192C118.316 252.063 119.833 253.58 121.703 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 253.58C132.676 253.58 134.193 252.063 134.193 250.192C134.193 248.321 132.676 246.805 130.806 246.805C128.936 246.805 127.419 248.321 127.419 250.192C127.419 252.063 128.936 253.58 130.806 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 253.58C141.776 253.58 143.292 252.063 143.292 250.192C143.292 248.321 141.776 246.805 139.906 246.805C138.035 246.805 136.519 248.321 136.519 250.192C136.519 252.063 138.035 253.58 139.906 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 253.58C150.876 253.58 152.392 252.063 152.392 250.192C152.392 248.321 150.876 246.805 149.005 246.805C147.135 246.805 145.619 248.321 145.619 250.192C145.619 252.063 147.135 253.58 149.005 253.58Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 253.58C159.979 253.58 161.495 252.063 161.495 250.192C161.495 248.321 159.979 246.805 158.108 246.805C156.238 246.805 154.722 248.321 154.722 250.192C154.722 252.063 156.238 253.58 158.108 253.58Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 253.58C169.078 253.58 170.594 252.063 170.594 250.192C170.594 248.321 169.078 246.805 167.208 246.805C165.338 246.805 163.821 248.321 163.821 250.192C163.821 252.063 165.338 253.58 167.208 253.58Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 253.58C178.181 253.58 179.697 252.063 179.697 250.192C179.697 248.321 178.181 246.805 176.311 246.805C174.441 246.805 172.924 248.321 172.924 250.192C172.924 252.063 174.441 253.58 176.311 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 253.58C187.281 253.58 188.797 252.063 188.797 250.192C188.797 248.321 187.281 246.805 185.41 246.805C183.54 246.805 182.024 248.321 182.024 250.192C182.024 252.063 183.54 253.58 185.41 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 253.58C196.384 253.58 197.901 252.063 197.901 250.192C197.901 248.321 196.384 246.805 194.514 246.805C192.644 246.805 191.127 248.321 191.127 250.192C191.127 252.063 192.644 253.58 194.514 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 253.58C205.483 253.58 207 252.063 207 250.192C207 248.321 205.483 246.805 203.613 246.805C201.743 246.805 200.227 248.321 200.227 250.192C200.227 252.063 201.743 253.58 203.613 253.58Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 262.72C5.25691 262.72 6.77313 261.204 6.77313 259.333C6.77313 257.462 5.25691 255.945 3.38656 255.945C1.51622 255.945 0 257.462 0 259.333C0 261.204 1.51622 262.72 3.38656 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 262.72C14.3565 262.72 15.8727 261.204 15.8727 259.333C15.8727 257.462 14.3565 255.945 12.4862 255.945C10.6158 255.945 9.09961 257.462 9.09961 259.333C9.09961 261.204 10.6158 262.72 12.4862 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 262.72C23.4595 262.72 24.9758 261.204 24.9758 259.333C24.9758 257.462 23.4595 255.945 21.5892 255.945C19.7189 255.945 18.2026 257.462 18.2026 259.333C18.2026 261.204 19.7189 262.72 21.5892 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 262.72C32.5592 262.72 34.0754 261.204 34.0754 259.333C34.0754 257.462 32.5592 255.945 30.6888 255.945C28.8185 255.945 27.3022 257.462 27.3022 259.333C27.3022 261.204 28.8185 262.72 30.6888 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 262.72C41.6627 262.72 43.1789 261.204 43.1789 259.333C43.1789 257.462 41.6627 255.945 39.7923 255.945C37.922 255.945 36.4058 257.462 36.4058 259.333C36.4058 261.204 37.922 262.72 39.7923 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 262.72C50.7618 262.72 52.278 261.204 52.278 259.333C52.278 257.462 50.7618 255.945 48.8914 255.945C47.0211 255.945 45.5049 257.462 45.5049 259.333C45.5049 261.204 47.0211 262.72 48.8914 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 262.72C59.8653 262.72 61.3815 261.204 61.3815 259.333C61.3815 257.462 59.8653 255.945 57.995 255.945C56.1246 255.945 54.6084 257.462 54.6084 259.333C54.6084 261.204 56.1246 262.72 57.995 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 262.72C68.9649 262.72 70.4811 261.204 70.4811 259.333C70.4811 257.462 68.9649 255.945 67.0946 255.945C65.2242 255.945 63.708 257.462 63.708 259.333C63.708 261.204 65.2242 262.72 67.0946 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 262.72C78.0679 262.72 79.5842 261.204 79.5842 259.333C79.5842 257.462 78.0679 255.945 76.1976 255.945C74.3273 255.945 72.811 257.462 72.811 259.333C72.811 261.204 74.3273 262.72 76.1976 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 262.72C87.1676 262.72 88.6838 261.204 88.6838 259.333C88.6838 257.462 87.1676 255.945 85.2972 255.945C83.4269 255.945 81.9106 257.462 81.9106 259.333C81.9106 261.204 83.4269 262.72 85.2972 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 262.72C96.2706 262.72 97.7868 261.204 97.7868 259.333C97.7868 257.462 96.2706 255.945 94.4002 255.945C92.5299 255.945 91.0137 257.462 91.0137 259.333C91.0137 261.204 92.5299 262.72 94.4002 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 262.72C105.37 262.72 106.886 261.204 106.886 259.333C106.886 257.462 105.37 255.945 103.5 255.945C101.629 255.945 100.113 257.462 100.113 259.333C100.113 261.204 101.629 262.72 103.5 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 262.72C114.474 262.72 115.99 261.204 115.99 259.333C115.99 257.462 114.474 255.945 112.603 255.945C110.733 255.945 109.217 257.462 109.217 259.333C109.217 261.204 110.733 262.72 112.603 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 262.72C123.573 262.72 125.09 261.204 125.09 259.333C125.09 257.462 123.573 255.945 121.703 255.945C119.833 255.945 118.316 257.462 118.316 259.333C118.316 261.204 119.833 262.72 121.703 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 262.72C132.676 262.72 134.193 261.204 134.193 259.333C134.193 257.462 132.676 255.945 130.806 255.945C128.936 255.945 127.419 257.462 127.419 259.333C127.419 261.204 128.936 262.72 130.806 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 262.72C141.776 262.72 143.292 261.204 143.292 259.333C143.292 257.462 141.776 255.945 139.906 255.945C138.035 255.945 136.519 257.462 136.519 259.333C136.519 261.204 138.035 262.72 139.906 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 262.72C150.876 262.72 152.392 261.204 152.392 259.333C152.392 257.462 150.876 255.945 149.005 255.945C147.135 255.945 145.619 257.462 145.619 259.333C145.619 261.204 147.135 262.72 149.005 262.72Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 262.72C159.979 262.72 161.495 261.204 161.495 259.333C161.495 257.462 159.979 255.945 158.108 255.945C156.238 255.945 154.722 257.462 154.722 259.333C154.722 261.204 156.238 262.72 158.108 262.72Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 262.72C169.078 262.72 170.594 261.204 170.594 259.333C170.594 257.462 169.078 255.945 167.208 255.945C165.338 255.945 163.821 257.462 163.821 259.333C163.821 261.204 165.338 262.72 167.208 262.72Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 262.72C178.181 262.72 179.697 261.204 179.697 259.333C179.697 257.462 178.181 255.945 176.311 255.945C174.441 255.945 172.924 257.462 172.924 259.333C172.924 261.204 174.441 262.72 176.311 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 262.72C187.281 262.72 188.797 261.204 188.797 259.333C188.797 257.462 187.281 255.945 185.41 255.945C183.54 255.945 182.024 257.462 182.024 259.333C182.024 261.204 183.54 262.72 185.41 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 262.72C196.384 262.72 197.901 261.204 197.901 259.333C197.901 257.462 196.384 255.945 194.514 255.945C192.644 255.945 191.127 257.462 191.127 259.333C191.127 261.204 192.644 262.72 194.514 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 262.72C205.483 262.72 207 261.204 207 259.333C207 257.462 205.483 255.945 203.613 255.945C201.743 255.945 200.227 257.462 200.227 259.333C200.227 261.204 201.743 262.72 203.613 262.72Z"
            fill="#DAFDA3"
        />
        <path
            d="M3.38656 271.861C5.25691 271.861 6.77313 270.344 6.77313 268.473C6.77313 266.603 5.25691 265.086 3.38656 265.086C1.51622 265.086 0 266.603 0 268.473C0 270.344 1.51622 271.861 3.38656 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 271.861C14.3565 271.861 15.8727 270.344 15.8727 268.473C15.8727 266.603 14.3565 265.086 12.4862 265.086C10.6158 265.086 9.09961 266.603 9.09961 268.473C9.09961 270.344 10.6158 271.861 12.4862 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 271.861C23.4595 271.861 24.9758 270.344 24.9758 268.473C24.9758 266.603 23.4595 265.086 21.5892 265.086C19.7189 265.086 18.2026 266.603 18.2026 268.473C18.2026 270.344 19.7189 271.861 21.5892 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 271.861C32.5592 271.861 34.0754 270.344 34.0754 268.473C34.0754 266.603 32.5592 265.086 30.6888 265.086C28.8185 265.086 27.3022 266.603 27.3022 268.473C27.3022 270.344 28.8185 271.861 30.6888 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 271.861C41.6627 271.861 43.1789 270.344 43.1789 268.473C43.1789 266.603 41.6627 265.086 39.7923 265.086C37.922 265.086 36.4058 266.603 36.4058 268.473C36.4058 270.344 37.922 271.861 39.7923 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 271.861C50.7618 271.861 52.278 270.344 52.278 268.473C52.278 266.603 50.7618 265.086 48.8914 265.086C47.0211 265.086 45.5049 266.603 45.5049 268.473C45.5049 270.344 47.0211 271.861 48.8914 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 271.861C59.8653 271.861 61.3815 270.344 61.3815 268.473C61.3815 266.603 59.8653 265.086 57.995 265.086C56.1246 265.086 54.6084 266.603 54.6084 268.473C54.6084 270.344 56.1246 271.861 57.995 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 271.861C68.9649 271.861 70.4811 270.344 70.4811 268.473C70.4811 266.603 68.9649 265.086 67.0946 265.086C65.2242 265.086 63.708 266.603 63.708 268.473C63.708 270.344 65.2242 271.861 67.0946 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 271.861C78.0679 271.861 79.5842 270.344 79.5842 268.473C79.5842 266.603 78.0679 265.086 76.1976 265.086C74.3273 265.086 72.811 266.603 72.811 268.473C72.811 270.344 74.3273 271.861 76.1976 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 271.861C87.1676 271.861 88.6838 270.344 88.6838 268.473C88.6838 266.603 87.1676 265.086 85.2972 265.086C83.4269 265.086 81.9106 266.603 81.9106 268.473C81.9106 270.344 83.4269 271.861 85.2972 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 271.861C96.2706 271.861 97.7868 270.344 97.7868 268.473C97.7868 266.603 96.2706 265.086 94.4002 265.086C92.5299 265.086 91.0137 266.603 91.0137 268.473C91.0137 270.344 92.5299 271.861 94.4002 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 271.861C105.37 271.861 106.886 270.344 106.886 268.473C106.886 266.603 105.37 265.086 103.5 265.086C101.629 265.086 100.113 266.603 100.113 268.473C100.113 270.344 101.629 271.861 103.5 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 271.861C114.474 271.861 115.99 270.344 115.99 268.473C115.99 266.603 114.474 265.086 112.603 265.086C110.733 265.086 109.217 266.603 109.217 268.473C109.217 270.344 110.733 271.861 112.603 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 271.861C123.573 271.861 125.09 270.344 125.09 268.473C125.09 266.603 123.573 265.086 121.703 265.086C119.833 265.086 118.316 266.603 118.316 268.473C118.316 270.344 119.833 271.861 121.703 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 271.861C132.676 271.861 134.193 270.344 134.193 268.473C134.193 266.603 132.676 265.086 130.806 265.086C128.936 265.086 127.419 266.603 127.419 268.473C127.419 270.344 128.936 271.861 130.806 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M139.906 271.861C141.776 271.861 143.292 270.344 143.292 268.473C143.292 266.603 141.776 265.086 139.906 265.086C138.035 265.086 136.519 266.603 136.519 268.473C136.519 270.344 138.035 271.861 139.906 271.861Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 271.861C159.979 271.861 161.495 270.344 161.495 268.473C161.495 266.603 159.979 265.086 158.108 265.086C156.238 265.086 154.722 266.603 154.722 268.473C154.722 270.344 156.238 271.861 158.108 271.861Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 271.861C169.078 271.861 170.594 270.344 170.594 268.473C170.594 266.603 169.078 265.086 167.208 265.086C165.338 265.086 163.821 266.603 163.821 268.473C163.821 270.344 165.338 271.861 167.208 271.861Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 271.861C178.181 271.861 179.697 270.344 179.697 268.473C179.697 266.603 178.181 265.086 176.311 265.086C174.441 265.086 172.924 266.603 172.924 268.473C172.924 270.344 174.441 271.861 176.311 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 271.861C187.281 271.861 188.797 270.344 188.797 268.473C188.797 266.603 187.281 265.086 185.41 265.086C183.54 265.086 182.024 266.603 182.024 268.473C182.024 270.344 183.54 271.861 185.41 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 271.861C196.384 271.861 197.901 270.344 197.901 268.473C197.901 266.603 196.384 265.086 194.514 265.086C192.644 265.086 191.127 266.603 191.127 268.473C191.127 270.344 192.644 271.861 194.514 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M203.613 271.861C205.483 271.861 207 270.344 207 268.473C207 266.603 205.483 265.086 203.613 265.086C201.743 265.086 200.227 266.603 200.227 268.473C200.227 270.344 201.743 271.861 203.613 271.861Z"
            fill="#DAFDA3"
        />
        <path
            d="M12.4862 280.999C14.3565 280.999 15.8727 279.483 15.8727 277.612C15.8727 275.741 14.3565 274.225 12.4862 274.225C10.6158 274.225 9.09961 275.741 9.09961 277.612C9.09961 279.483 10.6158 280.999 12.4862 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M21.5892 280.999C23.4595 280.999 24.9758 279.483 24.9758 277.612C24.9758 275.741 23.4595 274.225 21.5892 274.225C19.7189 274.225 18.2026 275.741 18.2026 277.612C18.2026 279.483 19.7189 280.999 21.5892 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M30.6888 280.999C32.5592 280.999 34.0754 279.483 34.0754 277.612C34.0754 275.741 32.5592 274.225 30.6888 274.225C28.8185 274.225 27.3022 275.741 27.3022 277.612C27.3022 279.483 28.8185 280.999 30.6888 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M39.7923 280.999C41.6627 280.999 43.1789 279.483 43.1789 277.612C43.1789 275.741 41.6627 274.225 39.7923 274.225C37.922 274.225 36.4058 275.741 36.4058 277.612C36.4058 279.483 37.922 280.999 39.7923 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M48.8914 280.999C50.7618 280.999 52.278 279.483 52.278 277.612C52.278 275.741 50.7618 274.225 48.8914 274.225C47.0211 274.225 45.5049 275.741 45.5049 277.612C45.5049 279.483 47.0211 280.999 48.8914 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M57.995 280.999C59.8653 280.999 61.3815 279.483 61.3815 277.612C61.3815 275.741 59.8653 274.225 57.995 274.225C56.1246 274.225 54.6084 275.741 54.6084 277.612C54.6084 279.483 56.1246 280.999 57.995 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M67.0946 280.999C68.9649 280.999 70.4811 279.483 70.4811 277.612C70.4811 275.741 68.9649 274.225 67.0946 274.225C65.2242 274.225 63.708 275.741 63.708 277.612C63.708 279.483 65.2242 280.999 67.0946 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M76.1976 280.999C78.0679 280.999 79.5842 279.483 79.5842 277.612C79.5842 275.741 78.0679 274.225 76.1976 274.225C74.3273 274.225 72.811 275.741 72.811 277.612C72.811 279.483 74.3273 280.999 76.1976 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M85.2972 280.999C87.1676 280.999 88.6838 279.483 88.6838 277.612C88.6838 275.741 87.1676 274.225 85.2972 274.225C83.4269 274.225 81.9106 275.741 81.9106 277.612C81.9106 279.483 83.4269 280.999 85.2972 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M94.4002 280.999C96.2706 280.999 97.7868 279.483 97.7868 277.612C97.7868 275.741 96.2706 274.225 94.4002 274.225C92.5299 274.225 91.0137 275.741 91.0137 277.612C91.0137 279.483 92.5299 280.999 94.4002 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M103.5 280.999C105.37 280.999 106.886 279.483 106.886 277.612C106.886 275.741 105.37 274.225 103.5 274.225C101.629 274.225 100.113 275.741 100.113 277.612C100.113 279.483 101.629 280.999 103.5 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M112.603 280.999C114.474 280.999 115.99 279.483 115.99 277.612C115.99 275.741 114.474 274.225 112.603 274.225C110.733 274.225 109.217 275.741 109.217 277.612C109.217 279.483 110.733 280.999 112.603 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M121.703 280.999C123.573 280.999 125.09 279.483 125.09 277.612C125.09 275.741 123.573 274.225 121.703 274.225C119.833 274.225 118.316 275.741 118.316 277.612C118.316 279.483 119.833 280.999 121.703 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M130.806 280.999C132.676 280.999 134.193 279.483 134.193 277.612C134.193 275.741 132.676 274.225 130.806 274.225C128.936 274.225 127.419 275.741 127.419 277.612C127.419 279.483 128.936 280.999 130.806 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M149.005 280.999C150.876 280.999 152.392 279.483 152.392 277.612C152.392 275.741 150.876 274.225 149.005 274.225C147.135 274.225 145.619 275.741 145.619 277.612C145.619 279.483 147.135 280.999 149.005 280.999Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M158.108 280.999C159.979 280.999 161.495 279.483 161.495 277.612C161.495 275.741 159.979 274.225 158.108 274.225C156.238 274.225 154.722 275.741 154.722 277.612C154.722 279.483 156.238 280.999 158.108 280.999Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M167.208 280.999C169.078 280.999 170.594 279.483 170.594 277.612C170.594 275.741 169.078 274.225 167.208 274.225C165.338 274.225 163.821 275.741 163.821 277.612C163.821 279.483 165.338 280.999 167.208 280.999Z"
            fill="#DAFDA3"
        />
        <path
            className="animated-part"
            d="M176.311 280.999C178.181 280.999 179.697 279.483 179.697 277.612C179.697 275.741 178.181 274.225 176.311 274.225C174.441 274.225 172.924 275.741 172.924 277.612C172.924 279.483 174.441 280.999 176.311 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M185.41 280.999C187.281 280.999 188.797 279.483 188.797 277.612C188.797 275.741 187.281 274.225 185.41 274.225C183.54 274.225 182.024 275.741 182.024 277.612C182.024 279.483 183.54 280.999 185.41 280.999Z"
            fill="#DAFDA3"
        />
        <path
            d="M194.514 280.999C196.384 280.999 197.901 279.483 197.901 277.612C197.901 275.741 196.384 274.225 194.514 274.225C192.644 274.225 191.127 275.741 191.127 277.612C191.127 279.483 192.644 280.999 194.514 280.999Z"
            fill="#DAFDA3"
        />
    </svg>
);

export default LockIcon;
