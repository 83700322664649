import BaseButton from "components/baseButton/baseButton";
import React, { useEffect, useState } from "react";
import "./errorRefreshPage.scss";
import { StringResources } from "utils/language/languageResource";
import { useTranslation } from "react-i18next";


const refreshPage = () => (window.location as any).reload(true);

export default function ErrorRefreshPage() {
    const [restartInterval, setRestartInterval] = useState(10);

    const { t } = useTranslation();
    useEffect(() => {
        const interval = setInterval(() => {
            setRestartInterval((t) => {
                const newInterval = t - 1;
                if (newInterval < 0) {
                    refreshPage()
                }
                return newInterval;
            })

        }, 1000);
        return () => clearInterval(interval);
    }, [setRestartInterval]);
    return <div className="error-refresh-page">
        <div className="content">
            <h3 className="large-text">{`${t(StringResources.errorBoundary.title)}`}</h3>
            <h6 className="small-text">{`${t(StringResources.errorBoundary.subtitle, { restartInterval })}`}</h6>
            <BaseButton
                className="btn"
                handleClick={refreshPage}
                text={`${t(StringResources.errorBoundary.btnText)}`}
                size={"medium"}
                styleType={"solid"} />
        </div>
    </div>
}

