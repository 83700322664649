import { IconSVGProps } from "./types";

const FileIcon = ({ height = 17, width = 12 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            opacity="0.4"
            d="M8 4.35938V0.359375H1.5C0.671562 0.359375 0 1.03094 0 1.85938V14.8594C0 15.6875 0.671562 16.3594 1.5 16.3594H10.5C11.3284 16.3594 12 15.6878 12 14.8594V4.35938H8ZM8.5 13.3594H3.5C3.225 13.3594 3 13.1344 3 12.8594C3 12.5844 3.225 12.3594 3.5 12.3594H8.5C8.77612 12.3594 9 12.5833 9 12.8594C9 13.1355 8.775 13.3594 8.5 13.3594ZM8.5 11.3594H3.5C3.225 11.3594 3 11.1344 3 10.8594C3 10.5844 3.225 10.3594 3.5 10.3594H8.5C8.77612 10.3594 9 10.5833 9 10.8594C9 11.1355 8.775 11.3594 8.5 11.3594ZM8.5 9.35938H3.5C3.225 9.35938 3 9.13437 3 8.85938C3 8.58438 3.225 8.35938 3.5 8.35938H8.5C8.775 8.35938 9 8.58438 9 8.85938C9 9.13437 8.775 9.35938 8.5 9.35938Z"
            fill="#002649"
        />
    </svg>
);

export default FileIcon;
