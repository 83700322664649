import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseEmployeesRoute } from "pages/employees/api/employeeApi";
import { ICreateEmployeeRequest, IDeacticateEmployeeRequest, IEditEmployeeRequest, IGetApiKeyPasswordRequest, IGetApiKeyPasswordResponse } from "redux/models/employeeModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const employeeCreateEmployee = createAsyncThunk<any, ICreateEmployeeRequest>(
    "employee/createEmployee",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseEmployeesRoute}/create`,
            method: "POST",
            data: request,
        });

        return response.data;
    }
);

export const employeeEditEmployee = createAsyncThunk<any, IEditEmployeeRequest>(
    "employee/editEmployee",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseEmployeesRoute}/edit`,
            method: "POST",
            data: request,
        });

        return response.data;
    }
);

export const employeeDeactivateEmployee = createAsyncThunk<any, IDeacticateEmployeeRequest>(
    "employee/deactivateEmployee",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseEmployeesRoute}/deactivate`,
            method: "POST",
            data: request,
        });

        return response.data;
    }
);

export const employeeGetApiKey = createAsyncThunk<IGetApiKeyPasswordResponse, IGetApiKeyPasswordRequest>(
    "employee/getApiKeyPassword",
    async request => {
        const response = await executeAxiosRequestWithRefresh({
            url: `${baseEmployeesRoute}/api-key-password`,
            method: "GET",
            params: request,
        });

        return response.data;
    }
)
