export enum SupportedLanguageEnum {
    Icelandic = 1,
    English = 2,
}

export enum LoginStateEnum {
    Uninitialized = 0,
    Pending = 1,
    LoggedIn = 2,
    Unauthorized = 3,
    Expired = 4,
}

export enum ModalTypeEnum {
    None = 0,
    ExportModal = 1,
    DeleteModal = 2,
    CreateEmployee = 3,
    EditEmployee = 4,
    SetSalesTargetModal = 5,
    DetailsModal = 6,
    UploadDisputeModal = 7,
    FeedbackFormModal = 8,
    SpecialMenuColumnModal = 9,
    SpecialMenuFilterModal = 10,
    RefundTransaction = 11,
    Confirm = 12,
    DisputeDetails = 13,
    RequestTerminal = 14,
    CreatePaymentLink = 15,
    AddNewEcomTheme = 16,
    CreateOrEditApiKey = 17,
    CreateOrEditWebhooks = 18,
}

export enum ModalActionButtonTypeEnum {
    Confirm = 0,
    Delete = 1,
}

export enum FilterTypeEnum {
    TextFilter = 1,
    DateFilter = 2,
    DropdownFilter = 3,
    MultiSelectFilter = 4,
    NumberFilter = 5,
    TotalSubtotal = 6,
    DropdownClearableFilter = 7,
    DateRangeFilter = 10,
}

export enum DetailsRowTypeFormatterEnum {
    Text = 1,
    Currency = 2,
    Date = 3,
    DateTime = 4,
    Drill = 5,
    Id = 6,
    List = 7,
    Boolean = 8,
    ValueName = 9,
    ValueNameList = 10,
    TransactionRefundLabel = 11,
    IsFinancialLabel = 12,
}

export enum DetailSectionEnum {
    SettlementBreakdown = 1,
    AgreementsQuickLinks = 2,
    PaymentsSettlementBreakdown = 3,
    SettlementBeaches = 4,
    EmployeePermissions = 5,
    EmployeeApiKey = 6,
    TransactionRefundBreakdown = 7,
    BatchDetails = 8,
}

export enum DetailHeaderEnum {
    None = 0,
    Default = 1,
    Employees = 2,
    Transactions = 4,
    ElectronicDocuments = 5,
    Authorizations = 6,
    Settlements = 7,
    Payments = 8,
}

export enum ExportType {
    CSV = "1",
    XLSX = "2",
    PDF = "3",
}

export enum ExportColumn {
    All = "0",
    VisibleColumns = "1",
}

export enum PermissionType {
    AgreementsRead = 1,
    BatchesRead = 2,
    BucketsRead = 3,
    DashboardRead = 4,
    PaymentsRead = 5,
    SettlementsRead = 6,
    TransactionsRead = 7,
    AuthorizationsRead = 8,
    AuthorizationsWrite = 9,
    UsersRead = 10,
    UsersWrite = 11,
    DisputesRead = 12,
    TerminalsRead = 13,
    StatementsRead = 14,
    DisputesWrite = 15,
    TransactionsWrite = 16,
    TerminalRequestsWrite = 17,
    ApiKeysReadWrite = 18,
    PayByLinkReadWrite = 19,
    EcomThemeWrite = 20,
    EcomThemeRead = 21,
    WebhooksReadWrite = 22,
}

export enum RoleType {
    Admin = 1,
    User = 2,
    ApiKey = 3,
}

export enum DropdownFilterNameEnum {
    AgreementNumbers = "agreementNumbers",
    CurrencyIds = "currencyIds",
    CardIssuers = "cardIssuers",
    CardTypes = "cardTypes",
    Periods = "periods",
    SettlementTypes = "settlementTypes",
    UserStatuses = "userStatuses",
    CardEntryModeTypes = "cardEntryModeTypes",
    AuthenticationMethodTypes = "authenticationMethodTypes",
    AuthorizationMethodTypes = "authorizationMethodTypes",
    ContractCurrencyIds = "contractCurrencyIds",
    SettlementStatuses = "settlementStatuses",
    DisputeType = "disputeType",
    PayByLinkStatuses = "payByLinkStatuses",
}

export enum RefundType {
    Full = 1,
    Partial = 2,
}

export enum TransactionType {
    Refund = 1,
    Purchase = 2,
}

export enum AuthorizationStatus {
    Approved = 1,
    Declined = 2,
}

export enum StatementNotificationType {
    Notification = 1,
    PdfToEmail = 2,
    Off = 3,
}

export enum DisputeNotificationType {
    Notification = 1,
    Off = 2,
}

export enum SettlementCardTypeRule {
    All = 1,
    Credit = 2,
    Debit = 3,
}

export enum DisputeStatusEnum {
    NotResponded = 1,
    Responded = 2,
    Represented = 3,
    Declined = 4,
    Closed = 5,
    Info = 6,
    ChargebackReversed = 7,
    IncomingFee = 8,
    IncomingFunds = 9,
    CaseLost = 10,
    CaseWon = 11,
    OutgoingFee = 12,
    OutgoingFunds = 13,
}

export enum EmployeeRole {
    Admin = 1,
    User = 2,
}

export enum AnnouncementType {
    Info = 1,
    Warning = 2,
    Error = 3,
}

export enum ContractStatusEnum {
    Active = 1,
    Inactive = 2,
}

export enum PayByLinkStatusEnum {
    Active = 1,
    Expired = 2,
    Disabled = 3,
}

export enum TerminalRequestDeliveryType {
    Pickup = 1,
    Send = 2,
}

export enum ShopperInfoFieldEnum {
    Ssn = 1,
    FullName = 2,
    Address = 3,
    PostCode = 4,
    City = 5,
    Country = 6,
    Telephone = 7,
    EmailAddress = 8,
    Comments = 9,
}

export enum ShopperInfoRequirementType {
    Hidden = 1,
    Optional = 2,
    Required = 3,
}

export enum UserStatusEnum {
    Active = 1,
    Disabled = 2,
}

export enum LogoStatusEnum {
    Upload = 1,
    Remove = 2,
    NoChange = 3,
}

export enum WebhooksCellType {
    ApiKey = 1,
    Hmac = 2,
    Contracts = 3,
}

export enum WebhookStatus {
    Active = 1,
    Disabled = 2,
}
