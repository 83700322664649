import { IconSVGProps } from "./types";

const DisputesIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M10 9.375C10.5176 9.375 10.9375 8.95508 10.9375 8.4375V4.0625C10.9375 3.54492 10.5176 3.125 10 3.125C9.48242 3.125 9.0625 3.54492 9.0625 4.0625V8.4375C9.0625 8.95703 9.48438 9.375 10 9.375ZM10 10.625C9.30977 10.625 8.75 11.1848 8.75 11.875C8.75 12.5652 9.30977 13.125 10 13.125C10.6902 13.125 11.25 12.5652 11.25 11.875C11.25 11.1836 10.6914 10.625 10 10.625Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M17.4648 0H2.46484C1.08789 0 -0.0351562 1.12305 -0.0351562 2.46484V13.6797C-0.0351562 15.0566 1.08789 16.1445 2.46484 16.1445H6.21484V19.3906C6.21484 19.7715 6.6543 19.9941 6.96172 19.7695L11.8406 16.1094H17.4656C18.8426 16.1094 19.9656 14.9863 19.9656 13.6445V2.46484C19.9648 1.12305 18.875 0 17.4648 0ZM9.0625 4.0625C9.0625 3.54492 9.48438 3.125 10 3.125C10.5156 3.125 10.9375 3.54492 10.9375 4.0625V8.4375C10.9375 8.95508 10.5176 9.375 10 9.375C9.48242 9.375 9.0625 8.95703 9.0625 8.4375V4.0625ZM10 13.125C9.30977 13.125 8.75 12.5652 8.75 11.875C8.75 11.1848 9.30977 10.625 10 10.625C10.6902 10.625 11.25 11.1848 11.25 11.875C11.25 12.5664 10.6914 13.125 10 13.125Z"
            fill="#002649"
        />
    </svg>
);

export default DisputesIcon;
