import { useState, FocusEvent, ChangeEvent } from "react";
import BaseInputWrapper from "components/baseInputWrapper/baseInputWrapper";

type InputELement = Omit<React.InputHTMLAttributes<HTMLTextAreaElement>, "size">;

type BaseTextArea = InputELement & {
    label?: React.ReactNode;
    labelRef?: React.RefObject<HTMLLabelElement>;
    inputRef?: React.RefObject<HTMLTextAreaElement>;
    disabled?: boolean;
    name?: string;
    autocomplete?: string;
    rows: number;
    readonly?: boolean;
    invalid?: string | undefined | boolean;
};

const BaseTextArea: React.FC<BaseTextArea> = ({
    value,
    id,
    label,
    onChange,
    name,
    autocomplete,
    readonly,
    invalid,
    onBlur,
    rows,
    placeholder,
    maxLength,
}) => {
    const [focus, setFocus] = useState<boolean>(false);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLTextAreaElement>) => {
        onBlur && onBlur(e);
        setFocus(false);
    };

    return (
        <BaseInputWrapper label={label} value={value} invalid={invalid}>
            <div className="textarea-wrapper">
                <textarea
                    name={name}
                    className="form-control"
                    onChange={handleChange}
                    onFocus={() => setFocus(true)}
                    onBlur={handleBlur}
                    rows={rows}
                    value={value ?? ""}
                    placeholder={focus ? placeholder : ""}
                    id={id}
                    autoComplete={autocomplete}
                    readOnly={readonly}
                    maxLength={maxLength}
                />
            </div>
        </BaseInputWrapper>
    );
};

export default BaseTextArea;
