import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import { StringResources } from "utils/language/languageResource";
import { IGetEmployeeResponseListData } from "../api/employeeModels";

import "./employeePermissions.scss";

const EmployeePermissions = () => {
    const activeRowData: IGetEmployeeResponseListData = useSelector((state: RootState) => state.report.activeRow);

    if (!activeRowData) {
        return <></>;
    }

    return (
        <>
            <div className="employee-permissions">
                <div className="employee-permissions__title">{`${t(StringResources.pages.employees.permissions)}`}</div>
            </div>
            <div className="employee-permissions__row">
                {activeRowData.permissions.map((x, index) => {
                    return (
                        <div
                            key={`employee-permissions_${index}_${x.value}`}
                            className="employee-permissions__row-item"
                        >
                            {x.name}
                        </div>
                    );
                })}
            </div>
        </>
    );
};

export default EmployeePermissions;
