import { IconSVGProps } from "./types";

const BatchesIcon = ({ height = 20, width = 20 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M5.57971 6.25C5.57971 5.90625 5.85535 5.625 6.19565 5.625H13.587C13.9257 5.625 14.2029 5.90625 14.2029 6.25C14.2029 6.59375 13.9257 6.875 13.587 6.875H6.19565C5.85535 6.875 5.57971 6.59375 5.57971 6.25ZM5.57971 13.75C5.57971 13.4062 5.85535 13.125 6.19565 13.125H13.587C13.9257 13.125 14.2029 13.4062 14.2029 13.75C14.2029 14.0938 13.9257 14.375 13.587 14.375H6.19565C5.85535 14.375 5.57971 14.0938 5.57971 13.75ZM13.587 9.375C13.9257 9.375 14.2029 9.65625 14.2029 10C14.2029 10.3438 13.9257 10.625 13.587 10.625H6.19565C5.85535 10.625 5.57971 10.3438 5.57971 10C5.57971 9.65625 5.85535 9.375 6.19565 9.375H13.587Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M3.03779 0.0857813C3.36578 -0.0671875 3.75113 -0.0125547 4.02523 0.225703L5.57971 1.57773L7.13496 0.225703C7.48143 -0.0752344 7.98958 -0.0752344 8.33605 0.225703L9.8913 1.57773L11.4466 0.225703C11.793 -0.0752344 12.3012 -0.0752344 12.6476 0.225703L14.2029 1.57773L15.7582 0.225703C16.0315 -0.0125547 16.4164 -0.0671875 16.7437 0.0857813C17.0709 0.238789 17.2826 0.571484 17.2826 0.9375V19.0625C17.2826 19.4297 17.0709 19.7617 16.7437 19.9141C16.4164 20.0664 16.0315 20.0117 15.7582 19.7734L14.2029 18.4219L12.6476 19.7734C12.3012 20.0742 11.793 20.0742 11.4466 19.7734L9.8913 18.4219L8.33605 19.7734C7.98958 20.0742 7.48143 20.0742 7.13496 19.7734L5.57971 18.4219L4.02523 19.7734C3.75113 20.0117 3.36578 20.0664 3.03779 19.9141C2.71004 19.7617 2.5 19.4297 2.5 19.0625V0.9375C2.5 0.571484 2.71004 0.23875 3.03779 0.0857813ZM6.19565 5.625C5.85534 5.625 5.57971 5.90625 5.57971 6.25C5.57971 6.59375 5.85534 6.875 6.19565 6.875H13.587C13.9257 6.875 14.2029 6.59375 14.2029 6.25C14.2029 5.90625 13.9257 5.625 13.587 5.625H6.19565ZM6.19565 14.375H13.587C13.9257 14.375 14.2029 14.0938 14.2029 13.75C14.2029 13.4063 13.9257 13.125 13.587 13.125H6.19565C5.85534 13.125 5.57971 13.4063 5.57971 13.75C5.57971 14.0938 5.85534 14.375 6.19565 14.375ZM6.19565 9.375C5.85534 9.375 5.57971 9.65625 5.57971 10C5.57971 10.3438 5.85534 10.625 6.19565 10.625H13.587C13.9257 10.625 14.2029 10.3438 14.2029 10C14.2029 9.65625 13.9257 9.375 13.587 9.375H6.19565Z"
            fill="#002649"
        />
    </svg>
);

export default BatchesIcon;
