import { IconSVGProps } from "./types";

const LogoutIcon = ({ height = 16, width = 16 }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.775 8.54687L11.2719 12.7969C11.0539 13.0024 10.7344 13.0586 10.4594 12.9404C10.1843 12.8218 10.0059 12.5507 10.0059 12.2514L10.0031 10.0295L6.00307 10.0295C5.45025 10.0295 5.00244 9.58165 5.00244 9.02946V7.02946C5.00244 6.47728 5.45057 6.02946 6.00307 6.02946L10.0031 6.0295L10.0059 3.7795C10.0059 3.48018 10.1843 3.20918 10.4594 3.09043C10.7345 2.97228 11.0541 3.02843 11.2719 3.234L15.775 7.484C16.075 7.7375 16.075 8.2625 15.775 8.54687Z"
            fill="#002649"
        />
        <path
            opacity="0.4"
            d="M3 15H5C5.55312 15 6 14.5531 6 14C6 13.4469 5.55312 13 5 13H3C2.44781 13 2 12.5522 2 12V4C2 3.44781 2.44781 3 3 3H5C5.55312 3 6 2.55219 6 2C6 1.44781 5.55312 1 5 1H3C1.34312 1 0 2.34312 0 4V12C0 13.6562 1.34312 15 3 15Z"
            fill="#002649"
        />
    </svg>
);

export default LogoutIcon;
