import { t } from "i18next";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "redux/store";
import { StringResources } from "utils/language/languageResource";
import { IGetEmployeeResponseListData } from "../api/employeeModels";
import BaseButton from "components/baseButton/baseButton";
import { employeeGetApiKey } from "redux/actions/employeeActions";
import { toast } from "react-toastify";
import { useState } from "react";
import { RoleType } from "utils/enums";
import "./employeeApiKey.scss";

const EmployeeApiKey = () => {
    const activeRowData: IGetEmployeeResponseListData = useSelector((state: RootState) => state.report.activeRow);

    const [apiKey, setApiKey] = useState("");

    const dispatch = useAppDispatch();

    if (!activeRowData || activeRowData.employeeRole.value != RoleType.ApiKey) {
        return <></>;
    }

    const handleClick = async () => {
        const model = {
            employeeId: activeRowData.employeeId,
        };

        try {
            const apiKeyValue = await dispatch(employeeGetApiKey(model)).unwrap();
            setApiKey(apiKeyValue.apiKey);
        } catch (error: any) {
            toast.error(t(StringResources.pages.employees.edit.errorApiKeyMsg).toString());
        }
    };

    return (
        <>
            <div className="employee-api-key">
                <div className="employee-api-key__title">{`${t(StringResources.pages.employees.apiKey)}`}</div>
            </div>
            <div className="row employee-api-key__row">
                <div className="col employee-api-key__row-item">
                    <BaseButton
                        handleClick={handleClick}
                        text={`${t(StringResources.pages.employees.details.showApiKey)}`}
                        styleType="line"
                        size="small"
                        disabled={apiKey != ""}
                    />
                </div>
                {apiKey != "" && <div className="col employee-api-key__row-item">{apiKey}</div>}
            </div>
        </>
    );
};

export default EmployeeApiKey;
