import EditUserIcon from "components/icons/editUserIcon";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { clearActiveRow, setQueryFilters } from "redux/reducers/reportReducer";
import { RootState, useAppDispatch } from "redux/store";
import { useModalManagement } from "utils/customHooks";
import { ModalTypeEnum, PermissionType } from "utils/enums";
import { StringResources } from "utils/language/languageResource";
import variables from "../../../style/colors.module.scss";
import "./employeeHeader.scss";
import BaseButton from "components/baseButton/baseButton";

const EmployeeHeader = () => {
    const dispatch = useAppDispatch();
    const modalManagement = useModalManagement();
    const queryFilters = useSelector((state: RootState) => state.report.queryParams.filters);
    const activeRowData = useSelector((state: RootState) => state.report.activeRow);
    const userPermissions = useSelector((state: RootState) => state.user.permissions);

    if (!activeRowData) {
        return <></>;
    }

    const handleClick = async () => {
        const modalData = await modalManagement.openModal({ modalType: ModalTypeEnum.EditEmployee });
        if (modalData) {
            // we are adding random query params to invalidate redux cache, and initiate new call to backend for up-to-date data
            dispatch(setQueryFilters({ ...queryFilters, random: new Date().toISOString() }));
            // we are clearing active row because if right panel is opened and table re-fetched it will not update the content of the right panel (drawer)
            dispatch(clearActiveRow());
        }
    };

    const hasUsersWritePermission = userPermissions.some(x => x === PermissionType[PermissionType.UsersWrite]);

    return (
        <div className="employee-header report-details__title">
            <div className="report-details__title--label">{`${t(StringResources.pages.employees.details.title)}`}</div>
            {hasUsersWritePermission && (
                <div className="report-details__title--action">
                    <BaseButton
                        handleClick={handleClick}
                        text={`${t(StringResources.pages.employees.details.edit)}`}
                        styleType="line"
                        leftIcon={<EditUserIcon color={variables.colorPrimaryAlpha} />}
                        size="small"
                    />
                </div>
            )}
        </div>
    );
};

export default EmployeeHeader;
