import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseDisputesRoute } from "pages/newDisputes/api/disputesApi";
import { IFilterTypesData, IUploadDisputeRequest } from "redux/models/globalModels";
import { executeAxiosRequestWithRefresh } from "redux/services";

export const getLastPendingDispute = createAsyncThunk("dispute/getLastPendingDispute", async request => {
    const response = await executeAxiosRequestWithRefresh({
        url: `${baseDisputesRoute}/get-last-pending-dispute`,
        method: "GET",
    });

    return response.data;
});

export const getFilterTypesThunk = createAsyncThunk<IFilterTypesData>("report/getFilterTypes", async request => {
    const response = await executeAxiosRequestWithRefresh({
        url: "/api/filterTypes",
        method: "GET",
    });
    return response.data;
});
