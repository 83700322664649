import { IconSVGProps } from "./types";

const CancelInputIcon = ({ height = 20, width = 20, color }: IconSVGProps) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
        <path
            d="M14.2747 3.60909C14.7953 3.0885 14.7953 2.24306 14.2747 1.72247C13.7541 1.20188 12.9086 1.20188 12.388 1.72247L8.0026 6.11208L3.61299 1.72664C3.09241 1.20605 2.24697 1.20605 1.72638 1.72664C1.20579 2.24723 1.20579 3.09266 1.72638 3.61325L6.11599 7.9987L1.73054 12.3883C1.20995 12.9089 1.20995 13.7543 1.73054 14.2749C2.25113 14.7955 3.09657 14.7955 3.61716 14.2749L8.0026 9.88531L12.3922 14.2708C12.9128 14.7913 13.7582 14.7913 14.2788 14.2708C14.7994 13.7502 14.7994 12.9047 14.2788 12.3841L9.88922 7.9987L14.2747 3.60909Z"
            fill="#002649"
        />
    </svg>
);

export default CancelInputIcon;
