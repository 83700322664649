import { IconSVGProps } from "./types";

const TrashIcon = ({ height = 20, width = 20, color }: IconSVGProps) => (
    <svg
        width={width}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M9.78386 0C10.1424 0 10.4713 0.214 10.6314 0.552813L10.8447 1H13.6894C14.2139 1 14.6377 1.44781 14.6377 2C14.6377 2.55219 14.2139 3 13.6894 3H2.31054C1.78694 3 1.3623 2.55219 1.3623 2C1.3623 1.44781 1.78694 1 2.31054 1H5.15527L5.36862 0.552813C5.52864 0.214 5.85756 0 6.21611 0H9.78386Z" fill={color} />
        <path opacity="0.4" d="M2.40576 3H13.5942L12.9706 14.5875C12.9269 15.3812 12.3151 16 11.575 16H4.42492C3.68398 16 3.07182 15.3812 3.02899 14.5875L2.40576 3Z" fill={color} />
    </svg>
);

export default TrashIcon;
